import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

export const getSEO = (routeName) => {
    let seo = {
        keywords: "meetings,livion,livion meetings,cws"
    };

    switch (routeName) {
        case 'Master.HomePage':
            seo.title = CommonHelper.GetTrans("common.pagetitle.public.homepage");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Master.ProductList':
            seo.title = CommonHelper.GetTrans("common.pagetitle.public.productlist");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Master.Product':
            seo.title = CommonHelper.GetTrans("common.pagetitle.public.product");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Master.Login':
            seo.title = CommonHelper.GetTrans("common.pagetitle.public.login");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Slave.Product':
            seo.title = CommonHelper.GetTrans("common.pagetitle.mobile.product");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Slave.Controller':
            seo.title = CommonHelper.GetTrans("common.pagetitle.mobile.controller");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;


        case 'Slave.HomePage':
            seo.title = CommonHelper.GetTrans("common.pagetitle.mobile.homepage");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Admin.Unauthorized':
            seo.title = CommonHelper.GetTrans("common.pagetitle.unauthorized");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        case 'Admin':
            seo.title = CommonHelper.GetTrans("common.pagetitle.admin.main");
            seo.keywords += "";
            seo.description = "CWS Meetings";
            break;

        default:
            break;
    }

    return seo;
}