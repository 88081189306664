import * as React from 'react';
import ListContext from './ListContext';

const ListContextProvider = ({ value, children }) => (
    <ListContext.Provider value={value}>
        {/* <ListFilterContext.Provider value={usePickFilterContext(value)}>
            <ListSortContext.Provider value={usePickSortContext(value)}>
                <ListPaginationContext.Provider
                    value={usePickPaginationContext(value)}
                > */}
                    {children}
                {/* </ListPaginationContext.Provider>
            </ListSortContext.Provider>
        </ListFilterContext.Provider> */}
    </ListContext.Provider>
);

export default ListContextProvider;
