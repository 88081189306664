/* eslint-disable no-unused-vars */
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-livion-blue pb-6 pt-5 pt-md-8">
          {/* Header container */}
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
