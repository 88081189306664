/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from 'react-router';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import { TeamsContext, teamsTabStyles, TabNavBar, rolesEnum, } from "libs/teams-tab";

import {
  Grid, Flex, Segment, Text, Loader, cardsContainerBehavior, Button, Divider,
} from '@fluentui/react-northstar'

import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from 'office-ui-fabric-react';

let myContext = null;

const customClass = mergeStyleSets({
  cardContainer: {
    minHeight: 120,
    '& > div:first-child:hover': {
      cursor: 'pointer',
      backgroundColor: '#f3f3f3',
    }
  },
  icon: {
    fontSize: 20,
    marginBottom: '0 !important',
  }
});

class InCallHomePage extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      callId: null,
    };
  }

  componentDidMount() {
    let callId = null;

    if (this.props.match.params) {
      if (this.props.match.params.callId) {
        callId = this.props.match.params.callId;
      }
    }

    this.setState({ callId }, this.getLessonSurvey)
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  getLessonSurvey = () => {
    let { callId } = this.state;

    if (callId) {
      this.setState({ loading: true }, async () => {
        const { teamChannel, dataProviders } = myContext;

        let result = await dataProviders.bot.getOne('surveyLesson', { id: callId, addMsToken: true, teamChannelId: teamChannel.id });

        if (result && result.data && result.data.success) {
          this.setState({ activeSurvey: result.data.data, loading: false });
        }
        else {
          if (result && result.data && result.data.error) {
            ToastHelper.Warn(result.data.error);
          }
          this.setState({ activeSurvey: null, loading: false });
        }
      });
    }
  }

  buildCards = () => {
    let { callId, activeSurvey } = this.state;

    const cards = [
      {
        id: "school_register",
        title: "PRESENZE",
        icon: <Icon iconName="UserFollowed" className={customClass.icon} />,
        onClick: () => myContext.navigate(`schoolRegister/${callId}`),
        active: [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      }
    ]
    
    if (activeSurvey) {
      cards.push({
        id: "surveys",
        title: <span>CONTINUA<br/>SONDAGGIO</span>,
        icon: <Icon iconName="SurveyQuestions" className={customClass.icon} />,
        onClick: () => myContext.navigate(`survey/${activeSurvey.id}/${callId}`),
        active: myContext.settings.surveysEnabled && [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      })
    }
    else{
      cards.push({
        id: "surveys",
        title: "SONDAGGI",
        icon: <Icon iconName="SurveyQuestions" className={customClass.icon} />,
        onClick: () => myContext.navigate(`surveysChoice/${callId}`),
        active: myContext.settings.surveysEnabled && [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      })
    }

    return cards;
  }

  render() {
    let { callId } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          return (
            <>
              <TabNavBar />
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext ?
                      <Loader /> :
                      <>
                        <Grid accessibility={cardsContainerBehavior} columns={6}>
                          {
                            callId ? (
                              this.buildCards().filter(card => card !== null && card.active).map(card => (
                                <div key={card.id} style={teamsTabStyles.cardContainer} className={customClass.cardContainer} onClick={card.onClick}>
                                  <Flex gap="gap.small" hAlign="center" vAlign="center" style={teamsTabStyles.cardNew} column fill>
                                    <Flex.Item>
                                      {card.icon}
                                    </Flex.Item>
                                    <Flex.Item>
                                      <Button content={card.title} text primary />
                                    </Flex.Item>
                                  </Flex>
                                </div>
                              ))
                            ) :
                              (
                                <Text size="large" content="Questa app può essere utilizzata solo durante un meeting monitorato" align="center" />
                            )
                          }
                        </Grid>
                      </>
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default withRouter(InCallHomePage);