import React, { useContext, useEffect, useState, useCallback } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { ToastHelper } from 'components/Common/Helper/Helper';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { Users } from 'libs/microsoft-graph-service';

import { TeamsContext, teamsTabStyles, callStateEnum, ConfirmDialog, PopupWithButton, ButtonTooltip } from "libs/teams-tab";

import {
    Flex, List, Loader, Button, SplitButton, Text, Card, cardChildrenFocusableBehavior, Status,
    QuestionCircleIcon, PlayIcon, BanIcon, AttendeeIcon, TenantPersonalIcon, MeetingTimeIcon,
    AcceptIcon, CallIcon, CallEndIcon, ExcelIcon, TrashCanIcon, EyeIcon, ApprovalsAppbarIcon, 
    CallMissedLineIcon, CallRecordingIcon, CloseIcon, SyncIcon, InfoIcon,
} from '@fluentui/react-northstar'

import { useInterval } from "libs/admin-dashboard";

const ContinueCallButton = ({ callsTrackings, content, onSelected }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    function onOpenChange(e, { open }) {
        setOpen(open);
    }

    function onMenuItemClick(e, { index }) {
        setSelected(callsTrackings[index]);
    }

    const getDialogButton = (callToContinue, callsToReset, onConfirm, isOpen) => {
        let dialogHeader = "Azioni sulle chiamate";
        const items = callsToReset.filter(rc => rc !== null).map((rc, key) => {
            return { key, media: <TrashCanIcon />, header: `Iniziata alle: ${moment(rc.startedAt).format('HH:mm')}` }
        });

        items.push({ key: callToContinue.callId, media: <ApprovalsAppbarIcon />, header: `Iniziata alle: ${moment(callToContinue.startedAt).format('HH:mm')}` });

        let dialogContent = <List key={callToContinue.callId} items={items} />

        return <ConfirmDialog
            key={callToContinue.callId}
            header={dialogHeader}
            content={dialogContent}
            isOpen={isOpen}
            onConfirmCallback={() => { onConfirm(callToContinue); }}
            onCancelCallback={() => { setSelected(null) }}
        />
    }

    const callsToReset = selected ? callsTrackings.filter(rc => rc !== null && rc.callId !== selected.callId) : [];

    return (
        <>
            <SplitButton
                menu={
                    callsTrackings.map((callsTracking, key) => {
                        return {
                            key: key,
                            content: `Iniziata alle: ${moment(callsTracking.startedAt).format('HH:mm')}`,
                        }
                    })
                }
                button={{ content }}
                primary
                onOpenChange={onOpenChange}
                onMainButtonClick={() => setOpen(!open)}
                open={open}
                onMenuItemClick={onMenuItemClick}
            />
            {selected && getDialogButton(selected, callsToReset, onSelected, selected !== null)}
        </>
    )
}

const DeleteCallsButton = ({ callsToReset, event, onConfirm, content, onlyIcon, tooltip }) => {
    let enabled = callsToReset && callsToReset.length > 0;
    let dialogHeader = null;
    let dialogContent = null;
    let items = [];

    if (enabled) {
        dialogHeader = "Azioni sulle chiamate";
        if (event) {
            items.push({ key: 'delete', media: <TrashCanIcon />, header: event.subject, content: `${moment(event.startTime).format('HH:mm:ss')} - ${moment(event.endTime).format('HH:mm:ss')}` });
        }
        else {
            items = callsToReset.filter(rc => rc !== null).map((rc, key) => {
                return { key, media: <TrashCanIcon />, header: `Iniziata alle: ${moment(rc.startedAt).format('HH:mm')}` }
            });
        }
        items.push({ key: 'info', media: <InfoIcon />, header: 'Verranno rimossi anche eventuali sondaggi attivati durante la riunione.' });
        dialogContent = <List items={items} />
    }

    let triggerButton;

    if (onlyIcon) {
        triggerButton = <ButtonTooltip circular primary icon={<TrashCanIcon />} tooltip={tooltip} />
    }
    else {
        triggerButton = <Button content={content} icon={<TrashCanIcon />} iconPosition="before" disabled={!enabled} />
    }   

    return <ConfirmDialog header={dialogHeader} content={dialogContent} triggerButton={triggerButton} onConfirmCallback={onConfirm} />
}

const TerminateOrCancelCallButton = ({ runningCalls, event, onTerminate, onCancel, disabled }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    function onOpenChange(e, { open }) { setOpen(open); }
    function onMenuItemClick(e, { index }) { setSelected('cancel'); }
    function onMainButtonClick() { setSelected('terminate'); }

    let callTracking = runningCalls && runningCalls.length === 1 ? runningCalls[0] : null;

    const getDialogButton = (type) => {
        let header;
        let action;
        const items = [{ key: callTracking.callId, header: event.subject, content: `Iniziata alle: ${moment(callTracking.startedAt).format('HH:mm')}` }];

        switch (type) {
            case 'cancel':
                header = "Conferma la cancellazione del monitoraggio?";
                items.push({ key: 'info', media: <InfoIcon />, header: 'Verranno rimossi anche eventuali sondaggi attivati durante la riunione.' });
                action = () => onCancel(runningCalls);
                break;
            case 'terminate':
                header = "Conferma la fine del monitoraggio?";
                action = () => onTerminate(callTracking);
                break;
            default:
                header = ""
                action = null;
                break;
        }

        let content = <List key={callTracking.callId} items={items} />
        
        return <ConfirmDialog
            key={callTracking.callId}
            header={header}
            content={content}
            isOpen={type !== null}
            onConfirmCallback={() => action()}
            onCancelCallback={() => { setSelected(null) }}
        />
    }

    return (
        <>
            <SplitButton
                menu={[{ key: 'cancel', icon: <BanIcon />, content: <Text content="Annulla" /> }]}
                button={{ icon: <CallEndIcon />, content: "Termina" }}
                primary
                onOpenChange={onOpenChange}
                onMainButtonClick={onMainButtonClick}
                open={open}
                onMenuItemClick={onMenuItemClick}
                disabled={disabled || callTracking === null}
            />
            {selected && getDialogButton(selected)}
        </>
    )
}

const downloadReport = async (teamsContext, callId) => {
    let result = await teamsContext.dataProviders.api.getFile(`getcalls/getxlsx/${callId}/all`);
    if(result && result.blob){
        setTimeout(saveAs(result.blob, result.filename), 300);
    }
}

const ActionButtons = ({ event, eventStatus, runningCalls, completedCalls, startMeeting, endMeeting, continueRunningCall, deleteCalls }) => {
    const teamsContext = useContext(TeamsContext);

    let canStartMeeting = runningCalls.length === 0 && completedCalls.length === 0;
    let canTerminateOrCancelMeeting = event && runningCalls.length === 1 && eventStatus === callStateEnum.Established;

    let actionButtons = null;

    switch (eventStatus) {
        case callStateEnum.Stalled:
        case callStateEnum.RunningMore: 
            actionButtons = <>
                <ContinueCallButton callsTrackings={runningCalls} content="Riprendi" onSelected={continueRunningCall} />
                <DeleteCallsButton callsToReset={runningCalls} content="Resetta" onConfirm={() => deleteCalls(runningCalls)} />
            </>;
            break;
        case callStateEnum.Completed:
            actionButtons = completedCalls.length === 1 && <>
                <ButtonTooltip circular primary icon={<ExcelIcon />} tooltip='Download Report' onClick={() => downloadReport(teamsContext, completedCalls[0].callId)} />
                <ButtonTooltip circular primary icon={<EyeIcon />} tooltip='Dettaglio monitoraggio' onClick={() => teamsContext.navigate(`callDetails/${completedCalls[0].callId}`)} />
                <DeleteCallsButton onlyIcon tooltip='Cancella monitoraggio' event={event} onConfirm={() => deleteCalls(completedCalls)} callsToReset={completedCalls} />
            </>;
            break;
        case callStateEnum.TooOld:
            actionButtons = <Text weight="bold" align="center" content="Evento non monitorato" />;
            break;
        case callStateEnum.TooNew:
            actionButtons = <Text weight="bold" align="center" content="Evento futuro non monitorabile" />;
            break;
        default:
            var isLoading = eventStatus === callStateEnum.Establishing || eventStatus === callStateEnum.Restarting;
            actionButtons = <>
                <Button content="Avvia" primary icon={<PlayIcon />} iconPosition="before" loading={isLoading} onClick={() => startMeeting()} disabled={!canStartMeeting} />
                <TerminateOrCancelCallButton runningCalls={runningCalls} completedCalls={completedCalls} event={event} onTerminate={endMeeting} onCancel={deleteCalls} disabled={!canTerminateOrCancelMeeting} />
            </>;
            break;
    }
    return actionButtons;
}

export const EventCard = ({ index, eventDetail, startOfDay, endOfDay, teamId, TeamChannelId, ...unhandledProps }) => {
    const teamsContext = useContext(TeamsContext)
    const { msTeamsContext, teamChannel, dataProviders } = teamsContext;
    
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [eventStatus, setEventStatus] = useStateWithCallbackLazy(null);
    
    const [organizer, setOrganizer] = useState(null);
    const [event, setEvent] = useState(null);
    const [runningCalls, setRunningCalls] = useState([]);
    const [completedCalls, setCompletedCalls] = useState([]);

    const getOrganizer = async () => {
        let organizerAttendees = eventDetail.msTeamEvent.attendees.filter(a => a !== null && a.emailAddress.address !== eventDetail.msTeamEvent.organizer.emailAddress.address);

        const organizer = await Users.Get(organizerAttendees[0].emailAddress.address, false).catch(e => {
            if (e.authError) { console.error(e.errorMessage); }
        });

        setOrganizer(organizer);
    }

    const getEvent = () => {
        setLoading(true, async () => {
            let newEventStatus = callStateEnum.Unknown;

            const filter = { eventId: eventDetail.msTeamEvent.id };
            const filterDate = { startTime: { from: startOfDay.toISOString(), to: endOfDay.toISOString() }, endTime: { from: startOfDay.toISOString(), to: endOfDay.toISOString() } };
            const expandFilterDate = `$filter=startedAt gt ${startOfDay.toISOString()} and startedAt lt ${endOfDay.toISOString()}`
            const expand = `callsTrackings(${expandFilterDate})`;

            let result = await dataProviders.api.getList('msevents', { filter, filterDate, expand });

            if (result && result.data.length > 0) {
                if (result.data.length === 0) { newEventStatus = callStateEnum.Available; }
                else if (result.data.length > 0) {
                    let currentEvent = result.data[0];
                    let allRunningCalls = currentEvent.callsTrackings ? currentEvent.callsTrackings.filter(ct => ct !== null && ct.endedAt === null) : [];
                    let allCompletedCalls = currentEvent.callsTrackings ? currentEvent.callsTrackings.filter(ct => ct !== null && ct.endedAt !== null) : [];

                    setEvent(currentEvent)
                    setRunningCalls(allRunningCalls);
                    setCompletedCalls(allCompletedCalls);

                    if (allRunningCalls && allRunningCalls.length > 0) {
                        newEventStatus = allRunningCalls.length === 1 ? callStateEnum.Establishing : callStateEnum.RunningMore;
                    }
                    else if (allCompletedCalls && allCompletedCalls.length > 0) {
                        newEventStatus = allCompletedCalls.length === 1 ? callStateEnum.Completed : callStateEnum.CompletedMore;
                    }
                    else {
                        newEventStatus = callStateEnum.Available;
                    }
                }
            }
            else {
                await teamsContext.isAvailable('bot');
                newEventStatus = callStateEnum.Available;
            }

            if (newEventStatus === callStateEnum.Available) {
                const startOfToday = moment().startOf('day');
                const now = moment();
                const endOfToday = moment().endOf('day');
                const eventEnd = moment(eventDetail.msTeamEvent.end.dateTime);

                if (eventEnd < now) {
                    newEventStatus = callStateEnum.TooOld;
                }

                if (eventEnd > endOfToday) {
                    newEventStatus = callStateEnum.TooNew;
                }
            }

            setLoading(false, () => {
                setEventStatus(newEventStatus);
            });
        });
    }

    const startMeeting = (callId = null) => {
        const prevStatus = eventStatus;
        setEventStatus(callId ? callStateEnum.Restarting : callStateEnum.Establishing, async () => {
            const { groupId, teamName, channelId, channelName } = msTeamsContext;

            const lessonRequestData = {
                name: eventDetail.msTeamEvent.subject,
                time: new Date(),
                msEvent: eventDetail.msTeamEvent,
                organizer: organizer,
                teamId: groupId,
                teamName: teamName,
                teamChannelId: channelId,
                teamChannelName: channelName,
            };

            if (callId){
                lessonRequestData.callId = callId;
            }

            let result = await dataProviders.bot.create('startLesson', { data: lessonRequestData, addMsToken: true, teamChannelId: teamChannel.id });

            if (result && result.data && result.data.success) {
                getEvent();
            }
            else {
                if (result.data.error){
                    ToastHelper.Warn(result.data.error);
                }
                setEventStatus(prevStatus)
            }
        })
    }

    const endMeeting = (callTracking) => {
        if (callTracking) {
            const prevStatus = eventStatus;
            setEventStatus(callStateEnum.Terminating, async () => {
                let result = await dataProviders.bot.delete('endLesson', { id: callTracking.callId, addMsToken: true, teamChannelId: teamChannel.id });
                if (result && result.data) { getEvent(); } else { setEventStatus(prevStatus) }
            })
        }
    }

    const continueRunningCall = (callToContinue) => {
        const prevStatus = eventStatus;
        if (callToContinue) {
            const callsToReset = runningCalls.filter(rc => rc !== null && rc.callId !== callToContinue.callId).map(rc => rc.callId);
            
            if (callsToReset.length > 0) {
                setEventStatus(callStateEnum.Terminating, async () => {
                    let resetLessonsResult = await dataProviders.bot.create('deleteLessons', { data: callsToReset, addMsToken: true, teamChannelId: teamChannel.id });
                    if (resetLessonsResult && resetLessonsResult.data && resetLessonsResult.data.success) {
                        startMeeting(callToContinue.callId);
                    }
                    else {
                        setEventStatus(prevStatus)
                    }
                })
            }
            else {
                console.info("NOTHING TO REMOVE", callsToReset);
                startMeeting(callToContinue.callId);
            }
        }
    }

    const deleteCalls = async (callsToDelete) => {
        if (callsToDelete && Array.isArray(callsToDelete) && callsToDelete.length > 0) {
            const callsToReset = callsToDelete.filter(rc => rc !== null).map(rc=> rc.callId);

            setEventStatus(callStateEnum.Terminating, async () => {
                let resetLessonsResult = await dataProviders.bot.create('deleteLessons', { data: callsToReset, addMsToken: true, teamChannelId: teamChannel.id });
                getEvent();
            })
        }
    }

    useEffect(() => {
        async function doAsync() {
            await getOrganizer();
            getEvent();
        }
        doAsync()
    }, []);

    const getRunningEventStatus = useCallback(async isCancelled => {
        try {
            if (runningCalls && runningCalls.length === 1) {
                const statusEnabled = [
                    callStateEnum.Available,
                    callStateEnum.Completed,
                    callStateEnum.RunningMore,
                    callStateEnum.CompletedMore,
                    callStateEnum.UnknownFutureValue,
                    callStateEnum.Established,
                    callStateEnum.Establishing,
                    callStateEnum.Terminating,
                    null
                ];

                console.log(eventStatus)
                if (!statusEnabled.includes(eventStatus)) {
                    console.log(event, runningCalls, callsStartedButBotDied)
                    let callsStartedButBotDied = event && runningCalls.length > 0 && (eventStatus === callStateEnum.NotFound || eventStatus === callStateEnum.RunningMore);
                    if (callsStartedButBotDied) {
                        setEventStatus(callStateEnum.Stalled);
                    }
                    return;
                }

                let result = await dataProviders.bot.getOne('status', { id: runningCalls[0].callId });

                // check for cancellation after each await to prevent unnecessary actions
                if (isCancelled()) return;

                if (!result || !result.data) {
                    // throw here so error handling is DRY
                    throw new Error("ERRORE");
                }

                if (isCancelled()) return;

                if (result && result.data) {
                    if (eventStatus === callStateEnum.Establishing && result.data === callStateEnum.NotFound && runningCalls.length === 0){
                        setTimeout(() => {
                            setEventStatus(callStateEnum.Establishing)
                        }, 2500);
                    }
                    else{
                        setEventStatus(result.data)
                    }
                }
            }
            else {
                return;
            }
        } catch (err) {
            console.error('Fetch Error:', err);
        }
    }, [setEventStatus]);

    useInterval(getRunningEventStatus, 5000, true);

    const eventInfoItems = [
        {
            key: 1,
            media: <TenantPersonalIcon />,
            header: eventDetail.organizer.displayName,
            content: eventDetail.organizer.mail,
        },
        {
            key: 2,
            media: <MeetingTimeIcon />,
            header: "Ultima modifica",
            content: moment(eventDetail.msTeamEvent.lastModifiedDateTime).format('DD/MM/YYYY HH:mm'),
        },
    ]

    if (eventDetail.participants) {
        eventInfoItems.push(
            {
                key: 3,
                media: <AttendeeIcon />,
                header: "Partecipanti",
                content: eventDetail.participants.length,
            }
        )
    }

    const startTime = moment(eventDetail.msTeamEvent.start.dateTime).format('DD/MM/YYYY HH:mm');
    const endTime = moment(eventDetail.msTeamEvent.end.dateTime).format('DD/MM/YYYY HH:mm');

    return (
        <div style={teamsTabStyles.cardContainer}>
            <Card id={`event_${index}`} centered accessibility={cardChildrenFocusableBehavior} aria-roledescription="event card" styles={teamsTabStyles.cardBordered} {...unhandledProps} >
                <Card.Header>
                    <Text weight="bold" align="center">
                        {eventDetail.msTeamEvent.subject}
                        <PopupWithButton icon={<QuestionCircleIcon size="medium" />} iconOnly text position="after" align="top" tooltip="Info Riunione">
                            <List items={eventInfoItems} />
                        </PopupWithButton>
                    </Text>
                    <Text content={`${startTime} - ${endTime}`} temporary align="center" />
                    {GetStatus(eventStatus)}
                </Card.Header>
                <Card.Footer>
                    <Flex space="between" gap="gap.small">
                        {
                            loading || (eventStatus === null && runningCalls.length > 0) || (!event && eventStatus !== callStateEnum.Completed && eventStatus !== callStateEnum.Available && eventStatus !== callStateEnum.TooOld && eventStatus !== callStateEnum.TooNew) ?
                                <Loader /> :
                                <ActionButtons
                                    event={event}
                                    eventStatus={eventStatus}
                                    runningCalls={runningCalls}
                                    completedCalls={completedCalls}
                                    startMeeting={startMeeting}
                                    endMeeting={endMeeting}
                                    continueRunningCall={continueRunningCall}
                                    deleteCalls={deleteCalls}
                                />
                        }
                    </Flex>
                </Card.Footer>
            </Card>
        </div>
    )
}

const GetStatus = (status) => {
    let onlyIcon = false;
    let color = "";
    let title = "";
    let icon = null;

    switch (status) {
        case callStateEnum.Unknown:
        default:
            color = null;
            title = "Unknown";
            break;
        case callStateEnum.Incoming:
            color = "orange";
            title = "Incoming";
            icon = <CallIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Establishing:
            color = "orange";
            title = "Establishing";
            icon = <CallIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Established:
            color = "red";
            title = "Recording";
            icon = <CallRecordingIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Hold:
            color = "yellow";
            title = "Hold";
            break;
        case callStateEnum.Transferring:
            color = "orange";
            title = "Transferring";
            break;
        case callStateEnum.TransferAccepted:
            color = "green";
            title = "TransferAccepted";
            break;
        case callStateEnum.Redirecting:
            color = "orange";
            title = "Redirecting";
            break;
        case callStateEnum.Terminating:
            color = "red";
            title = "Terminating";
            icon = <CallEndIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Terminated:
            color = "red";
            title = "Terminated";
            break;
        case callStateEnum.UnknownFutureValue:
            color = null;
            title = "UnknownFutureValue";
            break;
        case callStateEnum.NotFound:
            color = "blue";
            title = "NotFound";
            icon = <CallMissedLineIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Available:
            color = "green";
            title = "Available";
            break;
        case callStateEnum.Completed:
            color = "green";
            title = "Completed";
            icon = <AcceptIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.RunningMore:
            color = "yellow";
            title = "RunningMore";
            break;
        case callStateEnum.CompletedMore:
            color = "green";
            title = "CompletedMore";
            break;
        case callStateEnum.Stalled:
            color = "orange";
            title = "In Attesa";
            break;
        case callStateEnum.TooOld:
            color = "red";
            title = "Evento scaduto per il monitoraggio";
            icon = <CloseIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.TooNew:
            color = "orange";
            title = "Evento futuro non monitorabile";
            icon = <CloseIcon />;
            onlyIcon = true;
            break;
        case callStateEnum.Restarting:
            color = "orange";
            title = "Ripresa monitoraggio";
            icon = <SyncIcon />;
            onlyIcon = true;
            break; 
        case null:
            color = "green";
            title = "Ready";
            break;
    }

    if (onlyIcon) {
        return React.cloneElement(icon, { title, styles: { ...teamsTabStyles.status, color } });
    }
    else {
        return <Status color={color} title={title} icon={icon} size="larger" styles={teamsTabStyles.status} />;
    }
}