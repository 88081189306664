/* eslint-disable no-unused-vars */

import React from "react";
import { Switch } from "react-router-dom";
import { CommonHelper } from 'components/Common/Helper/Helper';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

import {
  Provider,
  teamsTheme,
  Flex,
  Text,
  Layout,
  Label,
  Loader,
  Button,
  Accordion,
  SyncIcon,
  LockIcon,
  ErrorIcon
}
  from '@fluentui/react-northstar'

import 'office-ui-fabric-react/dist/css/fabric.css';

import { TeamsAuthService, Users, Groups, clearMsCache } from 'libs/microsoft-graph-service';
import * as microsoftTeams from "@microsoft/teams-js";

import * as TeamsTab from "views/TeamsTab";
import { TeamsContextProvider, teamsTabStyles, TabNavBar, rolesEnum, } from "libs/teams-tab";

import authProvider from "authProvider";
import dataProvider from "dataProvider";

initializeIcons();

const errorTypeEnum = {
  External: 'external',
  Unauthorized: 'unauthorized',
  Canceled: 'canceled',
  BotUnavailable: 'botunavailable',
  PrivateChannel: 'privatechannel',
  InvalidContext: 'invalidcontext',
  Registered: 'registered',
  Other: 'other',
  BadRequest: 'badrequest',
  AuthenticationError: 'authenticationerror',
  ConsentRequired: 'consentrequired',
  ConsentDeny: 'consentdeny',
  ConsentProvided: 'consentprovided',
}

class TeamsTabLayout extends React.Component {
  constructor(props) {
    super();

    if (!microsoftTeams) return;

    try {
      /* Initialize the Teams library before any other SDK calls.
       * Initialize throws if called more than once and hence is wrapped in a try-catch to perform a safe initialization.
       */
      microsoftTeams.initialize();
    }
    catch (e) {
      console.error(e);
    }
    finally {
      const routeslist = [
        {
          path: "/members",
          name: "Members Page",
          render: props => (<TeamsTab.Members {...this.props} />),
          roles: ['*']
        },
        {
          path: "/callDetails/:callId",
          name: "Call Details Page",
          render: props => (<TeamsTab.CallDetails {...this.props} />),
          roles: ['*']
        },
        {
          path: "/events",
          name: "Events Page",
          render: props => (<TeamsTab.Events {...this.props} />),
          roles: ['*']
        },
        {
          path: "/surveysChoice/:callId",
          name: "Surveys Choice Page",
          render: props => (<TeamsTab.SurveysChoice {...this.props} />),
          roles: ['*']
        },
        {
          path: "/surveyDetails/:id?",
          name: "Survey Details Page",
          render: props => (<TeamsTab.SurveyDetails {...this.props} />),
          roles: ['*']
        },
        {
          path: "/surveyToComplete/:id",
          name: "Survey To Complete Page",
          render: props => (<TeamsTab.SurveyToComplete {...this.props} />),
          roles: ['*']
        },
        {
          path: "/surveysToComplete",
          name: "Surveys To Complete Page",
          render: props => (<TeamsTab.SurveysToComplete {...this.props} />),
          roles: ['*']
        },
        {
          path: "/survey/:id/:callId",
          name: "Survey Page",
          render: props => (<TeamsTab.Survey {...this.props} />),
          roles: ['*']
        },
        {
          path: "/surveys",
          name: "Surveys Page",
          render: props => (<TeamsTab.Surveys {...this.props} />),
          roles: ['*']
        },
        {
          path: "/schoolRegister/:callId",
          name: "School Register",
          render: props => (<TeamsTab.SchoolRegister {...this.props} />),
          roles: ['*']
        },
        {
          path: "/inCallHomePage/:callId",
          name: "InCall HomePage",
          render: props => (<TeamsTab.InCallHomePage {...this.props} />),
          roles: ['*']
        },
        {
          path: "/config",
          name: "Config Page",
          render: props => (<TeamsTab.Config {...this.props} />),
          roles: ['*']
        },
        {
          path: "/",
          name: "Home Page",
          render: props => (<TeamsTab.HomePage {...this.props} />),
          roles: ['*']
        },
      ];

      const routesauthlist = [
        {
          path: "/auth-end",
          name: "Close Popup",
          render: props => (<TeamsTab.ClosePopup {...this.props} />),
          roles: ['*']
        },
        {
          path: "/auth-start",
          name: "Consent Popup",
          render: props => (<TeamsTab.ConsentPopup {...this.props} />),
          roles: ['*']
        },
      ]

      this.state = {
        status: {
          api: false,
          bot: false,
        },
        routes: <Switch>{CommonHelper.GetCustomRoutes("/teamstab", routeslist)}</Switch>,
        routesAuthList: <Switch>{CommonHelper.GetCustomRoutes("/teamstab", routesauthlist)}</Switch>,
        authInitialized: false,
        authCompleted: false,
        loading: true,
        loggedIn: null,
        errorMessage: null,
        msTeamsContext: null,
        consentRequired: false,
        consentProvided: false,
        setState: this.setState,
        navigate: (page) => props.history.push(`/teamstab/${page}`),
        disconnect: this.disconnect,
        isAvailable: this.isAvailable
      };

      /** Pass the Context interface to the initialize function below */
      microsoftTeams.getContext(context => this.initialize(context));

      setTimeout(() => {
        if (props.location.search === "?registered=true") {
          this.setState({ loading: false, loggedIn: false, errorType: errorTypeEnum.Registered })
        }
        else {
          if (this.state.msTeamsContext === null) {
            this.setState({ loading: false, loggedIn: false, errorType: errorTypeEnum.External })
          }
        }
      }, 5000);
    }
  }

  initialize = async (context) => {
    const contextErrors = this.checkContext(context);
    if (contextErrors.length > 0) {
      this.setState({ loading: false, loggedIn: false, msTeamsContext: context, errorMessage: contextErrors, errorType: errorTypeEnum.InvalidContext });
    }
    else {
      this.setState({ msTeamsContext: context });
    }
  }

  handleError = (error) => {
    let errorMessage = "";
    let errorType = errorTypeEnum.Other;
    let rest = { consentRequired: false };

    let type = "common";
    let data = {};

    if (CommonHelper.IsObject(error)){
      type = error.type;
      data = error.data || {};
    }
    else{
      data = error;
    }

    console.log(type, data)

    switch (type) {
      case "auth_error":
        errorMessage = data.error;
        errorType = errorTypeEnum.AuthenticationError;
        break;
      case "user_cancelled":
        errorMessage = `Auth error: ${data.error}`;
        errorType = errorTypeEnum.Canceled;
        break;
      case "consent_required":
        rest.consentRequired = true;
        errorType = errorTypeEnum.ConsentRequired;
        break;
      case "consent_deny":
        errorType = errorTypeEnum.ConsentDeny;
        break;
      case "missing_token":
        errorMessage = "Errore duranet il recupero del token di autenticazione";
        break;
      case "no_response":
        errorType = errorTypeEnum.BotUnavailable;
        break; 
      case "bad_request":
        errorMessage = data.error;
        errorType = errorTypeEnum.BadRequest;
        break;
      case "exception":
        console.error(data)
        break;
      case "common":
      default:
        break;
    }

    this.setState({ loading: false, loggedIn: false, errorMessage, errorType, ...rest });

    return false;
  }

  async componentDidMount() {
    if (this.props.location.pathname !== "/teamstab/auth-start" && this.props.location.pathname !== "/teamstab/auth-end") {

      this.setState({ authInitialized: true }, async () => {
        var tasInit = await TeamsAuthService.init().catch(this.handleError);

        if (tasInit) {
          var tasAppToken = await TeamsAuthService.getApplicationToken().catch(this.handleError);

          if (tasAppToken) {
            var tasOBToken = await TeamsAuthService.getOnBehalfToken().catch(this.handleError);
            if (!tasOBToken) {
              var consentDialogResult = await TeamsAuthService.showConsentDialog().catch(this.handleError);

              if (consentDialogResult) {
                this.setState({ consentRequired: false, consentProvided: true }, this.disconnect);
              }
              else {
                this.setState({ consentRequired: false, consentProvided: false }, () => this.handleError({ type: "consent_deny" }));
              }
            }
            else {
              this.setState({ authInitialized: false, authCompleted: true }, this.authenticateApp);
            }
          }
        }
      });
    }
    else {
      this.setState({ loading: false, loggedIn: false, consentRequired: true })
    }
  }

  componentWillMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate = async (prevProps, prevState) => {
    this.isAvailable('api');
    this.isAvailable('bot');
  }

  isAvailable = async (type) => {
    let url;

    switch (type) {
      case 'api':
        url = CommonHelper.GetApiDomain() + '/ping';
        break;
      case 'bot':
        url = CommonHelper.GetBotApiDomain() + '/ping';
        break;
      default:
        url = null
        break;
    }

    const { status, errorMessage } = this.state;

    if (url !== null && errorMessage === null) {
      const timeout = new Promise((resolve, reject) => {
        setTimeout(reject, 500, 'Request timed out');
      });

      try {
        const request = fetch(url);
        const response = await Promise.race([timeout, request]);

        if (!status[type]) {
          status[type] = true;
          this.setState({ status });
        }
      } catch (error) {
        if (status[type]) {
          status[type] = false;
          this.setState({ status, }, () => this.handleError({ type: "no_response" }));
        }
      }
    }
  }

  disconnect = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    clearMsCache();
    setTimeout(() => { window.location.reload() }, 250);
  }

  checkContext = (context) => {
    const { groupId, channelType, userObjectId, upn, userTeamRole } = context;
    const errors = [];

    if (CommonHelper.IsEmpty(groupId)) {
      errors.push("'groupId' not setted");
    }

    if (CommonHelper.IsEmpty(channelType)) {
      errors.push("'channelType' not setted");
    }

    if (CommonHelper.IsEmpty(userObjectId) && CommonHelper.IsEmpty(upn)) {
      errors.push("'userObjectId' and 'upn' not setted");
    }

    if (CommonHelper.IsEmpty(userTeamRole)) {
      errors.push("'userTeamRole' not setted");
    }

    return errors;
  }

  authenticateApp = () => {
    const { pathname } = this.props.history.location;

    this.setState({ loading: true, loggedIn: null, errorMessage: null }, async () => {
      const { msTeamsContext, authCompleted } = this.state;

      if (authCompleted) {
        const { groupId, channelType, userObjectId, upn, userTeamRole } = msTeamsContext;

        if (channelType === "Private") {
          this.setState({ loading: false, loggedIn: false, errorType: errorTypeEnum.PrivateChannel });
          return;
        }

        const msUser = {
          id: userObjectId,
          mail: upn,
          isGuest: userTeamRole === 2
        };

        const dataProviders = {
          api: dataProvider(CommonHelper.GetApiDomain()),
          bot: dataProvider(CommonHelper.GetBotApiDomain())
        };

        const isOwner = msUser.isGuest ? false : await Groups.IsOwner(groupId, msUser.id).then(res => res).catch(err => err);

        if (pathname !== '/teamstab/config') {
          let data = { email: msUser.mail, providerId: msUser.id, providerType: 'microsoft', teamChannelId: msTeamsContext.channelId };
          let { success, error } = await authProvider.login(data).then(result => result).catch(result => result);

          if (!success) {
            let errorMessage = CommonHelper.IsString(error) ? error : (CommonHelper.IsObject(error) ? error.description : "Login to MS failed");
            let errorType = errorMessage === "Server not reachable" ? errorTypeEnum.BotUnavailable : errorTypeEnum.Unauthorized;
            this.setState({ loading: false, loggedIn: false, errorMessage, errorType });
          }
          else {
            const allowedUsers = [rolesEnum.Admin, rolesEnum.Organizer];
            const teacherRoutes = ['inCallHomePage', 'schoolRegister']

            if (teacherRoutes.filter(x => x !== null && this.props.location.pathname.indexOf(x) > -1).length > 0) {
              allowedUsers.push(rolesEnum.Teacher)
            }

            const userRole = authProvider.getPermissions().toLowerCase();
            const userAllowed = allowedUsers.includes(userRole);

            if (!userAllowed) {
              this.setState({ loading: false, loggedIn: false, errorMessage: `Utente '${msUser.mail}' non autorizzato`, errorType: errorTypeEnum.Unauthorized });
            }
            else {
              const authenticatedUser = authProvider.getAuthenticatedUser();
              let teamChannel = await dataProviders.api.getOne('msteamchannels/getbyteamchannelid', { id: msTeamsContext.channelId });

              if (teamChannel && teamChannel.data) {
                let settings = await dataProviders.bot.getOne('settings', { addMsToken: true, teamChannelId: teamChannel.data.id });

                if (settings && settings.data) {
                  this.setState({
                    dataProviders,
                    authProvider,
                    loading: false,
                    loggedIn: true,
                    errorMessage: null,
                    errorType: null,
                    teamChannel: teamChannel.data,
                    settings: settings.data,
                    user: {
                      ...msUser,
                      internalId: authenticatedUser.internalId,
                      role: userRole
                    },
                    isOwner
                  });
                }
                else {
                  this.setState({ loading: false, loggedIn: false, errorMessage: "Errore durante il recupero della configrazione", errorType: errorTypeEnum.Other });
                }
              }
              else {
                this.setState({ loading: false, loggedIn: false, errorMessage: "Errore durante il recupero delle informazioni sul TeamChannel", errorType: errorTypeEnum.Other });
              }
            }
          }
        }
        else {
          if (isOwner !== null && !isOwner) {
            this.setState({ loading: false, loggedIn: false, errorMessage: `Utente '${msUser.mail}' non è owner del team. Impossibile procedere`, errorType: errorTypeEnum.Unauthorized });
          }
          else {
            this.setState({
              dataProviders,
              authProvider,
              loading: false,
              loggedIn: true,
              errorMessage: null,
              errorType: null,
              teamChannel: null,
              isOwner
            });
          }
        }
      }
      else {
        this.setState({ loading: false, loggedIn: false, errorMessage: "Autenticazione dell'applicazione fallita. Contattare l'amministratore", errorType: errorTypeEnum.Other });
      }
    })
  }

  renderPage = () => {
    const { errorMessage, errorType } = this.state;

    switch (errorType) {
      case errorTypeEnum.BotUnavailable:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Bot non attivo" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Ricarica" primary icon={<SyncIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.Unauthorized:
        return (
          <>
            <Flex.Item>
              <Text size="large" content={errorMessage} align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Ricarica" primary icon={<SyncIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.ConsentRequired:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Richiesto il consenso dell'utente" align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.ConsentDeny:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Consenso annullato dell'utente" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Ricarica" primary icon={<SyncIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.Canceled:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Autenticazione annullata dall'utente" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Login" primary icon={<LockIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.External:
        return (
          <>
            <Flex.Item>
              <Text size="large" content={"Applicazione disponibile solo all'interno di MicrosoftTeams"} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.Registered:
        return (
          <>
            <Flex.Item>
              <Text size="large" content={"Registrazione completata"} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.PrivateChannel:
        return (
          <>
            <Flex.Item>
              <Text size="large" content={"Applicazione non disponibile per i canali privati"} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.InvalidContext:
        const panels = [
          {
            title: (
              <Layout
                key="title"
                start={<Label icon={<ErrorIcon />} iconPosition="start" circular content="Errors" />}
              />
            ),
            content: {
              key: 'errors',
              content: <Flex gap="gap.small" padding="padding.medium" column hAlign="center">
                {errorMessage.map(error =>
                  <Flex.Item>
                    <Text size="large" content={error} align="center" />
                  </Flex.Item>
                )}
              </Flex>,
            },
          },
        ];
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Impossibile recuperare le informazioni necessarie da Microsoft" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Accordion panels={panels} />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.Other:
        return (
          <>
            <Flex.Item>
              <Text size="large" content={errorMessage} align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Ricarica" primary icon={<SyncIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      case errorTypeEnum.AuthenticationError:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Errore durante l'autenticazione" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Text size="large" content={errorMessage} align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Ricarica" primary icon={<SyncIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
      default:
        return (
          <>
            <Flex.Item>
              <Text size="large" content="Autenticazione fallita" align="center" />
            </Flex.Item>
            <Flex.Item>
              <Text size="large" content={errorMessage} align="center" />
            </Flex.Item>
            <Flex.Item>
              <Button content="Login" primary icon={<LockIcon />} iconPosition="before" onClick={this.disconnect} align="center" />
            </Flex.Item>
          </>
        )
    }
  }


  render() {
    const { loading, routes, routesAuthList, authInitialized, status, errorType, ...rest } = this.state;

    return (
      <TeamsContextProvider value={rest}>
        <Provider theme={teamsTheme}>
          <Flex fill={true} column styles={teamsTabStyles.main}>
            {
              rest && (rest.loggedIn || rest.consentProvided) && !loading ?
                routes :
                <>
                  {
                    rest.consentRequired ? routesAuthList : null
                  }
                  {
                    this.props.location.pathname !== "/teamstab/auth-start" && this.props.location.pathname !== "/teamstab/auth-end" &&
                    <>
                      <TabNavBar />
                      <Flex gap="gap.small" padding="padding.medium" column hAlign="center">
                        {
                          loading || rest.loggedIn === null ?
                            <Loader label="Autenticazione in corso..." /> :
                            this.renderPage()
                        }
                      </Flex>
                    </>
                  }
                </>
            }
          </Flex>
        </Provider>
      </TeamsContextProvider>
    );
  }
}

export default TeamsTabLayout;