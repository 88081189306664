/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from "react-router-dom";
import { CommonHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import styles from './Permissions.module.css';

import { AdminDashboardContext } from "libs/admin-dashboard";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Permissions extends React.Component {
  constructor(props) {
    super();

    this.state = {
      error: null,
      error_description: null
    }
  }

  componentDidMount() {
    let error = CommonHelper.GetUrlParam("error");
    let error_description = CommonHelper.GetUrlParam("error_description");
    this.setState({ error, error_description });
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  render() {
    let { error, error_description } = this.state;
    let { seo } = this.props;

    return (
      <AdminDashboardContext.Consumer>
        {context => {
          if (context.currentPage !== "permissions")
            context.setData({ seo: seo, currentPage: "permissions" });

          return (
            <Container fluid className="bg-livion-white h-100 w-100 p-0">
              <Row className="no-gutters h-100">
                <Col xs={{ size: 4, offset: 4 }} className="no-gutters h-100 d-flex justify-content-center align-items-center flex-column text-center">
                  {
                    error ?
                      <>
                        <h1>Permissions not assigned</h1>
                        <h2>{error}</h2>
                        <h3>{decodeURIComponent(error_description).replaceAll("+", " ")}</h3>
                      </> :
                      <>
                        <h1>Permissions assigned</h1>
                        <h2>You can close this page</h2>
                      </>
                  }
                </Col>
              </Row>
            </Container>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

export default withRouter(Permissions);