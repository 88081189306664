import { CommonHelper } from 'components/Common/Helper/Helper';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import * as microsoftTeams from "@microsoft/teams-js";

import authProvider from "authProvider";

import { acquireToken, acquireOnBehalfToken } from './MicrosoftClient';

const isValidToken = (token) => {
    if (token) {
        let jwt = jwt_decode(token);
        var current_time = Date.now() / 1000;

        if (jwt.exp < current_time) {
            console.error("TOKEN EXPIRED");
            return false;
        }
        else {
            // console.log(jwt.exp - current_time)
            // const timeLeft = moment("2021-01-01").startOf('day').seconds(jwt.exp - current_time).format('H:mm:ss');
            // console.log("WILL EXPIRE IN", timeLeft)
            return true;
        }
    }
    return false;
}

// TeamsAuthService is a singleton so it can retain the user's
// state independent of React state. This module exports the single
// instance of the service rather than the service class; just use it,
// don't new it up.
class TeamsAuthService {

    constructor() {
        this.authState = {
            ssoToken: null,
            obToken: null,
            appToken: null,
            error: null,
            expiresOn: Date.now()
        }

        if (microsoftTeams) {
            microsoftTeams.initialize();
        }
    }

    // Call this on every request to an authenticated page
    // Promise returns true if user is logged in, false if user is not
    init = async () => {
        return this.getSsoToken();
    }

    // Determine if someone is logged in
    ssoAuth() {
        return !CommonHelper.IsEmpty(this.authState.ssoToken);
    }

    // Determine if someone is logged in
    obAuth() {
        return !CommonHelper.IsEmpty(this.authState.obToken);
    }

    // Determine if someone is logged in
    appAuth() {
        return !CommonHelper.IsEmpty(this.authState.appToken);
    }
    
    // Call this to get an access token
    showConsentDialog() {
        return new Promise((resolve, reject) => {
            microsoftTeams.authentication.authenticate({
                url: `${CommonHelper.GetFullHostName()}/teamstab/auth-start`,
                width: 600,
                height: 535,
                successCallback: ({ idToken, accessToken, tokenType, expiresIn  }) => {
                    let jwt = jwt_decode(accessToken);
                    console.log("EXPIRATIONS", jwt.exp, expiresIn);
                    this.authState.obToken = accessToken;
                    resolve(accessToken);
                },
                failureCallback: (error) => {
                    reject({ type: "auth_error", data: { error } })
                }
            });
        });
    }

    getSsoToken = async () => {
        return new Promise((resolve, reject) => {
            if (isValidToken(this.authState.ssoToken)) {
                resolve(this.authState.ssoToken)
            }
            else
            {
                microsoftTeams.getContext((context, error) => {
                    if (context) {
                        //Perform Azure AD single sign-on authentication
                        let authTokenRequestOptions = {
                            successCallback: (ssoToken) => {
                                this.authState.ssoToken = ssoToken;
                                resolve(ssoToken);
                            },
                            failureCallback: async (error) => {
                                const accessToken = await acquireToken();
                                if (!accessToken || accessToken.authError || accessToken.error) {
                                    var error = accessToken.errorCode || accessToken.errorMessage || accessToken.error;
                                    this.authState.error = error;
                                    reject({ type: "auth_error", data: { error } })
                                }
                                this.authState.ssoToken = accessToken;
                                resolve(accessToken);
                            }
                        };
                        microsoftTeams.authentication.getAuthToken(authTokenRequestOptions);
                    }
                    else { reject({ type: "missing_context", data: { error } }); }
                })
            }            
        });
    }

    getApplicationToken = async () => {
        return new Promise(async (resolve, reject) => {
            if (isValidToken(this.authState.appToken)) {
                resolve(this.authState.appToken)
            }
            else {
                await this.getSsoToken().then(async (ssoToken) => {
                    let { success, token, error } = await authProvider.getMSAppToken(ssoToken).then(result => result).catch(result => result);

                    if (!success) { reject({ type: "no_response", data: { throwIn: "getApplicationToken", error } }); }
                    if (token === null) { reject({ type: "missing_token" }); }

                    this.authState.appToken = token;
                    resolve(token);
                }).catch(({ error }) => {
                    reject({ type: "exception", data: { throwIn: "getApplicationToken", error } });
                });
            }
        });
    }

    getOnBehalfToken = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (isValidToken(this.authState.obToken)) {
                    resolve(this.authState.obToken)
                }
                else {
                    let response = await acquireOnBehalfToken(this.authState.ssoToken).catch((error) => reject({ type: "exception", data: { throwIn: "acquireOnBehalfToken", error } })); //This calls getUserAccessToken route on BE
                    
                    if (response) {
                        if (response.error === 'consent_required') {
                            //A consent_required error means it's the first time a user is logging into to the app, so they must consent to sharing their Graph data with the app.
                            //They may also see this error if MFA is required.
                            // this.authState.obToken = await this.showConsentDialog();
                            // resolve(this.authState.obToken); //Proceed to show the consent dialogue.
                            reject({ type: "consent_required" });
                        } else if (!response.hasOwnProperty("token")) {
                            //Unknown error
                            reject({ type: "missing_token", data: { throwIn: "acquireOnBehalfToken" } });
                        } else {
                            //Server side token exchange worked. Save the access_token to state, so that it can be picked up and used by the componentDidMount lifecycle method.
                            this.authState.obToken = response.token;
                            resolve(this.authState.obToken);
                        }
                    }
                    else {
                        reject({ type: "no_response", data: { throwIn: "acquireOnBehalfToken" } });
                    }
                }
            }
            catch (ex) {
                reject({ type: "exception", data: { throwIn: "acquireOnBehalfToken", error: ex.message } });
            }
        });
    }
}

export default new TeamsAuthService();