import React, { useState } from "react";
import classNames from 'classnames';
import { Button, Tooltip, Popup, Dialog, } from '@fluentui/react-northstar'

const styles = {
    iconButton: {
        minWidth: '0px !important',
        paddingLeft: '.5rem !important',
        paddingRight: '.5rem !important',
        '&:hover': {
            background: 'rgb(255, 255, 255)',
            color: 'rgb(37, 36, 35)',
        },
        '&:disabled': {
            background: 'rgb(237, 235, 233) !important',
            color: 'rgb(200, 198, 196) !important',
        },
        '&.noBg': {
            background: 'transparent !important',
            color: 'rgb(37, 36, 35)',
        },
        '&.noBg:hover': {
            background: 'transparent !important',
            color: 'rgb(37, 36, 35)',
        },
        '&.noBg:disabled': {
            color: 'rgb(200, 198, 196) !important',
        },
        '&.light': {
            background: 'transparent !important',
            color: 'rgb(255, 255, 255)',
        },
        '&.light:hover': {
            background: 'transparent !important',
            color: 'rgb(255, 255, 255)',
        },
    }
}

export const PopupWithButton = ({ children, align, position, content, ...buttonProps }) => (
    <Popup
        align={align}
        content={content ? content : children}
        position={position}
        trigger={<ButtonTooltip {...buttonProps} />}
    />
)

export const ButtonTooltip = ({ tooltip, noBg, light, ...buttonProps }) => {
    if (buttonProps.iconOnly) {
        return <Tooltip trigger={<Button styles={styles.iconButton} className={classNames(noBg ? 'noBg' : '', light ? 'light' : '')} {...buttonProps} />} content={tooltip} />
    }
    else {
        return <Tooltip trigger={<Button {...buttonProps} />} content={tooltip} />
    }
}

export const ConfirmDialog = ({ header, content, cancelButtonText, confirmButtonText, onConfirmCallback, triggerButton, isOpen, onCancelCallback }) => {
    const [open, setOpen] = useState(isOpen || false)

    const onCancel = () => {
        onCancelCallback && onCancelCallback();
        setOpen(false);
    }

    const onConfirm = () => {
        onConfirmCallback && onConfirmCallback();
        setOpen(false);
    }

    return (
        <Dialog
            cancelButton={cancelButtonText || "Annulla"}
            confirmButton={confirmButtonText || "Conferma"}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onOpen={() => setOpen(true)}
            open={open}
            content={content}
            header={header}
            trigger={!isOpen ? (triggerButton || <Button content="Apri" />) : null}
        />
    )
}