export const teamsTabStyles = {
    main: {
        padding: ".5rem",
    },
    content: {
        padding: ".8rem",
    },
    cardContainer: {
        width: '100%',
        padding: '10px'
    },
    card: {
        width: '100%',
        height: '100%',
    },
    cardBordered: {
        border: '1px solid #888',
        width: '100%',
    },
    cardNew: {
        width: '100%',
        height: '100%',
        border: '1px solid #888',
    },
    cardPreview: {
        //minHeight: 100,
        backgroundColor: '#A6A7DC'
    },
    cardPreviewSmall: {
        height: 40,
        backgroundColor: '#A6A7DC'
    },
    cardBody: {
        minHeight: 100,
        backgroundColor: '#f8f9fe'
    },
    cardFooter: {
        backgroundColor: '#f8f9fe'
    },
    cardActions: {
        border: '1px solid transparent',
        backgroundColor: 'rgba(0,0,0,.1)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.3)',
        }
    },
    cardActionsItem: {
        minHeight: 0,
        padding: 0
    },
    status: {
        position: 'absolute',
        right: '.5em',
        top: '.5em',
    },
}