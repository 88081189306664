import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import classNames from 'classnames';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';

import { TeamsContext, ButtonTooltip, answerTypeEnum } from "libs/teams-tab";

import {
    Flex, Box, Button, Input, RadioGroup, Checkbox, Loader, Text,
    PresenceStrokeIcon, WindowMaximizeIcon, AddIcon, TrashCanIcon,
    ArrowUpIcon, ArrowDownIcon, AcceptIcon,
} from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

const customClass = mergeStyleSets({
    infosTitle: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.open': {
            backgroundColor: '#f3f3f3',
            cursor: 'default',
        }
    },
    addQuestionButton: {
        height: 50,
        cursor: 'pointer',
        backgroundColor: '#03787C',
        '& .closed-container:hover': {
            backgroundColor: '#014446',
        },
        '& .opened-container': {
            cursor: 'default',
        },
    },
});

const Choice = ({ answer, multiple, editMode, actionButtons }) => {
    const [text, setText] = useState("");
    const [correct, setCorrect] = useState(false);

    useEffect(() => {
        setText(answer.text);
        setCorrect(answer.correct);
    }, [answer]);

    useEffect(() => {
        if (editMode) {
            answer.text = text;
        }
    }, [text]);

    return (
        editMode ?
            <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.infosTitle, 'open')}>
                {multiple ? <WindowMaximizeIcon /> : <PresenceStrokeIcon />}
                <Input name="text" inverted fluid placeholder="Inserire la domanda" value={text} onChange={(e) => setText(e.target.value)} />
                <Flex.Item push>
                    <Flex gap="gap.small">
                        {actionButtons}
                    </Flex>
                </Flex.Item>
            </Flex> :
            <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={customClass.infosTitle} >
                {
                    multiple ?
                        <Checkbox disabled label={text} /> :
                        <RadioGroup items={[{ key: answer.id, label: text, value: text, disabled: true, }]} />
                }
                {correct && <ButtonTooltip secondary text iconOnly noBg icon={<AcceptIcon />} content={""} tooltip='Risposta corretta' />}
            </Flex>
    )
}

const Answers = ({ question, updateQuestionAnswers, editMode }) => {
    const teamsContext = useContext(TeamsContext);
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [answers, setAnswers] = useStateWithCallbackLazy([]);

    useEffect(() => {
        if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            setAnswers(question.surveyAnswers.sort(CommonHelper.CompareValues("order")))
        }
    }, [question.surveyAnswers]);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            }
        }
    });

    const addAnswer = async () => {
        if (answers) {
            const answerRequestData = { text: `Opzione ${answers.length + 1}`, weight: 1, correct: false, order: answers.length, questionId: question.id };
            let result = await teamsContext.dataProviders.api.create('surveyanswers', { data: answerRequestData });

            if (result && result.data) {
                setAnswers(oldAnswers => [...oldAnswers, result.data], (newAnswers) => {
                    updateQuestionAnswers(newAnswers);
                });
            }
            else {
                ToastHelper.Error("Errore durante il salvataggio di una nuova risposta");
            }
        }
    };

    const updateAnswer = async (data) => {
        if (data && data.id) {
            const indexUpdated = CommonHelper.FindWithAttr(answers, "id", data.id);
            answers[indexUpdated] = data;
            setAnswers(oldAnswers => [...oldAnswers.filter((oldAnswer, i) => i !== indexUpdated), data]);
        }
    };

    const deleteAnswer = async (id) => {
        if (id) {
            let result = await teamsContext.dataProviders.api.delete('surveyanswers', { id: id });

            if (result && result.data) {
                const indexUpdated = CommonHelper.FindWithAttr(answers, "id", id);
                setAnswers(oldAnswers => oldAnswers.filter((oldAnswer, i) => i !== indexUpdated), (newAnswers) => {
                    updateQuestionAnswers(newAnswers);
                });
            }
            else {
                ToastHelper.Error("Errore durante la cancellazione della risposta");
            }
        }
    };

    const moveAnswer = async (id, direction) => {
        if (id) {
            const oldIndex = CommonHelper.FindWithAttr(answers, "id", id);
            let newIndex = -1;

            switch (direction) {
                case 'up':
                    newIndex = oldIndex > 0 ? oldIndex - 1 : 0;
                    break;
                case 'down':
                    newIndex = oldIndex < answers.length - 1 ? oldIndex + 1 : answers.length - 1;
                    break;
            }

            setAnswers(oldAnswers => CommonHelper.MoveItemInArray(oldAnswers, oldIndex, newIndex).map((a, key) => { a.order = key; return a; }), (newAnswers) =>{
                updateQuestionAnswers(newAnswers);
            });            
        }
    };

    const setCorrectAnswer = (id) => {
        setAnswers(oldAnswers => {
            if (id && question.type === answerTypeEnum.SingleChoice) {
                return [...oldAnswers.filter(a => a !== null).map(a => { a.correct = a.id === id; return a; })];
            }
            else {
                let correctAnswer = oldAnswers.filter(a => a !== null && a.id === id)[0];
                correctAnswer.correct = !correctAnswer.correct;
                return [...oldAnswers.filter(a => a !== null && a.id !== id), correctAnswer];
            }
        },
            (newAnswers) => {
                updateQuestionAnswers(newAnswers);
            });
    }

    const renderAnswers = () => {
        if (answers) {

            return answers.map((answer, key) => {
                let answerCmp = null;

                switch (question.type) {
                    case answerTypeEnum.Text:
                    default:
                        break;
                    case answerTypeEnum.SingleChoice:
                        answerCmp = <Choice index={key} answer={answer} editMode={editMode} />
                        break;
                    case answerTypeEnum.MultipleChoice:
                        answerCmp = <Choice index={key} answer={answer} editMode={editMode} multiple />
                        break;
                }

                if (answerCmp) {
                    let actionButtons = null;

                    if (editMode) {
                        const moveUpEnabled = key > 0;
                        const moveDownEnabled = key < answers.length - 1;
                        actionButtons = <>
                            <ButtonTooltip secondary text iconOnly noBg icon={<TrashCanIcon />} tooltip='Rimuovi opzione' onClick={() => deleteAnswer(answer.id, key)} />
                            <ButtonTooltip secondary text iconOnly noBg icon={<ArrowUpIcon />} tooltip='Sposta in alto' onClick={() => moveAnswer(answer.id, 'up')} disabled={!moveUpEnabled} />
                            <ButtonTooltip secondary text iconOnly noBg icon={<ArrowDownIcon />} tooltip='Sposta in basso' onClick={() => moveAnswer(answer.id, 'down')} disabled={!moveDownEnabled} />
                            <ButtonTooltip secondary text iconOnly noBg icon={<AcceptIcon />} content={answer.correct ? "Corretta" : ""} tooltip='Risposta corretta' onClick={() => setCorrectAnswer(answer.id)} />
                        </>;
                    }

                    return React.cloneElement(answerCmp, { key, actionButtons });
                }
                else {
                    return null;
                }
            })
        }
    }

    return (
        <Flex gap="gap.medium" padding="padding.medium" column>
            {
                renderAnswers(answers)
            }
            {
                editMode && question.type !== answerTypeEnum.Text && <Box>
                    <Button icon={<AddIcon />} text content="Aggiungi opzione" onClick={addAnswer} />
                </Box>
            }
        </Flex>
    )
}

export default Answers;