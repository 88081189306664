import React from "react";
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Avatar from 'react-avatar';
import Cookies from 'universal-cookie';

import { AdminDashboardContext } from "libs/admin-dashboard";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

const cookies = new Cookies();

class AdminNavbar extends React.Component {

  constructor(props) {
    super();

    this.state = {};
  }

  componentDidMount() {
  }

  logout = (e, context) => {
    e.preventDefault();
    context.onSignOutSuccess();
  };

  render() {
    const role = CommonHelper.GetUserRole();
    return (
      <AdminDashboardContext.Consumer>
        {context => {
          return (
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
              <Container fluid>
                <Link
                  className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                  to="/admin"
                >
                  {this.props.brandText}
                </Link>
                <Nav className="align-items-center d-none d-md-flex" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm">
                          <Avatar round={true} size="36" name={`${CommonHelper.GetFullName()}`} />
                        </span>
                        <Media className="ml-2 d-none d-lg-block">
                          <span className="mb-0 text-sm font-weight-bold">
                            {CommonHelper.GetFullName()}
                          </span>
                        </Media>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Welcome!</h6>
                      </DropdownItem>
                      <DropdownItem to="/" target="_blank" tag={Link}>
                        <i className="ni ni-single-02" />
                        <span>Home Page</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => this.logout(e, context)}>
                        <i className="ni ni-user-run" />
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Container>
            </Navbar>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

const withRouterInnerRef = (WrappedComponent) => {

  class AdminNavbarWithRefa extends React.Component {
    render() {
      const { forwardRef } = this.props;
      const { ...rest } = this.props.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const AdminNavbarWithRef = withRouter(AdminNavbarWithRefa, { withRef: true });

  return React.forwardRef((props, ref) => {
    return <AdminNavbarWithRef {...props} forwardRef={ref} />;
  });
}

export default withRouterInnerRef(AdminNavbar);