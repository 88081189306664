import { AppAuthClient } from "./MicrosoftClient";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';

export async function Get(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const team = await client
        .api(`/teams/${id}`)
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return [];
        });
    return team;
}

export async function GetMembers(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamMembers = await client
        .api(`/teams/${id}/members`)
        //.select('id,displayName,givenName,surname,mail,userPrincipalName')
        .get()
        .then(function (res) {
            return res.value.map(value => {
                delete value["@odata.type"];
                return value;
            })
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return teamMembers;
}

export async function GetMember(id, userId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamMembers = await client
        .api(`/teams/${id}/members`)
        .filter(`(microsoft.graph.aadUserConversationMember/userId eq \'${userId}\')`)
        .get()
        .then(function (res) {
            if (res["@odata.count"] > 0){
                let member = res.value[0];
                delete member["@odata.type"];
                return member;
            }
            else {
                return null;
            }
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return teamMembers;
}

export async function AddMember(id, userId, role) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const conversationMember = {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: [role],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users(\'${userId}\')`
    };

    const teamMember = await client
        .api(`/teams/${id}/members`)
        .post(conversationMember)
        .then(function (res) {
            delete res["@odata.type"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return teamMember;
}

export async function RemoveMember(id, membershipId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const res = await client
        .api(`/teams/${id}/members/${membershipId}`)
        .delete()
        .then(() => true)
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    return true;
                case "Request_BadRequest":
                case "Forbidden":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return res;
}

export async function AddPrivateChannelMember(id, channelId, userId, role) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const conversationMember = {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: [role],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users(\'${userId}\')`
    };

    const teamMember = await client
        .api(`/teams/${id}/channels/${channelId}/members`)
        .post(conversationMember)
        .then(function (res) {
            delete res["@odata.type"];
            console.log(res);
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return teamMember;
}

export async function RemovePrivateChannelMember(id, channelId, membershipId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const res = await client
        .api(`/teams/${id}/channels/${channelId}/members/${membershipId}`)
        .delete()
        .then(function (res) {
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return res;
}

export async function GetChannels(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamChannels = await client
        .api(`/teams/${id}/channels`)
        .select('id,displayName,description,webUrl')
        .get()
        .then(function (res) {
            return res.value;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return [];
        });

    return teamChannels;
}

export async function GetChannel(id, teamChannelId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamChannels = await client
        .api(`/teams/${id}/channels/${teamChannelId}`)
        .select('id,displayName,description,webUrl')
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return teamChannels;
}