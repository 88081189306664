import * as Me from "./me";
import * as Users from "./users";
import * as Groups from "./groups";
import * as Teams from "./teams";
import { UserAuthClient, AppAuthClient, clearMsCache, acquireToken, } from "./MicrosoftClient";
import TeamsAuthService from './TeamsAuthService';

export const teamsRolesEnum = {
    Owner: 'owner',
    Member: 'member',
    Guest: 'guest',
}

export {
    Me,
    Users,
    Groups,
    Teams,
    UserAuthClient,
    AppAuthClient,
    clearMsCache,
    acquireToken,
    TeamsAuthService,
};