/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from "react-router-dom";
import { CommonHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import styles from './HomePage.module.css';

import { AdminDashboardContext } from "libs/admin-dashboard";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class HomePage extends React.Component {
  constructor(props) {
    super();

    this.state = {}
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  render() {
    let { seo } = this.props;

    return (
      <AdminDashboardContext.Consumer>
        {context => {
          if (context.currentPage !== "homepage")
            context.setData({ seo: seo, currentPage: "homepage" });

          return (
            <Container fluid className="bg-livion-white h-100 w-100 p-0">
              <Row className="no-gutters h-100">
                <Col className="no-gutters h-100">
                  HOME PAGE
                </Col>
              </Row>
            </Container>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

export default withRouter(HomePage);