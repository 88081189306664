import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { styles } from './theme';
import Icon from '@material-ui/core/Icon';
import TextFieldExt from './TextFieldExt';

const red500 = red['500'];

const style = {
    errorMsg: {
        fontSize: '0.75rem',
        color: red500,
        position: 'absolute',
        margin: '28px 0px 0px 15px',
        fontFamily: 'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif'
    },
    chipdIcon: {
        fontSize: '1rem', backgroundColor: '#ffffff', borderRadius: 10, padding: 1
    }
};

const defaultColor = { r: '33', g: '150', b: '243', a: '1' };

class ChipListExt extends ValidatorComponent {

    constructor(props) {
        super();

        this.state = {
            searchValue: "",
            options: props.options ? props.options : [],
            selectedOptions: this.formatOptions(props.value, props.optionKey),
            isValid: true
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { options } = this.props;

        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            if (!CommonHelper.IsEmpty(options) && CommonHelper.IsArray(options) && options.length > 0) {
                this.setState({ options: options });
            }
        }
    }

    formatOptions = (values, key) => {
        let formattedValues = [];

        if (values && values.length > 0) {
            if (CommonHelper.IsObject(values[0]) && key && values[0][key]) {
                formattedValues = values.map(v => v[key]);
            }
            else {
                formattedValues = values.map(v => v);
            }
        }

        return formattedValues;
    }

    onHandleChange = (e, option) => {
        const { optionKey } = this.props;
        const { selectedOptions } = this.state;

        e.preventDefault();

        let newSelectedOptions = [...selectedOptions];

        let selectedOptionIndex = -1;

        if (newSelectedOptions.length > 0) {
            selectedOptionIndex = newSelectedOptions.indexOf(option[optionKey]);
        }

        if (selectedOptionIndex > -1) {
            newSelectedOptions.splice(selectedOptionIndex, 1);
        }
        else {
            newSelectedOptions.push(CommonHelper.IsObject(option) ? option[optionKey] : option);
        }

        this.validate(newSelectedOptions);

        this.setState({ selectedOptions: newSelectedOptions }, this.filter);

        if(this.props.callback !== undefined)
            this.props.onChange(newSelectedOptions, this.props.name, this.props.callback !== undefined ? this.props.callback(newSelectedOptions) : undefined);
        else
            this.props.onChange(newSelectedOptions, this.props.name);
    }

    onSearchTag = (e) => {        
        this.setState({ searchValue: e }, this.filter);
    }

    filter = () => {
        const { labelKey, options } = this.props;
        const { searchValue } = this.state;

        let filteredOptions = options;

        if (searchValue.length > 0) {
            if (filteredOptions && filteredOptions.length > 0 && CommonHelper.IsObject(filteredOptions[0]) && labelKey && filteredOptions[0][labelKey]) {
                filteredOptions = filteredOptions.filter(fo => fo && fo[labelKey].toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
            }
            else {
                filteredOptions = filteredOptions.filter(fo => fo && fo.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
            }
        }

        this.setState({ options: filteredOptions });
    }

    renderChips = () => {
        const { optionKey, iconKey, labelKey, colorKey } = this.props;
        const { selectedOptions, options } = this.state;

        let formattedOptions = [];

        options && options.map((option, key) => {
            let val = option, icon = null, color = null, isSelected = null;

            if (CommonHelper.IsObject(option) && optionKey && option[optionKey]) {
                val = labelKey && option[labelKey] ? option[labelKey] : option[optionKey];
                icon = iconKey && option[iconKey] ? option[iconKey] : null;
                color = colorKey && option[colorKey] ? option[colorKey] : defaultColor;
                isSelected = selectedOptions.includes(option[optionKey]);
            }
            else {
                color = defaultColor;
                isSelected = selectedOptions.includes(option);
            }

            color = !CommonHelper.IsObject(color) ? JSON.parse(color) : color;

            const parsedColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

            const bgColor = isSelected ? 'var(--gray)' : '#ffffff';
            const border = `1px solid var(--livion-blue)`;
            const textColor = isSelected ? '#ffffff' : 'rgba(0, 0, 0, 0.87)';

            formattedOptions.push({
                key: key,
                parsedColor: parsedColor,
                bgColor: bgColor,
                border: border,
                textColor: textColor,
                color: color,
                isSelected: isSelected,
                label: val,
                icon: icon,
                item: option
            });
        })

        formattedOptions.sort(CommonHelper.CompareValues("isSelected", "desc"));
    
        return formattedOptions.map(fo => {
            return (
                <Chip
                    key={fo.key}
                    size="small"
                    variant={!fo.isSelected ? 'outlined' : 'default'}
                    style={{ backgroundColor: fo.bgColor, color: fo.textColor, border: fo.border, fontSize: '1rem' }}
                    className="m-1 py-3 px-1"
                    label={fo.label}
                    onClick={(e) => this.onHandleChange(e, fo.item)}
                    icon={fo.icon && <Icon style={{ color: fo.parsedColor, ...style.chipdIcon }}>{fo.icon}</Icon>}
                />
            )
        })
    }

    render() {
        const { search } = this.props;
        const { isValid, searchValue } = this.state;

        return (
            <>
                {search && <TextFieldExt label="Cerca" value={searchValue} onChange={this.onSearchTag}/>}
                <FormLabel style={{ color: isValid ? '#0000008a' : red500 }} component="legend" margin="dense" className="MuiInputLabel-shrink-117 pt-2">{this.props.label}</FormLabel>
                <FormControlLabel
                    className="ml-0 mt-1"
                    control={
                        <div className="d-flex flex-wrap" >
                            {this.renderChips()}
                            {this.errorText()}
                        </div>
                    }
                />
            </>
        )
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style.errorMsg}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withStyles(styles)(ChipListExt);