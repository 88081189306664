/* eslint-disable no-loop-func */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React from 'react';
import { matchPath } from 'react-router-dom'
import i18n from "i18next";
import config from 'react-global-configuration';
import Cookies from 'universal-cookie';
import equal from "deep-equal";
import lodash from "lodash";
import jwt_decode from 'jwt-decode';
import { saveAs } from 'file-saver';
import { Parser } from 'expr-eval';
import parse from 'html-react-parser';

import 'assets/css/livion.css';

import ToastHelper from "./ToastHelper";

import trans_it from "./translations/it/trans";
import trans_en from "./translations/en/trans";

import { Route } from "react-router-dom";

i18n.init({
    interpolation: {
        escapeValue: false // react already safes from xss
    },
    fallbackLng: "it", // use it if detected lng is not available
    lng: 'it',
    resources: {
        it: {
            translation: trans_it
        },
        en: {
            translation: trans_en
        },
    },
    // react-i18next options
    react: {
        wait: true
    }
});

const cookies = new Cookies();

class CommonHelper {

    static GetBackgroundImage(src, width, height, styles) {
        let style = {
            backgroundImage: `url('${this.GetFullHostName()}/${src}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            width: this.IsEmpty(width) ? '100%' : width,
            height: this.IsEmpty(height) ? '100%' : height,
            ...styles
        };

        return <div style={style}></div>;
    }

    static GetEnv(name, defaultValue) {
        if (process.env[`REACT_APP_${name}`])
            return process.env[`REACT_APP_${name}`];

        if (!this.IsEmpty(defaultValue))
            return defaultValue;

        return null;
    }

    static GetFullHostName() {
        return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }

    static GetMatchPath(pathname, path, param, exact = true, strict = false) {
        const match = matchPath(pathname, {
            path: path,
            exact: exact,
            strict: strict
        });

        if (param)
            return match ? match.params[param] : null;
        else
            return match ? true : false;
    }
    
    static LogFormError(type) { console.log.bind(console, type) };

    static WaitAsyncData(toResolve) {
        var promise = new Promise((resolve, reject) => {
            resolve(toResolve);
        });
        return promise;
    }

    static SendEmail(subject, plainTextContent, htmlContent, showAllRecipients, to) {
        let successCallback = () => {
            ToastHelper.Success("Mail Spedita");
        };

        let errorCallback = (error) => {
            if (error !== null)
                ToastHelper.Error(error);
        }

        const emailObj = {
            "subject": subject,
            "plainTextContent": plainTextContent,
            "htmlContent": htmlContent,
            "showAllRecipients": showAllRecipients,
            "to": to
        };

        this.DoFetch('sendemail', 'POST', emailObj, successCallback, errorCallback, true);
    }

    static ShowFile(blob, filename) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob);
        //     return;
        // }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, '_blank');
        // var link = document.createElement('a');
        // link.href = data;
        // link.download = filename;
        // link.click();
        // setTimeout(function () {
        //     // For Firefox it is necessary to delay revoking the ObjectURL
        //     window.URL.revokeObjectURL(data);
        // }, 100);
    }

    static ShowBase64Image(base64) {
        var image = new Image();
        image.src = base64;

        var w = window.open("");
        w.document.write(image.outerHTML);
        w.document.close();
    }

    static SaveFile(blob, filename) {
        saveAs(blob, filename);
    }

    static LogOff() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('provider_type');
    }

    static IsLogged() {
        let isLogged;
        let decodedToken = this.GetToken(true);
        if (decodedToken)
            if (decodedToken.exp < Date.now() / 1000) {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('permissions');
                localStorage.removeItem('provider_type');
                isLogged = false;
            }
            else
                isLogged = decodedToken.isLogged;
        else
            isLogged = false;

        return isLogged;
    }

    static GetToken(decoded = false) {
        let token = localStorage.getItem('token');
        if (token)
            return decoded ? jwt_decode(token) : token;
        else
            return false;
    }

    static GetUserInfo() {
        let decodedToken = this.GetToken(true);
        return decodedToken;
    }

    static GetFullName() {
        let familyName = this.GetUserInfo().family_name;
        let givenName = this.GetUserInfo().given_name;

        const username = `${givenName} ${familyName}`;

        return username ? username : '';
    }

    static GetUserRole() {
        let role = this.GetUserInfo().rol;
        return role ? role : null;
    }

    static GetUserId() {
        let internalId = this.GetUserInfo().internal_id;
        return internalId ? internalId : null;
    }

    static GetLoginProviderType() {
        let providerType = this.GetUserInfo().provider_type;
        return providerType ? providerType : null;
    }

    static GetConfigValue(name) {
        return config.get(name);
    }

    static GetApiDomain() {
        return this.GetEnv('API_ENDPOINT');
    }

    static GetBotApiDomain() {
        return this.GetEnv('BOT_API_ENDPOINT');
    }
    
    static GetLang() {
        return cookies.get('lang');
    }

    static SetLang(lng) {
        if (i18n.language !== lng) {
            i18n.changeLanguage(lng);
            cookies.set('lang', lng, { path: '/' });
        }
    }

    static GetLoginStatus() {
        return cookies.get('login_status');
    }

    static SetLoginStatus(data) {
        if (this.IsEmpty(data)) {
            cookies.remove('login_status', { path: '/' });
        }
        else {
            cookies.set('login_status', data, { path: '/', sameSite: 'strict' });
        }
    }

    static GetTrans(key, param) {
        if (this.IsEmpty(param))
            return parse(i18n.t(key));
        else
            return parse(i18n.t(key, param));
    }

    static GetTransArray(key, param) {
        let values = i18n.t(key, { returnObjects: true, ...param });
        return Array.isArray(values) ? values.map(v => parse(v)) : [];
    }

    static ToJson(data){
        return JSON.stringify(data, null, 4);
    }

    static ReplacePlaceholder(text, placeholderName, value) {
        // eslint-disable-next-line no-template-curly-in-string
        var res = text.match('\\${(' + placeholderName + ')}');
        return res !== null ? text.replace(res[0], value) : text;
    }

    static ReplacePlaceholderRegex(regexPlaceholder) {
        // eslint-disable-next-line no-template-curly-in-string
        var res = regexPlaceholder.match('\\${(.+)}');
        return res !== null ? regexPlaceholder.replace(res[0], this.GetRegex(res[1])) : regexPlaceholder;
    }

    static ReplacePlaceholderCommon(commonsPlaceholder, commons) {
        const myRegexp = /(\${([^{}]*)})/g;
        let match = myRegexp.exec(commonsPlaceholder);
        let res = commonsPlaceholder;
        while (match != null) {
            const value = this.GetKey(match[2], commons);
            res = res.replace(match[0], !this.IsEmpty(value) ? value : 0);
            match = myRegexp.exec(commonsPlaceholder);
        }
        return res;
    }

    static GetRegex(type) {
        switch (type) {
            case "telaio":
                return '^(?<wmi>[A-HJ-NPR-Z\\d]{3})(?<vds>[A-HJ-NPR-Z\\d]{5})(?<check>[A-HJ-NPR-Z\\d])(?<vis>(?<year>[A-HJ-NPR-Z\\d])(?<plant>[A-HJ-NPR-Z\\d])(?<seq>[A-HJ-NPR-Z\\d]{6}))$';
            case "codicefiscale":
                return '^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$';
            case "partitaiva":
                return '^[0-9]{11}$';
            case "targa":
                return '^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$';
            case "alpha_num":
                return '^[a-zA-Z0-9 ]+$';
            case "alpha_num_hashtag":
                return '^#{1}[a-zA-Z0-9]+$';
            default:
                return "";
        };
    }

    static AreEquals(a, b) {
        return equal(a, b);
    };

    static GroupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    static Distinct(array, key) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item[key])) {
                map.set(item[key], true);    // set any value to Map
                result.push(item);
            }
        }
        return result;
    }

    static Clone(src) {
        var deepClone = lodash.cloneDeep(src);
        return deepClone;
    }

    static Capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    static IsExisty(value) {
        return value !== null && value !== undefined;
    };

    static IsEmpty(value) {
        if (value instanceof Array) {
            return value.length === 0;
        }
        return value === '' || !this.IsExisty(value);
    };

    static IsEmptyTrimed(value) {
        if (typeof value === 'string') {
            return value.trim() === '';
        }
        return true;
    };

    static Compute(equation, thisValue, decimals, commons) {
        let result = equation;

        if (!this.IsEmpty(commons))
            equation = this.ReplacePlaceholderCommon(equation, commons);

        try { result = Parser.evaluate(equation, { this: thisValue }); } catch{ }

        if (!this.IsEmpty(decimals) && this.IsNumber(result))
            result = result.toFixed(decimals);

        return result;
    }

    static FindNested(obj, key, value, memo, path) {
        let i, hasOwn = Object.prototype.hasOwnProperty.bind(obj);

        if ('[object Array]' !== this.GetType(memo)) memo = [];
        if ('[object String]' !== this.GetType(path)) path = '';

        for (i in obj) {
            if (hasOwn(i)) {
                if (i === key) {
                    if (obj[i] === value) {
                        memo.push({ o: obj, p: path });
                    }
                } else if ('[object Array]' === this.GetType(obj[i]) || '[object Object]' === this.GetType(obj[i])) {
                    var parsed = parseInt(i);
                    let newPath = isNaN(parsed) ? path.concat(`${i}`) : path.concat(`[${parsed}].`);
                    this.FindNested(obj[i], key, value, memo, newPath);
                }
            }
        }
        return memo;
    }

    /**
     * Performs a deep merge of `source` into `target`.
     * Mutates `target` only but not its objects and arrays.
     *
     * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
     */
    static MergeDeep(target, source) {
        if (!this.IsObject(target) || !this.IsObject(source)) {
            return source;
        }

        Object.keys(source).forEach(key => {
            const targetValue = target[key];
            const sourceValue = source[key];

            if (this.IsArray(targetValue) && this.IsArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue);
            } else if (this.IsObject(targetValue) && this.IsObject(sourceValue)) {
                target[key] = this.MergeDeep(Object.assign({}, targetValue), sourceValue);
            } else {
                target[key] = sourceValue;
            }
        });

        return target;
    }

    static DeepFind(obj, path) {
        var paths = path.split('.'),
            current = obj,
            i;

        for (i = 0; i < paths.length; ++i) {
            if (current[paths[i]] == undefined) {
                return undefined;
            } else {
                current = current[paths[i]];
            }
        }
        return current;
    }

    static FindWithAttr(array, attr, value) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    static GetKey(key, obj) {
        return key.split('.').reduce(function (a, b) {
            return a && a[b];
        }, obj);
    }

    static GetType(obj) {
        let proto = Object.prototype, ts = proto.toString;
        let type = null;
        return ts.call(obj);
    }

    static GetMomentInfo(regex, type) {
        const myRegexp = /(?<func>(?:\([^()]*\)|.)*)\((?<args>(?:\([^()]*\)|.)*)\)/g;
        let regExResult = myRegexp.exec(regex);
        let res = null;
        if (regExResult != null) {
            const func = regExResult.groups.func !== undefined ? regExResult.groups.func : null;
            const args = regExResult.groups.args !== undefined ? regExResult.groups.args.split(',') : null;

            if (func !== null && args !== null && args.length === 3) {
                res = {
                    action: func,
                    format: type === 'date' ? 'YYYY-MM-DD' : (type === 'datetime-local' ? 'YYYY-MM-DDTHH:mm' : ''),
                    unit: args[2].trim(),
                    amount: args[1].trim()
                }
            }
        }
        return res;
    }

    /**
     * Function to sort alphabetically an array of objects by some specific key.
     * 
     * @param {String} property Key of the object to sort.
     */
    static CompareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    static MoveItemInArray(array, from, to) {
        if (to >= array.length) {
            var k = to - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        array.splice(to, 0, array.splice(from, 1)[0]);
        return array;
    }

    static GetUrlParam(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        return results[1] || null;
    }

    static IsUrl(s) {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(s);
    }

    static IsFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    static IsString(obj) {
        return obj !== undefined && obj !== null && obj.constructor == String;
    }

    static IsObject(obj) {
        return obj !== undefined && obj !== null && obj.constructor == Object;
    }

    static IsArray(obj) {
        return obj !== undefined && obj !== null && obj.constructor == Array;
    }

    static IsBoolean(obj) {
        return obj !== undefined && obj !== null && obj.constructor == Boolean;
    }

    static IsFile(input) {
        if ('File' in window && input instanceof File)
            return true;
        else
            return false;
    }

    static IsNumber(obj) {
        return obj !== undefined && obj !== null && obj.constructor == Number;
    }

    static IsGuid(obj) {
        if (obj === undefined && obj === null){
            return false;
        }
        if (obj[0] === "{") {
            obj = obj.substring(1, obj.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(obj);
    }

    static GetExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    static IsImage(file, checkIsAllowed) {
        var isImage = file.type.split("/")[0] === 'image';

        if (isImage && checkIsAllowed) {
            var ext = this.GetExtension(file.name);
            switch (ext.toLowerCase()) {
                case 'jpg':
                case 'jpeg':
                case 'jpe':
                case 'jif':
                case 'jfif':
                case 'jfi':
                case 'gif':
                case 'bmp':
                case 'png':
                case 'webp':
                case 'tiff':
                case 'tif':
                case 'psd':
                case 'svg':
                    // etc
                    isImage = true;
            }
        }
        return isImage;
    }

    static IsVideo(file, checkIsAllowed) {
        var isVideo = file.type.split("/")[0] === 'video';

        if (isVideo && checkIsAllowed) {
            var ext = this.GetExtension(file.name);
            switch (ext.toLowerCase()) {
                case 'webm':
                case 'mpg':
                case 'mp2':
                case 'mpeg':
                case 'mpe':
                case 'mpv':
                case 'ogg':
                case 'mp4':
                case 'm4p':
                case 'm4v':
                case 'avi':
                case 'wmv':
                    // etc
                    isVideo = true;
            }
        }
        return isVideo;
    }

    static async Sleep(msec) {
        return new Promise(resolve => setTimeout(resolve, msec));
    }

    static GetCustomRoutes = (layout, routeslist) => {
        const role = CommonHelper.IsEmpty(CommonHelper.GetUserRole()) ? 'admin' : CommonHelper.GetUserRole();

        let routes = routeslist.map(({ path, render, roles }, key) => {
            if ((roles.includes(role) || roles.includes('*'))) {
                return <Route path={layout + path} render={render} key={key} />;
            }
        });

        return routes;
    };

}

export default CommonHelper;