/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ValidatorComponent } from 'react-form-validator-core';

class SelectExt extends ValidatorComponent {

    menuItemField = (i, v, l) => {
        return <option value={v} key={i}>{l}</option>
    }

    onHandleChange = (e) => {
        let value = e.target.value;
        
        this.validate(value);
        
        if(this.props.callback !== undefined)
            this.props.onChange(value, this.props.name, this.props.callback !== undefined ? this.props.callback(value) : undefined);
        else
            this.props.onChange(value, this.props.name);
    }
    
    render() {
        const inputProps = {}, InputProps = {};
        const {
            classes,
            callback,
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            options,
            symbol,
            pattern,
            patternTitle,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        if (validators.includes('required'))
            InputProps.classes = {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
            };

        if (symbol)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.symbol}</InputAdornment>;

        if (pattern)
            inputProps.pattern = this.props.pattern;

        if (patternTitle)
            inputProps.title = this.props.title;

        return (
            <TextField
                {...rest}
                fullWidth
                select
                margin="normal"
                variant="outlined"
                onChange={(e) => this.onHandleChange(e)}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                inputProps={inputProps}
                InputProps={InputProps}
                InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
                SelectProps={{
                    native: true
                }}
            >
                <option value=""></option>
                { options !== undefined && options.map((o, i) => this.menuItemField(i, o.value, o.label))}
            </TextField>
        );
    }
}

export default withStyles(styles)(SelectExt);