import React, { cloneElement } from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from '../Helper/Helper';
import moment from 'moment';

import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { LoaderExt } from '../MaterialUI-Ext/MaterialUI-Ext';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import LanguageIcon from '@material-ui/icons/Language';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import RedditIcon from '@material-ui/icons/Reddit';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { Row, Col, Button } from "reactstrap";
import { Link } from 'react-router-dom'

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    if (CommonHelper.IsEmpty(array) || !CommonHelper.IsArray(array)){
        return [];
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: 24,
        zIndex: 0,
        boxShadow: '0px 5px 10px 0px #7d7d7d'
    },
    rootNoShadows: {
        width: '100%',
        marginTop: 24,
        zIndex: 0,
        boxShadow: 'unset'
    },
    table: {
        minWidth: 1020
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    rowTitleConfirm: {
        paddingLeft: '20px',
        paddingBottom: '30px',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    rowButtonConfirm: {
        paddingLeft: '18px',
        marginBottom: '-12px',
        marginLeft: '-2px',
        backgroundColor: 'rgb(241,241,241)',
    },
    imageCell: {
        height: '30px',
        zIndex: 99999,
        cursor: 'pointer'
    }
});

class EnhancedTable extends React.Component {

    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: props.orderBy ? props.orderBy : '',
            selected: [],
            selectedData: null,
            columns: props.columns,
            data: props.data,
            origData: props.data,
            page: 0,
            rowsPerPage: 10,
            filter: [],
            filterData: props.data
        };
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            this.setState({ data: this.props.data });
            if (this.props.updatingData) {
                this.setState({
                    origData: this.props.data
                }, () => {
                    if ((this.state.filter !== undefined) && (this.state.filter.length > 0)) {
                        let singleFilter = { field: this.state.filter[0].columnToQuery, value: this.state.filter[0].query };
                        this.handleOnFilter(singleFilter);
                    }
                });
            }
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event, id) => {
        if (this.props.readOnly)
            return;

        const preventElements = ['button', 'a', 'svg', 'img'];

        if (preventElements.includes(event.target.tagName.toLowerCase())) {
            return;
        }

        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (!this.props.singleSelect) {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        }
        else {
            if (selectedIndex === 0)
                newSelected = [];
            else
                newSelected[0] = id;
        }

        let parsed = null;

        if (newSelected.length === 1) {
            let dataItem = this.state.data.find(item => item.id === newSelected[0]);

            const jsonToParse = JSON.stringify(dataItem);

            const colList = this.state.columns;

            parsed = JSON.parse(jsonToParse, (key, value) => {
                let col = colList.find(item => item.id === key)
                if ((col !== undefined) && (value !== null) && (col.type === 'date')) {
                    return value.substring(0, 10);
                }
                return value;
            });
        }

        this.setState({ selectedData: parsed });

        if (this.props.onRowSelected)
            this.props.onRowSelected(parsed);

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    handleUnselectAllClick = (event) => {
        this.setState({ selected: [] });
        this.setState({ form: { ...this.state.form, formData: null } });
    };

    handleOnFilter = (filter) => {
        let columnToQuery = filter.field;
        let query = filter.value;

        if ((columnToQuery !== undefined) && (query !== undefined)) {
            if ((this.state.origData.length === 0) && (this.state.data.length > 0)) {
                // eslint-disable-next-line react/no-direct-mutation-state
                this.state.origData = this.state.data;
            }
            let tempData = this.state.origData;
            if (columnToQuery !== '') {
                let filterlist = this.state.filter;
                let colIndex = filterlist.findIndex(col => col.columnToQuery === columnToQuery);
                if (colIndex === -1)
                    filterlist.push({ columnToQuery, query });
                else {
                    if (query !== '')
                        filterlist[colIndex] = { columnToQuery, query };
                    else
                        filterlist.splice(colIndex, 1);
                }
                this.setState({ filter: filterlist });

                let colList = this.state.columns;

                for (let col of this.state.filter) {
                    let colData = colList.find(item => item.id === col.columnToQuery)
                    if ((colData.type === "string") || (colData.type === "option")) {
                        tempData = tempData.filter(x => ((x[col.columnToQuery] !== null) && (x[col.columnToQuery].toLowerCase().includes(col.query.toLowerCase()))));
                    }
                    else if (colData.type === "number") {
                        tempData = tempData.filter(x => ((x[col.columnToQuery] !== null) && (x[col.columnToQuery].toString().startsWith(col.query.toString()))));
                    }
                    else if (colData.type === "bool") {
                        let bValue = CommonHelper.IsBoolean(col.query) ? col.query : (col.query.toLowerCase() === "si") || (col.query.toLowerCase() === "s")
                        tempData = tempData.filter(x => ((x[col.columnToQuery] !== null) && (x[col.columnToQuery] === bValue)));
                    }
                }
            }
            else
                this.setState({ filter: [] });

            this.setState({ data: tempData });
            this.setState({ filterData: tempData });
        }
    }

    handleOnResetFilterHandler = event => {
        this.setState({ filter: [] });
        this.setState({ data: this.state.origData });
        this.setState({ filterData: this.state.origData });
    }

    onLoading = () => {
        this.props.isLoading();
    }

    onLoaded = () => {
        this.props.isLoaded();
    }

    onDelete = (event, selected, callback) => {
        let confirm = <>
            <Row className={this.props.classes.rowTitleConfirm}>
                Sei sicuro di voler eliminare {selected.length > 1 ? 'gli elementi selezionati' : 'l\'elemento selezionato?'}
            </Row>
            <Row className={this.props.classes.rowButtonConfirm}>
                <Col>
                    <Button color="info" onClick={() => this.props.handleRequestDelete(event, selected)}>Si</Button>
                </Col>
                <Col>
                    <Button color="neutral" onClick={ToastHelper.Close}>No</Button>
                </Col>
            </Row>
        </>;

        ToastHelper.Confirm(confirm);
    }

    renderContent = (el, id, type, col) => {
        let value = CommonHelper.DeepFind(el, id);
        let validTypes = ['bool', 'count', 'action'];

        if (CommonHelper.IsEmpty(value) && !validTypes.includes(type))
            return "";

        const currencyFormatter = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        })

        const currencyFormatter5 = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 5
        })

        const percentFormatter = new Intl.NumberFormat('it-IT', {
            style: 'percent',
            minimumFractionDigits: 2
        })

        switch (type) {
            case 'string':
                return value;
            case 'date':
                {
                    let outputFormat = !CommonHelper.IsEmpty(col.outputFormat) ? col.outputFormat : 'YYYY-MM-DD';
                    if (!CommonHelper.IsEmpty(col.inputFormat)) {
                        return moment(value, col.inputFormat).format(outputFormat);
                    }
                    else {
                        return moment(value).format(outputFormat);
                    }
                }
            case 'datetime-local':
                {
                    let outputFormat = !CommonHelper.IsEmpty(col.outputFormat) ? col.outputFormat : 'YYYY-MM-DD HH:mm';
                    if (!CommonHelper.IsEmpty(col.inputFormat)) {
                        return moment(value, col.inputFormat).format(outputFormat);
                    }
                    else {
                        return moment(value).format(outputFormat);
                    }
                }
            case 'time':
                {
                    let outputFormat = !CommonHelper.IsEmpty(col.outputFormat) ? col.outputFormat : 'HH:mm:ss.SSS';
                    if (!CommonHelper.IsEmpty(col.inputFormat)) {
                        return moment(value, col.inputFormat).format(outputFormat);
                    }
                    else {
                        return moment(value).format(outputFormat);
                    }
                }
            case 'decimal':
                return currencyFormatter.format(value);
            case 'email':
                return <a href={`mailto:${value}`}>{value}</a>;
            case 'percent':
                return percentFormatter.format(value / 100);
            case 'image':
                let src = CommonHelper.IsUrl(value) ? value : `data:image/jpeg;base64,${value}`;
                return <img alt="" onClick={() => CommonHelper.ShowBase64Image(src)} className={this.props.classes.imageCell} src={src} />;
            case 'bool':
                return (
                    <IconButton className="p-0">
                        {value ? <CheckCircleOutlineIcon /> : <CancelIcon />}
                    </IconButton>
                );
            case 'external-link':
                return (
                    <a href={value ? value : '#'} target="_blank">
                        <IconButton className="p-0" data-type="external-link" title={value}>
                            <LanguageIcon color={value ? 'primary' : 'error'} />
                        </IconButton>
                    </a>
                );
            case 'decimal5':
                return currencyFormatter5.format(value);
            case 'count':
                return CommonHelper.IsArray(value) ? value.length : 0;
            case 'icon':
                let iconColor = `rgba(33, 150, 243, 1)`;

                if (!CommonHelper.IsEmpty(el["color"])) {
                    let rgbaColor = !CommonHelper.IsObject(el["color"]) ? JSON.parse(el["color"]) : el["color"];
                    iconColor = `rgba(${rgbaColor.r}, ${rgbaColor.g}, ${rgbaColor.b}, ${rgbaColor.a})`;
                }

                return <Icon style={{ color: iconColor }}>{value}</Icon>;
            case 'social_icon':
                switch (value.toLowerCase()) {
                    case 'facebook':
                    case 'fb':
                        return <FacebookIcon />;
                    case 'instagram':
                    case 'ig':
                        return <InstagramIcon />;
                    case 'linkedin':
                    case 'li':
                        return <LinkedInIcon />;
                    case 'pinterest':
                    case 'pr':
                        return <PinterestIcon />;
                    case 'reddit':
                    case 're':
                        return <RedditIcon />;
                    case 'twitter':
                    case 'tw':
                        return <TwitterIcon />;
                    case 'youtube':
                    case 'yt':
                        return <YouTubeIcon />;
                }
            case 'action':
                return (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={() => col.callback(el.id)}>
                            {col.icon}
                        </IconButton>
                    </Tooltip>
                );
            default:
                return value;
        }
    }

    render() {
        const { classes, title, modal, readOnly, loadingData, redirectPage, noBorderShadows } = this.props;
        const { data, order, orderBy, selected, rowsPerPage, page, columns, filter, selectedData, filterData } = this.state;

        let rowSelection = (readOnly === undefined) || (readOnly === false);

        let paperRootClass = classes.root;

        if (noBorderShadows){
            paperRootClass = classes.rootNoShadows;
        }

        return (
            <Paper className={paperRootClass} style={loadingData || CommonHelper.IsEmpty(data) ? { height: 285 } : null}>
                {
                    !this.props.onlyTable ?
                        <EnhancedTableToolbar
                            title={title}
                            numSelected={selected.length}
                            selected={selected}
                            modal={modal}
                            selectedData={selectedData}
                            columns={columns}
                            filter={filter}
                            readOnly={this.props.readOnly}
                            canDelete={this.props.canDelete}
                            clearSelected={event => this.handleUnselectAllClick(event)}
                            onDelete={event => this.onDelete(event, selected)}
                            onEdit={event => this.props.handleRequestEdit(event, this.formData)}
                            onFilter={this.handleOnFilter}
                            onResetFilter={event => this.handleOnResetFilterHandler(event)}
                            customActions={this.props.customActions}
                            downloadCsv={this.props.downloadCsv}
                            downloadXlsx={this.props.downloadXlsx}
                            onLoading={this.props.handleLoading}
                            onLoaded={this.props.handleLoaded}
                        />
                        : <></>
                }
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            columns={columns}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={CommonHelper.IsEmpty(data) ? 0 : data.length}
                            readOnly={this.props.readOnly}
                            singleSelect={this.props.singleSelect}
                        />
                        <TableBody>
                            {
                                loadingData ?
                                    <TableRow>
                                        <TableCell colSpan={columns.length + (rowSelection ? 1 : 0)} className="text-center">
                                            <LoaderExt />
                                        </TableCell>
                                    </TableRow> :
                                    !CommonHelper.IsEmpty(data) ?
                                        stableSort((filter.length > 0) ? filterData : data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);

                                                if (this.filter !== undefined && this.filter.columnToQuery !== '' && this.filter.query !== '')
                                                    if (n[this.filter.columnToQuery].toLowerCase().includes(this.filter.query.toLowerCase()))
                                                        console.log(n[this.filter.columnToQuery]);

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={event => this.handleClick(event, n.id)}
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        selected={isSelected}
                                                        style={{ backgroundColor: !CommonHelper.IsEmpty(n.bgColor) ? n.bgColor : null }}
                                                    >
                                                        {rowSelection ? (
                                                            <TableCell padding="checkbox" size={'small'}>
                                                                <Checkbox checked={isSelected} />
                                                            </TableCell>
                                                        ) : (null)}
                                                        {columns.filter(column => column.visible !== false).map(
                                                            col => (
                                                                (col.type === 'redirect' && redirectPage) ?
                                                                    (
                                                                        <TableCell
                                                                            key={col.id}
                                                                            align='right'
                                                                            padding={col.disablePadding ? 'none' : 'default'}
                                                                            sortDirection={orderBy === col.id ? order : false}
                                                                        >
                                                                            <Link to={`${redirectPage}/${n[col.id]}`}>{n[col.id]}</Link>
                                                                        </TableCell>
                                                                    ) :
                                                                    (
                                                                        <TableCell
                                                                            key={col.id}
                                                                            align={col.numeric ? 'right' : col.align ? col.align : 'left'}
                                                                            padding={col.disablePadding ? 'none' : 'default'}
                                                                            sortDirection={orderBy === col.id ? order : false}
                                                                            size={col.size ? col.size : 'medium'}
                                                                            style={col.width && { width: col.width }}
                                                                        >
                                                                            {
                                                                                CommonHelper.IsFunction(n.onClick) ?
                                                                                    <span onClick={() => n.onClick(n.id, col.id, CommonHelper.DeepFind(n, col.id))} >{this.renderContent(n, col.id, col.type, col)}</span> :
                                                                                    this.renderContent(n, col.id, col.type, col)
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                            ),
                                                            this,
                                                        )}

                                                    </TableRow>
                                                );
                                            }) :
                                        <TableRow>
                                            <TableCell colSpan={columns.length + (rowSelection ? 1 : 0)} className="text-center">
                                                No data
                                            </TableCell>
                                        </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
                {
                    !CommonHelper.IsEmpty(data) &&
                    <TablePagination
                        labelRowsPerPage={CommonHelper.GetTrans("common.table.pagination.labelRowsPerPage")}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={CommonHelper.IsEmpty(data) ? 0 : data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': CommonHelper.GetTrans("common.table.pagination.previousPage"),
                        }}
                        nextIconButtonProps={{
                            'aria-label': CommonHelper.GetTrans("common.table.pagination.nextPage"),
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                }
            </Paper>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable); 