import React from 'react';
import { CommonHelper, ToastHelper, FetchHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

import { AdminDashboardContext } from "libs/admin-dashboard";

import Header from "components/Headers/Header.jsx";
import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import ImportMSTeams from './ImportMSTeams';
import ImportMSTeamMembers from './ImportMSTeamMembers';
import ImportMSTeamChannels from './ImportMSTeamChannels';
import ImportMSTeamEvents from './ImportMSTeamEvents';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
    zIndex: 0,
    boxShadow: '0px 5px 10px 0px #7d7d7d'
  }
});

class MSTeamsImporter extends React.Component {
  static contextType = AdminDashboardContext;

  constructor(props) {
    super();

    this.tabsRef = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    const { seo } = this.props;
    this.context.setData({ seo: seo, currentPage: "msteamsimporter" });
  }

  componentWillMount() {
    this.getRelatedData();
  }

  getRelatedData() {
    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); }

    let fetchList = [];

    if (fetchList.length > 0) {
      FetchHelper.DoMultipleFetch(fetchList).then(results => { });
    }
  }

  buildTabs = () => {
    const msTeamsTab = (<ImportMSTeams />);
    const msTeamMembersTab = (<ImportMSTeamMembers />);
    const msTeamChannelsTab = (<ImportMSTeamChannels />);
    const msEventsTab = (<ImportMSTeamEvents />);

    return [
      { title: CommonHelper.GetTrans("msteamsimporter.tabs.ms_teams"), content: msTeamsTab },
      { title: CommonHelper.GetTrans("msteamsimporter.tabs.ms_team_channels"), content: msTeamChannelsTab },
      { title: CommonHelper.GetTrans("msteamsimporter.tabs.ms_team_members"), content: msTeamMembersTab },
      { title: CommonHelper.GetTrans("msteamsimporter.tabs.ms_events"), content: msEventsTab }
    ]
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--8" fluid>
          <Row>
            <Col>
              <Paper className={classNames(classes.root, 'px-3 pt-3 pb-0')}>
                <EnhancedTabs ref={this.tabsRef} tabs={this.buildTabs()} activeTab={0} />
              </Paper>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(MSTeamsImporter); 