import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

export const formData = {
    "title": "",
    "startDate": undefined,
    "endDate": undefined,
    "enabled": true,
};

export const getSchema = () => {
    return {
        "fields": {
            "title": {
                "name": "title", "type": "string", "label": CommonHelper.GetTrans("surveys.fields.title"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "startDate": {
                "name": "startDate", "type": "datetime-local", "label": CommonHelper.GetTrans("surveys.fields.startDate"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "endDate": {
                "name": "endDate", "type": "datetime-local", "label": CommonHelper.GetTrans("surveys.fields.endDate"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "enabled": {
                "name": "enabled", "type": "bool", "label": CommonHelper.GetTrans("surveys.fields.enabled"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
        }
    };
}

export const getTableColumns = () => {
    return [
        { id: 'title', label: CommonHelper.GetTrans("surveys.fields.title"), type: 'string', filter: true },
        { id: 'startDate', label: CommonHelper.GetTrans("surveys.fields.startDate"), type: 'datetime-local', filter: true },
        { id: 'endDate', label: CommonHelper.GetTrans("surveys.fields.endDate"), type: 'datetime-local', filter: true },
        { id: 'enabled', label: CommonHelper.GetTrans("surveys.fields.enabled"), type: 'bool', size: 'small', align: 'center' },
        { id: 'questions', label: CommonHelper.GetTrans("surveys.fields.questions"), type: 'count', size: 'small', align: 'center' },
        { id: 'createdAt', label: CommonHelper.GetTrans("surveys.fields.createdAt"), type: 'datetime-local' }
    ];
}