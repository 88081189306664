import React, { Component } from "react";
import classNames from 'classnames';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import styles from './AnswersType.module.css';
import parse from 'html-react-parser';

import { TextFieldExt, CheckboxExt, TinyMceExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import { Container, Row, Col, Button } from "reactstrap";

import AnswerNumeric from "./AnswerNumeric/AnswerNumeric.jsx";
import AnswerYesNo from "./AnswerYesNo/AnswerYesNo.jsx";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class AnswersType extends Component {

    constructor(props) {
        super();

        this.onAnswerChange = this.onAnswerChange.bind(this);
        this.renderAnswers = this.renderAnswers.bind(this);

        this.handleRemove = this.handleRemove.bind(this);

        this.state = {
            open: false,
            key: null,
            mode: null,
            answers: CommonHelper.IsEmpty(props.value) ? [] : [...props.value],
            ready: true
        };
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            if (!CommonHelper.AreEquals(this.props.answersType, prevProps.answersType)) {
                let answers = [...this.state.answers];
                answers = this.checkAnswersType(answers);
                this.setState({ answers: answers });
            }
        }
    }

    addAnswer = () => {
        let answers = [...this.state.answers];
        const answer = { text: "", weight: 1, correct: false };

        answers.push(answer);

        this.setState({ answers: answers }, () => {
            if (CommonHelper.IsFunction(this.props.onChange))
                this.props.onChange(this.state.answers, this.props.name);
            if (CommonHelper.IsFunction(this.props.callback))
                this.props.callback(this.state.answers);
        });
    }

    onAnswerChange = (value, keyField) => {
        let answers = [...this.state.answers];

        let key = keyField.split('.')[0];
        let field = keyField.split('.')[1];

        if (field === "correct" && value) {
            answers = this.checkAnswersType(answers);
        }

        answers[key][field] = value;

        this.setState({ answers: answers });
    }

    checkAnswersType = (answers) => {
        const { answersType } = this.props;

        if (!CommonHelper.IsEmpty(answersType)) {
            switch (answersType) {
                case 'SingleChoice':
                    {
                        answers = answers.map(a => { a.correct = false; return a });
                    }
                    break;
                case 'MultipleChoice':
                default:
                    break;
            }
        }

        return answers;
    }

    handleRemove = (key) => {
        let { answers } = this.state;

        if (key > -1) {
            answers.splice(key, 1);

            this.setState({ answers: answers }, () => {
                if (CommonHelper.IsFunction(this.props.onChange))
                    this.props.onChange(this.state.answers, this.props.name);
                if (CommonHelper.IsFunction(this.props.callback))
                    this.props.callback(this.state.answers);
            });
        }
    }

    renderAnswers = () => {
        const { answersType } = this.props;
        const { answers } = this.state;

        if (CommonHelper.IsEmpty(answers))
            return null;
        else
            return answers.map((answer, key) => {
                return (
                    <Row key={key} className={classNames(styles.AnswerWrapper, 'no-gutters')}>
                        <Col xs={answersType === "MultipleChoice" ? '6' : '8'}>
                            <TextFieldExt name={`${key}.text`} type="text" onChange={this.onAnswerChange} value={answer.text} disabled={this.props.disabled} />
                        </Col>
                        <Col xs={{ size: '1', offset: 1 }} className='d-flex justify-content-center align-items-center'>
                            <CheckboxExt name={`${key}.correct`} onChange={this.onAnswerChange} value={answer.correct} disabled={this.props.disabled} hideInlineLabel />
                        </Col>
                        {
                            answersType === "MultipleChoice" &&
                            <Col xs={{ size: '1', offset: 1 }}>
                                <TextFieldExt name={`${key}.weight`} type="number" onChange={this.onAnswerChange} value={answer.weight} disabled={this.props.disabled} />
                            </Col>
                        }
                        <Col className="d-flex justify-content-end align-items-center">
                            <Tooltip title={CommonHelper.GetTrans("buttons.delete")}>
                                <IconButton onClick={!this.props.disabled ? () => this.handleRemove(key) : null} className="text-danger" >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                        </Col>
                    </Row>
                )
            })
    }

    render() {
        const { open } = this.state;
        const { answersType } = this.props;

        return (
            <Container fluid className={styles.AnswersContainer}>
                <Row className={classNames(styles.AnswersList, 'no-gutters')}>
                    <Col className={styles.AnswersListWrapper} style={{ maxHeight: 390 }}>
                        <Row className={classNames(styles.AnswersListHeaderWrapper, 'no-gutters')}>
                            <Col xs={answersType === "MultipleChoice" ? '6' : '8'}>
                                {CommonHelper.GetTrans("answers.fields.text")}
                            </Col>
                            <Col xs={{ size: '1', offset: 1 }}>
                                {CommonHelper.GetTrans("answers.fields.correct")}
                            </Col>
                            {
                                answersType === "MultipleChoice" &&
                                <Col xs={{ size: '1', offset: 1 }}>
                                    {CommonHelper.GetTrans("answers.fields.weight")}
                                </Col>
                            }
                            <Col className="text-right" >
                                {CommonHelper.GetTrans("common.text.actions")}
                            </Col>
                        </Row>
                        {this.renderAnswers()}
                    </Col>
                </Row>
                <Row className={classNames(styles.AddAnswer, 'no-gutters')}>
                    <Col className="text-center">
                        <Tooltip title={CommonHelper.GetTrans("buttons.add")}>
                            <IconButton onClick={this.addAnswer} className="text-success" >
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AnswersType;