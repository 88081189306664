import * as React from 'react';
import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
    useCheckMinimumRequiredProps,
    ListContextProvider,
    useListController,
} from 'libs/admin-dashboard';

const List = props => {
    useCheckMinimumRequiredProps('List', ['children'], props);
    const controllerProps = useListController(props);

    return (
        <ListContextProvider value={controllerProps}>
            {props.children && cloneElement(Children.only(props.children), controllerProps)}
        </ListContextProvider>
    );
};

List.propTypes = {
    // the props you can change
    // @ts-ignore-line
    actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
    aside: PropTypes.element,
    // @ts-ignore-line
    bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    filter: PropTypes.object,
    filterDefaultValues: PropTypes.object,
    filters: PropTypes.element,
    // @ts-ignore-line
    pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    perPage: PropTypes.number.isRequired,
    //@ts-ignore-line
    sort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    title: PropTypes.string,
    // the props managed by react-admin
    authProvider: PropTypes.func,
    hasCreate: PropTypes.bool.isRequired,
    hasEdit: PropTypes.bool.isRequired,
    hasList: PropTypes.bool.isRequired,
    hasShow: PropTypes.bool.isRequired,
    location: PropTypes.any,
    match: PropTypes.any,
    path: PropTypes.string,
    resource: PropTypes.string.isRequired,
};

List.defaultProps = {
    filter: {},
    perPage: 10,
};

export default List;
