/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

import icons from 'data/icons.js';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class IconPickerExt extends React.Component {

    constructor(props) {
        super();

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);

        this.state = {
            anchorEl: null,
            icons: icons.slice(0, 15),
            selected: props.value
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    onHandleChange = (value) => {
        if (CommonHelper.IsFunction(this.props.onChange))
            this.props.onChange(value, this.props.name, CommonHelper.IsFunction(this.props.callback) ? this.props.callback(value) : undefined);

        this.setState({ anchorEl: null });
    }

    componentDidUpdate(prevProps, prevState) {
    }

    renderIconList() {
        const { icons } = this.state;
        const { classes, value } = this.props;

        return icons.map((icon, index) => {
            return (
                <div key={index} className={classes.iconsItem} onClick={() => this.onHandleChange(icon)}>
                    <Tooltip title={icon.split('_').join(' ')}>
                        <IconButton aria-label={icon.split('_').join(' ')} className={value === icon ? 'bg-light' : ''}>
                            <Icon className={classes.iconsItemIcon} className={value === icon ? 'text-dark' : ''}>{icon}</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            );
        });
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value, label, showValue, color,
            ...rest
        } = this.props;

        let iconColor = `rgba(33, 150, 243, 1)`;

        if (!CommonHelper.IsEmpty(color) && !CommonHelper.IsEmpty(value)) {
            let rgbaColor = !CommonHelper.IsObject(color) ? JSON.parse(color) : color;
            iconColor = `rgba(${rgbaColor.r}, ${rgbaColor.g}, ${rgbaColor.b}, ${rgbaColor.a})`;
        }

        return (
            <>
                {
                    !CommonHelper.IsEmpty(label) &&
                    <span className="mr-2">{label}</span>
                }
                <Tooltip title={CommonHelper.IsEmpty(value) ? 'Select icon' : `Selected '${value}'`}>
                    <IconButton aria-label={value} onClick={this.handleClick}>
                        <Icon style={{ color: iconColor }}>{CommonHelper.IsEmpty(value) ? 'apps' : value}</Icon>
                    </IconButton>
                </Tooltip>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.typography}>
                        <Container style={{ width: 500, maxHeight: 350 }}>
                            <Row>
                                {this.renderIconList()}
                            </Row>
                        </Container>
                    </div>
                </Popover>
                {
                    showValue &&
                    <span className="ml-2">{value}</span>
                }
            </>
        );
    }
}

export default withStyles(styles)(IconPickerExt);