import HttpError from './HttpError';
import { stringify } from 'query-string';
import authProvider, { UNAUTHORIZED, AUTHORIZED } from "authProvider";

export const createHeadersFromOptions = (options) => {
    const requestHeaders = (options.headers || new Headers({ Accept: 'application/json', }));

    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }

    return requestHeaders;
};

export const fetchJson = (url, options) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(async (response) => {
            let { status, statusText, headers, body } = response;
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }

            if (status < 200 || status >= 300) {
                const authResult = await authProvider.checkError(response).then(() => AUTHORIZED).catch(() => UNAUTHORIZED);

                if (authResult) {
                    return Promise.reject(authResult);
                }
                else {
                    return Promise.reject(
                        new HttpError((json && json.message) || statusText, status, json)
                    );
                }
            }
            return Promise.resolve({ status, headers, body, json });
        });
};

export const fetchBlob = (url, options, filename = null, fileExtension = null) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.blob().then(blob => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                blob,
            }))
        )
        .then(async (response) => {
            let { status, statusText, headers, blob } = response;

            if (!filename) {
                if (headers.has('content-disposition')) {
                    var disposition = headers.get('content-disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) {
                            filename = matches[1].replace(/['"]/g, '');
                        }
                    }
                }
                else {
                    filename = `temp.${fileExtension ? fileExtension.toLowerCase() : 'tmp'}`
                }
            }
            else {
                filename = `${filename}.${fileExtension ? fileExtension.toLowerCase() : 'tmp'}`
            }

            if (status < 200 || status >= 300) {
                const authResult = await authProvider.checkError(response).then(() => AUTHORIZED).catch(() => UNAUTHORIZED);

                if (authResult) {
                    return Promise.reject(authResult);
                }
                else {
                    return Promise.reject(
                        new HttpError(statusText, status, blob)
                    );
                }
            }
            return Promise.resolve({ status, headers, blob, filename });
        });
};

export const queryParameters = stringify;

const isValidObject = value => {
    if (!value) {
        return false;
    }

    const isArray = Array.isArray(value);
    const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
    const isObject =
        Object.prototype.toString.call(value) === '[object Object]';
    const hasKeys = !!Object.keys(value).length;

    return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
    if (isValidObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map(key =>
                flattenObject(value[key], path.concat([key]))
            )
        );
    } else {
        return path.length ? { [path.join('.')]: value } : value;
    }
};
