import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import EnhancedModal from 'components/Common//EnhancedModal/EnhancedModal';
import DatagridFilter from './DatagridFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import { MSTeamsIcon, MSTeamsIconWeight, MSTeamsIconType } from 'msteams-ui-icons-react';

const styles = theme => ({
    root: {
        paddingRight: 8,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        width: '25%',
        textAlign: 'right',
    },
    title: {
        flex: '0 0 auto',
    },
    typography: {
        margin: 16,
    },
});

class DatagridToolbar extends React.Component {

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    createDeleteHandler = property => event => {
        this.props.onDelete(event, property, () => this.props.clearSelected(event));
    }

    createEditHandler = property => event => {
        this.props.onEdit(event, property);
        this.props.clearSelected(event);
    }

    createFilterHandler = (e) => {
        this.props.onFilter(e);
    }

    createResetFilterHandler = event => {
        this.props.onResetFilter(event);
    }

    createExportHandler() {
        if (!CommonHelper.IsEmpty(this.props.downloadCsv)){
            return this.renderDownloadXlsxCsvAction(0, this.props.downloadCsv, this.props.downloadCsv.filename, 'CSV');
        }

        if (!CommonHelper.IsEmpty(this.props.downloadXlsx)) {
            return this.renderDownloadXlsxCsvAction(0, this.props.downloadXlsx, this.props.downloadXlsx.filename, 'XLSX');
        }
    }

    renderDownloadXlsxCsvAction(key, download, filename, fileType) {
        let downloadExport = () => {
            let successCallback = (blob, response) => {
                let { headers } = response;

                console.log(filename, headers.has('content-disposition'))

                if (CommonHelper.IsEmpty(filename)) {
                    if (headers.has('content-disposition')) {
                        var disposition = headers.get('content-disposition');
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(disposition);
                            if (matches != null && matches[1]) {
                                filename = matches[1].replace(/['"]/g, '');
                            }
                        }
                    }
                    else{
                        filename = `temp.${(fileType === "CSV" ? "csv" : "xlsx")}`
                    }
                }
                else {
                    filename = `${filename}.${(fileType === "CSV" ? "csv" : "xlsx")}`
                }

                setTimeout(CommonHelper.SaveFile(blob, filename), 300);

                if (CommonHelper.IsFunction(this.props.onLoaded)) {
                    this.props.onLoaded();
                }
            };

            let errorCallback = (error) => {
                if (CommonHelper.IsFunction(this.props.onLoaded)) {
                    this.props.onLoaded();
                }
                ToastHelper.Error(error === null ? 'Check logs in console' : error);
            }

            if (fileType === "CSV") {
                if (CommonHelper.IsFunction(this.props.onLoading)) {
                    this.props.onLoading()
                }

                if (download.customApi)
                    FetchHelper.DoFetch(`${download.customApi}`, 'GET', null, successCallback, errorCallback, false, false, true);
                else
                    FetchHelper.DoFetch(`${download.baseApi}/GetCsv`, 'GET', null, successCallback, errorCallback, false, false, true);
            }
            else {
                if (CommonHelper.IsFunction(this.props.onLoading)) {
                    this.props.onLoading()
                }

                if (download.customApi)
                    FetchHelper.DoFetch(`${download.customApi}`, 'GET', null, successCallback, errorCallback, false, false, true);
                else
                    FetchHelper.DoFetch(`${download.baseApi}/GetXlsx/${fileType}`, 'GET', null, successCallback, errorCallback, false, false, true);
            }
        }
        return <Tooltip key={key} title={CommonHelper.GetTrans("buttons.export_to", { type: fileType })}>
            <IconButton aria-label={CommonHelper.GetTrans("buttons.export_to", { type: fileType })} onClick={downloadExport} >
                <MSTeamsIcon className="d-inline-block" aria-label={'MSExcel'} iconWeight={MSTeamsIconWeight.Light} iconType={MSTeamsIconType['MSExcel']} />
            </IconButton>
        </Tooltip>;
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { numSelected, classes, title, columns, filter, modal, selectedData, customActions, canDelete } = this.props;

        const filterBtn = <>
            <Tooltip title={CommonHelper.GetTrans("buttons.filter")}>
                <IconButton aria-label={CommonHelper.GetTrans("buttons.filter")} className="text-livion-blue" onClick={this.handleClick} >
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id="simple-popper"
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.typography}>
                    <DatagridFilter
                        columns={columns}
                        filter={filter}
                        onFilter={this.createFilterHandler}
                        onResetFilter={this.createResetFilterHandler}
                    />
                </div>
            </Popover>
        </>;

        const deleteBtn = (CommonHelper.IsEmpty(canDelete) || canDelete) && !CommonHelper.IsEmpty(modal) &&
            !(title === "Ricerca" && !CommonHelper.IsEmpty(selectedData) && !CommonHelper.IsEmpty(selectedData.status) && selectedData.status === "Attivazione") ?
            <Tooltip title={CommonHelper.GetTrans("buttons.delete")}>
                <IconButton aria-label={CommonHelper.GetTrans("buttons.delete")} onClick={this.createDeleteHandler()} className="text-danger" >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            : <></>;

        const modalBtn = !CommonHelper.IsEmpty(modal) ?
            <Tooltip title="">
                <EnhancedModal modal={modal} isEdit={numSelected > 0} disabled={numSelected > 1} selectedData={selectedData} clearSelected={this.props.clearSelected} />
            </Tooltip>
            : <></>;

        const btnDownloadXlsxCsv = this.createExportHandler();

        let btnCustomActionsNone = null;
        let btnCustomActionsSingle = null;
        let btnCustomActionsMultiple = null;

        if (!CommonHelper.IsEmpty(customActions)) {
            btnCustomActionsNone = !CommonHelper.IsEmpty(customActions.none) && customActions.none.map((customAction, key, a, s) => { return customAction(key) });
            btnCustomActionsSingle = !CommonHelper.IsEmpty(customActions.single) && customActions.single.map((customAction, key) => { return customAction(selectedData, key) });
            btnCustomActionsMultiple = !CommonHelper.IsEmpty(customActions.multiple) && customActions.multiple.map((customAction, key) => { return customAction(selectedData, key) });
        }

        let actionButtons = null;

        if (this.props.readOnly){
            actionButtons = <>{filterBtn}{btnDownloadXlsxCsv}{btnCustomActionsNone}</>;
        }
        else {
            switch (numSelected) {
                case 0:
                    actionButtons = <>{modalBtn}{filterBtn}{btnDownloadXlsxCsv}{btnCustomActionsNone}</>;
                    break;
                case 1:
                    actionButtons = <>{deleteBtn}{filterBtn}{modalBtn}{btnCustomActionsSingle}</>;
                    break;
                default:
                    actionButtons = <>{deleteBtn}{filterBtn}{modalBtn}{btnCustomActionsMultiple}</>;
                    break;
            }
        }

        const tableTitle = numSelected === 0 ?
            <Typography variant="h6" id="tableTitle">
                {title}
            </Typography>
            : <Typography color="inherit" variant="subtitle1">
                {numSelected} selezionat{numSelected === 1 ? 'o' : 'i'}
            </Typography>;

        return (
            <Toolbar className={classNames(classes.root, { [classes.highlight]: numSelected > 0, })}>
                <div className={classes.title}>
                    {tableTitle}
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {actionButtons}
                </div>
            </Toolbar>
        );
    }
};

DatagridToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatagridToolbar); 