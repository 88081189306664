import React, { useContext, useEffect, useState } from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';
import CSVReader from 'react-csv-reader'

import Avatar from 'react-avatar';

import { Users, Groups, teamsRolesEnum } from 'libs/microsoft-graph-service';
import { TeamsContext, teamsTabStyles, TabNavBar, ButtonTooltip, PopupWithButton, ConfirmDialog, rolesEnum } from "libs/teams-tab";

import {
    Flex, Segment, Dialog, Button, Text, Loader, Grid, Divider, Card, List, Input, Checkbox,
    SyncIcon, MoreIcon, SearchIcon, AttendeeIcon, AddIcon, TrashCanIcon,
    LightningIcon, ApprovalsAppbarIcon, CloseIcon, AcceptIcon, InfoIcon, RetryIcon, radioGroupItemSlotClassNames,
} from '@fluentui/react-northstar'

import { DatePicker, DayOfWeek, mergeStyleSets } from 'office-ui-fabric-react';

const dpStyles = {
    root: { /* styles */ },
    textField: { /* styles */ },
    icon: { /* styles */ },
    callout:
    {
        selectors:
        {
            '& button.ms-DatePicker-goToday': {
                right: 14,
                textAlign: 'right',
            }
        }
    }
};

const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 0 0',
        maxWidth: '200px'
    },
});

const DayPickerStrings = {
    months: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ],

    shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],

    days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],

    shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],

    goToToday: 'Oggi',
    prevMonthAriaLabel: 'Mese precedente',
    nextMonthAriaLabel: 'Mese successivo',
    prevYearAriaLabel: 'Anno precedente',
    nextYearAriaLabel: 'Anno successivo',
    closeButtonAriaLabel: 'Chiudi',
    monthPickerHeaderAriaLabel: '{0}, seleziona per cambiare mese',
    yearPickerHeaderAriaLabel: '{0}, seleziona per cambiare anno',
};

export const CreateDialog = ({ reload, ...props }) => {
    var startOfDay = new Date();
    startOfDay.setHours(8, 30, 0, 0);

    var endOfDay = new Date();
    endOfDay.setHours(12, 30, 0, 0);

    const teamsContext = useContext(TeamsContext);
    const [subject, setSubject] = useState("");
    const [startDate, setStartDate] = useState(startOfDay);
    const { msTeamsContext, teamChannel } = teamsContext;
    const { groupId, upn } = msTeamsContext;
    let dialogHeader = null;
    let dialogContent = null;
    
    const [open, setOpen] = useState(false)

    const onCancel = () => {
        setOpen(false);
    }

    useEffect(() => {
    }, []);

    const onConfirm = async () => {
        const data = {
            teamId: groupId,
            msEvent: {
                subject: subject,
                isOrganizer: true,
                start: {
                    dateTime: "2021-02-15T09:30:00",
                    timeZone: "W. Europe Standard Time"
                },
                end: {
                    dateTime: "2021-02-15T12:30:00",
                    timeZone: "W. Europe Standard Time"
                },
                isAllDay: false,
                allowNewTimeProposals: false,
                attendees: [
                    {
                        emailAddress: {
                            address: "4dfcdbfa.cwstestarea.onmicrosoft.com@emea.teams.ms",
                            name: "Test_1"
                        },
                        type: "required"
                    }
                ],
                hideAttendees: false,
                type: "singleInstance",
            }
        };

        let result = await teamsContext.dataProviders.bot.create('createEvent', { data, addMsToken: true, teamChannelId: teamsContext.teamChannel.id });
        
        if (result && result.data) {
            const { success, error } = result.data;

            if (success){
                ToastHelper.Success(`Evento creato`);
                reload();
            }
            else{
                console.error(error)
                ToastHelper.Error(`Evento non creato`);
            }
         }
        else {
            ToastHelper.Error(`Errore di comunicazione con il Server`);
        }
    }

    dialogHeader = (
        <Text content="Crea un nuovo evento" />
    );

    let items = [
        <Input key={"subject"} name="subject" autoFocus fluid placeholder="Titolo" value={subject} onChange={(e) => setSubject(e.target.value)} />,
        <DatePicker
            key={"startDate"}
            calendarOpenState={false}
            className={controlClass.control}
            styles={dpStyles}
            strings={DayPickerStrings}
            firstDayOfWeek={DayOfWeek.Monday}
            onSelectDate={setStartDate}
            showGoToToday
            showCloseButton
            placeholder="Seleziona una data..."
            ariaLabel="Seleziona una data..."
            formatDate={(date) => date.toLocaleDateString()}
            value={startDate}
        />,
        <br key={"br_1"} />,
        <br key={"br_2"} />,
        <br key={"br_3"} />
    ];

    dialogContent = (
        <List items={items} />
    );

    return (
        <Dialog
            cancelButton="Chiudi"
            confirmButton="Conferma"
            onCancel={onCancel}
            onConfirm={onConfirm}
            onOpen={() => setOpen(true)}
            open={open}
            content={dialogContent}
            header={dialogHeader}
            styles={{ minHeight: 400 }}
            trigger={<ButtonTooltip icon={<AddIcon />} secondary iconOnly content="" tooltip="Crea" />}
        />
    )
}