import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import CircularProgress from '@material-ui/core/CircularProgress';

import livionlogo from 'assets/img/brand/livion-logo.png';

class LivionLoaderExt extends React.Component {
  render() {
    const { classes, fullWidth, small } = this.props;

    let style = {};

    if (small) {
      style = {
        padding: '45px 45px 25px 45px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: '#ffffff',
        backgroundColor: 'rgb(255, 255, 255, 0.4)'
      }
    }
    else {
      style = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: '#ffffff'
      }
    }

    return (
      small ?
        (
          <div style={style}>
            <div className="text-center">
              <img className={fullWidth ? 'w-100' : ''} src={livionlogo} alt="" />
              <br />
              <CircularProgress className={classes.progress} />
            </div>
          </div>
        ) :
        (
          <div style={style} className='bg-livion-white'>
            <img className={fullWidth ? 'w-100' : ''} src={livionlogo} alt="" />
          </div>
        )
    )
  }
}

export default withStyles(styles)(LivionLoaderExt);