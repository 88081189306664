import React, { Component } from "react";
import Cookies from 'universal-cookie';
import { CommonHelper, FetchHelper } from 'components/Common/Helper/Helper';
import { AdminDashboardContext } from "libs/admin-dashboard";

import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS,
    APP_REDIRECT
} from "./ms-utils";
import { WindowUtils } from "msal";

//import * as MsGraph from 'components/Common/EnhancedAuth/Microsoft/MicrosoftGraphService.js';

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
// const useRedirectFlow = true;

export default C =>
    class MicrosoftProvider extends Component {
        static contextType = AdminDashboardContext;

        constructor(props) {
            super();

            this.auth = React.createRef();

            this.state = {
                loading: false,
                loggedIn: false, 
                account: null,
                error: null,
                msMe: null
            };
        }

        async acquireToken(request, redirect) {
            return msalApp.acquireTokenSilent(request).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msalApp.acquireTokenRedirect({
                            ...request,
                            redirectUri: APP_REDIRECT.LOGIN
                        })
                        : msalApp.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error);
                    this.setState({ loading: false, account: null, error: null, msMe: null });
                    msalApp.clearCache();
                }
            });
        }

        async onSignIn(redirect) {
            if (redirect) {
                return msalApp.loginRedirect({ ...GRAPH_REQUESTS.LOGIN, redirectUri: APP_REDIRECT.LOGIN });
            }

            const loginResponse = await msalApp
                .loginPopup(GRAPH_REQUESTS.LOGIN)
                .catch(error => {
                    if (error.errorCode !== 'user_cancelled') {
                        this.setState({ error: error.message });
                    }
                });

            if (loginResponse) {
                // this.setState({ account: loginResponse.account, error: null });

                // let msMe = await MsGraph.getUserDetails();

                // if (!CommonHelper.IsEmpty(msMe)) {
                //     this.setState({ msMe: msMe, loading: true }, async () => {
                //         let email = CommonHelper.IsEmpty(msMe.mail) ? msMe.userPrincipalName : msMe.mail;
                //         let data = { email: email, providerId: msMe.id, providerType: 'microsoft' };
                //         let { success, errorMessage } = await this.context.onSignInSuccess(data);
                //         this.setState({ loggedIn: success, error: success ? null : errorMessage, loading: false });

                //         if (!success) {
                //             msalApp.clearCache();
                //             this.setState({ loggedIn: false, error: errorMessage, loading: false, account: null });
                //         }
                //         else{
                //             this.setState({ loggedIn: true, error: null, loading: false });
                //         }
                //     });
                // }
                // else {
                //     let errorMessage = "Cannot retrieve user info";
                //     this.setState({ loggedIn: false, error: errorMessage, loading: false });
                // }
            }
        }

        onSignOut() {
            msalApp.logout();
            //msalApp.clearCache();
            this.setState({ account: null, loading: false, loggedIn: false, error: null });
            this.context.onSignOutSuccess();
        }

        async componentDidMount() {
            msalApp.handleRedirectCallback(error => {
                if (error) {
                    const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                    // setState works as long as navigateToLoginRequestUrl: false
                    this.setState({ error: errorMessage });
                }
            });

            const account = msalApp.getAccount();

            if (account){
                msalApp.clearCache();
                this.setState({ account: null });
            }

            // this.setState({ account });

            // if (account && !WindowUtils.isInIframe()) {
            //     let msMe = await MsGraph.getUserDetails();

            //     if (!CommonHelper.IsEmpty(msMe)) {
            //         this.setState({ msMe: msMe, loading: true }, async () => {
            //             let email = CommonHelper.IsEmpty(msMe.mail) ? msMe.userPrincipalName : msMe.mail;
            //             let data = { email: email, providerId: msMe.id, providerType: 'microsoft' };
            //             let { success, errorMessage } = await this.context.onSignInSuccess(data);
            //             this.setState({ loggedIn: success, error: success ? null : errorMessage, loading: false });

            //             if (!success) {
            //                 msalApp.clearCache();
            //             }
            //         });
            //     }
            //     else {
            //         let errorMessage = "Cannot retrieve user info";
            //         this.setState({ loggedIn: false, error: errorMessage, loading: false });
            //     }
            // }
        }

        render() {
            return (
                <C
                    {...this.props}
                    ref={this.auth}
                    loading={this.state.loading}
                    account={this.state.account}
                    error={this.state.error}
                    msMe={this.state.msMe}
                    onSignIn={() => this.onSignIn(useRedirectFlow)}
                    onSignOut={() => this.onSignOut()}
                />
            );
        }
    };