/* eslint-disable no-unused-vars */

import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js'

import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import parse from 'html-react-parser';

const controls = [
    "bold", "italic", "underline", "strikethrough", "undo", "redo", "numberList", "bulletList", "quote"
];

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {},
            editor: {
                marginBottom: 5,
                minHeight: 150,
                backgroundColor: '#ffffff',
                padding: 10
            },
            hidePlaceholder: {
                display: 'block',
                height: '100%'
            }
        }
    }
})

class MUIRichTextEditorExt extends React.Component {

    constructor(props) {
        super();

        this.onHandleChange = this.onHandleChange.bind(this);

        this.state = {
            editorText: "",
        };
    }

    componentDidMount() {
        const { value } = this.props;

        const contentHTML = convertFromHTML(value)
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        const content = JSON.stringify(convertToRaw(state))

        this.setState({ editorText: content });
    }

    onHandleChange = (editorState) => {
        const { name } = this.props;
        const value = stateToHTML(editorState.getCurrentContent());

        if (CommonHelper.IsFunction(this.props.onChange))
            this.props.onChange(value, name, !CommonHelper.IsEmpty(this.props.callback) ? this.props.callback(value) : undefined);

        if (!CommonHelper.IsEmpty(this.props.handle) && this.props.handle.length > 0) {
            switch (this.props.type) {
                case 'number':
                    this.props.handle.map(h => this.props.onTrigger(CommonHelper.Compute(h.value, value, this.props.decimals, this.props.commons), h.field));
                    break;
                default:
                    this.props.handle.map(h => this.props.onTrigger(value, h.field));
                    break;
            }
        }
    }

    render() {
        const { editorText } = this.state;
        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value,
            label, name, ...rest
        } = this.props;

        return (
            <>
                {label && <label>{label}</label>}
                <MuiThemeProvider theme={defaultTheme}>
                    <MUIRichTextEditor
                        defaultValue={editorText}
                        controls={controls}
                        inlineToolbar={true}
                        onChange={this.onHandleChange}
                    />
                </MuiThemeProvider>
            </>
        );
    }
}

export default MUIRichTextEditorExt;