import React from "react";
import { Route, Switch } from "react-router-dom";
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { getSEO } from 'SEO';

import * as Public from "views/Public/Public.js";
import * as Admin from "views/Admin/Admin.js";

import { LivionLoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import CookieConsent from "react-cookie-consent";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const AdminNavbarWithRef = React.forwardRef((props, ref) => <Admin.AdminNavbar ref={ref} props={props} />);

class RouteExt extends React.Component {
  constructor(props) {
    super();

    this.state = {
      user: {}
    };
  }

  componentDidUpdate() {
    if (this.props.layout === '/admin') {
    }
  }

  getRoutes = routeslist => {
    const role = CommonHelper.IsEmpty(CommonHelper.GetUserRole()) ? 'admin' : CommonHelper.GetUserRole();
    let isLogged = CommonHelper.IsLogged();

    let routes = routeslist.filter(rl => rl !== null && this.props.layout === rl.layout).map(({ path, layout, render, roles }, key) => {
      if ((roles.includes(role) || roles.includes('*'))) {
        switch (layout) {
          case '/admin':
            if (isLogged)
              return <Route path={layout + path} render={render} key={key} />;
          case '/mobile':
            return <Route path={layout + path} render={render} key={key} />;
          case '/public':
            return <Route path={path} render={render} key={key} />;
        }
      }
      else {
        return <Route path={layout + path} render={props => (<Admin.Unauthorized {...this.props} user={this.state.user} seo={getSEO('Admin.Unauthorized')} />)} key={key} />;
      }
    });

    return routes;
  };

  getBrandText = routeslist => {
    let pathname = this.props.location.pathname;
    for (let i = 0; i < routeslist.length; i++) {
      if (pathname === `${routeslist[i].layout}${routeslist[i].path}`) {
        if (routeslist[i].layout === "/admin")
          return routeslist[i].name;
        else
          return "Unauthorized";
      }
    }
    return "";
  };

  render() {
    const { user } = this.state;

    const parentFolders = {
      reports: CommonHelper.GetTrans("common.pagetitle.admin.reports"),
      surveys: CommonHelper.GetTrans("common.pagetitle.admin.surveys")
    }

    const routeslist = [
      {
        path: "/reports/channel",
        name: CommonHelper.GetTrans("common.pagetitle.admin.reportbycourse"),
        icon: "fas fa-chart-bar text-livion-blue",
        render: props => (<Admin.ReportByCourse {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: false,
        parentFolder: parentFolders.reports,
        roles: ['admin', 'Organizer']
      },
      {
        path: "/reports/call",
        name: CommonHelper.GetTrans("common.pagetitle.admin.reportbylesson"),
        icon: "fas fa-chart-bar text-livion-blue",
        render: props => (<Admin.ReportByLesson {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        parentFolder: parentFolders.reports,
        roles: ['*']
      },
      {
        path: "/surveys",
        name: CommonHelper.GetTrans("common.pagetitle.admin.surveys"),
        icon: "fas fa-poll text-livion-blue",
        render: props => (<Admin.Surveys {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        parentFolder: parentFolders.surveys,
        roles: ['admin']
      },
      {
        path: "/questions",
        name: CommonHelper.GetTrans("common.pagetitle.admin.questions"),
        icon: "fas fa-question text-livion-blue",
        render: props => (<Admin.Questions {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        parentFolder: parentFolders.surveys,
        roles: ['admin']
      },
      {
        path: "/mseventsurveys",
        name: CommonHelper.GetTrans("common.pagetitle.admin.mseventsurveys"),
        icon: "fas fa-question text-livion-blue",
        render: props => (<Admin.MsEventSurveys {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        parentFolder: parentFolders.surveys,
        roles: ['admin']
      },
      {
        path: "/msteamsimporter",
        name: CommonHelper.GetTrans("common.pagetitle.admin.msteamsimporter"),
        icon: "fas fa-file-import text-livion-blue",
        render: props => (<Admin.MSTeamsImporter {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        roles: ['admin', 'Organizer']
      },
      {
        path: "/botconfigurations",
        name: CommonHelper.GetTrans("common.pagetitle.admin.botconfigurations"),
        icon: "fas fa-robot text-livion-blue",
        render: props => (<Admin.BotConfigurations {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: true,
        roles: ['admin']
      },
      {
        path: "/temp",
        name: CommonHelper.GetTrans("common.pagetitle.admin.liked_products"),
        icon: "far fa-thumbs-up text-livion-blue",
        render: props => (<Admin.Temp {...this.props} user={user} />),
        layout: "/admin",
        inSidebar: false,
        roles: ['*']
      },
      {
        path: "/login",
        name: CommonHelper.GetTrans("common.pagetitle.public.login"),
        icon: "ni ni-key-25 text-livion-blue",
        render: props => (<Public.Login {...this.props} seo={getSEO('Public.Login')} user={user} />),
        layout: "/public",
        inSidebar: false,
        roles: ['*']
      },
      {
        path: "/permissions",
        name: CommonHelper.GetTrans("common.pagetitle.public.permissions"),
        icon: "ni ni-key-25 text-livion-blue",
        render: props => (<Public.Permissions {...this.props} seo={getSEO('Public.Permissions')} user={user} />),
        layout: "/public",
        inSidebar: false,
        roles: ['*']
      },
      {
        path: "/",
        name: CommonHelper.GetTrans("common.pagetitle.public.homepage"),
        icon: "ni ni-key-25 text-livion-blue",
        render: props => (<Public.HomePage {...this.props} seo={getSEO('Public.HomePage')} user={user} />),
        layout: "/public",
        inSidebar: false,
        roles: ['*']
      },
    ];

    const { layout, logo } = this.props;
    const routes = <Switch>{this.getRoutes(routeslist)}</Switch>;
    
    if (layout === '/admin') {
      return <>
        <Admin.Sidebar {...this.props} user={user} routes={routeslist} logo={logo} />
        <div className="main-content" ref="mainContent">
          <AdminNavbarWithRef {...this.props} ref="adminNavbar" user={user} brandText={this.getBrandText(routeslist)} />
          {routes}
          <Container fluid>
            <Admin.AdminFooter />
          </Container>
        </div>
      </>;
    }
    else {
      return (
        <Container fluid id="livion-body" className="px-0 h-100 bg-livion-blue">
          <Row id="livion-body-row" className="no-gutters p-0 h-100">
            <Col xs="12" id="livion-body-col" className="no-gutters p-0 h-100">
              <Container fluid id="livion-page-content" className="h-100 w-100 p-0">
                <Row className="no-gutters h-100">
                  <Col className="no-gutters h-100">
                      {routes}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default RouteExt;