/* eslint-disable no-unused-vars */

import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import { CommonHelper } from 'components/Common/Helper/Helper';

import AdminDashboardContext from './AdminDashboardContext';
import { msalApp } from "components/Common/EnhancedAuth/Microsoft/ms-utils";

const styles = theme => ({})

class AdminDashboard extends React.Component {
    constructor(props) {
        super();

        this.setData = (data, callback) => {
            this.setState({ ...data }, callback);
        };

        this.onSignInSuccess = async (data) => {
            if (CommonHelper.IsEmpty(data)) {
                console.log('Missing data');
                return { success: false, errorMessage: 'Missing data' };
            }

            let { success, error } = await this.props.authProvider.login(data).then(result => result).catch(result => result);

            if (success) {
                this.setState({ loginStatus: null }, () => {
                    props.history.push("/admin");
                });
                return { success};
            }
            else {
                let errorMessage = "Login failed";

                if (CommonHelper.IsObject(error)) {
                    errorMessage = error.description;
                }
                else {
                    console.log(error);
                }
                return { success, errorMessage };
            }
        }

        this.onSignOutSuccess = async () => {
            let { redirectTo } = await this.props.authProvider.logout().then(result => result);
            console.log("SIGNED OUT");

            if (CommonHelper.GetLoginProviderType() === "microsoft") { msalApp.logout(); }

            this.setState({ loginStatus: null, loading: false }, () => {
                props.history.push(redirectTo);
            });
        }

        this.state = {
            authProvider: props.authProvider,
            dataProvider: props.dataProvider,
            loginStatus: null,
            loading: false,
            seo: null,
            currentPage: null,
            setData: this.setData,
            onSignInSuccess: this.onSignInSuccess,
            onSignOutSuccess: this.onSignOutSuccess
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
        }
    }

    componentWillMount() {
    }

    render() {
        let { seo } = this.state;

        return (
            <>
                {
                    seo && (
                        <Helmet>
                            <title>CWS MEETINGS {!CommonHelper.IsEmpty(seo.title) ? `- ${seo.title}` : ''}</title>
                            {!CommonHelper.IsEmpty(seo.description) && <meta name="description" content={seo.description} />}
                            {!CommonHelper.IsEmpty(seo.keywords) && <meta name="keywords" content={seo.keywords} />}
                        </Helmet>
                    )
                }
                <AdminDashboardContext.Provider value={this.state}>
                    {this.props.children}
                </AdminDashboardContext.Provider>
            </>
        );
    }
}

export default withStyles(styles)(AdminDashboard); 