import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

import { formData, getTableColumns } from './DataModels';
import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';
import Form from './Form';
import { LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";

const defaultData = CommonHelper.Clone(formData);

const baseApi = 'surveys';
const orderTableBy = "title";

let msg = null;

class Table extends React.Component {

  constructor(props) {
    super();

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.handleOnOpenForm = this.handleOnOpenForm.bind(this);
    this.handleUpdateFormData = this.handleUpdateFormData.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isLoaded = this.isLoaded.bind(this);

    this.getData = this.getData.bind(this);
    this.getRelatedData = this.getRelatedData.bind(this);

    this.state = {
      data: {
        columns: getTableColumns(),
        rows: []
      },
      formData: formData,
      relatedData: null,
      mode: null,
      loading: true,
      updatingData: false,
      serverErrorMessages: null
    };

    msg = {
      tableTitle: CommonHelper.GetTrans("surveys.table.title"),
      create: CommonHelper.GetTrans("surveys.modal.created"),
      edit: CommonHelper.GetTrans("surveys.modal.edited"),
      delete: CommonHelper.GetTrans("surveys.modal.deleted"),
      createModalTitle: CommonHelper.GetTrans("surveys.modal.new_title"),
      editModalTitle: CommonHelper.GetTrans("surveys.modal.edit_title")
    }
  }

  componentWillMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
      if (this.props.location.state !== undefined) {
      }
    }
  }

  isLoaded = () => {
    if (this.state.loading)
      setTimeout(() => { this.setState({ loading: false }) }, 500);
  }

  isLoading = () => {
    if (this.state.loading === false)
      this.setState({ loading: true });
  }

  getData(callback) {
    let successCallback = (response) => response.json().then((json) => {
      try {
        return json;
      }
      catch (error) {
        ToastHelper.Error(error);
      }
    });

    let errorCallback = (error) => {
      if (error !== null)
        ToastHelper.Error(error);
    }

    CommonHelper.WaitAsyncData(FetchHelper.DoFetch(`${baseApi}?$filter=enabled eq true`, 'GET', null, successCallback, errorCallback)).then((rows) => {
      let data = { ...this.state.data };
      data.rows = rows;
      
      this.setState({
        data: data
      }, () => {
          this.isLoaded();
          this.getRelatedData();

          if (this.table && CommonHelper.IsFunction(this.table.handleUnselectAllClick))
            this.table.handleUnselectAllClick();

          if (CommonHelper.IsFunction(callback))
            callback();
      });
    });
  }

  getRelatedData() {
    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); }

    let fetchList = [];

    if (fetchList.length > 0){
      FetchHelper.DoMultipleFetch(fetchList).then(results => {
        this.setState({ relatedData: results });
      });
    }
  }

  handleUpdateFormData = (value, field, callback) => {
    let formData = { ...this.state.formData };
    formData[field] = value;
    this.setState({
      formData: formData
    });
    if (callback !== undefined)
      callback(value);
  }

  handleOnOpenForm = (isEdit, selectedData) => {
    this.setState({
      formData: isEdit ? selectedData : defaultData,
      mode: isEdit ? 'edit' : 'create'
    });
  }

  handleOnDelete = (event, items) => {
    let successCallback = (response) => response.json().then((json) => {
      ToastHelper.Success(msg.delete);
      this.getData();
    });

    let errorCallback = (error) => {
      if (error !== null)
        ToastHelper.Error(error);
    }

    while (items.length > 0) {
      FetchHelper.DoFetch(`${baseApi}/${items[0]}`, 'DELETE', null, successCallback, errorCallback);
      items.splice(0, 1);
    }
  }

  handleOnSubmit = (form, onSuccess) => {
    const { formData } = this.state;
    
    let successCallback = () => {
      this.getData(() => this.setState({ updatingData: false }, () => {
        onSuccess();
        ToastHelper.Success(formData.id > 0 ? msg.edit : msg.create);
      }));
    };

    let errorCallback = (error) => {
      this.setState({ updatingData: false }, () => {
        if (error !== null) {
          if (CommonHelper.IsEmpty(error.errors)) {
            ToastHelper.Error(error.message);
          }
          else {
            let serverErrorMessages = [];

            for (var propertyName in error.errors)
              serverErrorMessages.push({ field: propertyName, message: error.errors[propertyName] });

            this.setState({ serverErrorMessages: serverErrorMessages, form: form }, () => {
              form.isFormValid(false).then(valid => {
                this.setState({ serverErrorMessages: null });
              });
            });
          }
        }
      })
    }

    this.setState({ updatingData: true }, () => {
      if (formData.id > 0) {
        FetchHelper.DoFetch(`${baseApi}/${formData.id}`, 'PUT', formData, successCallback, errorCallback);
      }
      else {
        FetchHelper.DoFetch(baseApi, 'POST', formData, successCallback, errorCallback);
      }
    });
  }

  handleOnCancel = () => {
    this.setState({
      formData: defaultData
    });
  }

  render() {
    const { mode, formData, serverErrorMessages, form, updatingData, data, loading } = this.state;
    const { user } = this.props;

    const modal = {
      newTitle: msg.createModalTitle,
      editTitle: msg.editModalTitle,
      form: <Form updateFormData={this.handleUpdateFormData} mode={mode} formData={formData} user={user} serverErrorMessages={serverErrorMessages} form={form} updatingData={updatingData} />,
      updatingData: updatingData,
      onSubmit: this.handleOnSubmit,
      onCancel: this.handleOnCancel,
      openForm: this.handleOnOpenForm
    };

    return (
      <>
        <Header />
        {/* {loading ?
          <Container fluid>
            <Row className="justify-content-center">
              <LoaderExt />
            </Row>
          </Container> : */}
          <Container className="mt--8" fluid>
            <Row>
              <EnhancedTable
                loadingData={loading}
                innerRef={ref => (this.table = ref)}
                title={msg.tableTitle}
                columns={data.columns}
                data={data.rows}
                //downloadCsv={{ baseApi: baseApi }}
                modal={modal}
                handleRequestDelete={this.handleOnDelete}
                orderBy={orderTableBy}
                updatingData={updatingData}
                handleLoading={this.isLoading}
                handleLoaded={this.isLoaded}
              />
            </Row>
          </Container>
        {/* } */}
      </>
    );
  }
}

export default Table;