import { useState, useRef, useEffect, useCallback } from 'react';
import isEqual from 'lodash/isEqual';

// thanks Kent C Dodds for the following helpers

export function useSafeSetState(initialState) {
    const [state, setState] = useState(initialState);

    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;
        return () => (mountedRef.current = false);
    }, []);
    const safeSetState = useCallback(
        args => {
            if (mountedRef.current) {
                return setState(args);
            }
        },
        [mountedRef, setState]
    );

    return [state, safeSetState];
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useDeepCompareEffect(callback, inputs) {
    const cleanupRef = useRef();
    useEffect(() => {
        if (!isEqual(previousInputs, inputs)) {
            cleanupRef.current = callback();
        }
        return cleanupRef.current;
    });
    const previousInputs = usePrevious(inputs);
}

export function useTimeout(ms = 0) {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let timer = setTimeout(() => {
            setReady(true);
        }, ms);

        return () => {
            clearTimeout(timer);
        };
    }, [ms]);

    return ready;
}

export const useInterval = (callback, delay, runImmediately) => {
    // effect automatically reloads if references to
    // callback, delay, or runImmediately change or
    // when component unmounts
    useEffect(() => {
        // when this flag is set, indicates callback is stale
        let cancelled = false;

        // wrap callback to pass isCancelled function as a parameter
        function func() {
            callback(() => cancelled);
        }

        // set interval and run immediately if requested
        const id = setInterval(func, delay);
        if (runImmediately) func();

        // define cleanup logic that runs when effect is
        // unloaded by a state change or component unmounting
        return () => {
            cancelled = true;
            clearInterval(id);
        };
    }, [callback, delay, runImmediately]);
}