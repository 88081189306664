import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

export const formData = {
    "text": "",
    "surveyId": undefined,
    "type": undefined,
    "timeFrame": 1,
    "answers": [],
};

export const getSchema = () => {
    return {
        "fields": {
            "text": {
                "name": "text", "type": "string", "label": CommonHelper.GetTrans("questions.fields.text"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "surveyId": {
                "name": "surveyId", "options": [], "type": "number", "label": CommonHelper.GetTrans("questions.fields.surveyId"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "type": {
                "name": "type", "options": [], "type": "number", "label": CommonHelper.GetTrans("questions.fields.type"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "timeFrame": {
                "name": "timeFrame", "type": "number", "label": CommonHelper.GetTrans("questions.fields.timeFrame"), min: 1
            },
            "answers": {
                "name": "answers", "type": "object", "label": CommonHelper.GetTrans("questions.fields.answers"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
        }
    };
}

export const getTableColumns = () => {
    return [
        { id: 'survey.title', label: CommonHelper.GetTrans("questions.fields.surveyId"), type: 'string', filter: true },
        { id: 'text', label: CommonHelper.GetTrans("questions.fields.text"), type: 'string', filter: true },
        { id: 'type', label: CommonHelper.GetTrans("questions.fields.type"), type: 'string', filter: true },
        { id: 'timeFrame', label: CommonHelper.GetTrans("questions.fields.timeFrame"), size: 'small', align: 'center', filter: true },
        { id: 'answers', label: CommonHelper.GetTrans("questions.fields.answers"), type: 'count', size: 'small', align: 'center' },
        { id: 'createdAt', label: CommonHelper.GetTrans("questions.fields.createdAt"), type: 'datetime-local', size: 'small', align: 'center' }
    ];
}