/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from 'react-router';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';

import * as microsoftTeams from "@microsoft/teams-js";

import { TeamsContext, teamsTabStyles, TabNavBar, ButtonTooltip, ConfirmDialog } from "libs/teams-tab";

import {
  Flex, Segment, Text, Loader, Grid, Divider, Card,
  Image, Input, SyncIcon, AcceptIcon, SearchIcon,
} from '@fluentui/react-northstar'

import { Icon } from '@fluentui/react/lib/Icon';

let myContext = null;

const CardSurvey = ({ index, survey, callId }) => {
  const { graphAccessToken } = myContext;
  const selectSurvey = async () => {
    let result = await myContext.dataProviders.bot.create(`addSurveyToLesson/${callId}/${survey.id}`, { addMsToken: true, teamChannelId: myContext.teamChannel.id });

    if (result && result.data && result.data.success) {
      setTimeout(() => {
        microsoftTeams.tasks.submitTask(null);
      }, 1500);
    }
    else {
      ToastHelper.Error("Errore durante l'aggiunta del sondaggio alla lezione");
    }
  };

  return (
    <div key={index} style={teamsTabStyles.cardContainer}>
      <Card compact style={teamsTabStyles.card}>
        <Card.TopControls styles={{ top: 0, left: 0 }}>
          <ButtonTooltip secondary text iconOnly noBg icon={<Icon iconName={survey.global ? "Globe" : "TeamsLogo"} className="p-2" style={{fontSize: 18}} />} tooltip={survey.global ? "Pubblico" : "Privato"} />
        </Card.TopControls>
        <Card.TopControls styles={{ top: 0 }}>
          <ConfirmDialog
            header="Conferma sondaggio?"
            content={`'${survey.title}' creato il ${moment(survey.createdAt).format('DD/MM/YYYY HH:mm')}`}
            triggerButton={
              <ButtonTooltip secondary text iconOnly noBg icon={<Icon iconName="SkypeCheck" className="p-2" style={{ fontSize: 18 }} />} tooltip={"Seleziona"} />
            } 
            onConfirmCallback={selectSurvey}
          />
        </Card.TopControls>
        <Card.Preview style={teamsTabStyles.cardPreviewSmall} fitted >
          <Image fluid src="https://cdn.forms.office.net/forms/images/theme/default-2.png" />
        </Card.Preview>
        <Card.Body style={teamsTabStyles.cardBody} fitted>
          <Flex padding="padding.medium" column>
            <Text as="h2" content={survey.title} weight="bold" />
            <Text as="h3" content={survey.description} />
          </Flex>
        </Card.Body>
      </Card>
    </div>
  )
}

class SurveysChoice extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      callId: null,
      surveys: null,
      filter: {
        title: null
      }
    };
  }

  componentDidMount() {
    let callId = null;

    if (this.props.match.params) {
      if (this.props.match.params.callId) {
        callId = this.props.match.params.callId;
      }
    }

    this.setState({ callId }, this.getSurveys)
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  setFilter = debounce((field, query) => {
    let { filter } = this.state;
    filter[field] = query ? query.toLowerCase() : null;
    this.setState({ filter }, this.getSurveys);
  }, 1000);

  getSurveys = () => {
    const { channelId } = myContext.msTeamsContext;
    const { title } = this.state.filter;

    this.setState({ loading: true }, async () => {
      const filter = { softDelete: false, 'TeamChannel/TeamChannelId': channelId };
      const filterOr = { softDelete: false, global: true };
      const expand = 'callsurveyactivities';
      const sort = { field: 'createdAt', order: 'desc' };

      if (title !== null) {
        filter.title = title;
        filterOr.title = title;
      }

      let result = await myContext.dataProviders.api.getList('surveys', { filter, filterOr, sort, expand });

      if (result && result.data) {
        this.setState({ surveys: result.data, loading: false });
      }
      else {
        this.setState({ surveys: [], loading: false });
      }
    });
  }

  filterByTitle = (value) => {
    this.setFilter('title', value);
  };

  resetFilter = () => {
    this.setState({ filter: { title: null } }, this.getSurveys);
  }

  render() {
    const { loading, surveys, callId } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          return (
            <>
              <TabNavBar title="Elenco Sondaggi" showBack backPageName="Homepage" backPageRoute={`inCallHomePage/${callId}`} icon={<Icon iconName="Questionnaire" />}>
                <Input icon={<SearchIcon />} clearable placeholder="Cerca..." onChange={(e) => this.filterByTitle(e.target.value)} />
                <Divider vertical styles={{ height: '1.7rem' }} />
                <ButtonTooltip icon={<SyncIcon />} secondary iconOnly content="" tooltip="Aggiorna" onClick={this.getSurveys} />
              </TabNavBar>
              <Flex.Item>
                <Segment styles={teamsTabStyles.content} style={{ height: 'calc(100vh - 80px)', overflow: 'auto' }}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      (
                        surveys && surveys.length > 0 ?
                          <Grid columns={4} >
                            {
                              surveys.map((survey, key) => { return (<CardSurvey key={key} index={key} survey={survey} callId={callId} />) })
                            }
                          </Grid> :
                          <Text size="large" content="Nessun sondaggio disponibile" align="center" />
                      )
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default withRouter(SurveysChoice);