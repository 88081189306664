import React from "react";
import { Flex, Text } from '@fluentui/react-northstar'
import { Icon } from '@fluentui/react/lib/Icon';

const WorkInProgress = ({ children, title, icon, showBack, backPageName, backPageRoute = null, ...unhandledProps }) => {
    return (
        <Flex gap="gap.small" padding="padding.medium" vAlign="center" hAlign="center">
            <Icon iconName="BuildDefinition" style={{ fontSize: 30 }} />
            <Text content={"Work in progress"} style={{ fontSize: '1.6rem' }} />
        </Flex>
    )
}

export default WorkInProgress;