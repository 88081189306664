import "isomorphic-fetch";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthError, ClientAuthError, ClientConfigurationError, ServerError, InteractionRequiredAuthError } from "msal";
import { msalApp, GRAPH_REQUESTS, APP_ENV } from "./ms-utils";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import authProvider from "authProvider";

import TeamsAuthService from 'libs/microsoft-graph-service/TeamsAuthService';

export async function UserAuthClient() {
    let token = await acquireToken();

    if (token === null || token.authError) {
        return Promise.reject(token);
    }

    // Initialize Graph client
    const client = Client.init({
        authProvider: (done) => { done(null, token); }
    });

    return client;
}

export async function AppAuthClient() {
    let client;

    if (TeamsAuthService.ssoAuth()) {
        const token = await TeamsAuthService.getApplicationToken();
        try {
            // Initialize Graph client
            client = Client.init({
                authProvider: (done) => { done(null, token); }
            })
        }
        catch {
            client = null;
        }
    }

    return client;
};

export async function OnBehalfAuthClient() {
    let client;

    if (TeamsAuthService.ssoAuth()) {
        const token = await TeamsAuthService.getOnBehalfToken();

        try {
            // Initialize Graph client
            client = Client.init({
                authProvider: (done) => { done(null, token); }
            })
        }
        catch {
            client = null;
        }
    }

    return client;
}

export function clearMsCache() {
    msalApp && msalApp.clearCache();
}

msalApp && msalApp.handleRedirectCallback((error) => {
    if (error) { console.log(error); }
});

const handleError = (error) => {
    switch (error.constructor) {
        case AuthError:
            return { authError: true, type: 'auth', errorCode: error.errorCode, errorMessage: error.errorMessage }
        case ClientAuthError:
            return { authError: true, type: 'client_auth', errorCode: error.errorCode, errorMessage: error.errorMessage }
        case ClientConfigurationError:
            return { authError: true, type: 'client_config', errorCode: error.errorCode, errorMessage: error.errorMessage }
        case ServerError:
            return { authError: true, type: 'server', errorCode: error.errorCode, errorMessage: error.errorMessage }
        case InteractionRequiredAuthError:
            return { authError: true, type: 'interaction', errorCode: error.errorCode, errorMessage: error.errorMessage }
        default:
            return { authError: true, type: 'unhandled', errorCode: "", errorMessage: error.message }
    }
}

export async function acquireToken() {
    return msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN).then(accessTokenResponse => {
        return accessTokenResponse.accessToken;
    }, async silentError => {
        if (handleError(silentError)) {
            let loginResponse = await msalApp.loginPopup(GRAPH_REQUESTS.LOGIN).catch(loginError => handleError(loginError));

            if (CommonHelper.IsEmpty(loginResponse.account)) {
                return loginResponse;
            }
            else {
                return acquireToken();
            }
        } else {
            console.error('Non-interactive error:', silentError);
            msalApp.clearCache();
        }
    });
}

//Exchange the SSO access token for a Graph access token
//Learn more: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-on-behalf-of-flow
export async function acquireOnBehalfToken(ssoToken) {
    try {
        const serverURL = `${CommonHelper.GetBotApiDomain()}/getUserAccessToken`;

        const options = { headers: new Headers({ Accept: 'application/json', }) };
        options.headers.set('MSAuthorization', `Bearer ${ssoToken}`);
        options.headers.set('Content-Type', 'application/json');

        let response = await fetch(serverURL, { ...options }).catch(unhandledFetchError); //This calls getUserAccessToken route on BE

        if (response) {
            let data = await response.json().catch(unhandledFetchError);

            if (!response.ok) {
                return data.error;
            } else {
                return { token: data };
            }
        }
        else {
            return "no_response";
        }
    }
    catch (ex) {
        console.error(ex);
        return ex.message;
    }
}

//Generic error handler ( avoids having to do async fetch in try/catch block )
const unhandledFetchError = (err) => {
    console.error("Unhandled fetch error: ", err);
    return err;
}