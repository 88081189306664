import React, { Component } from "react";
import classNames from 'classnames';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import styles from './AnswerNumeric.module.css';
import parse from 'html-react-parser';

import { TextFieldExt, IconPickerExt, TinyMceExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import { Container, Row, Col, Button } from "reactstrap";

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class AnswerNumeric extends Component {

    constructor(props) {
        super();

        this.openProductDetail = this.openProductDetail.bind(this);
        this.onDetailsChange = this.onDetailsChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.renderProductDetail = this.renderProductDetail.bind(this);

        this.state = {
            open: false,
            key: null,
            mode: null,
            productDetails: CommonHelper.IsEmpty(props.value) ? [] : [...props.value],
            productDetail: {
                title: "",
                info: "",
                icon: ""
            },
            ready: true
        };
    }

    openProductDetail = () => {
        this.setState({ mode: "create", open: true });
    }

    handleCancel = () => {
        this.setState({ productDetail: { title: "", info: "", icon: "" }, mode: null, open: false });
    }

    onDetailsChange = (value, field) => {
        let productDetail = { ...this.state.productDetail };
        productDetail[field] = value;

        this.setState({
            productDetail: productDetail
        });
    }

    handleSave() {
        const { mode, key, productDetail } = this.state;

        const getProductDetails = (prevState) => {
            if (mode === "create") {
                return prevState.productDetails.concat(productDetail);
            }
            else {
                prevState.productDetails[key] = productDetail;
                return prevState.productDetails;
            }
        }

        this.setState(prevState => ({ productDetails: getProductDetails(prevState), productDetail: { title: "", info: "", icon: "" }, mode: null, open: false }), () => {
            if (CommonHelper.IsFunction(this.props.onChange))
                this.props.onChange(this.state.productDetails, this.props.name);
            if (CommonHelper.IsFunction(this.props.callback))
                this.props.callback(this.state.productDetails);
        });
    }

    handleRemove = (key) => {
        let { productDetails } = this.state;

        if (key > -1) {
            productDetails.splice(key, 1);

            this.setState({ productDetails: productDetails }, () => {
                if (CommonHelper.IsFunction(this.props.onChange))
                    this.props.onChange(this.state.productDetails, this.props.name);
                if (CommonHelper.IsFunction(this.props.callback))
                    this.props.callback(this.state.productDetails);
            });
        }
    }

    handleEdit = (key) => {
        this.setState({ key: key, mode: "edit", productDetail: this.state.productDetails[key], open: true });
    }

    renderProductDetail = (productDetail, key) => {
        const { mode } = this.state;
        
        return (
            <Row key={key} className={classNames(styles.ProductDetailWrapper, 'no-gutters')}>
                <Col xs="3" className={classNames(styles.Title, 'd-flex align-items-center')}>
                    {productDetail.title}
                </Col>
                <Col xs="6" className={classNames(styles.Info, 'd-flex align-items-center')}>
                    {parse(productDetail.info)}
                </Col>
                <Col xs="1" className={classNames(styles.Icon, 'd-flex justify-content-center align-items-center')}>
                    <Tooltip title={productDetail.icon}>
                        <IconButton aria-label={productDetail.icon}>
                            <Icon>{productDetail.icon}</Icon>
                        </IconButton>
                    </Tooltip>
                </Col>
                <Col xs="2" className="d-flex justify-content-center align-items-center">
                    {
                        (CommonHelper.IsEmpty(mode) || mode === "create") &&
                        <Tooltip title="Remove">
                            <IconButton onClick={!this.props.disabled ? () => this.handleRemove(key) : null} className="text-danger" >
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        (CommonHelper.IsEmpty(mode) || mode === "edit") &&
                        <Tooltip title="Edit">
                            <IconButton onClick={!this.props.disabled ? () => this.handleEdit(key) : null} >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Col>
            </Row>
        )
    }

    render() {
        const { mode, open, productDetails, productDetail } = this.state;
        const { disabled } = this.props;

        return (
            <Container className={styles.ProductDetailsContainer}>
                <Row className={classNames(styles.Header, 'no-gutters')}>

                    {
                        open && !disabled ?
                            <Col>
                                <Row className="no-gutters">
                                    <Col xs="1" className="d-flex justify-content-center align-items-center">
                                        <IconPickerExt name="icon" onChange={!this.props.disabled ? this.onDetailsChange : null} value={productDetail.icon} />
                                    </Col>
                                    <Col>
                                        <TextFieldExt name="title" label="Titolo" onChange={this.onDetailsChange} value={productDetail.title} disabled={this.props.disabled} />
                                    </Col>
                                </Row>
                                <Row className="no-gutters">
                                    <Col>
                                        <TinyMceExt name="info" label="Info" onChange={this.onDetailsChange} value={productDetail.info} height={150} disabled={this.props.disabled} />
                                    </Col>
                                </Row>
                                <Row className="no-gutters">
                                    <Col className="text-right m-1">
                                        <Button color="primary" onClick={!this.props.disabled ? this.handleSave : null}>{mode === "create" ? "Aggiungi" : "Aggiorna"}</Button>
                                        <Button variant="contained" color="secondary" onClick={this.handleCancel}>Chiudi</Button>
                                    </Col>
                                </Row>
                            </Col> :
                            <Col className="text-right">
                                <Tooltip title="Add new detail">
                                    <IconButton onClick={!this.props.disabled ? this.openProductDetail : null} className="text-success" >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </Col>
                    }
                </Row>
                <Row className={classNames(styles.Content, 'no-gutters')}>
                    <Col className={styles.ProductDetailsList} style={{ maxHeight: open ? 260 : 360 }}>

                        <Row className={classNames(styles.ProductDetailHeaderWrapper, 'no-gutters')}>
                            <Col xs="3" className={classNames(styles.Title, 'd-flex align-items-center')}>
                                Title
                            </Col>
                            <Col xs="6" className={classNames(styles.Info, 'd-flex align-items-center')}>
                                Info
                            </Col>
                            <Col xs="1" className={classNames(styles.Icon, 'd-flex justify-content-center align-items-center')}>
                                Icon
                            </Col>
                            <Col xs="2" className="d-flex justify-content-center align-items-center">
                                Actions
                            </Col>
                        </Row>

                        {
                            productDetails.map((productDetail, key) => {
                                return this.renderProductDetail(productDetail, key);
                            })
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AnswerNumeric;