import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from 'classnames';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { ToastHelper } from 'components/Common/Helper/Helper';
import { TeamsContext, ConfirmDialog, ButtonTooltip, answerTypeEnum } from "libs/teams-tab";

import { Flex, Input, Text, Loader, Checkbox, TrashCanIcon, ArrowUpIcon, ArrowDownIcon, } from '@fluentui/react-northstar'
import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from 'office-ui-fabric-react';

import Answers from './AnswerTypes';

const customClass = mergeStyleSets({
    infosTitle: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.open': {
            backgroundColor: '#f3f3f3',
            cursor: 'default',
        }
    },
    addQuestionButton: {
        height: 50,
        cursor: 'pointer',
        backgroundColor: '#03787C',
        '& .closed-container:hover': {
            backgroundColor: '#014446',
        },
        '& .opened-container': {
            cursor: 'default',
        },
    },
});

export const QuestionBuilder = ({ index, question, openedQuestionId, lastQuestionId, refreshData, addQuestion, setOpenQuestion, setLastId, moveQuestion, moveUpEnabled, moveDownEnabled }) => {
    const teamsContext = useContext(TeamsContext);
    const questionRef = useRef(null);
    const [open, setOpen] = useState(openedQuestionId === question.id);
    const [tempQuestion, setTempQuestion] = useState(null);
    const [questionText, setQuestionText] = useState(question.text);
    const [questionType, setQuestionType] = useState(question.type);
    const [questionTimeFrame, setQuestionTimeFrame] = useState(question.timeFrame);

    useEffect(() => {
        if (lastQuestionId === question.id && !open) {
            setOpen(true);
            window.scrollTo(0, questionRef.current.offsetTop);
        }
        else {
            setOpen(false);
        }
    }, [lastQuestionId]);

    useEffect(() => {
        if (JSON.stringify(question) !== JSON.stringify(tempQuestion)) {
            setTempQuestion({ ...question });
            setQuestionText(question.text);
            setQuestionType(question.type);
            setQuestionTimeFrame(question.timeFrame);
        }
    }, [question]);

    useEffect(() => {
        if (open) {
            setOpenQuestion(question.id)
        }
    }, [open]);

    const toggleMultipleChoice = () => {
        if (tempQuestion.type === answerTypeEnum.MultipleChoice) {
            setQuestionType(answerTypeEnum.SingleChoice);
        }
        else {
            setQuestionType(answerTypeEnum.MultipleChoice);
        }
    };

    useEffect(() => {
        if (tempQuestion) {
            setTempQuestion({ ...tempQuestion, text: questionText, type: questionType });
        }
    }, [questionText, questionType]);

    const handleClick = () => {
        setOpen((prev) => !prev);
        window.scrollTo(0, questionRef.current.offsetTop);
    };

    const handleClickAway = () => {
        if (open && tempQuestion) {
            updateQuestion();
            setOpen(false);
            if (openedQuestionId === question.id) {
                setOpenQuestion(null)
            }
        }
    };

    const updateQuestionAnswers = (answers) => {
        if (open && tempQuestion) {
            setTempQuestion({ ...tempQuestion, answers });
        }
    };

    const updateQuestion = async () => {
        const questionRequestData = { ...tempQuestion };
        let result = await teamsContext.dataProviders.api.update('surveyquestions', { id: questionRequestData.id, data: questionRequestData });

        if (result && result.data) {
            setTempQuestion(result.data);
        }
        else {
            ToastHelper.Error("Errore durante il salvataggio della domanda");
        }
    };

    const copyQuestion = async () => {
        const questionRequestData = { ...tempQuestion };
        delete questionRequestData.id

        let result = await teamsContext.dataProviders.api.create('surveyquestions', { data: questionRequestData });

        if (result && result.data) {
            refreshData();
            setTimeout(() => {
                setOpen(false);
                setLastId(result.data.id)
            }, 500);
        }
        else {
            ToastHelper.Error("Errore durante la copia della domanda");
        }
    };

    const deleteQuestion = async () => {
        let result = await teamsContext.dataProviders.api.delete('surveyquestions', { id: tempQuestion.id });

        if (result && result.data) {
            refreshData();
            setOpen(false);
            setOpenQuestion(null)
        }
        else {
            ToastHelper.Error("Errore durante la cancellazione della domanda");
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div ref={questionRef}>
                {
                    !tempQuestion ?
                        <Loader /> :
                        (
                            open ?
                                <>
                                    <Flex gap="gap.medium" padding="padding.medium" column className={classNames(customClass.infosTitle, 'open')}>
                                        <Flex vAlign="center">
                                            <Text size="large" content={`${index}.`} weight="bold" className="pr-2" />
                                            <Input name="text" inverted fluid placeholder="Inserire la domanda" value={questionText} onChange={(e) => setQuestionText(e.target.value)} />
                                        </Flex>
                                        <Answers question={tempQuestion} updateQuestionAnswers={updateQuestionAnswers} editMode />
                                        <Flex vAlign="center" className={customClass.bottomBar}>
                                            <Flex.Item push>
                                                <Flex gap="gap.small">
                                                    <Checkbox label="Risposte multiple" toggle onClick={toggleMultipleChoice} checked={tempQuestion.type === answerTypeEnum.MultipleChoice} />
                                                </Flex>
                                            </Flex.Item>
                                        </Flex>
                                    </Flex>
                                    <Flex gap="gap.medium" fill column padding="padding.medium" className="px-0">
                                        {addQuestion}
                                    </Flex>
                                </> :
                                <Flex gap="gap.medium" padding="padding.medium" column className={customClass.infosTitle} >
                                    <Flex vAlign="center" className={customClass.topBar}>
                                        <Flex.Item push>
                                            <Flex gap="gap.small">
                                                <ButtonTooltip secondary text iconOnly noBg icon={<ArrowUpIcon />} tooltip='Sposta in alto' onClick={() => moveQuestion(question.id, 'up')} disabled={!moveUpEnabled} />
                                                <ButtonTooltip secondary text iconOnly noBg icon={<ArrowDownIcon />} tooltip='Sposta in basso' onClick={() => moveQuestion(question.id, 'down')} disabled={!moveDownEnabled} />
                                                <ButtonTooltip secondary text iconOnly noBg icon={<Icon iconName="Copy" />} tooltip='Copia domanda' onClick={copyQuestion} />
                                                <ConfirmDialog
                                                    header="Conferma eliminazione?"
                                                    content=""
                                                    triggerButton={
                                                        <ButtonTooltip secondary text iconOnly noBg icon={<TrashCanIcon />} tooltip='Rimuovi domanda' />
                                                    }
                                                    onConfirmCallback={deleteQuestion}
                                                />
                                            </Flex>
                                        </Flex.Item>
                                    </Flex>
                                    <div onClick={handleClick} >
                                        <Text size="medium" content={`${index}. ${tempQuestion.text}`} align="start" />
                                        <Answers question={tempQuestion} />
                                    </div>
                                </Flex>
                        )
                }
            </div>
        </ClickAwayListener>
    )
}