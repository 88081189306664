/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import Button from '@material-ui/core/Button';

const reader = new FileReader();

class FileUploadExt extends React.Component {

    constructor(props) {
        super();
        this.state = {
            value: props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    handleEvent = (event, file) => {
        if (event.type === "load") {
            this.props.onChange(file, this.props.name);
            if (this.props.callback !== undefined)
                this.props.callback(file);
        }
    }

    addListeners = (reader, file) => {
        // reader.addEventListener('loadstart', this.handleEvent);
        reader.addEventListener('load', (e) => this.handleEvent(e, file));
        // reader.addEventListener('loadend', this.handleEvent);
        // reader.addEventListener('progress', this.handleEvent);
        // reader.addEventListener('error', this.handleEvent);
        // reader.addEventListener('abort', this.handleEvent);
    }

    handleSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            this.addListeners(reader, selectedFile);
            reader.readAsDataURL(selectedFile);
        }
    }

    render() {
        const { classes, value, url, filename, errorMessages, callback, ...myProps } = this.props;

        const data = {};

        if (CommonHelper.IsFile(value)){
            data.value = null;
            data.filename = value.name;
            data.url = URL.createObjectURL(value);
        }
        else {
            data.value = value;
            data.filename = filename;
            data.url = url;
        }

        const ImgLogo = ({ data }) => {
            let src;

            if (!CommonHelper.IsEmpty(data.value))
                src = `data:image/jpeg;base64,${data.value}`;
            else
                src = data.url;

            return <img onClick={() => CommonHelper.ShowBase64Image(src)} src={src} className={classes.filepreview} alt="" />
        };

        return (
            <div>
                <label>{this.props.label}</label>
                <div>
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span" className={classes.button}>
                            Scegli File
                    </Button>
                    </label>
                    <input
                        {...myProps}
                        accept="image/*"
                        className={classes.input}
                        required={this.props.required}
                        id="contained-button-file"
                        single="true"
                        type="file"
                        onChange={(e) => this.handleSelected(e)}
                    />
                    <ImgLogo data={data} />
                    <span>{data.filename}</span>
                    {
                        errorMessages &&
                        <p className={classes.errorMessageFileUpload}>{errorMessages[0]}</p>
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(FileUploadExt);