import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Segment, Text, Button, Divider, Tooltip, ChevronStartIcon } from '@fluentui/react-northstar'

const TabNavBar = ({ children, title, icon, showBack, backPageName, backPageRoute = null, ...unhandledProps }) => {
    const history = useHistory();

    return (
        <Flex.Item>
            <Segment color="brand" inverted>
                <Flex gap="gap.small" vAlign="center">
                    <Flex.Item>
                        <Flex gap="gap.small" vAlign="center">
                            {
                                showBack &&
                                <>
                                    <Tooltip trigger={
                                        <Button
                                            icon={<ChevronStartIcon styles={{ color: '#fff' }} />}
                                            text
                                            iconOnly
                                            onClick={backPageRoute === null ? () => history.goBack() : () => history.push(`/teamstab/${backPageRoute}`)}
                                        />
                                    }
                                        content={backPageName ? backPageName : 'Indietro'}
                                    />
                                    <Divider vertical styles={{ height: '1.7rem' }} />
                                </>
                            }
                            {icon}
                            <Text size="large" color="white" content={title ? title : "CWS Meetings"} />
                        </Flex>
                    </Flex.Item>
                    <Flex.Item push>
                        <Flex gap="gap.small" vAlign="center">
                            {React.Children.map(children, child => (
                                React.cloneElement(child)
                            ))}
                        </Flex>
                    </Flex.Item>
                </Flex>
            </Segment>
        </Flex.Item>
    )
}

export default TabNavBar;