/* eslint-disable no-unused-vars */

import React from "react";
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import RouteExt from "../RouteExt";
import config from 'react-global-configuration';

import { LivionLoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

class PublicLayout extends React.Component {

  constructor(props) {
    super();

    this.getData = this.getData.bind(this);

    if (CommonHelper.GetLang())
      CommonHelper.SetLang(CommonHelper.GetLang());

    this.state = {
      isLoading: true,
      data: []
    };
  }

  componentDidMount() {
    document.body.classList.add("bg-livion-white");
  }

  componentWillMount() {
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-livion-white");
  }

  getData(baseApi, id, async) {
    const { wsLivion } = this.state;

    if (id === -1) {
      this.setState({ isLoading: false });
      return;
    }

    if (!CommonHelper.IsEmpty(id) && !wsLivion.connected) {
      this.setState({ isLoading: false, data: null });
      return;
    }

    if (!async) {
      this.setState({ isLoading: true, data: [] });
    }

    let successCallback = (response) => response.json().then((json) => json);
    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); };

    if (!async) {
      setTimeout(() => {
        CommonHelper.WaitAsyncData(FetchHelper.DoFetchAnonymous(CommonHelper.IsEmpty(id) ? baseApi : `${baseApi}/${id}`, 'GET', null, successCallback, errorCallback)).then((data) => {
          if (data === undefined) { return; }
          this.setState({ data: data, isLoading: false });
        });
      }, 100);
    }
    else {
      return CommonHelper.WaitAsyncData(FetchHelper.DoFetchAnonymous(CommonHelper.IsEmpty(id) ? baseApi : `${baseApi}/${id}`, 'GET', null, successCallback, errorCallback));
    }
  }

  render() {
    const { isLoading, data } = this.state;
    return (<RouteExt {...this.props} data={data} getData={this.getData} isLoading={isLoading} />);
  }
}

export default PublicLayout;