import decodeJwt from 'jwt-decode';
import { CommonHelper } from 'components/Common/Helper/Helper';

const loginResource = "auth/login";
const refreshTokenResource = "auth/refreshtoken";

export var AUTHORIZED = 'AUTHORIZED';
export var UNAUTHORIZED = 'UNAUTHORIZED';
export var ELAPSED = 'ELAPSED';

export default {
    login: async ({ userName, email, password, providerType, providerId, teamChannelId }) => {
        const request = new Request(`${CommonHelper.GetApiDomain()}/${loginResource}`, {
            method: 'POST',
            body: JSON.stringify({ userName, email, password, providerType, providerId }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (providerType === 'microsoft' && teamChannelId)
            request.headers.set('TeamChannelId', teamChannelId);

        //const response = await fetch(request).catch(err => console.log(err));
        const response = await fetch(request).then((response) => response).catch((error) => error);

        if (response instanceof Error) {
            return Promise.resolve({ success: false, error: response.message });
        }
        
        if (response.status < 200 || response.status >= 300) {
            if (response.status === 401 || response.status === 403) {
                const loginResponseFailedJson = await response.json();
                return Promise.resolve({ success: false, error: loginResponseFailedJson[0] });
            }
            else {
                const loginResponseFailedText = await response.text();
                return Promise.reject({ success: false, error: loginResponseFailedText });
            }
        }

        const responseAuth = await response.json();

        const decodedToken = decodeJwt(responseAuth.accessToken.token);
        localStorage.setItem('token', responseAuth.accessToken.token);
        localStorage.setItem('refresh_token', responseAuth.refreshToken);
        return Promise.resolve({ success: true });
    },
    refreshToken: async () => {
        let accessToken = localStorage.getItem('token');
        let refreshToken = localStorage.getItem('refresh_token');

        if (CommonHelper.IsEmpty(accessToken) || CommonHelper.IsEmpty(refreshToken)){
            this.logout();
            return Promise.reject({ status: UNAUTHORIZED, redirectTo: '/login' });
        }

        const request = new Request(`${CommonHelper.GetApiDomain()}/${refreshTokenResource}`, {
            method: 'POST',
            body: JSON.stringify({ accessToken, refreshToken }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            return Promise.reject({ status: UNAUTHORIZED, redirectTo: '/login' });
        }
        const responseAuth = await response.json();

        const decodedToken = decodeJwt(responseAuth.accessToken.token);
        localStorage.setItem('token', responseAuth.accessToken.token);
        localStorage.setItem('refresh_token', responseAuth.refreshToken);
        return Promise.reject({ status: AUTHORIZED, newToken: responseAuth.accessToken.token });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        return Promise.resolve({ redirectTo: '/login' });
    },
    checkError: (response) => {
        const { status } = response;

        if (status === 401 || status === 403) {
            return Promise.reject({ status: UNAUTHORIZED, redirectTo: '/login' });
        }
        else {
            return Promise.resolve(response);
        }
    },
    checkAuth: () => {
        if (localStorage.getItem('token')) {
            const decodedToken = decodeJwt(localStorage.getItem('token'));
            if (decodedToken.exp < Date.now() / 1000) {
                return Promise.reject({ status: ELAPSED })
            }
            else {
                return Promise.resolve({ status: AUTHORIZED, token: localStorage.getItem('token') })
            }
        }
        else{
            return Promise.reject({ status: UNAUTHORIZED, redirectTo: '/login' })
        }
    },
    getAuthenticatedUser: () => {
        const token = localStorage.getItem('token');
        const decodedToken = decodeJwt(token);
        return  { id: decodedToken.id, internalId: decodedToken.internal_id, displayName: `${decodedToken.family_name} ${decodedToken.given_name}` };
    },
    getPermissions: () => {
        const token = localStorage.getItem('token');
        const decodedToken = decodeJwt(token);
        return decodedToken.rol;
    },
    getMSAppToken: async (accessToken) => {
        const request = new Request(`${CommonHelper.GetBotApiDomain()}/token`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'MSAuthorization': `Bearer ${accessToken}` }),
        });
        const response = await fetch(request).then((response) => response).catch((error) => error);

        if (response instanceof Error) {
            return Promise.resolve({ success: false, error: response.message });
        }

        if (response.status < 200 || response.status >= 300) {
            if (response.status === 401 || response.status === 403) {
                const loginResponseFailedJson = await response.json();
                return Promise.resolve({ success: false, error: loginResponseFailedJson[0] });
            }
            else {
                const loginResponseFailedText = await response.text();
                return Promise.reject({ success: false, error: loginResponseFailedText });
            }
        }

        const responseAuth = await response.json();
        
        if (responseAuth && responseAuth.success) {
            return Promise.reject({ success: true, token: responseAuth.oAuthResult.access_Token });
        }
        else {
            return Promise.reject({ success: false, error: responseAuth.hasOwnProperty('error') ? responseAuth.error : JSON.stringify(responseAuth) });
        }
    }
};