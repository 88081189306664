import React, { Component } from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { AdminDashboardContext } from "libs/admin-dashboard";

export default C =>
    class LocalProvider extends Component {
        static contextType = AdminDashboardContext;

        constructor(props) {
            super();

            this.auth = React.createRef();

            this.state = {
                loading: false,
                loggedIn: false,
                error: null
            };
        }

        onSignIn(userName, password) {
            this.setState({ loading: true }, async () => {
                let data = { userName: userName, password: password, providerType: 'local' };
                let { success, errorMessage } = await this.context.onSignInSuccess(data);
                this.setState({ loggedIn: success, error: success ? null : errorMessage, loading: false });
            });
        }

        onSignOut() {
            this.setState({ loading: false, loggedIn: false, error: null });
            this.context.onSignOutSuccess();
        }

        componentDidMount() {
        }

        render() {
            return (
                <C
                    {...this.props}
                    ref={this.auth}
                    loading={this.state.loading}
                    loggedIn={this.state.loggedIn}
                    error={this.state.error}
                    onSignIn={(userName, password) => this.onSignIn(userName, password)}
                    onSignOut={() => this.onSignOut()}
                />
            );
        }
    };
