import { OnBehalfAuthClient, AppAuthClient } from "./MicrosoftClient";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';

export async function Get(emailOrId, isGuest = false) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    if (isGuest) {
        return await Check(emailOrId);
    }

    const user = await client
        .api(`/users/${emailOrId}`)
        .version('beta')
        //.select('id,displayName,givenName,surname,mail,userPrincipalName')
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return user;
}

export async function GetMany(ids) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = await client
        .api(`/users`)
        .version('beta')
        .filter(`Id in (${ids.join(",")})`)
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res.value;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return user;
}

export async function GetDirectoryObject(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = await client
        .api(`/users/${id}/getMemberObjects`)
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return user;
}

export async function GetPhoto(id, ssoToken = null) {
    const client = await OnBehalfAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = await client
        .api(`/users/${id}/photo/$value`)
        .version('beta')
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return user;
}

export async function GetInstalledApps(id, appId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const installedApps = await client
        .api(`/users/${id}/teamwork/installedApps`)
        .version('beta')
        .expand('teamsApp')
        .filter(`teamsApp/externalId eq '${appId}' and teamsApp/distributionMethod eq 'Organization'`)
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return installedApps;
}

export async function Create(givenName, surname, mailNickname, userPrincipalName, password) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = {
        accountEnabled: true,
        displayName: `${givenName} ${surname}`,
        mailNickname: mailNickname,
        userPrincipalName: userPrincipalName,
        passwordProfile: {
            forceChangePasswordNextSignIn: true,
            password: password
        }
    };

    const createdUser = await client
        .api('/users')
        .post(user)
        .then(function (res) {
            delete res["@odata.context"];
            return Update(res.id, givenName, surname, userPrincipalName);
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                case "Request_BadRequest":
                    if (err.message.indexOf("The domain portion of the userPrincipalName property is invalid") > -1) {
                        console.info(`Domain not in organization ('${userPrincipalName}')`)
                        return CreateGuest(givenName, surname, userPrincipalName, `${CommonHelper.GetFullHostName()}/teamstab/?registered=true`)
                    }
                    else {
                        return null;
                    }
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return createdUser;
}

export async function Update(id, givenName, surname, mail) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = {
        accountEnabled: true,
        displayName: `${givenName} ${surname}`,
        givenName: givenName,
        surname: surname,
        mail: mail
    };

    const updatedUser = await client
        .api(`/users/${id}`)
        .update(user)
        .then(async () => await Get(id, false))
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return updatedUser;
}

export async function CreateGuest(givenName, surname, invitedUserEmailAddress, inviteRedirectUrl) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const guest_invitation = {
        invitedUserDisplayName: `${givenName} ${surname}`,
        invitedUserEmailAddress,
        inviteRedirectUrl,
        sendInvitationMessage: true
    };

    const invitation = await client
        .api('/invitations')
        .post(guest_invitation)
        .then(function (res) {
            delete res["@odata.context"];
            console.log(res);
            return Update(res.invitedUser.id, givenName, surname, invitedUserEmailAddress);
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                case "Request_BadRequest":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });
    return invitation;
}

export async function Check(emailOrId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = await client
        .api(`/users`)
        .version('beta')
        .filter(`startswith(mail,'${emailOrId}') or userPrincipalName eq '${emailOrId}'`)
        .get()
        .then(function (res) {
            return res.value.length === 1 ? res.value[0] : null;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return user;
}

export async function HasGroup(id, groupId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const groupIds = { groupIds: [groupId] };

    const hasGroup = await client
        .api(`/users/${id}/checkMemberGroups`)
        .post(groupIds)
        .then(function (res) {
            return res.value.length > 0;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return hasGroup;
}