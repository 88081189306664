export const callStateEnum = {
    Unknown: -1,
    Incoming: 0,
    Establishing: 1,
    Ringing: 2,
    Established: 3,
    Hold: 4,
    Transferring: 5,
    TransferAccepted: 6,
    Redirecting: 7,
    Terminating: 8,
    Terminated: 9,
    UnknownFutureValue: 10,
    NotFound: 12,
    Available: 13,
    Completed: 101,
    RunningMore: 102,
    CompletedMore: 103,
    Stalled: 104,
    TooOld: 105,
    TooNew: 106,
    Restarting: 107
}

export const answerTypeEnum = {
    Text: 'text',
    SingleChoice: 'SingleChoice',
    MultipleChoice: 'MultipleChoice',
}

export const rolesEnum = {
    Admin: 'admin',
    Organizer: 'organizer',
    Teacher: 'teacher',
    Member: 'member',
    Guest: 'guest',
}