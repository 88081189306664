import React from 'react';

import { AdminDashboardContext, List, Datagrid } from "libs/admin-dashboard";

// reactstrap components
import { Container, Row } from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";

class Temp extends React.Component {
  static contextType = AdminDashboardContext;

  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { seo, history } = this.props;
    this.context.setData({ seo: seo, currentPage: history.location.pathname });
  }

  render() {

    let query = {
      page: 1,
      perPage: 10,
      displayedFilters: "",
      filterValues: "",
    };

    let queryModifiers = {
      hideFilter: () => { },
      setFilters: () => { },
      setPage: () => { },
      setPerPage: () => { },
      setSort: () => { },
      showFilter: () => { },
    };

    let selectionModifiers = {
      select: () => { },
      toggle: () => { },
      clearSelection: () => { },
    }

    let myProps = { query, queryModifiers, selectionModifiers }

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <h1>{this.props.msg}</h1>
            <List basePath="" resource="" {...this.props} {...myProps}>
              <Datagrid>
                <h1>TEST</h1>
              </Datagrid>
            </List>
          </Row>
        </Container>
      </>
    );
  }
}

export default Temp;