import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

export const formData = {
    "name": "",
    "appId": "",
    "appPassword": "",
    "appTenant": "",
    "connectionName": "",
    "surveysEnabled": false,
    "enabled": false,
};

export const getSchema = () => {
    return {
        "fields": {
            "name": {
                "name": "name", "type": "string", "label": CommonHelper.GetTrans("botconfigurations.fields.name"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "appId": {
                "name": "appId", "type": "string", "label": CommonHelper.GetTrans("botconfigurations.fields.appId"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "appPassword": {
                "name": "appPassword", "type": "string", "label": CommonHelper.GetTrans("botconfigurations.fields.appPassword"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "appTenant": {
                "name": "appTenant", "type": "string", "label": CommonHelper.GetTrans("botconfigurations.fields.appTenant"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "connectionName": {
                "name": "connectionName", "type": "string", "label": CommonHelper.GetTrans("botconfigurations.fields.connectionName")
            },
            "surveysEnabled": {
                "name": "surveysEnabled", "type": "bool", "label": CommonHelper.GetTrans("botconfigurations.fields.surveysEnabled"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
            "enabled": {
                "name": "enabled", "type": "bool", "label": CommonHelper.GetTrans("botconfigurations.fields.enabled"), "validators": ["required"], "errorMessages": [CommonHelper.GetTrans("validator.field_required")]
            },
        }
    };
}

export const getTableColumns = () => {
    return [
        { id: 'name', label: CommonHelper.GetTrans("botconfigurations.fields.name"), type: 'string', filter: true },
        { id: 'appId', label: CommonHelper.GetTrans("botconfigurations.fields.appId"), type: 'string', filter: true },
        { id: 'appPassword', label: CommonHelper.GetTrans("botconfigurations.fields.appPassword"), type: 'string' },
        { id: 'appTenant', label: CommonHelper.GetTrans("botconfigurations.fields.appTenant"), type: 'string', filter: true },
        { id: 'connectionName', label: CommonHelper.GetTrans("botconfigurations.fields.connectionName"), type: 'string', filter: true },
        { id: 'surveysEnabled', label: CommonHelper.GetTrans("botconfigurations.fields.surveysEnabled"), type: 'bool', size: 'small', align: 'center' },
        { id: 'enabled', label: CommonHelper.GetTrans("botconfigurations.fields.enabled"), type: 'bool', size: 'small', align: 'center' },
        { id: 'createdAt', label: CommonHelper.GetTrans("botconfigurations.fields.createdAt"), type: 'datetime-local' }
    ];
}