import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import classNames from 'classnames';
import { CommonHelper } from 'components/Common/Helper/Helper';

import { TeamsContext, ButtonTooltip, answerTypeEnum } from "libs/teams-tab";

import { Flex, RadioGroup, Checkbox, AcceptIcon, } from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

const customClass = mergeStyleSets({
    answer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.sent': {
            color: '#FFFFFF',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'none',
            },
            '&.sent *': {
                color: '#FFFFFF',
                cursor: 'default',
            },
        }
    },
});

const Choice = ({ answer, multiple, sent }) => {
    const [text, setText] = useState("");
    const [correct, setCorrect] = useState(false);

    useEffect(() => {
        setText(answer.text);
        setCorrect(answer.correct);
    }, [answer]);

    return (
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.answer, sent ? 'sent' : '')} >
            {
                multiple ?
                    <Checkbox disabled label={text} /> :
                    <RadioGroup items={[{ key: answer.id, label: text, value: text, disabled: true, }]} />
            }
            {correct && <ButtonTooltip secondary text iconOnly noBg icon={<AcceptIcon />} content={""} tooltip='Risposta corretta' />}
        </Flex>
    )
}

const Answers = ({ question, sent }) => {
    const [answers, setAnswers] = useStateWithCallbackLazy([]);

    useEffect(() => {
        if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            setAnswers(question.surveyAnswers.sort(CommonHelper.CompareValues("order")))
        }
    }, [question.surveyAnswers]);

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            }
        }
    });

    const renderAnswers = () => {
        if (answers) {
            return answers.map((answer, key) => {
                let answerCmp = null;

                switch (question.type) {
                    case answerTypeEnum.Text:
                    default:
                        break;
                    case answerTypeEnum.SingleChoice:
                        answerCmp = <Choice index={key} answer={answer} />
                        break;
                    case answerTypeEnum.MultipleChoice:
                        answerCmp = <Choice index={key} answer={answer} multiple />
                        break;
                }

                if (answerCmp) {
                    return React.cloneElement(answerCmp, { key, sent });
                }
                else {
                    return null;
                }
            })
        }
    }


    return (
        <Flex gap="gap.medium" padding="padding.medium" column>
            {
                renderAnswers(answers)
            }
        </Flex>
    )
}

export default Answers;