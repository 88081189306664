/* eslint-disable no-unused-vars */

import React from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';

import { Notify, TeamsContext, teamsTabStyles, TabNavBar, ButtonTooltip, PopupWithButton, ConfirmDialog } from "libs/teams-tab";

import {
  Flex, Segment, Button, Text, Loader, Grid, Divider, Card,
  Image, List, Input,
  SyncIcon, MoreIcon, PollIcon, SearchIcon,
} from '@fluentui/react-northstar'

import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from 'office-ui-fabric-react';

let myContext = null;

const customClass = mergeStyleSets({
  cardContainer: {
    '& div:first-child:hover': {
      cursor: 'pointer',
    }
  },
});

const CardSurvey = ({ index, survey, reload }) => {
  const { teamChannel } = myContext;

  const deleteSurvey = async () => {
    if (survey.callSurveyActivities.length > 0) {
      ToastHelper.Warn("Sondaggio attualmente associato ad una riunione attiva. Impossibile cancellare");
      return;
    }
    let result = await myContext.dataProviders.api.delete('surveys', { id: survey.id });

    if (result && result.data) {
      reload();
    }
    else {
      ToastHelper.Error("Errore durante la cancellazione del sondaggio");
    }
  };

  const copySurvey = async () => {
    const surveyRequestData = { ...survey };
    surveyRequestData.teamChannelId = teamChannel.id;
    surveyRequestData.global = false;
    surveyRequestData.locked = false;
    delete surveyRequestData.id

    let result = await myContext.dataProviders.api.create('surveys', { data: surveyRequestData });

    if (result && result.data) {
      myContext.navigate(`surveyDetails/${result.data.id}`)
    }
    else {
      ToastHelper.Error("Errore durante la copia del sondaggio");
    }
  };

  const setAsGlobal = async () => {
    let result = await myContext.dataProviders.api.update('surveys/setglobal', { id: survey.id, data: !survey.global });

    if (result && result.data) {
      reload();
    }
    else {
      ToastHelper.Error("Errore durante il salvataggio di un nuovo sondaggio");
    }
  };

  const openSurvey = (e) => {
    myContext.navigate(`surveyDetails/${survey.id}`);
  }

  return (
    <div key={index} style={teamsTabStyles.cardContainer} className={customClass.cardContainer}>
      <Card compact style={teamsTabStyles.card}>
        <Card.TopControls styles={{ top: 0, left: 0 }}>
          <ButtonTooltip secondary text iconOnly noBg icon={<Icon iconName={survey.global ? "Globe" : "TeamsLogo"} className="p-2" style={{ fontSize: 18 }} />} tooltip={survey.global ? "Globale" : "Privato"} />
        </Card.TopControls>
        <Card.TopControls styles={{ top: 0 }}>
          <PopupWithButton icon={<MoreIcon size="medium" />} iconOnly text position="below" align="end" tooltip="Altre azioni" style={teamsTabStyles.cardActions}>
            <List>
              <List.Item styles={teamsTabStyles.cardActionsItem} content={<Button text content="Copia" primary onClick={copySurvey} />} />
              {
                survey.teamChannelId === teamChannel.id &&
                <>
                  <List.Item styles={teamsTabStyles.cardActionsItem} content={
                    <ConfirmDialog
                      header="Conferma eliminazione?"
                      content={`'${survey.title}' creato il ${moment(survey.createdAt).format('DD/MM/YYYY HH:mm')}`}
                      triggerButton={<Button text content="Elimina" primary />}
                      onConfirmCallback={deleteSurvey}
                    />
                  } />
                  <List.Item styles={teamsTabStyles.cardActionsItem} content={<Button text content={survey.global ? "Rendi Privato" : "Rendi Globale"} primary onClick={setAsGlobal} />} />
                </>
              }
            </List>
          </PopupWithButton>
        </Card.TopControls>
        <div onClick={openSurvey}>
          <Card.Preview fitted style={teamsTabStyles.cardPreview} >
            <Image fluid src="https://cdn.forms.office.net/forms/images/theme/default-2.png" />
          </Card.Preview>
          <Card.Body style={teamsTabStyles.cardBody} fitted>
            <Flex padding="padding.medium">
              <Text as="h2" content={survey.title} weight="bold" />
            </Flex>
          </Card.Body>
          <Card.Footer fitted style={teamsTabStyles.cardFooter}>
            <Flex padding="padding.medium" vAlign="center" hAlign="center" >
              <Text content={`${survey.callSurveyActivities.length} risposte`} />
            </Flex>
          </Card.Footer>
        </div>
      </Card>
    </div>
  )
}

const CardNewSurvey = () => {
  const { teamChannel } = myContext;

  const createNewSurvey = async () => {
    const surveyRequestData = {
      title: "Sondaggio senza titolo",
      description: null,
      teamChannelId: teamChannel.id,
      global: false,
      startDate: null,
      endDate: null,
      enabled: true,
    }

    let result = await myContext.dataProviders.api.create('surveys', { data: surveyRequestData });
    if (result && result.data) {
      myContext.navigate(`surveyDetails/${result.data.id}`)
    }
    else {
      ToastHelper.Error("Errore nella creazione di un nuovo sondaggio");
    }
  }

  return (
    <div style={teamsTabStyles.cardContainer} className={customClass.cardContainer} onClick={createNewSurvey}>
      <Flex gap="gap.small" hAlign="center" vAlign="center" style={teamsTabStyles.cardNew} column fill>
        <Flex.Item>
          <PollIcon size="largest" outline />
        </Flex.Item>
        <Flex.Item>
          <Button content="Nuovo sondaggio" text primary />
        </Flex.Item>
      </Flex>
    </div>
  )
}

class Surveys extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      surveys: null,
      filter: {
        title: null
      }
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  setFilter = debounce((field, query) => {
    let { filter } = this.state;
    filter[field] = query ? query.toLowerCase() : null;
    this.setState({ filter }, this.getSurveys);
  }, 1000);

  getSurveys = () => {
    const { channelId } = myContext.msTeamsContext;
    const { title } = this.state.filter;

    this.setState({ loading: true }, async () => {
      const filter = { softDelete: false, 'TeamChannel/TeamChannelId': channelId };
      const filterOr = { softDelete: false, global: true };
      const expand = 'callsurveyactivities';
      const sort = { field: 'createdAt', order: 'desc' };

      if (title !== null) {
        filter.title = title;
        filterOr.title = title;
      }

      let result = await myContext.dataProviders.api.getList('surveys', { filter, filterOr, sort, expand });

      if (result && result.data) {
        this.setState({ surveys: result.data, loading: false });
      }
      else {
        this.setState({ surveys: [], loading: false });
      }
    });
  }

  filterByTitle = (value) => {
    this.setFilter('title', value);
  };

  resetFilter = () => {
    this.setState({ filter: { title: null } }, this.getSurveys);
  }

  render() {
    const { loading, surveys } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          if (!surveys && myContext && myContext.msTeamsContext && !loading) {
            if (!myContext.settings.surveysEnabled) {
              context.navigate('/');
            }
            else {
              this.getSurveys();
            }
          }

          return (
            <>
              <TabNavBar title="Elenco Sondaggi" showBack backPageName="Homepage" backPageRoute="" icon={<Icon iconName="Questionnaire" />}>
                <Input icon={<SearchIcon />} clearable placeholder="Cerca..." onChange={(e) => this.filterByTitle(e.target.value)} />
                {/* <ButtonTooltip icon={<UndoIcon />} secondary iconOnly content="" tooltip="Ripristina filtro" onClick={this.resetFilter} disabled /> */}
                <Divider vertical styles={{ height: '1.7rem' }} />
                <ButtonTooltip icon={<SyncIcon />} secondary iconOnly content="" tooltip="Aggiorna" onClick={this.getSurveys} />
              </TabNavBar>
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      (
                        surveys && surveys.length > 0 ?
                          <Grid columns={6}>
                            <CardNewSurvey />
                            {
                              surveys.map((survey, key) => { return (<CardSurvey key={key} index={key} survey={survey} reload={this.getSurveys} />) })
                            }
                          </Grid> :
                          <Text size="large" content="Nessun sondaggio disponibile" align="center" />
                      )
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default Surveys;