import { OnBehalfAuthClient, UserAuthClient, AppAuthClient } from "./MicrosoftClient";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { Users, teamsRolesEnum } from '../microsoft-graph-service';

export async function GetAll(onlyTeams = true, skipToken = null, allTeams = []) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    let teams;
    if (skipToken === null) {
        teams = await client
            .api('/groups')
            .version('beta')
            .filter(onlyTeams ? 'resourceProvisioningOptions/Any(x:x eq \'Team\')' : '')
            .get()
            .then(function (res) {
                if (res.hasOwnProperty("@odata.nextLink")) {
                    let skipToken = res["@odata.nextLink"].split("$skiptoken=")[1];
                    return GetAll(onlyTeams, skipToken, [...res.value])
                }
                return res.value;
            })
            .catch(function (err) {
                switch (err.code) {
                    case "Request_ResourceNotFound":
                        break;
                    default:
                        console.error(err);
                        ToastHelper.Error(err.message);
                        break;
                }
                return [];
            });
    }
    else {
        teams = await client
            .api('/groups')
            .version('beta')
            .filter(onlyTeams ? 'resourceProvisioningOptions/Any(x:x eq \'Team\')' : '')
            .skipToken(skipToken)
            .get()
            .then(function (res) {
                if (res.hasOwnProperty("@odata.nextLink") && allTeams.length < 5000) {
                    let skipToken = res["@odata.nextLink"].split("$skiptoken=")[1];
                    return GetAll(onlyTeams, skipToken, [...allTeams, ...res.value])
                }
                return [...allTeams, ...res.value];
            })
            .catch(function (err) {
                switch (err.code) {
                    case "Request_ResourceNotFound":
                        break;
                    default:
                        console.error(err);
                        ToastHelper.Error(err.message);
                        break;
                }
                return [...allTeams];
            });
    }
    return teams;
}

export async function Get(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teams = await client
        .api(`/groups/${id}`)
        .version('beta')
        .select('id,displayName,description,mail,mailNickname')
        .get()
        .then(function (res) {
            delete res["@odata.context"];
            return res;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return teams;
}

export async function GetByName(displayName) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teams = await client
        .api('/groups')
        .version('beta')
        .filter(`displayName eq \'${displayName}\'`)
        .select('id,displayName,description,mail,mailNickname')
        .get()
        .then(function (res) {
            return res.value;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return null;
        });

    return teams;
}

export async function GetOwners(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamOwners = await client
        .api(`/groups/${id}/owners`)
        //.select('id,displayName,givenName,surname,mail,userPrincipalName')
        .get()
        .then(function (res) {
            return res.value;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return [];
        });

    return teamOwners;
}

export async function IsOwner(id, userId) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const isOwner = await client
        .api(`/groups/${id}/owners`)
        .filter(`id eq '${userId}'`)
        .get()
        .then(() => true)
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return false;
        });
    return isOwner;
}

export async function GetMembers(id) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const teamOwners = await client
        .api(`/groups/${id}/members`)
        //.select('id,displayName,givenName,surname,mail,userPrincipalName')
        .get()
        .then(function (res) {
            return res.value;
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return [];
        });

    return teamOwners;
}

export async function GetCalendarView(id, startDateTime, endDateTime, selectFields = true, top = 100, asUser = true) {
    const client = asUser ? await OnBehalfAuthClient().catch(e => e) : await AppAuthClient().catch(e => e);
    
    if (CommonHelper.IsEmpty(client) || client.error) {
        return Promise.reject(client);
    }

    if (CommonHelper.IsEmpty(startDateTime) || CommonHelper.IsEmpty(endDateTime)) {
        return Promise.reject({ type: 'params_error', errorMessage: "Selected rage of date is not valid" });
    }

    const teamEvents = await client
        .api(`/groups/${id}/calendarView?startdatetime=${startDateTime}&enddatetime=${endDateTime}&$top=${top}`)
        .header('Prefer', 'outlook.timezone="W. Europe Standard Time"')
        .select(selectFields ? 'id,subject,attendees,start,end,isAllDay,isCancelled,isOnlineMeeting,lastModifiedDateTime,onlineMeeting,webLink' : '')
        .get()
        .then(function (res) {
            return res.value.filter(r => r !== null && r.isOnlineMeeting);
        })
        .catch(function (err) {
            switch (err.code) {
                case "Request_ResourceNotFound":
                    break;
                default:
                    if (asUser && err.code === "ErrorAccessDenied") {
                        console.log("calendarView asUser")
                        return GetCalendarView(id, startDateTime, endDateTime, selectFields, top, false);
                    }
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return [];
        });

    return teamEvents;
}


export async function AddMember(id, userId, role) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const user = {
        '@odata.id': `https://graph.microsoft.com/v1.0/users/${userId}`
    }

    const createdUser = await client
        .api(`/groups/${id}/${role === teamsRolesEnum.Owner ? "owners" : "members"}/$ref`)
        .post(user)
        .then(() => true)
        .catch(function (err) {
            switch (err.code) {
                case "Request_BadRequest":
                    if (err.message.indexOf("already exist") > -1) {
                        return true;
                    }
                    break;
                case "Request_ResourceNotFound":
                case "Forbidden":
                    break;
                default:
                    console.error(err)
                    ToastHelper.Error(err.message);
                    break;
            }
            return false;
        });
    return createdUser;
}

export async function RemoveMember(id, userId, role) {
    const client = await AppAuthClient().catch(e => e);

    if (CommonHelper.IsEmpty(client) || client.authError || client.error) {
        return Promise.reject(client);
    }

    const res = await client
        .api(`/groups/${id}/${role === teamsRolesEnum.Owner ? "owners" : "members"}/${userId}/$ref`)
        .delete()
        .then(() => true)
        .catch(function (err) {
            console.error(err)
            switch (err.code) {
                case "Request_ResourceNotFound":
                    return true;
                case "Request_BadRequest":
                case "Forbidden":
                    break;
                default:
                    ToastHelper.Error(err.message);
                    break;
            }
            return false;
        });
    return res;
}

export function FormatMsTeamEvents(msTeamEvents, teamChannelId) {
    msTeamEvents = msTeamEvents.sort((a, b) => new Date(b.start.dateTime) > new Date(a.start.dateTime) ? -1 : 1);
    msTeamEvents = msTeamEvents.reduce(function (result, msTeamEvent) {
        let joinUrl = decodeURIComponent(msTeamEvent.onlineMeeting.joinUrl);

        let regex = /https:\/\/teams\.microsoft\.com.*\/(?<teamChannelId>[^/]+)\/(?<message>[^/]+)\\?context=(?<context>\{.*\})/;
        let matchResult = joinUrl.match(regex);
        if (matchResult.groups && matchResult.groups.teamChannelId && matchResult.groups.teamChannelId === teamChannelId) {
            result.push(msTeamEvent);
        }
        return result;
    }, []);

    if (CommonHelper.IsEmpty(msTeamEvents)) { return []; }

    return msTeamEvents.map(async msTeamEvent => {
        let organizer = await Users.Get(msTeamEvent.attendees[0].emailAddress.address, false);
        return {
            msTeamEvent: msTeamEvent,
            organizer: CommonHelper.IsEmpty(organizer) ? null : organizer,
            teamChannelId: teamChannelId,
        };
    })
}