import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import classNames from 'classnames';
import moment from 'moment';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { TeamsContext, teamsTabStyles, answerTypeEnum } from "libs/teams-tab";

import { Flex, Input, Text, Box, Button, AddIcon, SurveyIcon, CallRecordingIcon, } from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

import { QuestionBuilder } from './QuestionBuilder';

const customClass = mergeStyleSets({
    infosTitle: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.open': {
            backgroundColor: '#f3f3f3',
            cursor: 'default',
        }
    },
    addQuestionButton: {
        height: 50,
        cursor: 'pointer',
        backgroundColor: '#03787C',
        '& .closed-container:hover': {
            backgroundColor: '#014446',
        },
        '& .opened-container': {
            cursor: 'default',
        },
    },
});

const Infos = ({ survey, refreshData }) => {
    const teamsContext = useContext(TeamsContext);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        setTitle(survey.title);
        setDescription(survey.description);
    }, [survey]);

    useEffect(() => {
        if (open) {
            survey.title = title;
            survey.description = description;
        }
    }, [title, description]);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        if (open) {
            updateSurvey();
        }
        setOpen(false);
    };

    const updateSurvey = async () => {
        const surveyRequestData = { ...survey };
        let result = await teamsContext.dataProviders.api.update('surveys', { id: surveyRequestData.id, data: surveyRequestData });

        if (result && result.data) {
            refreshData();
            return;
        }
        else {
            ToastHelper.Error("Errore durante il salvataggio del sondaggio");
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                {
                    open ?
                        <Flex gap="gap.medium" padding="padding.medium" column className={classNames(customClass.infosTitle, 'open')}>
                            <Input name="title" inverted fluid placeholder="Inserire il titolo" value={title} onChange={(e) => setTitle(e.target.value)} style={{ fontSize: '2rem' }} />
                            <Input name="description" inverted fluid placeholder="Inserire una descrizione" value={description} onChange={(e) => setDescription(e.target.value)} style={{ fontSize: '1rem' }} />
                        </Flex> :
                        <Flex gap="gap.medium" padding="padding.medium" column className={customClass.infosTitle} onClick={handleClick} >
                            <Text size="larger" content={title} align="center" />
                            <Text size="large" content={description} align="center" />
                        </Flex>
                }
            </div>
        </ClickAwayListener>
    )
}

const AddQuestionButton = React.forwardRef(({ surveyId, refreshData, setLastId, order }, ref) => {
    const teamsContext = useContext(TeamsContext);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const addQuestion = async (type) => {
        const defaultAnswers = [];

        switch (type) {
            case answerTypeEnum.Text:
            default:
                break;
            case answerTypeEnum.SingleChoice:
            case answerTypeEnum.MultipleChoice:
                defaultAnswers.push({ text: "Opzione 1", weight: 1, correct: false, order: 0 })
                defaultAnswers.push({ text: "Opzione 2", weight: 1, correct: false, order: 1 })
                break;
        }

        const questionRequestData = {
            text: "Domanda",
            surveyId: surveyId,
            type: type,
            timeFrame: 1,
            surveyAnswers: defaultAnswers,
            order: order,
        };

        let result = await teamsContext.dataProviders.api.create('surveyquestions', { data: questionRequestData });
        if (result && result.data) {
            refreshData();
            setLastId(result.data.id)
        }
        else {
            ToastHelper.Error("Errore durante il salvataggio del sondaggio");
        }
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box ref={ref} style={{ maxWidth: open ? 280 : 135 }} className={classNames(customClass.addQuestionButton)} >
                <Flex space="between" fill>
                    <Flex gap="gap.medium" fill padding="padding.medium" className="closed-container" vAlign="center" hAlign="center" onClick={handleClick}>
                        <AddIcon style={{ color: '#ffffff' }} />
                        {!open && <Text style={{ color: '#ffffff' }} size="large" content="Aggiungi" align="center" />}
                    </Flex>
                    {
                        open &&
                        <Flex gap="gap.medium" padding="padding.medium" className="opened-container">
                            <>
                                <Button icon={<CallRecordingIcon />} content="Scelta" iconPosition="before" onClick={() => addQuestion(answerTypeEnum.SingleChoice)} />
                                <Button icon={<SurveyIcon />} content="Testo" iconPosition="before" onClick={() => addQuestion(answerTypeEnum.Text)} />
                            </>
                        </Flex>
                    }
                </Flex>
            </Box>
        </ClickAwayListener>
    )
});

export const QuestionsTab = ({ id, survey, ...unhandledProps }) => {
    const teamsContext = useContext(TeamsContext)

    const addButtonRef = useRef(null);
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [surveyData, setSurveyData] = useStateWithCallbackLazy({});
    const [openedQuestionId, setOpenedQuestionId] = useState(null);
    const [lastQuestionId, setLastQuestionId] = useState(null);
    const [newQuestionOrder, setNewQuestionOrder] = useState(0);
    const [questions, setQuestions] = useStateWithCallbackLazy([]);

    // console.log("RUNNING", event, runningCalls, eventStatus)
    // console.log("COMPLETED", event, completedCalls, eventStatus)

    const refreshData = () => {
        setTimeout(async () => {
            const filter = { id };
            const expand = `surveyquestions($expand=surveyanswers),callsurveyactivities($expand=surveyteammemberanswers)`;
            let result = await teamsContext.dataProviders.api.getList('surveys', { filter, expand });
            if (result && result.data.length > 0) {
                setSurveyData({ ...result.data[0] });
                setQuestions(result.data[0].surveyQuestions);
            }
            else {
                ToastHelper.Error("Errore durante il recupero dei sondaggi");
            }
        }, 250);
    }

    const setOpenQuestion = (id) => {
        setOpenedQuestionId(id);
    }

    const setLastId = (id) => {
        setLastQuestionId(id);
    }

    const moveQuestion = async (id, direction) => {
        if (id) {
            const oldIndex = CommonHelper.FindWithAttr(surveyData.surveyQuestions, "id", id);
            let newIndex = -1;

            switch (direction) {
                case 'up':
                    newIndex = oldIndex > 0 ? oldIndex - 1 : 0;
                    break;
                case 'down':
                    newIndex = oldIndex < surveyData.surveyQuestions.length - 1 ? oldIndex + 1 : surveyData.surveyQuestions.length - 1;
                    break;
            }

            setQuestions(oldQuestions => CommonHelper.MoveItemInArray(oldQuestions, oldIndex, newIndex).map((q, key) => { q.order = key; return q; }), async (sortedQuestions) => {
                let result = await teamsContext.dataProviders.api.create('surveyquestions/questionsordered', { data: sortedQuestions });
                if (!result) {
                    ToastHelper.Error("Errore durante il salvataggio");
                }
            })
        }
    };

    useEffect(() => {
        setSurveyData(survey);
        setQuestions(survey.surveyQuestions);
    }, [survey]);

    useEffect(() => {
        if (surveyData.surveyQuestions) {
            let lastIndex = surveyData.surveyQuestions.length;

            if (openedQuestionId) {
                const _lastIndex = surveyData.surveyQuestions.findIndex(sq => sq.id === openedQuestionId);
                if (_lastIndex > -1) { lastIndex = _lastIndex + 1; }
            }

            setNewQuestionOrder(lastIndex);
        }
    }, [surveyData, openedQuestionId]);

    useEffect(() => {
        async function doAsync() {
        }
        doAsync()
    }, []);

    return (
        <div style={teamsTabStyles.cardContainer}>
            <Flex gap="gap.medium" fill column padding="padding.medium">
                <Infos survey={surveyData} refreshData={refreshData} />
                {
                    surveyData.surveyQuestions &&
                    surveyData.surveyQuestions.sort(CommonHelper.CompareValues("order")).map((question, key) => {
                        const moveUpEnabled = key > 0;
                        const moveDownEnabled = key < surveyData.surveyQuestions.length - 1;

                        return <QuestionBuilder
                            key={key}
                            index={++key}
                            question={question}
                            refreshData={refreshData}
                            lastQuestionId={lastQuestionId}
                            openedQuestionId={openedQuestionId}
                            setOpenQuestion={setOpenQuestion}
                            moveQuestion={moveQuestion}
                            moveUpEnabled={moveUpEnabled}
                            moveDownEnabled={moveDownEnabled}
                            setLastId={setLastId}
                            addQuestion={<AddQuestionButton ref={addButtonRef} surveyId={surveyData.id} refreshData={refreshData} setLastId={setLastId} order={newQuestionOrder} />} />;
                    })
                }
                {
                    !openedQuestionId &&
                    <AddQuestionButton ref={addButtonRef} surveyId={surveyData.id} refreshData={refreshData} setLastId={setLastId} order={newQuestionOrder} />
                }
            </Flex>
        </div>
    )
}