/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ValidatorComponent } from 'react-form-validator-core';

class TextFieldExt extends ValidatorComponent {

    componentDidUpdate(prevProps, prevState) {
        const { name, triggers, onChange } = this.props;

        if(!CommonHelper.IsEmpty(triggers) && !CommonHelper.IsEmpty(triggers[name]) && !CommonHelper.AreEquals(triggers[name], prevProps.value))
            onChange(triggers[name], name);
    }

    onHandleChange = (e) => {
        const { decimals, type } = this.props;
        let value = e.target.value;

        if (type === 'number') {
            if (CommonHelper.IsEmpty(value) && isNaN(value))
                value = 0;
            if (!isNaN(decimals) && !CommonHelper.IsEmpty(decimals))
                value = Number(value).toFixed(decimals);
        }

        this.validate(value);

        if (CommonHelper.IsFunction(this.props.onChange))
            this.props.onChange(value, this.props.name, !CommonHelper.IsEmpty(this.props.callback) ? this.props.callback(value) : undefined);
        
        if(!CommonHelper.IsEmpty(this.props.handle) && this.props.handle.length > 0){
            switch (this.props.type) {
                case 'number':
                    this.props.handle.map(h => this.props.onTrigger(CommonHelper.Compute(h.value, value, this.props.decimals, this.props.commons), h.field));
                    break;
                default:
                    this.props.handle.map(h => this.props.onTrigger(value, h.field));
                    break;
            }
        }
    }

    render() {
        const inputProps = {}, InputProps = {};
        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value,
            ...rest
        } = this.props;
        const { isValid } = this.state;
        let customValue = value;

        if (validators.includes('required'))
            InputProps.classes = {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
            };

        let validatorValue = validators.find(a => a.includes("matchRegexp"));
        if (validatorValue) {
            let index = validators.indexOf(validatorValue);
            let newValue = CommonHelper.ReplacePlaceholderRegex(validatorValue);
            validators[index] = newValue;
        }

        if (symbol)
            InputProps.startAdornment = <InputAdornment position="start">{symbol}</InputAdornment>;

        if (pattern)
            inputProps.pattern = pattern;

        if (patternTitle)
            inputProps.title = patternTitle;

        if (min)
            inputProps.min = min;

        if (max)
            inputProps.max = max;

        if (step)
            inputProps.step = step;
        
        if(decimals && !isNaN(decimals))
            customValue = customValue.toFixed(value);

        if(customValue === null)
            customValue = undefined;
            
        return (
            <TextField
                {...rest}
                value={customValue}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => this.onHandleChange(e)}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                inputProps={inputProps}
                InputProps={InputProps}
                InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
            />
        );
    }
}

export default withStyles(styles)(TextFieldExt);