import React from 'react';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { CommonHelper, ToastHelper, FetchHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Avatar from 'react-avatar';

import { AdminDashboardContext } from "libs/admin-dashboard";

import Header from "components/Headers/Header.jsx";
import { SelectExt, LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';
import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';
import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { MSTeamsIcon, MSTeamsIconWeight, MSTeamsIconType } from 'msteams-ui-icons-react';
import DeleteIcon from '@material-ui/icons/Delete';

import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';

import AddParticipant from './AddParticipant';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
    zIndex: 0,
    boxShadow: '0px 5px 10px 0px #7d7d7d'
  }
});

class ReportByLesson extends React.Component {
  static contextType = AdminDashboardContext;

  constructor(props) {
    super();

    this.tabsRef = React.createRef();
    this.getReport = this.getReport.bind(this);

    this.state = {
      data: null,
      relatedData: {
        teamsOptions: [],
        teamChannelsOptions: [],
        callsOptions: []
      },
      request: {
        teamId: undefined,
        teamChannelId: undefined,
        callId: undefined
      },
      loading: false
    };
  }

  componentDidMount() {
    const { seo } = this.props;
    this.context.setData({ seo: seo, currentPage: "report" });
  }

  componentWillMount() {
    this.getTeams();
  }

  getTeams = async () => {
    let result = await this.context.dataProvider.getList('msteams', { select: 'id,displayName' });

    if (result && result.data) {
      let options = result.data.map(j => ({ value: j.id, label: j.displayName }));
      options = options.sort(CommonHelper.CompareValues("label"));

      let { relatedData } = this.state;
      relatedData.teamsOptions = options;
      this.setState({ relatedData: relatedData });
    }
  }

  getTeamChannels = async () => {
    let { relatedData, request } = this.state;
    let { teamId } = request;

    relatedData.teamChannelsOptions = [];
    request.teamChannelId = undefined;
    relatedData.callsOptions = [];
    request.callId = undefined;

    this.setState({ relatedData: relatedData, data: null, request: request }, async () => {
      if (CommonHelper.IsEmpty(teamId)) {
        return;
      }

      let result = await this.context.dataProvider.getList(`msteamchannelsbyteam/${teamId}`);

      if (result && result.data) {
        let options = result.data.map(j => ({ value: j.teamChannelId, label: j.displayName, data: j }));
        options = options.sort(CommonHelper.CompareValues("label"));

        let { relatedData } = this.state;
        relatedData.teamChannelsOptions = options;
        this.setState({ relatedData: relatedData });
      }
    });
  }

  getCalls = async () => {
    let { relatedData, request } = this.state;
    let { teamChannelId } = request;

    relatedData.callsOptions = [];
    request.callId = undefined;

    this.setState({ relatedData: relatedData, data: null, request: request }, async () => {
      if (CommonHelper.IsEmpty(teamChannelId)) {
        return;
      }

      let result = await this.context.dataProvider.getList(`getcalls/${teamChannelId}`, { select: 'callId,subject,startedAt,endedAt' });

      if (result && result.data) {
        let options = result.data.map(j => {
          var callStartedAt = moment(j.startedAt).format('DD MMM YYYY HH:mm:ss');
          var callEndedAt = CommonHelper.IsEmpty(j.endedAt) ? '' : moment(j.endedAt).format('DD MMM YYYY HH:mm:ss');

          return {
            value: j.callId,
            label: `${j.subject} (${callStartedAt} - ${callEndedAt})`,
            startedAt: moment(j.startedAt).format('YYYYMMDDHHmmss'),
          }
        });
        options = options.sort((a, b) => a.startedAt - b.startedAt);

        let { relatedData } = this.state;
        relatedData.callsOptions = options;
        this.setState({ relatedData: relatedData });
      }
    });
  }

  onHandleChange = (value, field) => {
    let { request } = this.state;

    request[field] = value;

    this.setState({ request })

    switch (field) {
      case 'teamId':
        this.getTeamChannels();
        break;
      case 'teamChannelId':
        this.getCalls();
        break;
      case 'callId':
        this.getReport();
        break;
    }
  }

  getReport = (callback) => {
    let { callId } = this.state.request;

    if (CommonHelper.IsEmpty(callId)) {
      this.setState({ data: null });
      return false;
    }

    this.setState({ loading: true }, async () => {
      let result = await this.context.dataProvider.getOne('getreportdata', { id: callId });

      if (result && result.data && result.data.success) {
        this.setState({ data: result.data.data }, async () => {
          if (CommonHelper.IsFunction(callback))
            callback();

          this.setState({ loading: false });
        });
      }
    });
  }

  renderCallInfo = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { callInfo, recapParticipants } = data;

    var eventStartTimeMoment = moment(callInfo.eventStartTime);
    var eventEndTimeMoment = moment(callInfo.eventEndTime);

    var eventDate = eventStartTimeMoment.format("DD MMM YYYY");
    var eventStartTime = eventStartTimeMoment.format("HH:mm");
    var eventEndTime = eventEndTimeMoment.format("HH:mm");

    return (
      <Row>
        <Col xs="12" className="d-flex justify-content-start align-items-center mb-3">
          <MSTeamsIcon style={{ fontSize: 38 }} className="d-inline-block mr-3" aria-label={'ContactGroup'} iconWeight={MSTeamsIconWeight.Light} iconType={MSTeamsIconType['ContactGroup']} />
          <strong style={{ fontSize: 25 }} className="mr-2" >{eventDate}</strong>
          <span style={{ fontSize: 20 }}>{eventStartTime} - {eventEndTime}</span>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.status")}</span>
          {
            CommonHelper.IsEmpty(callInfo.callEndTime) ?
              <span className="text-danger">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.ongoing")}</span> :
              <span className="text-success">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.completed")}</span>
          }
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.type")}</span>
          <strong>Microsoft Teams</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.organizer")}</span>
          <strong>
            <Avatar round={true} size="36" name={callInfo.organizerDisplayName} /> {callInfo.organizerDisplayName}
          </strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.participants")}</span>
          <strong>{recapParticipants.length}</strong>
        </Col>
      </Row>
    )
  }

  renderGraph = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    let barData = {
      options: {
        layout: {
          padding: { left: 0, right: 0, top: 0, bottom: 20 }
        },
        scales: {
          yAxes: [{
            gridLines: { display: true, drawBorder: true },
            ticks: {
              display: false
            }
          }],
          xAxes: [{
            gridLines: { display: true, drawBorder: true },
            barPercentage: 0.3
          }]
        },
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'start',
            textAlign: 'center',
            color: '#000000',
            font: { size: 14 },
            formatter: function (value, ctx) {
              return [value];
            },
            offset: 0
          }
        },
        tooltips: {
          enabled: false
        },
        responsive: true
      },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        fullWidth: true,
        reverse: false,
        labels: {
          usePointStyle: true
        }
      },
      data: { datasets: [] }
    };

    const { callInfo, recapParticipants } = data;

    let getByPercentage = (percentageMin, percentageMax) => {
      return recapParticipants.filter(rp => {
        if (rp === null || rp.percentualePresenza == 0) {
          return false;
        }

        if (CommonHelper.IsEmpty(percentageMax)) {
          if (rp.percentualePresenza === percentageMin)
            return true;
        }
        else {
          if (rp.percentualePresenza >= percentageMin && rp.percentualePresenza < percentageMax)
            return true;
        }
      }).length;
    }

    var chartDataset = [{
      label: '100%',
      data: [getByPercentage(95, 101)],
      backgroundColor: '#CC5190',
      hoverBackgroundColor: '#CC5190'
    }, {
      label: '> 75%',
      data: [getByPercentage(75, 95)],
      backgroundColor: '#6B2480',
      hoverBackgroundColor: '#6B2480'
    }, {
      label: '> 50%',
      data: [getByPercentage(50, 75)],
      backgroundColor: '#E66D3E',
      hoverBackgroundColor: '#E66D3E'
    }, {
      label: '> 25%',
      data: [getByPercentage(25, 50)],
      backgroundColor: '#253193',
      hoverBackgroundColor: '#253193'
    }, {
      label: '< 25%',
      data: [getByPercentage(0, 25)],
      backgroundColor: '#4D85B8',
      hoverBackgroundColor: '#4D85B8'
    }];

    barData.data.datasets = chartDataset;

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center mb-3">
          <Bar {...barData} />
        </Col>
      </Row>
    )
  }

  renderEventInfo = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { callInfo, recapParticipants } = data;

    // var callStartTimeMoment = moment(callInfo.callStartTime);
    // var callEndTimeMoment = CommonHelper.IsEmpty(callInfo.callEndTime) ? null : moment(callInfo.callEndTime);

    // var callStartTime = callStartTimeMoment.format('DD MMM YYYY HH:mm:ss');
    // var callEndTime = callEndTimeMoment !== null ? callEndTimeMoment.format('DD MMM YYYY HH:mm:ss') : '';

    // var callDuration = callEndTimeMoment !== null ? moment.utc(callEndTimeMoment.diff(callStartTimeMoment)).format('HH:mm:ss') : '';

    var eventStartTimeMoment = moment(callInfo.eventStartTime);
    var eventEndTimeMoment = moment(callInfo.eventEndTime);

    var eventStartTime = eventStartTimeMoment.format('DD MMM YYYY HH:mm:ss');
    var eventEndTime = eventEndTimeMoment.format('DD MMM YYYY HH:mm:ss');

    var eventDuration = moment.utc(eventEndTimeMoment.diff(eventStartTimeMoment)).format('HH:mm:ss');

    const getTotalMilliseconds = (dateTime) => {
      var eventM = moment(dateTime);

      var total = 0;

      if (eventM.hour() > 0)
        total += eventM.hour() * 3600000;
      if (eventM.minute() > 0)
        total += eventM.minute() * 60000;
      if (eventM.second() > 0)
        total += eventM.second() * 1000;
      if (eventM.millisecond() > 0)
        total += eventM.millisecond();

      return total;
    }

    var validParticipants = recapParticipants.filter(rp => rp !== null && getTotalMilliseconds(`01-01-2020 ${rp.tempoPresenza}`) > 0).length;

    return (
      <Row className="h-100 mb-2">
        <Col xs="12">
          <strong>{CommonHelper.GetTrans("reports.headerInfos.event.details")}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.startDate")}</span>
          <strong>{eventStartTime}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.endDate")}</span>
          <strong>{eventEndTime}</strong>
        </Col>
        <Col xs="12 mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.participants")}</span>
          <strong>{validParticipants}</strong>
        </Col>
        <Col xs="12 mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.duration")}</span>
          <strong>{eventDuration}</strong>
        </Col>
      </Row>
    )
  }

  renderCallDetails = () => {
    const { data, request } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { recapParticipants } = data;
    let { callId } = request;

    const columns = [
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callDetails.sessionType"), type: 'string', filter: true },
      { id: 'tempoPresenza', label: CommonHelper.GetTrans("reports.fields.callDetails.attendanceTime"), type: 'time', inputFormat: 'HH:mm:ss.SSS' },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callDetails.role"), type: 'string', filter: true },
      { id: 'disconnessioni', label: CommonHelper.GetTrans("reports.fields.callDetails.disconnections"), type: 'string', filter: true, size: 'small', align: 'center' }
    ];

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={recapParticipants}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callDetails` }}
            readOnly={true}
            orderBy='partecipante'
          />
        </Col>
      </Row>
    )
  }

  renderCallFullDetails = () => {
    const { data, request } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { participantsLogs } = data;
    let { callId } = request;

    const columns = [
      //{ id: 'nomeTeam', label: CommonHelper.GetTrans("reports.fields.callFullDetails.teamName"), type: 'string', filter: true },
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callFullDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callFullDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callFullDetails.sessionType"), type: 'string', filter: true },
      { id: 'stato', label: CommonHelper.GetTrans("reports.fields.callFullDetails.status"), type: 'string', filter: true },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callFullDetails.role"), type: 'string', filter: true },
      { id: 'dataLog', label: CommonHelper.GetTrans("reports.fields.callFullDetails.logDate"), type: 'datetime-local', filter: true }
    ];
    
    let userRole = CommonHelper.GetUserRole();
    let isAdmin = ['admin'].includes(userRole);

    if (isAdmin) {
      const deleteRow = async (id) => {
        if (window.confirm('Conferma cancellazione?')) {
          let result = await this.context.dataProvider.delete(`CallParticipantsTrackings`, { id: id });
          if (result && result.data) {
            ToastHelper.Success("Rimosso");
            //this.getReport();
          }
          else {
            ToastHelper.Error("Errore");
          }
        }
      }
      columns.push({ id: 'remove', label: "", type: 'action', icon: <DeleteIcon />, callback: deleteRow, width: '100px' });
    }

    return (
      <Row className="h-100">
        {
          isAdmin &&
          <Col xs="12" className="h-100 d-flex justify-content-center align-items-center p-2">
            <AddParticipant callId={callId} callInfo={data.callInfo} getReport={this.getReport} />
          </Col>
        }
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={participantsLogs}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callFullDetails` }}
            readOnly={true}
            orderBy='partecipante'
          />
        </Col>
      </Row>
    )
  }

  buildCallDetailsTabs = () => {
    return [
      { title: CommonHelper.GetTrans("reports.tabs.callDetails"), content: this.renderCallDetails() },
      { title: CommonHelper.GetTrans("reports.tabs.callFullDetails"), content: this.renderCallFullDetails() }
    ]
  }

  render() {
    const { loading, request, data, relatedData } = this.state;
    const { classes, seo } = this.props;

    return (
      <>
        <Header />
        <Container className="mt--8" fluid>
          <Row>
            <Paper className={classNames(classes.root, 'px-3 pt-3 pb-0')}>
              <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                <Row className="no-gutters">
                  <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                    <SelectExt name="teamId" label="Teams" options={relatedData.teamsOptions} onChange={this.onHandleChange} value={request.teamId} />
                  </Col>
                  <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                    <SelectExt name="teamChannelId" label="Channels" options={relatedData.teamChannelsOptions} onChange={this.onHandleChange} value={request.teamChannelId} />
                  </Col>
                  <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                    <SelectExt name="callId" label="Call" options={relatedData.callsOptions} onChange={this.onHandleChange} value={request.callId} />
                  </Col>
                </Row>
              </ValidatorForm>
              {loading ?
                <Row className="justify-content-center">
                  <LoaderExt />
                </Row>
                :
                <>
                  <Row>
                    <Col>
                      {this.renderCallInfo()}
                    </Col>
                    <Col>
                      {this.renderGraph()}
                    </Col>
                    <Col>
                      {this.renderEventInfo()}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-0">
                      {
                        data && <EnhancedTabs ref={this.tabsRef} tabs={this.buildCallDetailsTabs()} activeTab={0} />
                      }
                    </Col>
                  </Row>
                </>
              }
            </Paper>
          </Row>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(ReportByLesson); 