import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from 'classnames';

import { TeamsContext, ConfirmDialog, ButtonTooltip } from "libs/teams-tab";
import { Flex, Accordion, Text, Loader, AcceptIcon, SendIcon } from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

import Answers from './Answers';

const customClass = mergeStyleSets({
    question: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.sent': {
            backgroundColor: '#237B4B',
            color: '#FFFFFF',
            cursor: 'default',
        }
    },
    accordionTitle: {
        '& *': {
            backgroundRepeat: 'no-repeat',
        },
    }, 
    accordionContent: {
    }
});

export const QuestionsList = ({ index, question }) => {
    const teamsContext = useContext(TeamsContext);
    const questionRef = useRef(null);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        setSent(question.surveyTeamMemberAnswers && question.surveyTeamMemberAnswers.length > 0);
    }, [question]);

    const panels =
        [
            {
                title: {
                    key: 'question',
                    className: customClass.accordionTitle,
                    content: (
                        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.question, sent ? 'sent' : '')} >
                            <Text size="medium" content={`${index}. ${question.text}`} align="start" />
                            <Flex.Item push>
                                <Flex gap="gap.small">
                                    {
                                        sent && <ButtonTooltip secondary text iconOnly noBg light icon={<AcceptIcon />} tooltip='Domanda inviata' />
                                    }
                                </Flex>
                            </Flex.Item>
                        </Flex>
                    ),
                },
                content: {
                    key: 'answers',
                    className: customClass.accordionContent,
                    content: <Answers question={question} sent={sent}  />,
                },
            },
        ]

    return (
        <div ref={questionRef} className="my-2">
            {
                !question ?
                    <Loader /> :
                    <Flex gap="gap.medium" padding="padding.medium" column className={classNames(customClass.question, sent ? 'sent' : '')} >
                        <Accordion panels={panels} className="pippo" />
                    </Flex>
            }
        </div>
    )
}