import React from 'react';
import moment from 'moment';
import { CommonHelper, ToastHelper, FetchHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { AdminDashboardContext } from "libs/admin-dashboard";

import Paper from '@material-ui/core/Paper';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { SelectExt, MousePopoverExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import Header from "components/Headers/Header.jsx";

// reactstrap components
import { Card, CardHeader, CardFooter, CardBody, CardTitle, CardSubtitle, Container, Row, Col } from "reactstrap";
import Button from 'reactstrap-button-loader';

import { MSTeamsIcon, MSTeamsIconWeight, MSTeamsIconType } from 'msteams-ui-icons-react';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
    zIndex: 0,
    boxShadow: '0px 5px 10px 0px #7d7d7d'
  }
});

class MsEventSurveys extends React.Component {
  static contextType = AdminDashboardContext;

  constructor(props) {
    super();

    this.tabsRef = React.createRef();

    this.state = {
      loading: true,
      relatedData: {
        teamsOptions: [],
        teamChannelsOptions: [],
        teamEventsOptions: [],
        event: null,
        surveys: []
      },
      updatingData: false,
      request: {
        teamId: undefined,
        teamChannelId: undefined,
        teamEventId: undefined,
        surveyIds: []
      }
    };
  }

  componentDidMount() {
    const { seo } = this.props;
    this.context.setData({ seo: seo, currentPage: "mseventsurvey" });
    this.getTeams();
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  cleanData = () => {
    const defData = {
      updatingData: false,
      relatedData: {
        teamsOptions: [],
        teamChannelsOptions: [],
        teamEventsOptions: [],
        event: null,
        surveys: []
      },
      request: {
        teamId: undefined,
        teamChannelId: undefined,
        teamEventId: undefined,
        surveyIds: []
      }
    };

    this.setState({ ...defData }, () => this.getTeams());
  }

  onHandleChange = (value, field) => {
    let { request } = this.state;

    request[field] = value;

    this.setState({ field: value })

    switch (field) {
      case 'teamId':
        this.getTeamChannels();
        break;
      case 'teamChannelId':
        this.getTeamEvents();
        break;
      case 'teamEventId':
        this.getSurveys();
        break;
    }
  }

  isLoaded = () => {
    if (this.state.loading)
      setTimeout(() => { this.setState({ loading: false }) }, 500);
  }

  isLoading = () => {
    if (this.state.loading === false)
      this.setState({ loading: true });
  }

  async getTeams() {
    let result = await this.context.dataProvider.getList("msteams?$select=id,displayName");
    let options = result.data.map(j => ({ value: j.id, label: j.displayName })).sort(CommonHelper.CompareValues("label"));
    this.setOptionsData(options, [], []);
  }

  async getTeamChannels() {
    let { teamId } = this.state.request;
    let options = [];

    if (teamId){
      let result = await this.context.dataProvider.getList(`msteamchannelsbyteam/${teamId}?$select=teamChannelId,displayName`);
      options = result.data.map(j => ({ value: j.teamChannelId, label: j.displayName })).sort(CommonHelper.CompareValues("label"));
    }

    this.setOptionsData(null, options, []);
  }

  async getTeamEvents() {
    let { teamChannelId } = this.state.request;
    let options = [];

    if (teamChannelId) {
      let result = await this.context.dataProvider.getList(`msteameventsbyteamchannel/${teamChannelId}?$select=id,subject`);
      options = result.data.map(j => ({ value: j.id, label: j.subject })).sort(CommonHelper.CompareValues("label"));
    }

    this.setOptionsData(null, null, options);
  }

  async getSurveys() {
    let { teamEventId } = this.state.request;
    let event = null;
    let options = [];

    if (teamEventId) {
      let eventResult = await this.context.dataProvider.getOne(`msevents`, { id: teamEventId, expand: 'EventSurveys' });
      let surveysResult = await this.context.dataProvider.getList(`surveys`, { filter: { enabled: true }, expand: 'Questions' });
      event = eventResult.data;
      options = surveysResult.data;
    }

    this.setOptionsData(null, null, null, options, event);
  }

  setOptionsData(teamsOptions = null, teamChannelsOptions = null, teamEventsOptions = null, surveys = [], event = null) {
    let { relatedData, request } = this.state;

    relatedData = { ...relatedData };
    request = { ...request };

    if (teamsOptions !== null){
      relatedData.teamsOptions = teamsOptions;
      request.teamId = undefined;
    }

    if (teamChannelsOptions !== null) {
      relatedData.teamChannelsOptions = teamChannelsOptions;
      request.teamChannelId = undefined;
    }

    if (teamEventsOptions !== null) {
      relatedData.teamEventsOptions = teamEventsOptions;
      request.teamEventId = undefined;
    }

    relatedData.event = event;
    relatedData.surveys = surveys;

    request.surveyIds = event !== null ? event.eventSurveys.map(e => e.surveyId) : [];

    this.setState({ relatedData, request });
  }

  renderSurveys = () => {
    let that = this;
    let { relatedData } = this.state;
    let { surveys } = relatedData;

    if (CommonHelper.IsEmpty(surveys)) {
      return null;
    }

    let selectSurvey = (id) => {
      let _request = { ...that.state.request };
      let _surveysIds = [..._request.surveyIds];

      let sIndex = _surveysIds.findIndex(sId => sId === id);
      if (sIndex > -1) { _surveysIds.splice(sIndex, 1); } else { _surveysIds.push(id); }
      _request.surveyIds = _surveysIds;

      this.setState({ request: _request });
    }

    let renderQuestions = (questions) => {
      return questions.sort(CommonHelper.CompareValues("createdAt")).map((question, key) => {
        return (
          <ListItem key={key}>
            <span><strong>{question.text}</strong>&nbsp;({question.type})</span>
          </ListItem>
        )
      })
    }

    surveys = surveys.map((survey, key) => {
      let selected = this.state.request.surveyIds.includes(survey.id);
      
      return (
        <Col key={key} xs="3" className="h-100 w-100 d-flex justify-content-center align-items-center p-3">
          <Card className="border border-livion-blue w-100 h-100">
            <CardHeader className="bg-livion-blue"></CardHeader>
            <CardBody>
              <CardTitle className="h2">{survey.title}</CardTitle>
              <CardSubtitle>
                {survey.questions.length === 1 ? `1 domanda` : `${survey.questions.length} domande`}
                {
                  survey.questions && survey.questions.length > 0 &&
                  <MousePopoverExt popoverElement={renderQuestions(survey.questions)}>
                    <VisibilityOutlinedIcon />
                  </MousePopoverExt>
                }
              </CardSubtitle>
              {
                !CommonHelper.IsEmpty(survey.maxDelay) ?
                  <CardSubtitle className="mt-2">{`Utenti ammessi entro ${survey.maxDelay} dall'inizio del sondaggio`}</CardSubtitle> :
                  <CardSubtitle className="mt-2">{`Utenti ammessi sempre`}</CardSubtitle>
              }
            </CardBody>
            <CardFooter>
              <Button className={classNames(selected ? "btn-danger" : "btn-success", "w-100 mt-3")} onClick={() => selectSurvey(survey.id)}>
                {
                  selected ?
                    "Rimuovi" :
                    "Seleziona"
                }
              </Button>
            </CardFooter>
          </Card>
        </Col>
      )
    })

    return (
      <Row className="h-100">
        {surveys}
      </Row>
    )
  }

  renderEventInfo = () => {
    let { relatedData } = this.state;
    let { event } = relatedData;

    if (CommonHelper.IsEmpty(event))
      return null;

    var eventStartTimeMoment = moment(event.startTime);
    var eventEndTimeMoment = moment(event.endTime);

    var eventDate = eventStartTimeMoment.format("DD MMM YYYY");
    var eventStartTime = eventStartTimeMoment.format('DD MMM YYYY HH:mm');
    var eventEndTime = eventEndTimeMoment.format('DD MMM YYYY HH:mm');
    var eventDuration = moment.utc(eventEndTimeMoment.diff(eventStartTimeMoment)).format('HH:mm:ss');

    return (
      <Row className="mb-4">
        <Col xs="6" className="d-flex justify-content-start align-items-center mb-3">
          <MSTeamsIcon style={{ fontSize: 38 }} className="d-inline-block mr-3" aria-label={'ContactGroup'} iconWeight={MSTeamsIconWeight.Light} iconType={MSTeamsIconType['ContactGroup']} />
          <strong style={{ fontSize: 25 }} className="mr-2" >{eventDate}</strong>
          <span style={{ fontSize: 20 }}>{eventStartTime} - {eventEndTime}</span>
        </Col>
        <Col xs="3" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.duration")}</span>
          <strong>{eventDuration}</strong>
        </Col>
        <Col xs="3" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.type")}</span>
          <strong>Microsoft Teams</strong>
        </Col>
      </Row>
    )
  }

  handleSave = async () => {
    const { request, relatedData } = this.state;
    let { surveyIds } = request;
    let { event } = relatedData;

    event.eventSurveys = surveyIds.map(surveyId => { return { eventId: event.id, surveyId: surveyId }; });

    this.setState({ updatingData: true }, async () => {
      let result = await this.context.dataProvider.update(`eventsurveys`, { id: event.id, data: event });
      if (result.data.success) {
        ToastHelper.Success("Aggiornato");
        this.cleanData();
      }
      else {
        this.setState({ updatingData: true }, () => {
          ToastHelper.Error("Errore");
        });
      }
    });
  }

  render() {
    const { updatingData, relatedData, request } = this.state;
    const { classes } = this.props;

    let canSave = !(!updatingData && relatedData.event && !CommonHelper.AreEquals(relatedData.event.eventSurveys.map(es => es.surveyId), request.surveyIds));

    return (
      <>
        <Header />
        <Container className="mt--8" fluid>
          <Row>
            <Col>
              <Paper className={classNames(classes.root, 'px-3 pt-3 pb-0')}>
                <Container className="py-2 px-4 mw-100">
                  <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                    <Row className="no-gutters">
                      <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                        <SelectExt name="teamId" label="Teams" options={relatedData.teamsOptions} onChange={this.onHandleChange} value={request.teamId} />
                      </Col>
                      <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                        <SelectExt name="teamChannelId" label="Team Channels" options={relatedData.teamChannelsOptions} onChange={this.onHandleChange} value={request.teamChannelId} />
                      </Col>
                      <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                        <SelectExt name="teamEventId" label="Team Events" options={relatedData.teamEventsOptions} onChange={this.onHandleChange} value={request.teamEventId} />
                      </Col>
                    </Row>
                  </ValidatorForm>
                  <Row className="py-2">
                    {
                      relatedData &&
                      <>
                        {
                          relatedData.event &&
                          <Col xs="12">
                            {this.renderEventInfo()}
                          </Col>
                        }
                        {
                          relatedData.surveys.length > 0 &&
                          <>
                            <Col xs="12">
                              {this.renderSurveys()}
                            </Col>
                            <Col xs="12" className="pt-3 text-center">
                              <Button className={classes.button} variant="contained" color="success" onClick={this.handleSave} disabled={canSave}>{CommonHelper.GetTrans("buttons.save")}</Button>
                            </Col>
                          </>
                        }
                      </>
                    }
                  </Row>
                </Container>
              </Paper>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(MsEventSurveys); 