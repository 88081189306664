import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from 'classnames';

import { ConfirmDialog, ButtonTooltip } from "libs/teams-tab";
import { Flex, Accordion, Text, Loader, AcceptIcon, SendIcon } from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

import Answers from './Answers';

const customClass = mergeStyleSets({
    question: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.sent': {
            backgroundColor: '#237B4B',
            color: '#FFFFFF',
            cursor: 'default',
        }
    },
    accordionTitle: {
        '& *': {
            backgroundRepeat: 'no-repeat',
        },
    }, 
    accordionContent: {
    }
});

export const QuestionsList = ({ index, question, callSurveyActivities, selectQuestion }) => {
    const questionRef = useRef(null);
    const [sent, setSent] = useState(false);

    const handleClick = () => {
        if (!sent) {
            selectQuestion(question);
        }
    };

    useEffect(() => {
        if (callSurveyActivities.length > 0) {
            if (callSurveyActivities[0].surveyTeamMemberAnswers.length > 0) {
                let isSent = callSurveyActivities[0].surveyTeamMemberAnswers.filter(stma => stma !== null && stma.questionId === question.id).length > 0;
                setSent(isSent);
            }
        }
    }, [callSurveyActivities]);

    const panels =
        [
            {
                title: {
                    key: 'question',
                    className: customClass.accordionTitle,
                    content: (
                        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.question, sent ? 'sent' : '')} >
                            <Text size="medium" content={`${index}. ${question.text}`} align="start" />
                            <Flex.Item push>
                                <Flex gap="gap.small">
                                    {
                                        sent ?
                                            <ButtonTooltip secondary text iconOnly noBg light icon={<AcceptIcon />} tooltip='Domanda inviata' /> :
                                            <ConfirmDialog
                                                header="Conferma invio domanda?"
                                                content={question.text}
                                                triggerButton={
                                                    <ButtonTooltip secondary text iconOnly noBg icon={<SendIcon />} tooltip='Invia domanda' />
                                                }
                                                onConfirmCallback={handleClick}
                                            />
                                    }
                                </Flex>
                            </Flex.Item>
                        </Flex>
                    ),
                },
                content: {
                    key: 'answers',
                    className: customClass.accordionContent,
                    content: <Answers question={question} sent={sent} />,
                },
            },
        ]

    return (
        <div ref={questionRef} className="my-2">
            {
                !question ?
                    <Loader /> :
                    <Flex gap="gap.medium" padding="padding.medium" column className={classNames(customClass.question, sent ? 'sent' : '')} >
                        <Accordion panels={panels} className="pippo" />
                    </Flex>
            }
        </div>
    )
}