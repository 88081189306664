import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import i18n from "i18next";
import LocalProvider from "./LocalProvider";

import { CommonHelper } from 'components/Common/Helper/Helper';

// reactstrap components
import { FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

import styles from "./LocalAuth.module.css";

import Button from 'reactstrap-button-loader';

class LocalAuth extends React.Component {
    static propTypes = {
        loggedIn: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.string,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func,
    };

    constructor(props) {
        super();

        this.state = {
            data: {
                userName: "",
                password: ""
            }
        };
    }

    validateForm() {
        let { userName, password } = this.state.data;
        return userName.length > 0 && password.length > 0;
    }

    handleChange = e => {
        let data = { ...this.state.data };
        data[e.target.id] = e.target.value;
        this.setState({ data });
    }

    handleSubmit = e => {
        e.preventDefault();

        const { onSignIn } = this.props;
        let { userName, password } = this.state.data;

        onSignIn(userName, password);
    };

    render() {
        const { loggedIn, loading, error, onSignOut } = this.props;
        const { userName, password } = this.state.data;

        return (
            <div>
                <section>
                    {
                        !loggedIn ?
                            <Form role="form" onSubmit={this.handleSubmit} method="post">
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-user text-livion-gray" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Username" className="pl-2" type="text" id="userName" name="userName" value={userName} onChange={this.handleChange} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-unlock-alt text-livion-gray" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input placeholder="Password" className="pl-2" type="password" id="password" name="password" value={password} onChange={this.handleChange} />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-center">
                                    <Button loading={loading} className="my-4" color="livion-blue" type="submit" disabled={!this.validateForm()}>
                                        {i18n.t("buttons.signin")}
                                    </Button>
                                </div>
                            </Form> :
                            <button onClick={onSignOut}>{CommonHelper.GetTrans("buttons.signout")}</button>
                    }
                    {
                        error &&
                        <p className={classNames(styles.error, "text-center mt-2")}>
                            <strong style={{ textDecoration: 'underline' }}>Error</strong>
                            <br />
                            {error}
                        </p>
                    }
                </section>
            </div>
        );
    }
}

export default LocalProvider(LocalAuth);