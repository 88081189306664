/* eslint-disable no-unused-vars */

import React from "react";
import classNames from 'classnames';
import { CommonHelper } from 'components/Common/Helper/Helper';

import * as microsoftTeams from "@microsoft/teams-js";
import { Users, Teams, Groups, teamsRolesEnum } from 'libs/microsoft-graph-service';
import { TeamsContext, TabNavBar, teamsTabStyles, rolesEnum } from "libs/teams-tab";

import { Flex, Segment, Text, Loader, Button, Input, SendIcon } from '@fluentui/react-northstar'
import { red } from "@material-ui/core/colors";

let myContext = null;

class Config extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      importing: false,
      importingMessage: null,
      completedConfig: false,
      errorMessage: null,
      successMessage: null
    };
  }

  componentDidMount() {
  }

  completeConfig = () => {
    this.setState({ completedConfig: true, errorMessage: null, loading: false }, () => {
      /** Enable the Save button  */
      microsoftTeams.settings.setValidityState(true);
      /** Register the save handler */
      microsoftTeams.settings.registerOnSaveHandler(saveEvent => {
        /** Store Tab content settings */
        microsoftTeams.settings.setSettings({
          contentUrl: `${window.location.origin}/teamstab/`,
          suggestedDisplayName: "CWS Meetings",
          websiteUrl: `${window.location.origin}/teamstab/`
        });
        saveEvent.notifySuccess();
        console.info("COMPLETED CONFIG")
      });
    });
  }

  login = async () => {
    this.setState({ importing: true }, async () => {
      const { userName, password } = this.state;
      let data = { userName, password, providerType: 'local' };
      let { success, error } = await myContext.authProvider.login(data).then(result => result).catch(result => result);

      if (success) {
        this.createOrUpdateTeam();
      }
      else {
        console.error(error)
        this.setState({ completedConfig: false, errorMessage: "userName e/o password non corretti", importing: false });
      }
    })
  }

  createOrUpdateTeam = () => {
    this.setState({ importing: true, importingMessage: 'Importazione team ...' }, async () => {
      const { msTeamsContext, ssoToken } = myContext;
      const { groupId } = msTeamsContext;
      
      const msTeam = await Teams.Get(groupId).catch(e => { if (e.authError) { console.info(e.errorMessage); } });
      let errorMessage = null;

      if (msTeam) {
        let result = await myContext.dataProviders.api.create('msteams/createorupdate', { data: msTeam, addMsToken: true });

        if (result && result.data) {
          this.createOrUpdateTeamChannel();
          return;
        }
        else {
          errorMessage = "Errore nella creazione del Team"
        }
      }
      else {
        errorMessage = "Errore nel recupero delle informazioni sul Team"
      }

      this.setState({ completedConfig: false, errorMessage, importing: false, loading: false });
    })
  }

  createOrUpdateTeamChannel = async () => {
    this.setState({ importingMessage: 'Importazione team channel ...' }, async () => {
      const { msTeamsContext, ssoToken } = myContext;
      const { groupId, channelId } = msTeamsContext;
      const msTeamChannel = await Teams.GetChannel(groupId, channelId).catch(e => { if (e.authError) { console.info(e.errorMessage); } });
      let errorMessage = null;

      if (msTeamChannel) {
        msTeamChannel.teamId = groupId;
        msTeamChannel.teamChannelId = channelId;
        delete msTeamChannel.id;
        let result = await myContext.dataProviders.api.create('msteamchannels/createorupdate', { data: msTeamChannel, addMsToken: true });

        if (result && result.data) {
          this.addTeamChannelMembers(result.data.id)
          return;
        }
        else {
          errorMessage = "Errore nella creazione del Team Channel"
        }
      }
      else {
        errorMessage = "Errore nel recupero delle informazioni sul Team Channel"
      }

      this.setState({ completedConfig: false, errorMessage, importing: false, loading: false });
    })
  }

  addTeamChannelMembers = async (teamChannelId) => {
    this.setState({ importingMessage: 'Importazione utenti ...' }, async () => {
      const { msTeamsContext, ssoToken } = myContext;
      const { groupId } = msTeamsContext;

      let msTeamMembers = await Teams.GetMembers(groupId).catch(e => { if (e.authError) { console.info(e.errorMessage); } });
      
      if (msTeamMembers) {
        let teacherGroup = null;

        try {
          let teachersGroupName = CommonHelper.GetEnv("DEFAULT_TEACHERS_GROUP_NAME", "Teachers");

          if (!CommonHelper.IsEmpty(teachersGroupName)) {
            let teachersGroup = await Groups.GetByName(teachersGroupName).catch(err => err);
            if (teachersGroup && CommonHelper.IsArray(teachersGroup) && teachersGroup.length > 0) {
              teacherGroup = teachersGroup[0];
            }
          }
        }
        catch (e) {
          console.error(e);
        }

        const userIds = msTeamMembers.map(x => `'${x.userId}'`);
        const limit = 15;

        let msUsers = [];

        const forLoopAsync = async _ => {
          for (var i = 0; i < userIds.length; i = i + limit) {
            const _msUsers = await Users.GetMany(userIds.slice(i, i + limit)).catch(e => e);
            Array.prototype.push.apply(msUsers, _msUsers);
          }
        }

        await forLoopAsync();

        const allData = await Promise.all(msUsers.map(async msUser => {
          let msTeamMember = msTeamMembers.find(x => x.userId === msUser.id);
          let data = {};
          
          if (msTeamMember) {
            let type;

            if (msTeamMember.roles.includes(teamsRolesEnum.Guest)) {
              type = rolesEnum.Guest;
            }
            else if (msTeamMember.roles.includes(teamsRolesEnum.Owner)) {
              type = rolesEnum.Organizer;
            }
            else {
              type = rolesEnum.Member;
            }

            data = {
              id: msUser.id,
              displayName: msUser.displayName,
              givenName: msUser.givenName,
              surname: msUser.surname,
              mail: msUser.mail || msUser.userPrincipalName,
              userPrincipalName: msUser.userPrincipalName,
              msteamMemberMsteamChannels: [{ teamChannelId, teamMemberId: msUser.id, type: type }]
            }

            try {
              if (teacherGroup !== null) {
                let hasTeacherGroup = await Users.HasGroup(data.id, teacherGroup.id).catch(err => err);
                if (hasTeacherGroup) {
                  if (type === rolesEnum.Organizer) {
                    data.msteamMemberMsteamChannels.push({
                      teamChannelId,
                      teamMemberId: msTeamMember.userId,
                      type: rolesEnum.Teacher
                    });
                  }
                  else {
                    data.msteamMemberMsteamChannels = [{ teamChannelId, teamMemberId: msTeamMember.userId, type: rolesEnum.Teacher }];
                  }
                }
              }
            }
            catch (e) {
              console.error(e);
            }
          }
          return { success: true, data };
        }));

        const allDataSuccess = allData.filter(x => x.success).map(x => x.data);
        const allDataFailed = allData.filter(x => !x.success).map(x => x.data);

        let result = await myContext.dataProviders.api.create('msteammembers/createorupdatemany', { data: allDataSuccess, addMsToken: true }).catch(e => e);

        if (result && result.data) {
          let successMessage = "Importazione completata";
          if (allDataFailed.length > 0){
            successMessage = (
              <>
                <p>Importazione completata</p>
                <p style={{ color: red[500] }}>Utenti non importati:</p>
                <ul style={{ listStyleType: 'none', padding: 0 }} >
                  {
                    allDataFailed.map((x, key) => <li key={key}>{`${x.displayName} (${x.email})`}</li>)
                  }
                </ul>
              </>
            )
          }
          this.setState({ successMessage, importingMessage: null, importing: false });
          this.completeConfig();
        }
        else {
          this.setState({ errorMessage: "Importazione fallita", importingMessage: null, importing: false });
          this.completeConfig();
        }
      }
      else {
        this.setState({ errorMessage: "Nessun membro del team trovato" });
      }
    })
  }

  renderAdminAuth = () => {
    const { errorMessage, successMessage, userName, password } = this.state;
    const { isOwner, consentRequired } = myContext;

    if (successMessage) {
      return (
        <Flex.Item>
          <Text size="medium" content={successMessage} align="center" />
        </Flex.Item>
      )
    }

    return (
      <>
        <Flex.Item>
          <Text size="medium" content="Benvenuto" align="center" />
        </Flex.Item>
        <Flex.Item>
          <Text size="medium" weight="bold" content={myContext.msTeamsContext.upn} align="center" />
        </Flex.Item>
        {/* <Flex.Item>
            <Text size="medium" content="Inserire credenziali da Admin per configurare la scheda" align="center" className="mt-2" />
          </Flex.Item>
          <Flex.Item>
            <Flex vAlign="center">
              <Input name="text" fluid placeholder="Username o Email" value={userName} autoComplete="off" onChange={(e) => this.setState({ userName: e.target.value, errorMessage: null })} />
            </Flex>
          </Flex.Item>
          <Flex.Item>
            <Flex vAlign="center">
              <Input name="text" fluid placeholder="Password" type="password" value={password} onChange={(e) => this.setState({ password: e.target.value, errorMessage: null })} />
            </Flex>
          </Flex.Item> */}
        {
          isOwner ?
            <Flex.Item>
              <Button content="Importa dati del Team" primary icon={<SendIcon />} iconPosition="after" onClick={this.createOrUpdateTeam} align="center" disabled={!isOwner} />
            </Flex.Item> :
            <Flex.Item>
              <Text size="medium" content={consentRequired ? "Richiesto il consenso per procedere. Controllare popup." : "Permessi insufficienti per procedere."} align="center" className="mt-2" />
            </Flex.Item>
        }
      </>
    )
  }

  renderImportingStatus = () => {
    const { importingMessage } = this.state;
    return (
        <Flex.Item>
          <Loader label={importingMessage} />
        </Flex.Item>
    )
  }

  render() {
    const { loading, importing } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;
          return (
            <>
              <TabNavBar />
              <Segment styles={teamsTabStyles.content}>
                {
                  !myContext || !myContext.msTeamsContext || loading ?
                    <Loader label="Autenticazione in corso..." /> :
                    <Flex gap="gap.small" column hAlign="center">
                      {
                        myContext.msTeamsContext.upn !== null && myContext.msTeamsContext.groupId !== null ?
                          <>
                            {
                              importing ? this.renderImportingStatus() : this.renderAdminAuth()
                            }                            
                          </> :
                          <>
                            <Flex.Item>
                              <Text size="large" content="Autenticazione fallita" align="center" />
                            </Flex.Item>
                            <Flex.Item>
                              <Text size="large" content={"Applicazione non eseguita in Microsoft Teams"} align="center" />
                            </Flex.Item>
                          </>
                      }
                    </Flex>
                }
              </Segment>
            </>
          )
        }}
      </TeamsContext.Consumer>
    );
  }
}

export default Config;