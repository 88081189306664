import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../MaterialUI-Ext/theme';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class EnhancedTabs extends React.Component {

    constructor(props) {
        super();

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: props.activeTab
        };
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    render() {
        const { value } = this.state;
        const { classes, tabs } = this.props;

        return (
            <div className="w-100">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        {tabs.map((t, key) => {
                            return (
                                <Tab className={classes.tab} key={key} label={t.title} {...a11yProps(key)} />
                            );
                        })}
                    </Tabs>
                </AppBar>
                {tabs.map((t, key) => {
                    return (
                        <TabPanel key={key} value={value} index={key}>
                            {t.content}
                        </TabPanel>
                    );
                })}
            </div>
        )
    }
}

export default withStyles(styles)(EnhancedTabs); 