import * as React from 'react';
import {
    isValidElement,
    Children,
    cloneElement,
    useCallback,
    FC,
    ReactElement,
} from 'react';
import PropTypes from 'prop-types';

import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import moment from 'moment';

import DatagridHeader from './DatagridHeader';
import DatagridToolbar from './DatagridToolbar';
import { LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import LanguageIcon from '@material-ui/icons/Language';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import RedditIcon from '@material-ui/icons/Reddit';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import { Row, Col, Button } from "reactstrap";
import { Link } from 'react-router-dom'

import DatagridBody from './DatagridBody';
import useDatagridStyles from './useDatagridStyles';

import { useListContext } from 'libs/admin-dashboard';

const Datagrid = props => {
    const classes = useDatagridStyles(props);
    const {
        optimized = false,
        body = <DatagridBody />,
        children,
        className,
        expand,
        hasBulkActions = false,
        hover,
        isRowSelectable,
        rowClick,
        rowStyle,
        size = 'small',
        ...rest
    } = props;

    const {
        basePath,
        currentSort,
        data,
        ids,
        loaded,
        onSelect,
        onToggleItem,
        resource,
        selectedIds,
        setSort,
        total,
    } = useListContext(props);

    const updateSort = useCallback(
        event => {
            event.stopPropagation();
            // const newField = event.currentTarget.dataset.field;
            // const newOrder =
            //     currentSort.field === newField
            //         ? currentSort.order === 'ASC'
            //             ? 'DESC'
            //             : 'ASC'
            //         : event.currentTarget.dataset.order;

            // setSort(newField, newOrder);
            console.log(event.currentTarget)
        },
        [currentSort.field, currentSort.order, setSort]
    );

    const handleSelectAll = useCallback(
        event => {
            // if (event.target.checked) {
            //     const all = ids.concat(
            //         selectedIds.filter(id => !ids.includes(id))
            //     );
            //     onSelect(
            //         isRowSelectable
            //             ? all.filter(id => isRowSelectable(data[id]))
            //             : all
            //     );
            // } else {
            //     onSelect([]);
            // }
            console.log(event.target.checked)
        },
        [data, ids, onSelect, isRowSelectable, selectedIds]
    );

    return (
        <Paper className={classes.paperRootClass} style={!loaded || CommonHelper.IsEmpty(data) ? { height: 285 } : null}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">

                </Table>
            </div>
        </Paper>
    );
}

Datagrid.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.object,
    // @ts-ignore
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
};

export default Datagrid;