/* eslint-disable no-unused-vars */

import React from "react";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { CommonHelper } from 'components/Common/Helper/Helper';

import { AdminDashboardContext } from "libs/admin-dashboard";
import { LocalAuth } from "components/Common/EnhancedAuth/EnhancedAuth";

import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

const styles = theme => ({});

class Login extends React.Component {
  static contextType = AdminDashboardContext;

  constructor(props) {
    super();

    this.tabsRef = React.createRef();

    this.state = {
      loggedIn: !CommonHelper.IsEmpty(CommonHelper.GetLoginStatus()),
      tabs: []
    };
  }

  componentDidMount() {
    const { seo } = this.props;
    this.context.setData({ seo: seo, currentPage: "login" });
    this.buildTabs();
  }

  buildTabs = () => {
    const { onSignInSuccess, onSignOutSuccess } = this.context;
    let tabs = [];

    const localAuthTab = (
      <>
        <Row>
          <Col>
            <Card className="bg-livion-white shadow border-0 rounded-0 w-100">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-1 mb-1">
                  <small>{CommonHelper.GetTrans("auth.local.message")}</small>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-2">
                <LocalAuth />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );

    // const microsoftAuthTab = (
    //   <>
    //     <Row>
    //       <Col>
    //         <Card className="bg-livion-white shadow border-0 rounded-0 w-100">
    //           <CardHeader className="bg-transparent">
    //             <div className="text-muted text-center mt-1 mb-1">
    //               <small>{CommonHelper.GetTrans("auth.microsoft.message")}</small>
    //             </div>
    //           </CardHeader>
    //           <CardBody className="px-lg-2 pt-lg-4 pb-lg-6">
    //             <MicrosoftAuth onSignInSuccess={onSignInSuccess} onSignOutSuccess={onSignOutSuccess} />
    //           </CardBody>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </>
    // );

    tabs = [
      //{ title: CommonHelper.GetTrans("auth.tabs.microsoft"), content: microsoftAuthTab },
      { title: CommonHelper.GetTrans("auth.tabs.local"), content: localAuthTab }
    ];

    this.setState({ tabs })
  }

  render() {
    return (
      <Container className="h-100">
        <Row className="h-100 justify-content-center">
          <Col xs="6" className="h-100 d-flex justify-content-center align-items-center">
            {
              <EnhancedTabs ref={this.tabsRef} tabs={this.state.tabs} activeTab={0} />
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(styles)(Login); 