/* eslint-disable no-unused-vars */

import React from "react";
import { CommonHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import { TeamsContext, teamsTabStyles, TabNavBar, rolesEnum, ButtonTooltip } from "libs/teams-tab";

import {
  Grid, Flex, Segment, Text, Loader, cardsContainerBehavior, Button, Divider,
} from '@fluentui/react-northstar'

import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from 'office-ui-fabric-react';

import * as TeamsTab from "views/TeamsTab";

let myContext = null;

const customClass = mergeStyleSets({
  cardContainer: {
    minHeight: 120,
    '& > div:first-child:hover': {
      cursor: 'pointer',
      backgroundColor: '#f3f3f3',
    }
  },
  icon: {
    fontSize: 20,
    marginBottom: '0 !important',
  }
});

class HomePage extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  buildCards = () => {
    return [
      {
        id: "events",
        title: "EVENTI",
        icon: <Icon iconName="ScheduleEventAction" className={customClass.icon} />,
        onClick: () => myContext.navigate("events"),
        active: [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      },
      {
        id: "surveys",
        title: "SONDAGGI",
        icon: <Icon iconName="SurveyQuestions" className={customClass.icon} />,
        onClick: () => myContext.navigate("surveys"),
        active: myContext.settings.surveysEnabled && [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      },
      {
        id: "users",
        title: "UTENTI",
        icon: <Icon iconName="Group" className={customClass.icon} />,
        onClick: () => myContext.navigate("members"),
        active: [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      },
      {
        id: "surveysToComplete",
        title: <><Text content="SONDAGGI" /><br /><Text content="DA COMPLETARE" /></>,
        icon: <Icon iconName="PollResults" className={customClass.icon} />,
        onClick: () => myContext.navigate("surveysToComplete"),
        active: myContext.settings.surveysEnabled && [rolesEnum.Admin, rolesEnum.Organizer, rolesEnum.Teacher].includes(myContext.user.role)
      },
    ]
  }

  render() {
    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          return (
            <>
              <TabNavBar />
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext ?
                      <Loader /> :
                      <>
                        <Grid accessibility={cardsContainerBehavior} columns={6}>
                          {this.buildCards().filter(card => card !== null && card.active).map(card => (
                            <div key={card.id} style={teamsTabStyles.cardContainer} className={customClass.cardContainer} onClick={card.onClick}>
                              <Flex gap="gap.small" hAlign="center" vAlign="center" style={teamsTabStyles.cardNew} column fill>
                                <Flex.Item>
                                  {card.icon}
                                </Flex.Item>
                                <Flex.Item>
                                  <Button content={card.title} text primary />
                                </Flex.Item>
                              </Flex>
                            </div>
                          ))}
                        </Grid>
                        <Divider content="Eventi di oggi" />
                        <Grid accessibility={cardsContainerBehavior} columns={1}>
                          <TeamsTab.Events {...this.props} justToday />
                        </Grid>
                      </>
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default HomePage;