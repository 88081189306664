import React, { useContext, useEffect, useState } from "react";
import classNames from 'classnames';
import { useParams } from "react-router-dom";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import moment from 'moment';

import { Groups } from 'libs/microsoft-graph-service';

import { TeamsContext, teamsTabStyles, TabNavBar, ConfirmDialog } from "libs/teams-tab";

import {
    Flex, Grid, Loader, Button, Text, cardsContainerBehavior, Segment, Checkbox, List,
    SaveIcon, CheckmarkCircleIcon,
} from '@fluentui/react-northstar'

import * as microsoftTeams from "@microsoft/teams-js";

import { mergeStyleSets } from 'office-ui-fabric-react';

const customClass = mergeStyleSets({
    cardContainer: {},
    icon: {
        fontSize: 20,
        marginBottom: '0 !important',
    }
});

export const SchoolRegister = (props) => {
    const teamsContext = useContext(TeamsContext)
    let params = useParams();
    const { msTeamsContext, teamChannel, dataProviders } = teamsContext;

    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [members, setMembers] = useState([]);

    const getMembers = async () => {
        let allMsTeamMembers = [];

        setLoading(true, async () => {
            const { groupId } = msTeamsContext;

            if (groupId) {
                let msTeamOwners = await Groups.GetOwners(groupId).catch(e => { if (e.authError) { ToastHelper.Info(e.errorMessage); } });

                if (!CommonHelper.IsEmpty(msTeamOwners)) {
                    msTeamOwners = msTeamOwners.map(msTeamOwners => {
                        delete msTeamOwners["@odata.type"];
                        msTeamOwners.presence = false;
                        msTeamOwners.type = "Owner";
                        return msTeamOwners;
                    })
                }

                let msTeamMembers = await Groups.GetMembers(groupId).catch(e => { if (e.authError) { ToastHelper.Info(e.errorMessage); } });;;

                if (!CommonHelper.IsEmpty(msTeamMembers)) {
                    msTeamMembers = msTeamMembers.reduce(function (result, msTeamMember) {
                        if (msTeamOwners.filter(to => to.id === msTeamMember.id).length === 0) {
                            delete msTeamMember["@odata.type"];
                            msTeamMember.presence = false;
                            msTeamMember.type = "Member";
                            result.push(msTeamMember);
                        }
                        return result;
                    }, []);
                }

                if (msTeamMembers.length > 0) {
                    allMsTeamMembers = [...msTeamMembers].sort(CommonHelper.CompareValues("displayName"));
                }
                else {
                    if (msTeamMembers !== undefined) {
                        ToastHelper.Info(CommonHelper.GetTrans("msteamsimporter.messages.ms_team_members.not_found"));
                    }
                }
            }
            setLoading(false, async () => {
                let lastPresences = {};
                
                let result = await dataProviders.bot.getOne('lastLessonPresences', { id: params.callId, addMsToken: true, teamChannelId: teamChannel.id });

                if (result && result.data) {
                    const { success, data, error } = result.data;
                    if (success) { if (data != null) lastPresences = data; }
                    else { ToastHelper.Error(error); }
                }
                else {
                    ToastHelper.Error("Errore durante il recupero delle ultime presenze");
                }

                if (Object.keys(lastPresences).length > 0){
                    allMsTeamMembers.forEach(msTeamMember => {
                        const memberPresence = lastPresences.userCallPresences.filter(x => x !== null && x.userId === msTeamMember.id);
                        if (memberPresence.length > 0){
                            msTeamMember.presence = memberPresence[0].presence;
                        }
                    });
                }

                setMembers(allMsTeamMembers);
            });
        });
    }

    const setPresence = (id) => {
        if (members.some(x => x.id === id)) {
            setMembers([...members].map(member => { if (member.id === id) { member.presence = !member.presence; } return member; }))
        }
    }

    const handleSubmit = async () => {
        let formData = members.map(x => ({ userId: x.id, presence: x.presence }));

        let result = await dataProviders.bot.create(`lessonPresence/${params.callId}`, { data: formData, addMsToken: true, teamChannelId: teamChannel.id });

        if (result && result.data && result.data.success) {
            setTimeout(() => {
                microsoftTeams.tasks.submitTask(null);
            }, 1500);
        }
        else {
            ToastHelper.Error("Errore durante il salvataggio delle presenze");
        }
    };

    useEffect(() => {
        async function doAsync() {
            await getMembers();
        }
        doAsync()
    }, []);

    return (
        <>
            <TabNavBar showBack backPageName="Homepage" backPageRoute={`inCallHomePage/${params.callId}`}  />
            <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                    {
                        loading ?
                            <Loader /> :
                            (
                                members ? (
                                    <>
                                        <div className="ms-Grid">
                                            <div className="ms-Grid-row">
                                                {
                                                    members.map((member, key) => {
                                                        const nonZeroIndex = key + 1
                                                        return (
                                                            <div key={nonZeroIndex} className={classNames(customClass.cardContainer, "ms-Grid-col ms-sm12 ms-md6")}>
                                                                <Flex gap="gap.small" fill>
                                                                    <Checkbox checked={member.presence} className="align-items-center" label={
                                                                        <>
                                                                            <Text size="large" content={member.displayName} />
                                                                            <br />
                                                                            <Text size="small" content={member.mail !== null ? member.mail : member.userPrincipalName} />
                                                                        </>
                                                                    } toggle onClick={() => setPresence(member.id)} />
                                                                </Flex>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <Flex gap="gap.small" hAlign="end" vAlign="center" >
                                            <ConfirmDialog
                                                header={"Azioni sugli utenti"}
                                                content={`Conferma la presenza di ${members.filter(x => x.presence).length} utenti e l\'assenza di ${members.filter(x => !x.presence).length} utenti?`}
                                                triggerButton={<Button content={CommonHelper.GetTrans("buttons.save")} primary />}
                                                onConfirmCallback={handleSubmit} />
                                        </Flex>
                                    </>
                                ) :
                                    <Text size="large" content="Nessun utente registrato" align="center" />
                            )
                    }
                </Segment>
            </Flex.Item>
        </>
    )
}