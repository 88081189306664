/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { ValidatorComponent } from 'react-form-validator-core';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

let suggestions = [];

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
    return (
        <MenuItem
            {...itemProps}
            key={index}
            value={suggestion.value}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}

renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function renderClearSuggestion({ itemProps }) {
    return (
        <MenuItem {...itemProps} key="-1" selected={false} value="" component="div" style={{ fontWeight: 400, }} >
            <DeleteOutlineIcon style={{ marginRight: '5px' }} /> Clear
        </MenuItem>
    );
}

function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const menuItemsCount = 8;
    let menuItems = [];

    if (inputLength > -1)
        menuItems = suggestions.filter(suggestion => {
            const keep = count < menuItemsCount && suggestion.label.toLowerCase().indexOf(inputValue) > -1;

            if (keep)
                count += 1;

            return keep;
        });

    menuItems.splice(0, 0, { value: 0, label: 'Clear' });

    console.log(menuItems);
    return menuItems;
}

class AutoCompleteExt extends ValidatorComponent {

    componentDidUpdate(prevProps, prevState) {
        const { name, triggers, onChange } = this.props;

        if (!CommonHelper.IsEmpty(triggers) && !CommonHelper.IsEmpty(triggers[name]) && !CommonHelper.AreEquals(triggers[name], prevProps.value))
            onChange(triggers[name], name);
    }

    renderInput = (myProps) => {
        const { props, InputProps, inputProps } = myProps;
        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value, ref,
            selectedItem, disabled, ...rest
        } = props;
        const { isValid } = this.state;
        let customValue = value;

        if (validators.includes('required'))
            InputProps.classes = {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
            };

        let validatorValue = validators.find(a => a.includes("matchRegexp"));
        if (validatorValue) {
            let index = validators.indexOf(validatorValue);
            let newValue = CommonHelper.ReplacePlaceholderRegex(validatorValue);
            validators[index] = newValue;
        }

        if (ref)
            InputProps.inputRef = ref;

        if (symbol)
            InputProps.startAdornment = <InputAdornment position="start">{symbol}</InputAdornment>;

        if (pattern)
            inputProps.pattern = pattern;

        if (patternTitle)
            inputProps.title = patternTitle;

        if (min)
            inputProps.min = min;

        if (max)
            inputProps.max = max;

        if (step)
            inputProps.step = step;

        if (decimals && !isNaN(decimals))
            customValue = customValue.toFixed(value);

        return (
            <TextField
                {...rest}
                autoComplete="off"
                value={customValue}
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={disabled}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                inputProps={inputProps}
                InputProps={{ ...InputProps }}
                InputLabelProps={{ shrink: true, classes: { root: classes.inputLabel } }}
            />
        );
    }

    onHandleChange = (value) => {
        console.log(value);
        const { decimals, type } = this.props;
        let validInput = false;
        
        const inputValue = deburr(value.trim()).toLowerCase();

        suggestions.filter(suggestion => {
            if(suggestion.label.toLowerCase().indexOf(inputValue) > -1)
                validInput = true;
        });

        if(!validInput && CommonHelper.IsEmpty(inputValue))
            return; 

        if (type === 'number') {
            if (CommonHelper.IsEmpty(value) && isNaN(value))
                value = 0;
            if (!isNaN(decimals) && !CommonHelper.IsEmpty(decimals))
                value = Number(value).toFixed(decimals);
        }

        this.validate(value);

        this.props.onChange(value, this.props.name, !CommonHelper.IsEmpty(this.props.callback) ? this.props.callback(value) : undefined);

        if (!CommonHelper.IsEmpty(this.props.handle) && this.props.handle.length > 0) {
            switch (this.props.type) {
                case 'number':
                    this.props.handle.map(h => this.props.onTrigger(CommonHelper.Compute(h.value, value, this.props.decimals, this.props.commons), h.field));
                    break;
                default:
                    this.props.handle.map(h => this.props.onTrigger(value, h.field));
                    break;
            }
        }
    }

    render() {
        suggestions = this.props.suggestions ? this.props.suggestions : [];
        const { classes, selectedItem } = this.props;

        return (<div className={classes.autocompleteRoot}>
            {
                this.props.suggestions.length === 0 ?
                    this.renderInput({
                        props: { disabled: true, ...this.props },
                        InputProps: {  },
                    })
                    :
                    <Downshift id="downshift-popper" selectedItem={selectedItem} onChange={selection => this.onHandleChange(selection)}>
                        {({
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            selectedItem,
                        }) => {
                            const { onBlur, onFocus, ...inputProps } = getInputProps();

                            return (
                                <div className={classes.container}>
                                    {this.renderInput({
                                        props: this.props,
                                        InputLabelProps: getLabelProps({ shrink: true }),
                                        InputProps: { onBlur, onFocus },
                                        inputProps,
                                    })}

                                    <div {...getMenuProps()}>
                                        {isOpen ? (
                                            <Paper className={classes.paper} square>
                                                {getSuggestions(inputValue).map((suggestion, index) => {
                                                    if (index === 0)
                                                        return renderClearSuggestion({
                                                            itemProps: getItemProps({ item: "" }),
                                                        })
                                                    else
                                                        return renderSuggestion({
                                                            suggestion,
                                                            index,
                                                            itemProps: getItemProps({ item: suggestion.label }),
                                                            highlightedIndex,
                                                            selectedItem,
                                                        })
                                                })}
                                            </Paper>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        }}
                    </Downshift>
            }
        </div>
        );
    }
}

export default withStyles(styles)(AutoCompleteExt);