import React from 'react';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { CommonHelper, ToastHelper, FetchHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Avatar from 'react-avatar';

import { AdminDashboardContext } from "libs/admin-dashboard";

import Header from "components/Headers/Header.jsx";
import { TextFieldExt, SelectExt, LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';
import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';
import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Button from 'reactstrap-button-loader';

import { MSTeamsIcon, MSTeamsIconWeight, MSTeamsIconType } from 'msteams-ui-icons-react';

import { Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 24,
    zIndex: 0,
    boxShadow: '0px 5px 10px 0px #7d7d7d'
  }
});

class ReportByCourse extends React.Component {
  constructor(props) {
    super();

    this.tabsRef = React.createRef();
    this.getReport = this.getReport.bind(this);

    this.state = {
      data: null,
      relatedData: {
        teamsOptions: null,
        callsOptions: null
      },
      request: {
        teamId: undefined,
        callId: undefined
      },
      loading: false
    };
  }

  componentWillMount() {
    this.getRelatedData();
  }

  getRelatedData() {
    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); }

    let fetchList = [
      { apiName: 'msteams', method: 'GET', formData: null, successCallback: this.teamsSuccessCallback, errorCallback: errorCallback },
    ];

    if (fetchList.length > 0) {
      FetchHelper.DoMultipleFetch(fetchList).then(results => { });
    }
  }

  teamsSuccessCallback = (response) => response.json().then((json) => {
    try {
      let options = json.map(j => ({ value: j.id, label: j.displayName }));
      options = options.sort(CommonHelper.CompareValues("label"));

      let { relatedData } = this.state;
      relatedData.teamsOptions = options;
      this.setState({ relatedData: relatedData });

      return true;
    }
    catch (error) {
      ToastHelper.Error(error);
    }
  });

  getCalls() {
    let { teamId } = this.state.request;

    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); }

    if (CommonHelper.IsEmpty(teamId)) {
      let { relatedData, request } = this.state;
      relatedData.callsOptions = null;
      request.callId = undefined;
      this.setState({ relatedData: relatedData, data: null, request: request });
      return false;
    }

    let fetchList = [
      { apiName: `getcalls/${teamId}`, method: 'GET', formData: null, successCallback: this.callsSuccessCallback, errorCallback: errorCallback }
    ];

    if (fetchList.length > 0) {
      FetchHelper.DoMultipleFetch(fetchList).then(results => { });
    }
  }
  
  callsSuccessCallback = (response) => response.json().then((json) => {
    try {
      let options = json.map(j => ({ value: j.callId, label: `${j.title} (${moment(j.startTime).format('DD MMM YYYY HH:mm:ss')} - ${moment(j.endTime).format('DD MMM YYYY HH:mm:ss')})` }));
      options = options.sort(CommonHelper.CompareValues("label"));
      
      let { relatedData } = this.state;
      relatedData.callsOptions = options;
      this.setState({ relatedData: relatedData });

      return true;
    }
    catch (error) {
      ToastHelper.Error(error);
    }
  });

  onHandleChange = (value, field) => {
    let { request } = this.state;

    request[field] = value;

    this.setState({ field: value })

    switch (field) {
      case 'teamId':
        this.getCalls();
        break;
      case 'callId':
        this.getReport();
        break;
    }
  }

  getReport(callback) {
    let { callId } = this.state.request;

    if (CommonHelper.IsEmpty(callId)) {
      this.setState({ data: null });
      return false;
    }

    let successCallback = (response) => response.json().then(json => json);
    let errorCallback = (error) => { if (error !== null) ToastHelper.Error(error); }

    this.setState({ loading: true }, () => {
      CommonHelper.WaitAsyncData(FetchHelper.DoFetch(`getreportdata/${callId}`, 'GET', null, successCallback, errorCallback)).then((resp) => {
        if (resp.success) {
          this.setState({ data: resp.data }, () => {

            if (CommonHelper.IsFunction(callback))
              callback();

            this.setState({ loading: false });
          });
        }
      });
    });
  }

  renderCallInfo = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { callInfo, recapParticipants } = data;

    return (
      <Row>
        <Col xs="12" className="d-flex justify-content-start align-items-center mb-3">
          <MSTeamsIcon style={{ fontSize: 38 }} className="d-inline-block mr-3" aria-label={'ContactGroup'} iconWeight={MSTeamsIconWeight.Light} iconType={MSTeamsIconType['ContactGroup']} />
          <strong style={{ fontSize: 25 }} className="mr-2" >{moment(callInfo.callStartTime).format("DD MMM YYYY")}</strong>
          <span style={{ fontSize: 20 }}>{moment(callInfo.callStartTime).format("HH:mm")}</span>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.status")}</span>
          {
            CommonHelper.IsEmpty(callInfo.callStartTime) ?
              <span className="text-danger">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.ongoing")}</span> :
              <span className="text-success">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.completed")}</span>
          }
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.type")}</span>
          <strong>Microsoft Teams</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.organizer")}</span>
          <strong>
            <Avatar round={true} size="36" name={callInfo.organizerDisplayName} /> {callInfo.organizerDisplayName}
          </strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.participants")}</span>
          <strong>{recapParticipants.length}</strong>
        </Col>
      </Row>
    )
  }

  renderGraph = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    let barData = {
      options: {
        layout: {
          padding: { left: 0, right: 0, top: 0, bottom: 20 }
        },
        scales: {
          yAxes: [{
            gridLines: { display: true, drawBorder: true },
            ticks: {
              display: false
            }
          }],
          xAxes: [{
            gridLines: { display: true, drawBorder: true },
            barPercentage: 0.3
          }]
        },
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'start',
            textAlign: 'center',
            color: '#000000',
            font: { size: 14 },
            formatter: function (value, ctx) {
              return [value];
            },
            offset: 0
          }
        },
        tooltips: {
          enabled: false
        },
        responsive: true
      },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        fullWidth: true,
        reverse: false,
        labels: {
          usePointStyle: true
        }
      },
      data: { datasets: [] }
    };

    const { callInfo, recapParticipants } = data;
    
    let getByPercentage = (percentageMin, percentageMax) => {
      return recapParticipants.filter(rp => {
        if (rp === null || rp.percentualePresenza == 0) {
          return false;
        }
        
        if (CommonHelper.IsEmpty(percentageMax)) {
          if (rp.percentualePresenza === percentageMin)
            return true;
        }
        else {
          if (rp.percentualePresenza >= percentageMin && rp.percentualePresenza < percentageMax)
            return true;
        }
      }).length;
    }

    var chartDataset = [{
      label: '100%',
      data: [getByPercentage(95, 101)],
      backgroundColor: '#CC5190',
      hoverBackgroundColor: '#CC5190'
    }, {
        label: '> 75%',
        data: [getByPercentage(75, 95)],
        backgroundColor: '#6B2480',
        hoverBackgroundColor: '#6B2480'
      }, {
        label: '> 50%',
        data: [getByPercentage(50, 75)],
        backgroundColor: '#E66D3E',
        hoverBackgroundColor: '#E66D3E'
      }, {
        label: '> 25%',
        data: [getByPercentage(25, 50)],
        backgroundColor: '#253193',
        hoverBackgroundColor: '#253193'
      }, {
        label: '< 25%',
        data: [getByPercentage(0, 25)],
        backgroundColor: '#4D85B8',
        hoverBackgroundColor: '#4D85B8'
      }];

    barData.data.datasets = chartDataset;

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center mb-3">
          <Bar {...barData} />
        </Col>
      </Row>
    )
  }

  renderMeetingInfo = () => {
    const { data } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { callInfo, recapParticipants } = data;

    var callStartTime = moment(callInfo.callStartTime);
    var callEndTime = moment(callInfo.callEndTime);

    var meetingStartTime = moment(callInfo.meetingStartTime).format('DD MMM YYYY HH:mm:ss');
    var meetingEndTime = moment(callInfo.meetingEndTime).format('DD MMM YYYY HH:mm:ss');

    var callDuration = moment.utc(callEndTime.diff(callStartTime)).format('HH:mm:ss');
    var validParticpants = recapParticipants.filter(rp => rp !== null && moment(rp.tempoPresenza, 'HH:mm:ss.SSS').millisecond() > 0).length;

    return (
      <Row className="h-100 mb-2">
        <Col xs="12">
          <strong>{CommonHelper.GetTrans("reports.headerInfos.meeting.details")}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.meeting.startDate")}</span>
          <strong>{meetingStartTime}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.meeting.endDate")}</span>
          <strong>{meetingEndTime}</strong>
        </Col>
        <Col xs="12 mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.meeting.participants")}</span>
          <strong>{validParticpants}</strong>
        </Col>
        <Col xs="12 mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.meeting.duration")}</span>
          <strong>{callDuration}</strong>
        </Col>
      </Row>
    )
  }

  renderCallDetails = () => {
    const { data, request } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { recapParticipants } = data;
    let { callId } = request;

    const columns = [
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callDetails.sessionType"), type: 'string', filter: true },
      { id: 'tempoPresenza', label: CommonHelper.GetTrans("reports.fields.callDetails.attendanceTime"), type: 'time', format: 'HH:mm:ss.SSS' },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callDetails.role"), type: 'string', filter: true },
      { id: 'disconnessioni', label: CommonHelper.GetTrans("reports.fields.callDetails.disconnections"), type: 'string', filter: true, size: 'small', align: 'center' }
    ];

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={recapParticipants}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callDetails`, filename:'callDetails' }}
            readOnly={true}
            orderBy='tempoPresenza'
          />
        </Col>
      </Row>
    )
  }

  renderCallFullDetails = () => {
    const { data, request } = this.state;

    if (CommonHelper.IsEmpty(data))
      return null;

    const { participantsLogs } = data;
    let { callId } = request;

    const columns = [
      { id: 'nomeTeam', label: CommonHelper.GetTrans("reports.fields.callFullDetails.teamName"), type: 'string', filter: true },
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callFullDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callFullDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callFullDetails.sessionType"), type: 'string', filter: true },
      { id: 'stato', label: CommonHelper.GetTrans("reports.fields.callFullDetails.status"), type: 'string', filter: true },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callFullDetails.role"), type: 'string', filter: true },
      { id: 'dataLog', label: CommonHelper.GetTrans("reports.fields.callFullDetails.logDate"), type: 'datetime-local', filter: true }
    ];

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={participantsLogs}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callFullDetails`, filename: 'callFullDetails'  }}
            readOnly={true}
            orderBy='dataLog'
          />
        </Col>
      </Row>
    )
  }

  buildCallDetailsTabs = () => {
    return [
      { title: CommonHelper.GetTrans("reports.tabs.callDetails"), content: this.renderCallDetails() },
      { title: CommonHelper.GetTrans("reports.tabs.callFullDetails"), content: this.renderCallFullDetails() }
    ]
  }

  render() {
    const { loading, request, data, relatedData } = this.state;
    const { classes, seo } = this.props;

    return (
      <AdminDashboardContext.Consumer>
        {context => {
          if (context.currentPage !== "report")
            context.setData({ seo: seo, currentPage: "report" });

          return (
            <>
              <Header />
              <Container className="mt--8" fluid>
                <Row>
                  <Paper className={classNames(classes.root, 'px-3 pt-3 pb-0')}>
                    <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                      <Row className="no-gutters">
                        <Col xs="3" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                          {
                            relatedData && relatedData.teamsOptions &&
                            <SelectExt name="teamId" label="Teams" options={relatedData.teamsOptions} onChange={this.onHandleChange} value={request.teamId} />
                          }
                        </Col>
                        <Col xs="3" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                          {
                            relatedData && relatedData.callsOptions &&
                            <SelectExt name="callId" label="Call" options={relatedData.callsOptions} onChange={this.onHandleChange} value={request.callId} />
                          }
                        </Col>
                      </Row>
                    </ValidatorForm>
                    {loading ?
                      <Row className="justify-content-center">
                        <LoaderExt />
                      </Row>
                      :
                      <>
                        <Row>
                          <Col>
                            {this.renderCallInfo()}
                          </Col>
                          <Col>
                            {this.renderGraph()}
                          </Col>
                          <Col>
                            {this.renderMeetingInfo()}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="p-0">
                            {
                              data && <EnhancedTabs ref={this.tabsRef} tabs={this.buildCallDetailsTabs()} activeTab={0} />
                            }
                          </Col>
                        </Row>
                      </>
                    }
                  </Paper>
                </Row>
              </Container>
            </>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

export default withStyles(styles)(ReportByCourse); 