import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import MicrosoftProvider from "./MicrosoftProvider";
import MicrosoftLoginButton from "./MicrosoftLoginButton";

import { CommonHelper } from 'components/Common/Helper/Helper';

import styles from "./MicrosoftAuth.module.css";

import Button from 'reactstrap-button-loader';

class MicrosoftAuth extends React.Component {
    static propTypes = {
        loggedIn: PropTypes.bool,
        loading: PropTypes.bool,
        account: PropTypes.object,
        error: PropTypes.string,
        msMe: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired
    };

    render() {
        const { account, loggedIn, error, onSignIn, onSignOut, buttonTheme } = this.props;

        return (
            <div>
                <section>
                    {
                        !account ?
                            <div className="text-center">
                                <MicrosoftLoginButton buttonTheme={buttonTheme || "light"} onClick={onSignIn} />
                            </div> :
                            (
                                loggedIn ?
                                    <div className="text-center">
                                        <Button className="my-4" color="danger" onClick={onSignOut} >{CommonHelper.GetTrans("buttons.signout")}</Button>
                                    </div> :
                                    <div className={classNames(styles.loadingDots)}>
                                        <h3 className="text-muted">{CommonHelper.GetTrans("auth.signingin")}</h3>
                                        <h1 className={classNames(styles.dot, styles.one)}>.</h1><h1 className={classNames(styles.dot, styles.two)}>.</h1><h1 className={classNames(styles.dot, styles.three)}>.</h1>
                                    </div>
                            )
                    }
                    {
                        error &&
                        <p className={classNames(styles.error, "text-center mt-2")}>
                            <strong style={{ textDecoration: 'underline' }}>Error</strong>
                            <br />
                            {error}
                        </p>
                    }
                </section>
            </div>
        );
    }
}

export default MicrosoftProvider(MicrosoftAuth);