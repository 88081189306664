import { CommonHelper } from 'components/Common/Helper/Helper';
import { stringify } from 'query-string';

import { TeamsAuthService, acquireToken } from 'libs/microsoft-graph-service';

import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_FILE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'libs/admin-dashboard';

import authProvider, { UNAUTHORIZED, AUTHORIZED, ELAPSED } from "authProvider";

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} customApiUrl Request API url, e.g https://myapi.com/api
 * @returns {Promise} the Promise for a data response
 */
export default (apiUrl, history) => {
    if (CommonHelper.IsEmpty(apiUrl)){
        throw Error("Missing endpoint base URL");
    }

    let handleUnhautorized = ({ redirectTo }) => {
        if (!CommonHelper.IsEmpty(redirectTo) && history) {
            history.push(redirectTo);
        }
    }

    return ({
        getList: (resource, params) => query(apiUrl, GET_LIST, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        getOne: (resource, params) => query(apiUrl, GET_ONE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        getFile: (resource, params) => query(apiUrl, GET_FILE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        getMany: (resource, params) => query(apiUrl, GET_MANY, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        getManyReference: (resource, params) => query(apiUrl, GET_MANY_REFERENCE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        update: (resource, params) => query(apiUrl, UPDATE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        updateMany: (resource, params) => query(apiUrl, UPDATE_MANY, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        create: (resource, params) => query(apiUrl, CREATE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        delete: (resource, params) => query(apiUrl, DELETE, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        deleteMany: (resource, params) => query(apiUrl, DELETE_MANY, resource, params).catch(e => { handleUnhautorized(e); return null; }),
        getBaseUrl: () => apiUrl,
    });
}

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const query = async (apiUrl, type, resource, params) => {
    let url = '';

    const options = { headers: new Headers() };

    let authResponse = await authProvider.checkAuth().then((response) => response).catch((response) => response);

    switch (authResponse.status) {
        case AUTHORIZED:
            options.headers.set('Authorization', `Bearer ${authResponse.token}`);
            break;
        case UNAUTHORIZED:
            if (!params && !params.addMsToken) {
                return Promise.reject({ redirectTo: authResponse.redirectTo });
            }
            break;
        case ELAPSED:
            let refreshTokenResponse = await authProvider.refreshToken().then((response) => response).catch((response) => response);

            if (refreshTokenResponse.status !== AUTHORIZED) {
                return Promise.reject({ redirectTo: refreshTokenResponse.redirectTo });
            }

            options.headers.set('Authorization', `Bearer ${refreshTokenResponse.newToken}`);
            break;
        default:
            break;
    }
    
    if (params && !CommonHelper.IsEmpty(params.addMsToken)) {
        let accessToken;

        if (CommonHelper.IsBoolean(params.addMsToken) && params.addMsToken) {
            accessToken = await TeamsAuthService.getSsoToken();
        }
        else{
            accessToken = params.addMsToken;
        }

        options.headers.set('MSAuthorization', `Bearer ${accessToken}`);
        
        if (params.teamChannelId)
            options.headers.set('TeamChannelId', params.teamChannelId);
    }

    switch (type) {
        case GET_LIST: {
            url = `${apiUrl}/${resource}`;

            if (!CommonHelper.IsEmpty(params)) {
                const query = {};

                if (!CommonHelper.IsEmpty(params.sort)) {
                    const { field, order } = params.sort;
                    query["$orderby"] = `${field} ${order}`;
                }

                if (!CommonHelper.IsEmpty(params.pagination)) {
                    const { page, perPage } = params.pagination;
                    const rangeStart = (page - 1) * perPage, rangeEnd = page * perPage - 1;
                    query["$skip"] = rangeStart;
                    query["$top"] = perPage;
                    options.headers.set('Range', `${resource}=${rangeStart}-${rangeEnd}`);
                }

                if (!CommonHelper.IsEmpty(params.filter)) {
                    const filterKeys = Object.keys(params.filter);

                    if (filterKeys.length > 0) {
                        query['$filter'] = filterKeys.map(key => {
                            if (CommonHelper.IsNumber(params.filter[key]) || CommonHelper.IsBoolean(params.filter[key]) || CommonHelper.IsGuid(params.filter[key])) {
                                return `${key} eq ${params.filter[key]}`;
                            }
                            else {
                                return `contains(${key}, '${params.filter[key]}')`;
                            }
                        }).join(' and ');
                    }
                }

                if (!CommonHelper.IsEmpty(params.filterOr)) {
                    const filterKeys = Object.keys(params.filterOr);

                    if (filterKeys.length > 0) {
                        const filterOr = filterKeys.map(key => {
                            if (CommonHelper.IsNumber(params.filterOr[key]) || CommonHelper.IsBoolean(params.filterOr[key])) {
                                return `${key} eq ${params.filterOr[key]}`;
                            }
                            else {
                                return `contains(${key}, '${params.filterOr[key]}')`;
                            }
                        }).join(' and ');

                        query['$filter'] = query['$filter'] ? `(${query['$filter']}) or (${filterOr})` : filterOr;
                    }
                }

                if (!CommonHelper.IsEmpty(params.filterDate)) {
                    const filterDateKeys = Object.keys(params.filterDate);

                    if (filterDateKeys.length > 0) {
                        const filterDate = filterDateKeys.map(key => {
                            let filterDateQry = [];

                            if (!CommonHelper.IsEmpty(params.filterDate[key].from)) {
                                filterDateQry.push(`${key} gt ${params.filterDate[key].from}`);
                            }

                            if (!CommonHelper.IsEmpty(params.filterDate[key].to)) {
                                filterDateQry.push(`${key} lt ${params.filterDate[key].to}`);
                            }

                            return filterDateQry.join(' and ');
                        }).join(' and ');

                        query['$filter'] = query['$filter'] ? `(${query['$filter']}) and (${filterDate})` : filterDate;
                    }
                }

                if (!CommonHelper.IsEmpty(params.expand)) {
                    query["$expand"] = `${params.expand}`;
                }

                if (!CommonHelper.IsEmpty(params.select)) {
                    query["$select"] = `${params.select}`;
                }

                url = `${url}?${stringify(query)}`;
            }
            break;
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}`;
            const query = {};

            if (!CommonHelper.IsEmpty(params)) {
                if (!CommonHelper.IsEmpty(params.expand)) {
                    query["$expand"] = `${params.expand}`;
                }

                if (!CommonHelper.IsEmpty(params.select)) {
                    query["$select"] = `${params.select}`;
                }

                if (!CommonHelper.IsEmpty(params.id)) {
                    url = `${url}/${params.id}`;
                }

                url = `${url}?${stringify(query)}`;
            }
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break;
        case UPDATE_MANY:
            {
                url = `${apiUrl}/${resource}`;
                options.method = 'PATCH';
                options.body = JSON.stringify({ ids: params.ids, data: params.data});
                break;
            }
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        case DELETE_MANY:
            {
                const query = {
                    $filter: `id in (${params.ids.join(',')})`,
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = 'DELETE';
                break;
            }
        case GET_MANY: {
            url = `${apiUrl}/${resource}`;
            
            if (!CommonHelper.IsEmpty(params)) {
                const query = {};

                if (!CommonHelper.IsEmpty(params.ids)) {
                    query["$filter"] = `id in (${params.ids.join(',')})`;
                }

                if (!CommonHelper.IsEmpty(params.expand)) {
                    query["$expand"] = `${params.expand}`;
                }

                if (!CommonHelper.IsEmpty(params.select)) {
                    query["$select"] = `${params.select}`;
                }

                url = `${url}?${stringify(query)}`;
            }
            break;
        }
        case GET_MANY_REFERENCE: {
            url = `${apiUrl}/${resource}`;

            if (!CommonHelper.IsEmpty(params)) {
                const query = {};

                if (!CommonHelper.IsEmpty(params.sort)) {
                    const { field, order } = params.sort;
                    query["$orderby"] = `${field} ${order}`;
                }

                if (!CommonHelper.IsEmpty(params.pagination)) {
                    const { page, perPage } = params.pagination;
                    const rangeStart = (page - 1) * perPage, rangeEnd = page * perPage - 1;
                    query["$skip"] = rangeStart;
                    query["$top"] = perPage;
                    options.headers.set('Range', `${resource}=${rangeStart}-${rangeEnd}`);
                }

                if (!CommonHelper.IsEmpty(params.filter)) {
                    const filterKeys = Object.keys(params.filter);

                    if (filterKeys.length > 0) {
                        query['$filter'] = filterKeys.map(key => {
                            if (CommonHelper.IsNumber(params.filter[key]) || CommonHelper.IsBoolean(params.filter[key])) {
                                return `${key} eq ${params.filter[key]}`;
                            }
                            else {
                                return `contains(${key}, '${params.filter[key]}')`;
                            }
                        }).join(' and ');
                    }
                }

                if (!CommonHelper.IsEmpty(params.expand)) {
                    query["$expand"] = `${params.expand}`;
                }

                if (!CommonHelper.IsEmpty(params.select)) {
                    query["$select"] = `${params.select}`;
                }
                
                url = `${url}?${stringify(query)}`;
            }
            break;
        }
        case GET_FILE:
            {
                url = `${apiUrl}/${resource}`;
                return fetchUtils.fetchBlob(url, options)
                    .then(({ blob, filename }) => {
                        return { blob, filename };
                    })
            }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    return fetchUtils.fetchJson(url, options)
        .then(({ headers, json }) => {
            switch (type) {
                case GET_LIST:
                case GET_MANY_REFERENCE:
                    if (!headers.has('content-range')) {
                        console.info('The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
                        return { data: json || [] };
                    }
                    return {
                        data: json,
                        total: parseInt(
                            headers
                                .get('content-range')
                                .split('/')
                                .pop(),
                            10
                        ),
                    };
                case CREATE:
                    if (json.id){
                        return { data: { ...params.data, id: json.id } };
                    }
                    return { data: json };
                case DELETE_MANY:
                    return { data: json || [] };
                default:
                    return { data: json };
            }
        });
};