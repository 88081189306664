/* eslint-disable no-unused-vars */

import React from "react";
import { CommonHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';

import { TeamsContext, teamsTabStyles, TabNavBar, ButtonTooltip, } from "libs/teams-tab";

import {
  Flex, Segment, Text, Loader, Grid, Divider, Card,  Image, Input,
  SyncIcon, SearchIcon,
} from '@fluentui/react-northstar'

import { Icon } from '@fluentui/react/lib/Icon';
import { mergeStyleSets } from 'office-ui-fabric-react';

let myContext = null;

const customClass = mergeStyleSets({
  cardContainer: {
    '& > div:first-child:hover': {
      cursor: 'pointer',
    }
  },
});

const CardSurveysToComplete = ({ callSurveyActivity }) => {
  const openSurvey = () => {
    myContext.navigate(`surveyToComplete/${callSurveyActivity.id}`);
  }

  return (
    <div style={teamsTabStyles.cardContainer} className={customClass.cardContainer}>
      <Card compact style={teamsTabStyles.card}>
        <div onClick={openSurvey}>
          <Card.TopControls styles={{ top: 0, left: 0 }}>
            <Flex padding="padding.medium" column>
              <Text as="h2" content={callSurveyActivity.survey.title} weight="bold" />
            </Flex>
          </Card.TopControls>
          <Card.Preview fitted style={teamsTabStyles.cardPreview} >
            <Image fluid src="https://cdn.forms.office.net/forms/images/theme/default-2.png" />
          </Card.Preview>
          <Card.Body style={teamsTabStyles.cardBody} fitted>
            <Flex padding="padding.medium" column>
              <Text content="Riunione" weight="bold" />
              <Text content={callSurveyActivity.call.event.subject} />
              <Text content="Del" weight="bold" />
              <Text content={moment(callSurveyActivity.call.event.startTime).format("DD/MM/YYYY")} />
              <Text content="Iniziata Alle" weight="bold" />
              <Text content={moment(callSurveyActivity.call.event.startTime).format("HH.mm")} />
            </Flex>
          </Card.Body>
          <Card.Footer fitted style={teamsTabStyles.cardFooter}>
            {
              callSurveyActivity.survey.endDate &&
              <Flex padding="padding.medium" vAlign="center" hAlign="center" column >
                <Text content="Da completare entro" weight="bold" />
                <Text content={moment(callSurveyActivity.survey.endDate).format("DD/MM/YYYY HH.mm")} />
              </Flex>
            }
          </Card.Footer>
        </div>
      </Card>
    </div>
  )
}

class SurveysToComplete extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      callSurveyActivities: null,
      filter: {
        title: null
      }
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  setFilter = debounce((field, query) => {
    let { filter } = this.state;
    filter[field] = query ? query.toLowerCase() : null;
    this.setState({ filter }, this.getCallSurveyActivities);
  }, 1000);

  getCallSurveyActivities = () => {
    const { teamChannel, msTeamsContext } = myContext;
    const { title } = this.state.filter;

    this.setState({ loading: true }, async () => {
      const filter = { completed: false, 'Call/Event/TeamChannelId': teamChannel.id };
      const expand = `survey,call($expand=event)`;
      const sort = { field: 'startedAt', order: 'desc' };

      if (title !== null) {
        filter.title = title;
      }

      let result = await myContext.dataProviders.api.getList('surveyactivities', { filter, sort, expand });

      if (result && result.data) {
        const callSurveyActivities = result.data.filter(d => {
          return d !== null && (d.survey.endDate === null || moment(d.survey.endDate) >= moment())
        });
        this.setState({ callSurveyActivities, loading: false });
      }
      else {
        this.setState({ surveys: [], loading: false });
      }
    });
  }

  filterByTitle = (value) => {
    this.setFilter('title', value);
  };

  resetFilter = () => {
    this.setState({ filter: { title: null } }, this.getCallSurveyActivities);
  }

  render() {
    const { loading, callSurveyActivities } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          if (!callSurveyActivities && myContext && myContext.msTeamsContext && !loading) {
            if (!myContext.settings.surveysEnabled) {
              context.navigate('/');
            }
            else {
              this.getCallSurveyActivities();
            }
          }

          return (
            <>
              <TabNavBar title="Elenco Sondaggi Da Completare" showBack backPageName="Homepage" backPageRoute="" icon={<Icon iconName="Questionnaire" />}>
                <Input icon={<SearchIcon />} clearable placeholder="Cerca..." onChange={(e) => this.filterByTitle(e.target.value)} />
                {/* <ButtonTooltip icon={<UndoIcon />} secondary iconOnly content="" tooltip="Ripristina filtro" onClick={this.resetFilter} disabled /> */}
                <Divider vertical styles={{ height: '1.7rem' }} />
                <ButtonTooltip icon={<SyncIcon />} secondary iconOnly content="" tooltip="Aggiorna" onClick={this.getCallSurveyActivities} />
              </TabNavBar>
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      (
                        callSurveyActivities && callSurveyActivities.length > 0 ?
                          <Grid columns={6}>
                            {
                              callSurveyActivities.map((callSurveyActivity, key) => {
                                return (<CardSurveysToComplete key={key} index={key} callSurveyActivity={callSurveyActivity} reload={this.getCallSurveyActivities} />)
                              })
                            }
                          </Grid> :
                          <Text size="large" content="Nessun sondaggio da completare" align="center" />
                      )
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default SurveysToComplete;