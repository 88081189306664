import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({});

class EnhancedTableHead extends React.Component {

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { columns, onSelectAllClick, order, orderBy, numSelected, rowCount, singleSelect } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {((this.props.readOnly === undefined) || (this.props.readOnly === false)) ?
                        (
                            <TableCell padding="checkbox" size={'small'}>
                                {
                                    !singleSelect &&
                                    <Checkbox
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={numSelected === rowCount}
                                        onChange={onSelectAllClick}
                                    />
                                }
                            </TableCell>
                        ) : (null)}
                    {columns.filter(column => column.visible !== false).map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : row.align ? row.align : 'left'}
                                size={row.size ? row.size : 'medium'}
                                padding={row.disablePadding ? 'none' : (row.id === 'Edit') || (row.id === 'Delete') ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                {
                                    row.sort || row.sort === null || row.sort === undefined ?
                                        <Tooltip title="Ordina" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                                            <TableSortLabel
                                                active={orderBy === row.id}
                                                direction={order}
                                                onClick={this.createSortHandler(row.id)}
                                            >
                                                {row.label}
                                            </TableSortLabel>
                                        </Tooltip> :
                                        row.label
                                }
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    columns: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(EnhancedTableHead); 