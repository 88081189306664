/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import moment from 'moment';
import debounce from 'lodash.debounce';

import Avatar from 'react-avatar';

import { Groups, Teams } from 'libs/microsoft-graph-service';
import { TeamsContext, teamsTabStyles, TabNavBar, ButtonTooltip, PopupWithButton, ConfirmDialog } from "libs/teams-tab";

import {
  Flex, Segment, Button, Text, Loader, Grid, Divider, Card, List, Input,
  SyncIcon, MoreIcon, SearchIcon, AttendeeIcon, TrashCanIcon, InfoIcon,
} from '@fluentui/react-northstar'

import { mergeStyleSets } from 'office-ui-fabric-react';

import { CreateDialog } from './CreateDialog';
import { TeamChannelMemberImporter } from './TeamChannelMemberImporter';

let myContext = null;

const customClass = mergeStyleSets({});

const DeleteMemberButton = ({ member, role, reload }) => {
  const teamsContext = useContext(TeamsContext);
  const { msTeamsContext, teamChannel, dataProviders } = teamsContext;
  const { groupId } = msTeamsContext;
  const [hasMemberCalls, setHasMemberCalls] = useState(false);
  let dialogHeader = null;
  let dialogContent = null;
  let items = [];

  useEffect(() => {
    async function doAsync() {
      const filter = { userId: member.id };
      let result = await dataProviders.api.getList('callParticipantsTrackings', { filter });
      if (result) {
        setHasMemberCalls(result.data.filter(x => x !== null).length > 0);
      }
    }
    doAsync()
  }, [member]);

  const onConfirm = async () => {
    const teamMember = await Teams.GetMember(groupId, member.id).catch(err => err);

    if (teamMember) {
      let deletedMember = await Teams.RemoveMember(groupId, teamMember.id).catch(err => err);
      if (deletedMember) {
        let result = await dataProviders.api.delete('msteammembers', { id: `${member.id}/${teamChannel.id}` });
        if (result && result.data) {
          reload();
          ToastHelper.Info("Utente rimosso dal team. Se l'utente è ancora essere visibile nella lista, attendere 5 minuti.");
        }
        else {
          ToastHelper.Error("Errore durante la rimozione dell'utente dal Database");
        }
      }
      else {
        ToastHelper.Error("Errore durante la rimozione dell'utente dal Team");
      }
    }
    else {
      ToastHelper.Error("Utente non trovato su Teams");
    }
  };

  dialogHeader = "Conferma rimozione dell'utente dal canale?";

  items.push({ key: 'member', media: <TrashCanIcon />, header: `${member.displayName} importato il ${moment(member.createdAt).format('DD/MM/YYYY HH:mm')}` });

  if (hasMemberCalls) {
    items.push({ key: 'info', media: <InfoIcon />, header: 'Sono presenti dei monitoraggi per l\'utente.' });
  }

  dialogContent = <List items={items} />

  let triggerButton = <Button text content="Elimina" primary />;

  return <ConfirmDialog header={dialogHeader} content={dialogContent} triggerButton={triggerButton} onConfirmCallback={onConfirm} />
}

const getColorByRole = (role) => {
  switch (role.toLowerCase()) {
    case 'organizer':
      return '#8E192E';
    case 'teacher':
      return '#FFAA44';
    case 'organizer,teacher':
      return '#BDDA9B';
    case 'guest,teacher':
      return '#F9EC02';
    case 'member,teacher':
      return '#BD432C';
    default:
      return '#6264A7';
  }
}

const CardMember = ({ index, member, reload }) => {
  const { id } = myContext.teamChannel;

  var role = "Member";

  if (member.msteamMemberMsteamChannels.length > 0) {
    var memberRoles = member.msteamMemberMsteamChannels.filter(tm => tm !== null && tm.teamChannelId === id);
    if (memberRoles.length > 0) {
      role = memberRoles.sort(CommonHelper.CompareValues('type')).map(memberRole => memberRole.type).join(",");
    }
  }

  return (
    <div key={index} style={teamsTabStyles.cardContainer}>
      <Card compact style={teamsTabStyles.card}>
        {
          role !== "Owner" && (
            <Card.TopControls styles={{ top: 0 }}>
              <PopupWithButton icon={<MoreIcon size="medium" />} iconOnly text position="below" align="end" tooltip="Altre azioni" style={teamsTabStyles.cardActions}>
                <List>
                  <List.Item styles={teamsTabStyles.cardActionsItem} content={
                    <CreateDialog reload={reload} member={member} />
                  } />
                  <List.Item styles={teamsTabStyles.cardActionsItem} content={
                    <DeleteMemberButton member={member} role={role} reload={reload} />
                  } />
                </List>
              </PopupWithButton>
            </Card.TopControls>
          )
        }
        <Card.Header fitted style={teamsTabStyles.cardHeader}>
          <Flex gap="gap.small" padding="padding.medium" >
            <Avatar round={true} size={30} name={member.displayName} maxInitials={3} title={member.displayName} color={getColorByRole(role)} />
            <Flex column>
              <Text content={member.displayName} weight="bold" />
              <Text content={role} style={{ textTransform: 'capitalize' }} size="small" />
              <Text content={member.mail} style={{ textTransform: 'lowercase' }} size="small" />
            </Flex>
          </Flex>
        </Card.Header>
      </Card>
    </div>
  )
}

class Members extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      members: null,
      filter: {
        displayName: null
      }
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  setFilter = debounce((field, query) => {
    let { filter } = this.state;
    filter[field] = query ? query.toLowerCase() : null;
    this.setState({ filter }, this.getMembers);
  }, 1000);

  getMembers = () => {
    const { id } = myContext.teamChannel;
    const { displayName } = this.state.filter;

    this.setState({ loading: true }, async () => {
      const _filter = [`MsteamMemberMsteamChannels/any(a:a/teamChannelId eq ${id} and a/softDelete eq false)`, 'softDelete eq false'];
      const expand = '$expand=MsteamMemberMsteamChannels';
      const sort = '$orderby=displayName';

      if (displayName !== null) {
        _filter.push(`contains(displayName, '${displayName}')`);
      }

      const filter = `$filter=${_filter.join(' and ')}`;

      let result = await myContext.dataProviders.api.getList(`msteammembers?&${filter}&${expand}&${sort}`);
      if (result && result.data) {
        this.setState({ members: result.data, loading: false });
      }
      else {
        this.setState({ members: [], loading: false });
      }
    });
  }

  filterByDisplayName = (value) => {
    this.setFilter('displayName', value);
  };

  resetFilter = () => {
    this.setState({ filter: { displayName: null } }, this.getMembers);
  }

  render() {
    const { loading, members } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          if (!members && myContext && myContext.msTeamsContext && !loading) {
            this.getMembers();
          }

          return (
            <>
              <TabNavBar title="Elenco Utenti" showBack backPageName="Homepage" backPageRoute="" icon={<AttendeeIcon />}>
                <Input icon={<SearchIcon />} clearable placeholder="Cerca..." onChange={(e) => this.filterByDisplayName(e.target.value)} />
                <Divider vertical styles={{ height: '1.7rem' }} />
                <CreateDialog reload={this.getMembers} />
                <TeamChannelMemberImporter members={members} reload={this.getMembers} />
                <Divider vertical styles={{ height: '1.7rem' }} />
                <ButtonTooltip icon={<SyncIcon />} secondary iconOnly content="" tooltip="Aggiorna" onClick={this.getMembers} />
              </TabNavBar>
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      (
                        members && members.length > 0 ?
                          <Grid columns={3}>
                            {
                              members.map((member, key) => { return (<CardMember key={key} index={key} member={member} reload={this.getMembers} />) })
                            }
                          </Grid> :
                          <Text size="large" content="Nessun utente trovato" align="center" />
                      )
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default Members;