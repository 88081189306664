import * as Msal from "msal";
import { CommonHelper } from 'components/Common/Helper/Helper';

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1 ||
        errorMessage.indexOf("user_login_error") > -1 ||
        errorMessage.indexOf("null_or_empty_id_token") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ],
        prompt: 'select_account'
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

export const APP_REDIRECT = {
    LOGIN: CommonHelper.GetEnv("MICROSOFT_REDIRECT_URI"),
    LOGOUT: CommonHelper.GetEnv("MICROSOFT_POST_LOGOUT_REDIRECT_URI"),
};

const msalConfig = {
    auth: {
        clientId: CommonHelper.GetEnv("MICROSOFT_APP_ID"),
        authority: "https://login.microsoftonline.com/common",
        redirectUri: CommonHelper.GetEnv("MICROSOFT_REDIRECT_URI"),
        validateAuthority: true,
        postLogoutRedirectUri: CommonHelper.GetEnv("MICROSOFT_POST_LOGOUT_REDIRECT_URI"),
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        navigateFrameWait: 500,
        logger: new Msal.Logger((logLevel, message) => {
            //console.log(message);
        }, {
                level: Msal.LogLevel.Verbose,
            piiLoggingEnabled: true
        }),
        telemetry: {
            applicationName: "cws-meetings",
            applicationVersion: "1.0.0",
            telemetryEmitter: (events) => {
                //console.log('Telemetry Events:', events);
            }
        }
    }
};

export const msalApp = null; // new Msal.UserAgentApplication(msalConfig);