/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from 'react-router';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import * as microsoftTeams from "@microsoft/teams-js";

import { Flex, Segment, Text, Loader, CallPstnIcon, } from '@fluentui/react-northstar'

import { TeamsContext, teamsTabStyles, TabNavBar } from "libs/teams-tab";

import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { QuestionsList } from './QuestionsList';

let myContext = null;

class Survey extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: true,
      error: null,
      id: null,
      data: null,
    };
  }

  componentDidMount() {
    let id = null;

    if (this.props.match.params) {
      if (this.props.match.params.id) {
        id = this.props.match.params.id;
      }
    }

    this.setState({ id }, this.getSurvey);
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  getSurvey = () => {
    const { id } = this.state;
    if (id) {
      this.setState({ loading: true }, async () => {
        const filter = { id: id };
        const expand = `survey($expand=surveyquestions($expand=surveyanswers,surveyteammemberanswers($filter=callSurveyActivityId eq ${id})))`;
        let result = await myContext.dataProviders.api.getList('surveyactivities', { filter, expand });

        if (result && result.data.length > 0) {
          this.setState({ loading: false, data: result.data[0] });
          return;
        }
        else {
          ToastHelper.Error(`Impossibile recuperare il sondaggio con id '${id}'`);
          this.setState({ loading: false, data: null });
        }
      });
    }
    else {
      ToastHelper.Error('Id del sondaggio mancante');
      this.setState({ loading: false, data: null });
    }
  }

  render() {
    const { loading, data } = this.state;
    
    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          return (
            <>
              <TabNavBar
                title={<><Text content="Sondaggio Da Completare" /> {data && <Text content={data.title} weight="bold" size="large" />}</>}
                showBack backPageName="Elenco Sondaggi Da Completare" backPageRoute="surveysToComplete" 
                icon={<CallPstnIcon size="large" />}
              />
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      <Container fluid>
                        <Row>
                          <Col className="p-0">
                            {
                              data.survey.surveyQuestions &&
                              data.survey.surveyQuestions.map((question, key) =>
                                <QuestionsList
                                  key={key}
                                  index={++key}
                                  question={question}
                                />
                              )
                            }
                          </Col>
                        </Row>
                      </Container>
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default withRouter(Survey);