/* eslint-disable no-unused-vars */

import React from "react";
import moment from 'moment';
import { withRouter } from 'react-router';
import { CommonHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import Avatar from 'react-avatar';

import { TeamsContext, teamsTabStyles, TabNavBar, } from "libs/teams-tab";

import { Flex, Segment, Text, Loader, CallPstnIcon } from '@fluentui/react-northstar'

import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';
import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { Typography, Tooltip  } from '@material-ui/core';
import { MSTeamsIcon, MSTeamsIconWeight, MSTeamsIconType } from 'msteams-ui-icons-react';

import { Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';

let myContext = null;

class CallDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: true,
      data: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.callId) {
      this.getReport(this.props.match.params.callId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  getReport = async (callId) => {
    let result = await myContext.dataProviders.api.getOne('getreportdata', { id: callId });

    if (result && result.data && result.data.success) {
      this.setState({ loading: false, callId, data: result.data.data });
    }
    else {
      this.setState({ loading: false, callId, data: null });
    }
  }

  renderCallInfo = () => {
    const { data } = this.state;
    const { callInfo, recapParticipants } = data;

    var eventStartTimeMoment = moment(callInfo.eventStartTime);
    var eventEndTimeMoment = moment(callInfo.eventEndTime);

    var eventDate = eventStartTimeMoment.format("DD MMM YYYY");
    var eventStartTime = eventStartTimeMoment.format("HH:mm");
    var eventEndTime = eventEndTimeMoment.format("HH:mm");

    return (
      <Row>
        <Col xs="12" className="d-flex justify-content-start align-items-center mb-3">
          <MSTeamsIcon style={{ fontSize: 38 }} className="d-inline-block mr-3" aria-label={'ContactGroup'} iconWeight={MSTeamsIconWeight.Light} iconType={MSTeamsIconType['ContactGroup']} />
          <strong style={{ fontSize: 25 }} className="mr-2" >{eventDate}</strong>
          <span style={{ fontSize: 20 }}>{eventStartTime} - {eventEndTime}</span>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.status")}</span>
          {
            CommonHelper.IsEmpty(callInfo.callEndTime) ?
              <span className="text-danger">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.ongoing")}</span> :
              <span className="text-success">{CommonHelper.GetTrans("reports.headerInfos.call.status-types.completed")}</span>
          }
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.type")}</span>
          <strong>Microsoft Teams</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.organizer")}</span>
          <strong>
            <Avatar round={true} size="36" name={callInfo.organizerDisplayName} /> {callInfo.organizerDisplayName}
          </strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.call.participants")}</span>
          <strong>{recapParticipants.length}</strong>
        </Col>
      </Row>
    )
  }

  renderGraph = () => {
    const { data } = this.state;
    const { recapParticipants } = data;


    let barData = {
      options: {
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 20 } },
        scales: {
          yAxes: [{
            gridLines: { display: true, drawBorder: true },
            ticks: { display: false }
          }],
          xAxes: [{
            gridLines: { display: true, drawBorder: true },
            barPercentage: 0.3
          }]
        },
        plugins: {
          datalabels: {
            align: 'start',
            anchor: 'start',
            textAlign: 'center',
            color: '#000000',
            font: { size: 14 },
            formatter: function (value, ctx) { return [value]; },
            offset: 0
          }
        },
        tooltips: { enabled: false },
        responsive: true
      },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        fullWidth: true,
        reverse: false,
        labels: { usePointStyle: true }
      },
      data: { datasets: [] }
    };

    let getByPercentage = (percentageMin, percentageMax) => {
      return recapParticipants.filter(rp => {
        if (rp === null || rp.percentualePresenza == 0) { return false; }
        if (CommonHelper.IsEmpty(percentageMax)) { if (rp.percentualePresenza === percentageMin) return true; }
        else { if (rp.percentualePresenza >= percentageMin && rp.percentualePresenza < percentageMax) return true; }
      }).length;
    }

    var chartDataset = [
      { label: '100%', data: [getByPercentage(95, 101)], backgroundColor: '#CC5190', hoverBackgroundColor: '#CC5190' },
      { label: '> 75%', data: [getByPercentage(75, 95)], backgroundColor: '#6B2480', hoverBackgroundColor: '#6B2480' },
      { label: '> 50%', data: [getByPercentage(50, 75)], backgroundColor: '#E66D3E', hoverBackgroundColor: '#E66D3E' },
      { label: '> 25%', data: [getByPercentage(25, 50)], backgroundColor: '#253193', hoverBackgroundColor: '#253193' },
      { label: '< 25%', data: [getByPercentage(0, 25)], backgroundColor: '#4D85B8', hoverBackgroundColor: '#4D85B8' }
    ];

    barData.data.datasets = chartDataset;

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center mb-3">
          <Bar {...barData} />
        </Col>
      </Row>
    )
  }

  renderEventInfo = () => {
    const { data } = this.state;
    const { callInfo, recapParticipants } = data;

    var eventStartTimeMoment = moment(callInfo.eventStartTime);
    var eventEndTimeMoment = moment(callInfo.eventEndTime);

    var eventStartTime = eventStartTimeMoment.format('DD MMM YYYY HH:mm:ss');
    var eventEndTime = eventEndTimeMoment.format('DD MMM YYYY HH:mm:ss');

    var eventDuration = moment.utc(eventEndTimeMoment.diff(eventStartTimeMoment)).format('HH:mm:ss');

    const getTotalMilliseconds = (dateTime) => {
      var eventM = moment(dateTime);
      var total = 0;
      if (eventM.hour() > 0)
        total += eventM.hour() * 3600000;
      if (eventM.minute() > 0)
        total += eventM.minute() * 60000;
      if (eventM.second() > 0)
        total += eventM.second() * 1000;
      if (eventM.millisecond() > 0)
        total += eventM.millisecond();
      return total;
    }

    var validParticipants = recapParticipants.filter(rp => rp !== null && getTotalMilliseconds(`01-01-2020 ${rp.tempoPresenza}`) > 0).length;

    return (
      <Row className="h-100 mb-2">
        <Col xs="12">
          <strong>{CommonHelper.GetTrans("reports.headerInfos.event.details")}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.startDate")}</span>
          <strong>{eventStartTime}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.endDate")}</span>
          <strong>{eventEndTime}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.participants")}</span>
          <strong>{validParticipants}</strong>
        </Col>
        <Col xs="6" className="mb-2">
          <span className="d-block mb-2">{CommonHelper.GetTrans("reports.headerInfos.event.duration")}</span>
          <strong>{eventDuration}</strong>
        </Col>
      </Row>
    )
  }

  renderCallDetails = () => {
    const { data, callId } = this.state;
    const { recapParticipants } = data;

    const columns = [
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callDetails.sessionType"), type: 'string', filter: true },
      { id: 'tempoPresenza', label: CommonHelper.GetTrans("reports.fields.callDetails.attendanceTime"), type: 'time', inputFormat: 'HH:mm:ss.SSS' },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callDetails.role"), type: 'string', filter: true },
      { id: 'disconnessioni', label: CommonHelper.GetTrans("reports.fields.callDetails.disconnections"), type: 'number', filter: true, size: 'small', align: 'center' }
    ];

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={recapParticipants}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callDetails` }}
            readOnly={true}
            orderBy='partecipante'
          />
        </Col>
      </Row>
    )
  }

  renderCallFullDetails = () => {
    const { data, callId } = this.state;
    const { participantsLogs } = data;

    const columns = [
      //{ id: 'nomeTeam', label: CommonHelper.GetTrans("reports.fields.callFullDetails.teamName"), type: 'string', filter: true },
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.callFullDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.callFullDetails.email"), type: 'string', filter: true },
      { id: 'tipoSessione', label: CommonHelper.GetTrans("reports.fields.callFullDetails.sessionType"), type: 'string', filter: true },
      { id: 'stato', label: CommonHelper.GetTrans("reports.fields.callFullDetails.status"), type: 'string', filter: true },
      { id: 'ruolo', label: CommonHelper.GetTrans("reports.fields.callFullDetails.role"), type: 'string', filter: true },
      { id: 'dataLog', label: CommonHelper.GetTrans("reports.fields.callFullDetails.logDate"), type: 'datetime-local', filter: true }
    ];

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <EnhancedTable
            noBorderShadows
            columns={columns}
            data={participantsLogs}
            downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callFullDetails` }}
            readOnly={true}
            orderBy='partecipante'
          />
        </Col>
      </Row>
    )
  }

  renderPresenceDetails = () => {
    const { data, callId } = this.state;
    const { recapParticipantsPresences } = data;

    const columns = [
      { id: 'partecipante', label: CommonHelper.GetTrans("reports.fields.presenceDetails.participant"), type: 'string', filter: true },
      { id: 'email', label: CommonHelper.GetTrans("reports.fields.presenceDetails.email"), type: 'string', filter: true },
    ];

    const presencesData = [];

    recapParticipantsPresences.map((presences, key) => {
      columns.push(
        {
          id: `presence_${key}`,
          label: (
            <>
              <Typography variant="body1" align="center">
                {presences.oraPresenza}
                <br />
                <Tooltip title={presences.docenteEmail} placement="right">
                  <Typography variant="body1" align="center">{presences.docente}</Typography>
                </Tooltip>
              </Typography>
            </>
          ),
          type: 'bool',
          align: "center"
        }
      )
    })

    recapParticipantsPresences.forEach((presences, key) => {
      presences.partecipanti.forEach(user => {
        let partecipanteData = presencesData.filter(x => x !== null && x.id === user.idUtente);

        if (partecipanteData.length > 0) {
          partecipanteData[0][`presence_${key}`] = user.presente;
        }
        else {
          partecipanteData = { id: user.idUtente, partecipante: user.partecipante, email: user.email, [`presence_${key}`]: user.presente };
          presencesData.push(partecipanteData)
        }
      });
    });

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          {
            recapParticipantsPresences.length === 0 ?
              <Typography variant="body1" className="my-3">
                {CommonHelper.GetTrans("reports.fields.presenceDetails.noPresence")}
              </Typography> :

              <EnhancedTable
                noBorderShadows
                columns={columns}
                data={presencesData}
                //downloadXlsx={{ customApi: `getcalls/getxlsx/${callId}/callFullDetails` }}
                readOnly={true}
                orderBy='partecipante'
              />
          }
        </Col>
      </Row>
    )
  }

  buildCallDetailsTabs = () => {
    return [
      { title: CommonHelper.GetTrans("reports.tabs.callDetails"), content: this.renderCallDetails() },
      { title: CommonHelper.GetTrans("reports.tabs.callFullDetails"), content: this.renderCallFullDetails() },
      { title: CommonHelper.GetTrans("reports.tabs.presenceDetails"), content: this.renderPresenceDetails() }
    ]
  }

  renderEventTable = () => {
    return <EnhancedTabs tabs={this.buildCallDetailsTabs()} activeTab={0} />
  }

  render() {
    const { loading, data } = this.state;
    
    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;
          return (
            <>
              <TabNavBar
                title={<><Text content="Dettaglio evento" /> {data && <Text content={data.callInfo.eventTitle} weight="bold" size="large" />}</>}
                showBack
                backPageName="Eventi"
                backPageRoute="events"
                icon={<CallPstnIcon size="large" />}
              />
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading || !data ?
                      <Loader label="Caricamento..." /> :
                      <Container fluid>
                        <Row>
                          <Col>
                            {this.renderCallInfo()}
                          </Col>
                          <Col>
                            {this.renderGraph()}
                          </Col>
                          <Col>
                            {this.renderEventInfo()}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="p-0">
                            {this.renderEventTable()}
                          </Col>
                        </Row>
                      </Container>
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default withRouter(CallDetails);