import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

export default createMuiTheme({
    palette: {
        primary: { main: blue[500], contrastText: '#ffffff' },
        secondary: { main: grey[500], contrastText: '#ffffff' }
    },
    typography: {
        fontFamily: [
            "Roboto",
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Arial",
            "sans-serif"
        ].join(",")
    }
});