import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

export default function ListItemExt({ link, name, icon, collapseDesktopOpen, closeMobileCollapse, active, className, children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => setOpen(active), [active]);

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    link ?
      <ListItem component={ForwardNavLink} to={link} onClick={closeMobileCollapse} className={classNames(className, 'text-livion-blue nav-link')} >
        <ListItemIcon style={{minWidth: 25}}>
          <Tooltip title={name}>
            <i className={icon} />
          </Tooltip>
        </ListItemIcon>
        {collapseDesktopOpen && <ListItemText primary={name} />}
      </ListItem> :
      <>
        <ListItem button onClick={handleClick} className={'text-livion-blue'}>
          {
            icon &&
            <ListItemIcon style={{ minWidth: 25 }}>
              <Tooltip title={name}>
                <i className={icon} />
              </Tooltip>
            </ListItemIcon>
          }
          {collapseDesktopOpen && <ListItemText primary={name} />}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </>
  );
}