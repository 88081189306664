import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { styles } from './theme';

const red500 = red['500'];

class CheckboxExt extends ValidatorComponent {

    onHandleChange = (e) => {
        this.validate(e);
        if(this.props.callback !== undefined)
            this.props.onChange(e, this.props.name, this.props.callback !== undefined ? this.props.callback(e) : undefined);
        else
            this.props.onChange(e, this.props.name);
    }

    render() {
        const { isValid } = this.state;
        const { classes, value, label, disabled, hideInlineLabel } = this.props;

        let inlineLabel = value ? 'SI' : 'NO';
        
        if (hideInlineLabel)
            inlineLabel = null;
            
        return (
            <>
                <FormLabel style={{ color: isValid ? '#0000008a' : red500 }} component="legend" margin="normal" className="MuiInputLabel-shrink-117 pt-2">{label}</FormLabel>
                <FormControlLabel
                    classes={hideInlineLabel && { root: classes.checkBoxRootNoInlineLabel }}
                    control={
                        <>
                            <Switch
                                checked={value}
                                disabled={disabled}
                                onChange={(e) => this.onHandleChange(!value)}
                                value={value}
                                color='primary'
                            />
                            {this.errorText()}
                        </>
                    }
                    label={inlineLabel}
                />
            </>
        )
    }

    errorText() {
        const { classes } = this.props;
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={classes.errorMessage}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withStyles(styles)(CheckboxExt);