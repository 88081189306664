import React from 'react';
import { CommonHelper } from 'components/Common/Helper/Helper';

import { AdminDashboardContext } from "libs/admin-dashboard";

import Header from "components/Headers/Header.jsx";

// reactstrap components
import { Container, Row } from "reactstrap";

class Unauthorized extends React.Component {
  render() {
    let { seo } = this.props;

    return (
      <AdminDashboardContext.Consumer>
        {context => {
          if (context.currentPage !== "unauthorized")
            context.setData({ seo: seo, currentPage: "unauthorized" });

          return (
            <>
              <Header />
              <Container className="mt-7" fluid>
                <Row>
                  <h1 className="mb-7">{CommonHelper.GetTrans("common.pagetitle.admin.unauthorized")}</h1>
                </Row>
              </Container>
            </>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

export default Unauthorized;