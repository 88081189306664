/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';
import CSVReader from 'react-csv-reader'
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import Avatar from 'react-avatar';

import { Users, Groups, teamsRolesEnum } from 'libs/microsoft-graph-service';
import { TeamsContext, ButtonTooltip, rolesEnum } from "libs/teams-tab";

import {
    Dialog, Text, List, Checkbox,
    FilesUploadIcon, LightningIcon, LikeIcon,
    CloseIcon, AcceptIcon, InfoIcon, RetryIcon, BanIcon,
} from '@fluentui/react-northstar'

import { mergeStyleSets } from 'office-ui-fabric-react';

const customClass = mergeStyleSets({});

const importStatusEnum = {
    Ready: 'ready',
    Processing: 'processing',
    Processed: 'processed',
    Error: 'error',
    NoProcess: 'noprocess',
}

const getColorByRole = (role) => {
    switch (role.toLowerCase()) {
        case 'organizer':
            return '#8E192E';
        case 'teacher':
            return '#FFAA44';
        default:
            return '#6264A7';
    }
}

export const TeamChannelMemberImporter = ({ members, reload }) => {
    const teamsContext = useContext(TeamsContext);
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const { msTeamsContext, teamChannel } = teamsContext;
    const { groupId } = msTeamsContext;
    let dialogHeader = null;
    let dialogContent = null;
    let items = [];

    const [csvLines, setCsvLines] = useState([]);
    const [open, setOpen] = useState(false)

    const onCancel = () => {
        setCsvLines([]);
        setOpen(false);
    }

    useEffect(() => {
        if (csvLines.length > 0) {
            setCsvLines([]);
        }
    }, []);

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
    }

    const onFileLoaded = (data, fileInfo) => {
        let _csvLines = [];

        if (data && data.length > 0) {
            let requiredColumns = ['email', 'nome', 'cognome', 'tipo'];
            let missingColumns = requiredColumns.filter(rc => rc !== null && !(rc in data[0]));

            if (missingColumns.length > 0) {
                missingColumns.forEach(missingColumn => { ToastHelper.Warn(`Colonna mancante: ${missingColumn}`) });
                setOpen(false);
                return;
            }
        }

        _csvLines = data.map(_line => {
            const line = {
                firstname: _line.nome,
                lastname: _line.cognome,
                email: _line.email,
                type: _line.tipo,
            }

            switch (line.type.toLowerCase()) {
                case "studente":
                case "allievo":
                default:
                    line.type = 'student';
                    break;
                case "docente":
                case "insegnante":
                    line.type = 'teacher';
                    break;
                case "organizzatore":
                case "tutor":
                    line.type = 'organizer';
                    break;
            }

            let currentMember = members.filter(m => m !== null && m.userPrincipalName === line.email);
            if (currentMember && currentMember.length > 0) {
                currentMember = currentMember[0];
                var currentMemberRole = rolesEnum.Member;

                if (currentMember.msteamMemberMsteamChannels.length > 0) {
                    currentMemberRole = currentMember.msteamMemberMsteamChannels.filter(tm => tm !== null && tm.teamChannelId === teamChannel.id);
                    currentMemberRole = currentMemberRole.length > 0 ? currentMemberRole[0].type.toLowerCase() : rolesEnum.Member;
                }

                if (currentMemberRole === rolesEnum.Organizer && currentMemberRole !== line.type.toLowerCase()) {
                    line.type = rolesEnum.Organizer;
                }
            }
            return { ...line, status: importStatusEnum.Ready }
        })

        setCsvLines(_csvLines);
    }

    const onError = () => {
        ToastHelper.Error("Errore durante l'importazione del csv. Controllare il file");
    }

    const updateStatus = (key, newstatus) => {
        setCsvLines(oldCsvLines => {
            let others = oldCsvLines.filter((oldCsvLine, i) => i !== key);
            let changed = oldCsvLines.filter((oldCsvLine, i) => i === key)[0];
            changed.status = newstatus;
            return [...others, changed];
        });
    }

    const onConfirm = async () => {
        let linesToProcess = csvLines.filter((line) => line !== null && line.status === importStatusEnum.Ready);

        if (linesToProcess.length === 0) {
            ToastHelper.Info("Nessuna riga da importare");
            return;
        }

        setLoading(true, async () => {
            let results = Promise.all(
                csvLines.map(async (member, key) => {
                    let success = false;
                    let user = null;
                    let error = null;

                    if (member.status === importStatusEnum.Ready){
                        updateStatus(key, importStatusEnum.Processing);

                        let password = CommonHelper.GetEnv("DEFAULT_USER_PASSWORD", "CwsM33t1ng5!");
                        let userExist = await Users.Check(member.email).catch(err => { return { err } });

                        if (userExist === null || !userExist.err) {
                            let tempUser = userExist;
                            if (tempUser) {
                                tempUser = await Users.Update(tempUser.id, member.firstname, member.lastname, member.email).catch(err => err);
                            }
                            else {
                                const nickName = `${member.firstname.replace(" ", "")}${member.lastname.replace(" ", "").charAt(0)}`;
                                tempUser = await Users.Create(member.firstname, member.lastname, nickName, member.email, password).catch(err => err);
                            }

                            if (tempUser) {
                                let currentUser = {};
                                let isGuest = tempUser.userType === "Guest";
                                let teamsRole = null;
                                let customRole = null;

                                switch (member.type.toLowerCase()) {
                                    case "student":
                                    default:
                                        teamsRole = isGuest ? teamsRolesEnum.Guest : teamsRolesEnum.Member;
                                        customRole = isGuest ? rolesEnum.Guest : rolesEnum.Member;
                                        break;
                                    case "teacher":
                                        teamsRole = isGuest ? teamsRolesEnum.Guest : teamsRolesEnum.Member;
                                        customRole = rolesEnum.Teacher;
                                        break;
                                    case "organizer":
                                        teamsRole = teamsRolesEnum.Owner;
                                        customRole = rolesEnum.Organizer;
                                        break;
                                }

                                currentUser.id = tempUser.id;
                                currentUser.displayName = tempUser.displayName;
                                currentUser.mail = tempUser.mail || tempUser.userPrincipalName;
                                currentUser.userPrincipalName = tempUser.userPrincipalName;
                                currentUser.teamsRole = teamsRole;
                                currentUser.role = customRole;

                                user = currentUser;

                                let hasGroup = await Users.HasGroup(currentUser.id, groupId).catch(err => err);

                                if (!hasGroup) {
                                    let addMemberInTeam = await Groups.AddMember(groupId, currentUser.id, currentUser.teamsRole).catch(err => err);
                                    if (addMemberInTeam) {
                                        hasGroup = true;
                                    }
                                }

                                if (hasGroup) {
                                    if (currentUser.role === rolesEnum.Teacher) {
                                        let teachersGroupName = CommonHelper.GetEnv("DEFAULT_TEACHERS_GROUP_NAME", "Teachers");
                                        if (!CommonHelper.IsEmpty(teachersGroupName)) {
                                            let teachersGroup = await Groups.GetByName(teachersGroupName).catch(err => err);
                                            if (teachersGroup && CommonHelper.IsArray(teachersGroup) && teachersGroup.length > 0) {
                                                let teacherGroup = teachersGroup[0];
                                                let hasTeacherGroup = await Users.HasGroup(currentUser.id, teacherGroup.id).catch(err => err);
                                                if (!hasTeacherGroup) {
                                                    let addMemberInTeam = await Groups.AddMember(teacherGroup.id, currentUser.id, teamsRolesEnum.Member).catch(err => err);
                                                    if (!addMemberInTeam) {
                                                        ToastHelper.Error(`Utente non aggiunto al gruppo "${teachersGroupName}". Procedere manualmente`);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    
                                    let data = {
                                        id: currentUser.id,
                                        displayName: `${member.firstname} ${member.lastname}`,
                                        givenName: member.firstname,
                                        surname: member.lastname,
                                        mail: member.email,
                                        userPrincipalName: currentUser.userPrincipalName,
                                        msteamMemberMsteamChannels: [{ teamChannelId: teamChannel.id, teamMemberId: currentUser.id, type: currentUser.role }]
                                    }

                                    let result = await teamsContext.dataProviders.api.create('msteammembers/createorupdate', { data, addMsToken: true, teamChannelId: teamChannel.id }).catch(e => e);

                                    if (result && result.data) { success = true; }
                                    else { error = 'Utente non aggiunto nel Database'; }
                                }
                                else {
                                    if (isGuest && teamsRole === teamsRolesEnum.Owner) {
                                        error = 'Un utente ospite non può essere owner di un Team';
                                    }
                                    else {
                                        error = 'Utente non aggiunto nel Team';
                                    }
                                }
                            }
                            else { error = 'Utente non creato/aggiunto'; }
                        }
                        else { error = 'Errore durante la verifica dell\'utente'; }

                        updateStatus(key, success ? importStatusEnum.Processed : importStatusEnum.Error);
                    }
                    else {
                        return null;
                    }
                    return { success, user, error };
                }).filter(r => r !== null)
            );

            await results.then(members => {
                var errors= {};
                var addedUsers = members.filter(r => r !== null && r.success);
                var notAddedUsers = members.filter(r => r !== null && !r.success);

                addedUsers.map(addedUser => {
                    ToastHelper.Success(`${addedUser.user.displayName} (${addedUser.user.mail})`);
                })

                notAddedUsers.map(notAddedUser => {
                    console.info('not added', notAddedUser);
                    errors[notAddedUser.user.mail] = notAddedUser.error;
                })

                if (Object.keys(errors).length === 0) {
                    setTimeout(() => {
                        setOpen(false);
                    }, 1000);
                }
                else{
                    setErrors(errors);
                }
                reload();
            })
            setLoading(false);
        });
    }

    dialogHeader = <>
        <Text content="Importa utenti nel canale" />
        <ButtonTooltip secondary text iconOnly noBg icon={<InfoIcon outline />} content={""} tooltip="File .csv con le seguenti colonne: nome,cognome,email,tipo" />
    </>;

    items.push({
        key: 'csvreader',
        content: <CSVReader cssClass="csv-reader-input" parserOptions={papaparseOptions} onFileLoaded={onFileLoaded} onError={onError} />
    });

    const renderCsvLines = () => {
        let lineItems = csvLines.sort(CommonHelper.CompareValues("email")).map((csvLine, key) => {
            const toggleUser = () => {
                setCsvLines(oldCsvLines => {
                    let others = oldCsvLines.filter((oldCsvLine, i) => i !== key);
                    let changed = oldCsvLines.filter((oldCsvLine, i) => i === key)[0];
                    changed.status = changed.status === importStatusEnum.NoProcess ? importStatusEnum.Ready : importStatusEnum.NoProcess;
                    return [...others, changed];
                });
            }

            let displayName = `${csvLine.firstname} ${csvLine.lastname}`;
            let email = csvLine.email;
            let type = csvLine.type.toUpperCase();
            let status = csvLine.status;
            let checked = ![importStatusEnum.Error, importStatusEnum.NoProcess].includes(status);

            let statusIcon = null

            switch (status) {
                case importStatusEnum.Ready:
                    statusIcon = <ButtonTooltip secondary text iconOnly noBg icon={<AcceptIcon />} content={""} tooltip='Pronto' />;
                    break;
                case importStatusEnum.Processing:
                    statusIcon = <ButtonTooltip secondary text iconOnly noBg icon={<LightningIcon />} content={""} tooltip='Importazione in corso' />;
                    break;
                case importStatusEnum.Processed:
                    statusIcon = <ButtonTooltip secondary text iconOnly noBg icon={<LikeIcon />} content={""} tooltip='Importazione completata' />;
                    break;
                case importStatusEnum.NoProcess:
                    statusIcon = <ButtonTooltip secondary text iconOnly noBg icon={<BanIcon />} content={""} tooltip='Non importare' />;
                    break;
                case importStatusEnum.Error:
                    let errMsg = "Errore durante l'importazione";
                    if (Object.keys(errors).length > 0 && errors.hasOwnProperty(email)) {
                        console.error(errors[email])
                        errMsg = errors[email];
                    }
                    statusIcon = <ButtonTooltip secondary text iconOnly noBg icon={<CloseIcon />} content={""} tooltip={errMsg} />;
                    break;
            }

            return {
                key: key,
                media: <Avatar round={true} size={30} name={displayName} maxInitials={3} title={displayName} color={getColorByRole(type)} />,
                header: `${displayName} (${email})`,
                content: type,
                endMedia: <>
                    <Checkbox toggle checked={checked} onClick={() => toggleUser(key)} />
                    {statusIcon}
                </>
            }
        })

        lineItems.push({
            key: 'clear',
            header: <ButtonTooltip icon={<RetryIcon />} secondary content="Seleziona un altro file" tooltip="Seleziona un altro file" onClick={() => setCsvLines([])} />,
        })

        return lineItems;
    }

    dialogContent = <>
        { csvLines.length === 0 && <List items={items} />}
        { csvLines.length > 0 && <List items={renderCsvLines()} />}
    </>

    return (
        <Dialog
            cancelButton="Chiudi"
            confirmButton="Conferma"
            onCancel={!loading ? onCancel : null}
            onConfirm={!loading ? onConfirm : null}
            onOpen={() => setOpen(true)}
            open={open}
            closeOnOutsideClick={false}
            content={dialogContent}
            header={dialogHeader}
            trigger={<ButtonTooltip icon={<FilesUploadIcon />} secondary iconOnly content="" tooltip="Importa" />}
        />
    )
}