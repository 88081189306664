import React from 'react';
import moment from 'moment';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextFieldExt, SelectExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import { AdminDashboardContext } from "libs/admin-dashboard";
import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Button from 'reactstrap-button-loader';

import { Groups } from 'libs/microsoft-graph-service';

import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({});

const baseApi = 'msevents';
const orderTableBy = "startTime";

class ImportMSTeamEvents extends React.Component {
    static contextType = AdminDashboardContext;

    constructor(props) {
        super();

        this.tabsRef = React.createRef();

        this.state = {
            data: null,
            loading: true,
            relatedData: {
                teamsOptions: [],
                teamChannelsOptions: []
            },
            request: {
                teamId: undefined,
                teamChannelId: undefined,
                startDateTime: new Date().toISOString().substring(0, 10),
                endDateTime: new Date().toISOString().substring(0, 10)
            },
            importedData: [],
            updatingData: false,
            msDataPage: 0
        };
    }

    componentDidMount() {
        this.getTeams();
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
        }
    }

    onHandleChange = (value, field) => {
        let { request } = this.state;

        request[field] = value;

        this.setState({ request })

        switch (field) {
            case 'teamId':
                this.getTeamChannels();
                break;
            case 'teamChannelId':
                this.getEvents();
                break;
        }
    }

    isLoaded = () => {
        if (this.state.loading)
            setTimeout(() => { this.setState({ loading: false }) }, 500);
    }

    isLoading = () => {
        if (this.state.loading === false)
            this.setState({ loading: true });
    }

    getTeams = async () => {
        let result = await this.context.dataProvider.getList('msteams', { select: 'id,displayName' });

        if (result && result.data) {
            let options = result.data.map(j => ({ value: j.id, label: j.displayName }));
            options = options.sort(CommonHelper.CompareValues("label"));

            let { relatedData } = this.state;
            relatedData.teamsOptions = options;
            this.setState({ relatedData: relatedData });
        }
    }

    getTeamChannels = async () => {
        let { relatedData, request } = this.state;
        let { teamId } = request;

        relatedData.teamChannelsOptions = [];
        request.teamChannelId = undefined;

        this.setState({ relatedData: relatedData, data: null, request: request }, async () => {
            if (CommonHelper.IsEmpty(teamId)) {
                return;
            }

            let result = await this.context.dataProvider.getList(`msteamchannelsbyteam/${teamId}`);

            if (result && result.data) {
                let options = result.data.map(j => ({ value: j.teamChannelId, label: j.displayName, data: j }));
                options = options.sort(CommonHelper.CompareValues("label"));

                let { relatedData } = this.state;
                relatedData.teamChannelsOptions = options;
                this.setState({ relatedData: relatedData });
            }
        });
    }

    getEvents = async (callback) => {
        let { request, relatedData } = this.state;
        const { teamChannelId } = request;
        const { teamChannelsOptions } = relatedData;

        if (CommonHelper.IsEmpty(teamChannelId)) {
            this.setState({ data: null, importedData: [] });
            return null;
        }

        const teamChannelData = teamChannelsOptions.filter(x => x.value === teamChannelId);

        if (teamChannelData.length === 0) {
            return null;
        }

        let result = await this.context.dataProvider.getList(baseApi, { filter: { teamChannelId: teamChannelData[0].data.id } }, { expand: "callsTrackings" });
        if (result && result.data) {
            if (result.data.length === 0) {
                ToastHelper.Info(CommonHelper.GetTrans("msteamsimporter.messages.ms_events.no_data"))
            }

            let groupedEvents = CommonHelper.GroupBy(result.data, "eventId");

            let validEvents = [];

            for (var eventId in groupedEvents) {
                let validEvent = null;
                if (groupedEvents[eventId].length === 1) {
                    validEvent = groupedEvents[eventId][0];
                }
                else {
                    validEvent = groupedEvents[eventId].reduce((a, b) => (new Date(a.lastModifiedDateTime) > new Date(b.lastModifiedDateTime) ? a : b));
                }

                validEvents.push(validEvent);
            }

            this.setState({ data: validEvents, importedData: [] }, async () => {
                this.isLoaded();
                if (CommonHelper.IsFunction(callback))
                    callback();
            });
        }
    }

    setStore = (eventId, value) => {
        const { importedData } = this.state;
        importedData.filter(d => d.eventId === eventId)[0].store = value;
        this.setState({ importedData: importedData });
    }

    renderMSData = () => {
        let { data, updatingData } = this.state;

        if (CommonHelper.IsEmpty(data))
            return null;

        const columns = [
            { id: 'id', label: "Id", type: 'string', filter: true },
            { id: 'subject', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_events.subject"), type: 'string', filter: true },
            { id: 'startTime', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_events.startTime"), type: 'datetime-local', outputFormat: 'DD/MM/YYYY HH:mm', filter: true },
            { id: 'endTime', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_events.endTime"), type: 'datetime-local', outputFormat: 'DD/MM/YYYY HH:mm', filter: true },
            { id: 'onlineMeetingJoinUrl', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_events.onlineMeetingJoinUrl"), type: 'external-link', size: 'small', align: 'center', width: 100, sort: false },
            { id: 'status', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_events.status"), type: 'string', filter: true, size: 'small', align: 'center', width: 100 }
        ];

        data = data.map(d => {
            const status = this.getDataStatus(d);

            switch (status) {
                case 'equals':
                    d.bgColor = "#85caac";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.nothing");
                    break;
                case 'updated':
                    d.bgColor = "#e6d88e";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.to_update");
                    break;
                case 'removed':
                    d.bgColor = "#ea8585";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.to_delete");
                    break;
                default:
                    delete d.bgColor;
                    delete d.status;
                    break;
            }

            return d;
        })

        return (
            <Row className="h-100">
                <Col xs="12" className="h-100 d-flex justify-content-center align-items-center p-0 mt--2">
                    <EnhancedTable
                        innerRef={ref => (this.table = ref)}
                        updatingData={updatingData}
                        noBorderShadows
                        columns={columns}
                        data={data}
                        readOnly={true}
                        orderBy={orderTableBy}
                        handleLoading={this.isLoading}
                        handleLoaded={this.isLoaded}
                    />
                </Col>
            </Row>
        )
    }

    renderImportedMSData = () => {
        const { importedData, updatingData, msDataPage } = this.state;

        if (CommonHelper.IsEmpty(importedData))
            return null;

        const rowsPerPage = 4;

        const handleChangePage = (event, page) => {
            this.setState({ msDataPage: page });
        };

        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell hidden>Id</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_events.subject")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_events.startTime")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_events.endTime")}</TableCell>
                            <TableCell size="small" align="center">{CommonHelper.GetTrans("msteamsimporter.fields.ms_events.onlineMeetingJoinUrl")}</TableCell>
                            <TableCell size="small" align="center">{CommonHelper.GetTrans("common.text.import")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {importedData.slice(msDataPage * rowsPerPage, msDataPage * rowsPerPage + rowsPerPage)
                            .map((data, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row" hidden>{data.eventId}</TableCell>
                                        <TableCell>{data.subject}</TableCell>
                                        <TableCell>{moment(data.startTime).format('DD/MM/YYYY HH:mm')}</TableCell>
                                        <TableCell>{moment(data.endTime).format('DD/MM/YYYY HH:mm')}</TableCell>
                                        <TableCell size="small" align="center" style={{ width: 100 }}>
                                            <a href={data.onlineMeetingJoinUrl} target="_blank">
                                                <IconButton data-type="external-link" title={data.onlineMeetingJoinUrl}>
                                                    <LanguageIcon color='primary' />
                                                </IconButton>
                                            </a>
                                        </TableCell>
                                        <TableCell size="small" align="center" style={{ width: 100 }}>
                                            <Switch
                                                checked={data.store}
                                                onChange={() => this.setStore(data.eventId, !data.store)}
                                                value={data.store}
                                                color='primary'
                                                disabled={updatingData}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage={CommonHelper.GetTrans("common.table.pagination.labelRowsPerPage")}
                    component="div"
                    count={CommonHelper.IsEmpty(importedData) ? 0 : importedData.length}
                    rowsPerPageOptions={[rowsPerPage]}
                    rowsPerPage={rowsPerPage}
                    page={msDataPage}
                    backIconButtonProps={{
                        'aria-label': CommonHelper.GetTrans("common.table.pagination.previousPage"),
                    }}
                    nextIconButtonProps={{
                        'aria-label': CommonHelper.GetTrans("common.table.pagination.nextPage"),
                    }}
                    onChangePage={handleChangePage}
                />
            </TableContainer>
        );
    }

    getDataStatus = (data) => {
        const { importedData } = this.state;

        if (CommonHelper.IsEmpty(importedData)) {
            return null;
        }

        if (CommonHelper.IsEmpty(data)) {
            return "equals";
        }

        let newData = importedData.filter(d => d.eventId === data.eventId);

        if (newData.length === 0) {
            const { relatedData } = this.state;

            let startTime = moment.utc(data.startTime);
            let selectedStartTime = moment.utc(relatedData.startDateTime);

            if (data.callsTrackings.length > 0 || selectedStartTime.diff(startTime) >= 0) {
                return "";
            }
            else {
                return "removed";
            }
        }

        for (var propertyName in newData[0]) {
            let toUpdate = false;

            switch (propertyName) {
                case 'startTime':
                case 'endTime':
                case 'lastModifiedDateTime':
                    {
                        let newDate = moment.utc(newData[0][propertyName]).format('DD/MM/YYYY HH:mm:ss');
                        let oldDate = moment.utc(data[propertyName]).format('DD/MM/YYYY HH:mm:ss');
                        toUpdate = newDate !== oldDate;
                        break;
                    }
                case 'store':
                    toUpdate = false;
                    break;
                default:
                    toUpdate = newData[0][propertyName] !== data[propertyName];
                    break;
            }

            if (toUpdate) {
                return "updated";
            }
        }

        return "equals";
    }

    getMSData = () => {
        const { request, relatedData } = this.state;
        const { teamId, teamChannelId, startDateTime, endDateTime } = request;

        if (CommonHelper.IsEmpty(teamId))
            return null;

        let _startDateTime = new Date(startDateTime);
        let _endDateTime = new Date(endDateTime);

        if (_startDateTime > _endDateTime) {
            let temp = _startDateTime;
            _startDateTime = _endDateTime;
            _endDateTime = temp;
        }

        this.setState({ loading: true }, async () => {
            let msTeamEvents = await Groups.GetCalendarView(teamId, _startDateTime.toISOString(), _endDateTime.toISOString(), false).catch(e => {
                if (e.authError) { ToastHelper.Info(e.errorMessage); }
            });

            if (msTeamEvents !== undefined) {
                const eventDetails = Groups.FormatMsTeamEvents(msTeamEvents, teamChannelId);
                const getMsTeamEvents = async () => { return Promise.all(eventDetails) }
                getMsTeamEvents().then(importedData => {
                    let msTeamChannelId = relatedData.teamChannelsOptions.filter(tco => tco.value === teamChannelId)[0].data.id;
                    const finalMsTeamEvents = importedData.map(_importedData => {
                        return {
                            eventId: _importedData.msTeamEvent.id,
                            subject: _importedData.msTeamEvent.subject,
                            startTime: _importedData.msTeamEvent.start.dateTime,
                            endTime: _importedData.msTeamEvent.end.dateTime,
                            organizerId: CommonHelper.IsEmpty(_importedData.organizer) ? null : _importedData.organizer.id,
                            isAllDay: _importedData.msTeamEvent.isAllDay,
                            isCancelled: _importedData.msTeamEvent.isCancelled,
                            teamChannelId: msTeamChannelId,
                            onlineMeetingJoinUrl: _importedData.msTeamEvent.onlineMeeting.joinUrl,
                            webLink: _importedData.msTeamEvent.webLink,
                            lastModifiedDateTime: _importedData.msTeamEvent.lastModifiedDateTime,
                            store: true
                        };
                    })
                    this.setState({ importedData: finalMsTeamEvents }, this.checkToStore);
                })
            }
            else {
                ToastHelper.Info(CommonHelper.GetTrans("msteamsimporter.messages.ms_events.not_found"))
            }
        });
    }

    checkToStore = () => {
        let { data, importedData } = this.state;
        importedData = importedData.map(id => {
            if (!CommonHelper.IsEmpty(data)) {
                id.store = data.filter(d => d !== null && d.eventId === id.eventId && this.getDataStatus(d) !== "equals").length > 0;
            }
            return id;
        })
        this.setState({ importedData });
    }

    resetMSData = () => {
        this.setState({ importedData: [] });
    }

    syncMSData = async (removeOld = false) => {
        const { data, importedData, request } = this.state;
        const { teamId } = request;

        await Promise.all(importedData.map(async (msData) => {
            if (msData.store) {
                if (!CommonHelper.IsEmpty(data) && data.filter(d => d.eventId === msData.eventId).length > 0) {
                    msData = { ...data.filter(d => d.eventId === msData.eventId)[0], ...msData };
                }

                if (CommonHelper.IsEmpty(msData.createdAt)) {
                    await this.context.dataProvider.create(baseApi, { data: msData });
                }
                else {
                    await this.context.dataProvider.update(baseApi, { id: msData.id, data: msData });
                }
            }
        }));

        if (removeOld) {
            await Promise.all(data.map(async (d) => {
                if (importedData.filter(id => id.eventId === d.eventId).length === 0 && this.getDataStatus(d) === "removed") {
                    await this.context.dataProvider.delete(baseApi, { id: d.id });
                }
            }));
        }

        this.setState({ updatingData: false }, () => {
            this.getEvents();
        })
    }

    canSync = () => {
        const { updatingData, data, importedData } = this.state;

        return (
            !updatingData &&
            importedData && (
                importedData.filter(d => d.store).length > 0 ||
                data && data.filter(d => importedData.filter(id => id.eventId === d.eventId).length === 0 && this.getDataStatus(d) === "removed").length > 0)
        )
    }

    render() {
        const { updatingData, importedData, relatedData, request } = this.state;
        const { teamId, teamChannelId, startDateTime, endDateTime } = request;
        const { classes } = this.props;

        return (
            <Container className="py-2 px-4 mw-100">
                {
                    importedData.length === 0 &&
                    <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                        <Row className="no-gutters">
                            <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <SelectExt name="teamId" label="Teams" options={relatedData.teamsOptions} onChange={this.onHandleChange} value={teamId} disabled={updatingData} />
                            </Col>
                            <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <SelectExt name="teamChannelId" label="Team Channels" options={relatedData.teamChannelsOptions} onChange={this.onHandleChange} value={teamChannelId} disabled={updatingData} />
                            </Col>
                            <Col xs="2" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <TextFieldExt name="startDateTime" label="Start Date" type="date" onChange={this.onHandleChange} value={startDateTime} disabled={updatingData} />
                            </Col>
                            <Col xs="2" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <TextFieldExt name="endDateTime" label="End Date" type="date" onChange={this.onHandleChange} value={endDateTime} disabled={updatingData} />
                            </Col>
                        </Row>
                    </ValidatorForm>
                }
                <Row className="py-2 row bg-light rounded row">
                    {
                        importedData.length === 0 ?
                            <>
                                <Col xs="12" className="text-center">
                                    <Button className={classNames(classes.button, 'm-0')} variant="contained" color="success" onClick={this.getMSData} disabled={updatingData || CommonHelper.IsEmpty(teamChannelId)}>{CommonHelper.GetTrans("buttons.getdatafromMS")}</Button>
                                </Col>
                            </> :
                            <>
                                <Col xs="12">
                                    {this.renderImportedMSData()}
                                </Col>
                                <Col xs="12" className="pt-3 text-center">
                                    <Button className={classes.button} variant="contained" color="danger" onClick={this.resetMSData} disabled={updatingData}>{CommonHelper.GetTrans("buttons.cancel")}</Button>
                                    <Button className={classes.button} variant="contained" color="success" onClick={() => this.syncMSData()} disabled={!this.canSync()}>{CommonHelper.GetTrans("buttons.sync")}</Button>
                                    <Button className={classes.button} variant="contained" color="success" onClick={() => this.syncMSData(true)} disabled={!this.canSync()}>{CommonHelper.GetTrans("buttons.syncAndRemove")}</Button>
                                </Col>
                            </>
                    }
                </Row>
                {this.renderMSData()}
            </Container>
        );
    }
}

export default withStyles(styles)(ImportMSTeamEvents);