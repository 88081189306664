import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextFieldExt, SelectExt, CheckboxExt } from 'components/Common//MaterialUI-Ext/MaterialUI-Ext';
import { Container, Row, Col, Button } from "reactstrap";
import { ValidatorForm } from 'react-material-ui-form-validator';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: 24,
        zIndex: 0
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class DatagridFilter extends React.Component {

    state = {
        columns: this.props.columns
    };

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            this.setState({ columns: this.props.columns });
        }
    }

    onFilterHandler = (value, field) => {
        this.props.onFilter({ value, field });
    }

    createResetFilterHandler = event => {
        this.props.onResetFilter(event);
    }

    getComponent = (id, type, label, value, options) => {
        switch (type) {
            case 'option':
                return <SelectExt
                    name={id}
                    type={type}
                    label={label}
                    options={options}
                    onChange={(e) => this.onFilterHandler(e, id)}
                    value={value}
                />;
            case 'bool':
                return <CheckboxExt
                    name={id}
                    label={label}
                    onChange={(e) => this.onFilterHandler(e, id)}
                    value={value}
                />;
            default:
                return <TextFieldExt
                    name={id}
                    type={type}
                    label={label}
                    onChange={(e) => this.onFilterHandler(e, id)}
                    value={value}
                />;
        }
    }

    render() {
        const { columns } = this.state;
        const { filter } = this.props;

        const footerStyle = {
            marginTop: '24px',
            textAlign: 'center'
        };

        let filterActive = false;

        const filterFields = columns.filter(column => column.visible !== false && column.filter === true).map(column => {
            let { id, type, label, options } = column;
            let colFound = filter.findIndex(f => f.columnToQuery === column.id) !== -1;
            let value = colFound ? filter[filter.findIndex(f => f.columnToQuery === column.id)].query : ""
            if (colFound)
                filterActive = true;
            return (
                <Col xs="6" key={column.id}>
                    {this.getComponent(id, type, label, value, options)}
                </Col>
            )
        })

        return (
            <ValidatorForm ref={this.form} onSubmit={() => { return false }} onError={errors => console.log(errors)}>
                <Container style={{ width: '500px' }}>
                    <Row>
                        {filterFields}
                    </Row>
                    <div style={footerStyle}>
                        <Button color="primary" disabled={!filterActive} onClick={this.createResetFilterHandler}>Resetta</Button>
                    </div>
                </Container>
            </ValidatorForm>
        );
    }
}

DatagridFilter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DatagridFilter); 