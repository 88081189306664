import "@babel/polyfill";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import { toast } from 'react-toastify';

import "assets/vendor/nucleo/css/nucleo.css";
//import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/css/argon-dashboard-react.css";
import 'react-toastify/dist/ReactToastify.css';

import App from "App";

// Call it once in your app. At the root of your app is the best place
toast.configure()

const AppContainer = withRouter(props => <App {...props} />);

ReactDOM.render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// (function () {
//   var hidden = "hidden";

//   // Standards:
//   if (hidden in document)
//     document.addEventListener("visibilitychange", onchange);
//   else if ((hidden = "mozHidden") in document)
//     document.addEventListener("mozvisibilitychange", onchange);
//   else if ((hidden = "webkitHidden") in document)
//     document.addEventListener("webkitvisibilitychange", onchange);
//   else if ((hidden = "msHidden") in document)
//     document.addEventListener("msvisibilitychange", onchange);
//   // IE 9 and lower:
//   else if ("onfocusin" in document)
//     document.onfocusin = document.onfocusout = onchange;
//   // All others:
//   else
//     window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;

//   function onchange(evt) {
//     var v = "visible", h = "hidden",
//       evtMap = {
//         focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
//       };

//     evt = evt || window.event;
//     if (evt.type in evtMap)
//       document.body.className = evtMap[evt.type];
//     else
//       document.body.className = this[hidden] ? "hidden" : "visible";

//     console.log(document.body.className);
//   }

//   // set the initial state (but only if browser supports the Page Visibility API)
//   if (document[hidden] !== undefined)
//     onchange({ type: document[hidden] ? "blur" : "focus" });
// })();