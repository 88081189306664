import React from 'react';
import classNames from 'classnames';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { getSchema } from './DataModels';
import { TextFieldExt, CheckboxExt, LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Form extends React.Component {

    constructor(props) {
        super();

        this.setRelatedData = this.setRelatedData.bind(this);

        this.state = {
            formData: props.formData,
            schema: getSchema(),
            mode: props.mode,
            ready: false
        };
    }

    componentDidMount() {
        this.setRelatedData();
        this.setMode();
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            if (!CommonHelper.AreEquals(this.props.relatedData, prevProps.relatedData)) {
                this.setRelatedData();
            }
        }
    }

    setRelatedData() {
        const { relatedData } = this.props;
        let { schema } = this.state;

        if (CommonHelper.IsArray(relatedData) && relatedData.length > 0) {
            relatedData.forEach(rd => {
                schema.fields = CommonHelper.MergeDeep(schema.fields, rd);
            });
        }

        this.setState({ schema: schema, ready: true });
    }

    setMode() {
        this.setState({ mode: this.props.mode });
    }

    onChange = (value, field, callback) => {
        let formData = { ...this.state.formData };
        let schema = { ...this.state.schema };
        let { form } = this.props;
        formData[field] = value;

        let _field = schema.fields[field];

        if (!CommonHelper.IsEmpty(_field.validators) && _field.validators.includes("serverValidation")) {
            if (_field.validators.length > 1) {
                _field.validators.splice(0, 1);
                _field.errorMessages.splice(0, 1);
            }
            else {
                delete _field.validators
                delete _field.errorMessages
            }
            form.isFormValid(false).then(valid => { });
        }

        this.setState({
            formData: formData,
            schema: schema
        });

        this.props.updateFormData(value, field, callback);
    }

    render() {
        const { serverErrorMessages, updatingData } = this.props;
        const { formData, schema, ready } = this.state;
        const { fields } = schema;

        if (!CommonHelper.IsEmpty(serverErrorMessages)) {
            serverErrorMessages.forEach((serverErrorMessage) => {
                let field = fields[serverErrorMessage.field];
                if (!CommonHelper.IsEmpty(field.validators) && field.validators.length > 0 && !field.validators.includes("serverValidation")) {
                    field.validators.splice(0, 0, "serverValidation");
                    field.errorMessages.splice(0, 0, serverErrorMessage.message);
                }
                else {
                    field.validators = ["serverValidation"];
                    field.errorMessages = [serverErrorMessage.message];
                }
            })
        }

        return (
            !ready ?
                <Container fluid>
                    <Row className="justify-content-center">
                        <LoaderExt />
                    </Row>
                </Container> :
                <Container fluid>
                    <Row>
                        <Col>
                            <TextFieldExt {...fields.title} onChange={this.onChange} value={formData.title} disabled={updatingData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextFieldExt {...fields.startDate} onChange={this.onChange} value={formData.startDate} disabled={updatingData} />
                        </Col>
                        <Col>
                            <TextFieldExt {...fields.endDate} onChange={this.onChange} value={formData.endDate} disabled={updatingData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CheckboxExt {...fields.enabled} onChange={this.onChange} value={formData.enabled} disabled={updatingData} />
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default Form; 