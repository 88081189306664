import { isValidElement, useEffect, useMemo } from 'react';
import inflection from 'inflection';
import { useCheckMinimumRequiredProps } from './checkMinimumRequiredProps';
import { CommonHelper } from 'components/Common/Helper/Helper';

const defaultSort = {
    field: 'id',
    order: "SORT_ASC",
};

const defaultData = {};

const useListController = (props) => {
    useCheckMinimumRequiredProps('List', ['basePath', 'resource'], props);
    const translate = (trans) => CommonHelper.GetTrans(trans);

    const {
        basePath,
        exporter = null,
        resource,
        hasCreate,
        filterDefaultValues,
        sort = defaultSort,
        perPage = 10,
        filter,
        debounce = 500,
    } = props;

    if (filter && isValidElement(filter)) {
        throw new Error(
            '<List> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.'
        );
    }

    // const [query, queryModifiers] = useListParams({
    //     resource,
    //     location,
    //     filterDefaultValues,
    //     sort,
    //     perPage,
    //     debounce,
    // });

    // const [selectedIds, selectionModifiers] = useRecordSelection(resource);

    /**
     * We want the list of ids to be always available for optimistic rendering,
     * and therefore we need a custom action (CRUD_GET_LIST) that will be used.
     */
    const { ids, total, error, loading, loaded, selectedIds, selectionModifiers, query, queryModifiers } = props;

    const data = defaultData;

    
    // When the user changes the page/sort/filter, this controller runs the
    // useGetList hook again. While the result of this new call is loading,
    // the ids and total are empty. To avoid rendering an empty list at that
    // moment, we override the ids and total with the latest loaded ones.
    const defaultIds = [];
    const defaultTotal = 0;

    // Since the total can be empty during the loading phase
    // We need to override that total with the latest loaded one
    // This way, the useEffect bellow won't reset the page to 1
    const finalTotal = typeof total === 'undefined' ? defaultTotal : total;
    const finalIds = typeof total === 'undefined' ? defaultIds : ids;

    const totalPages = useMemo(() => {
        return Math.ceil(finalTotal / query.perPage) || 1;
    }, [query.perPage, finalTotal]);

    useEffect(() => {
        if (
            query.page <= 0 ||
            (!loading && query.page > 1 && (finalIds || []).length === 0)
        ) {
            // Query for a page that doesn't exist, set page to 1
            queryModifiers.setPage(1);
        } else if (!loading && query.page > totalPages) {
            // Query for a page out of bounds, set page to the last existing page
            // It occurs when deleting the last element of the last page
            queryModifiers.setPage(totalPages);
        }
    }, [
        loading,
        query.page,
        finalIds,
        queryModifiers,
        total,
        totalPages,
        defaultIds,
    ]);

    const currentSort = useMemo(
        () => ({
            field: query.sort,
            order: query.order,
        }),
        [query.sort, query.order]
    );

    const resourceName = translate(`resources.${resource}.name`, {
        smart_count: 2,
        _: inflection.humanize(inflection.pluralize(resource)),
    });
    const defaultTitle = translate('ra.page.list', {
        name: resourceName,
    });

    return {
        basePath,
        currentSort,
        data,
        defaultTitle,
        displayedFilters: query.displayedFilters,
        error,
        exporter,
        filterValues: query.filterValues,
        hasCreate,
        hideFilter: queryModifiers.hideFilter,
        ids: finalIds,
        loaded: loaded || defaultIds.length > 0,
        loading,
        onSelect: selectionModifiers.select,
        onToggleItem: selectionModifiers.toggle,
        onUnselectItems: selectionModifiers.clearSelection,
        page: query.page,
        perPage: query.perPage,
        resource,
        selectedIds,
        setFilters: queryModifiers.setFilters,
        setPage: queryModifiers.setPage,
        setPerPage: queryModifiers.setPerPage,
        setSort: queryModifiers.setSort,
        showFilter: queryModifiers.showFilter,
        total: finalTotal,
    };
};

export default useListController;