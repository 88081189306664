/* eslint-disable no-unused-vars */

import React from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import { teamsTabStyles, ButtonTooltip, TeamsContext, TabNavBar } from "libs/teams-tab";

import { Users, Groups } from 'libs/microsoft-graph-service';

import { DatePicker, DayOfWeek, mergeStyleSets } from 'office-ui-fabric-react';
import {
  Flex, Segment, Text, Loader, Grid, cardsContainerBehavior, Divider,
  SyncIcon, CalendarIcon, UndoIcon,
} from '@fluentui/react-northstar'

import { EventCard } from './EventCard';
import { CreateDialog } from './CreateDialog';

const dpStyles = {
  root: { /* styles */ },
  textField: { /* styles */ },
  icon: { /* styles */ },
  callout:
  {
    selectors:
    {
      '& button.ms-DatePicker-goToday': {
        right: 14,
        textAlign: 'right',
      }
    }
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 0 0',
    maxWidth: '300px'
  },
});

const DayPickerStrings = {
  months: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ],

  shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],

  days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],

  shortDays: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],

  goToToday: 'Oggi',
  prevMonthAriaLabel: 'Mese precedente',
  nextMonthAriaLabel: 'Mese successivo',
  prevYearAriaLabel: 'Anno precedente',
  nextYearAriaLabel: 'Anno successivo',
  closeButtonAriaLabel: 'Chiudi',
  monthPickerHeaderAriaLabel: '{0}, seleziona per cambiare anno',
  yearPickerHeaderAriaLabel: '{0}, seleziona per cambiare mese',
};

let myContext = null;

let _startOfDay = new Date();
let _endOfDay = new Date();

class Events extends React.Component {
  constructor(props) {
    super();

    _startOfDay.setHours(0, 0, 0, 0);
    _endOfDay.setHours(23, 59, 59, 999);

    this.state = {
      loading: false,
      eventDetails: null,
      startOfDay: _startOfDay,
      endOfDay: _endOfDay
    };

  }

  componentDidMount() {
    console.log(_startOfDay, _endOfDay)
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  getEvents = () => {
    const { startOfDay, endOfDay } = this.state;
    const { msTeamsContext } = myContext;
    const { groupId, channelId } = msTeamsContext;

    this.setState({ loading: true }, async () => {
      _startOfDay = startOfDay;
      _endOfDay = endOfDay;
      
      let msTeamEvents = await Groups.GetCalendarView(groupId, startOfDay.toISOString(), endOfDay.toISOString(), false, 100).catch(e => {
        if (e.authError) { ToastHelper.Info(e.errorMessage); }
      });

      if (msTeamEvents !== undefined) {
        const eventDetails = Groups.FormatMsTeamEvents(msTeamEvents, channelId);
        const getMsTeamEvents = async () => { return Promise.all(eventDetails) }
        getMsTeamEvents().then(eventDetails => { this.setState({ eventDetails, loading: false }); })
      }
      else {
        this.setState({ eventDetails: [], loading: false });
      }
    });
  }

  setStartOfDay = (selectedDate) => {
    selectedDate.setHours(0, 0, 0, 0);
    this.setState({ startOfDay: selectedDate });
  }

  setEndOfDay = (selectedDate) => {
    selectedDate.setHours(23, 59, 59, 999);
    this.setState({ endOfDay: selectedDate });
  }

  resetFilter = () => {
    var startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    var endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);

    this.setState({ startOfDay, endOfDay });
  }

  render() {
    const { loading, eventDetails, startOfDay, endOfDay } = this.state;

    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;

          if (!eventDetails && myContext && myContext.msTeamsContext && !loading) {
            this.getEvents();
          }

          return !this.props.justToday ?
            <>
              <TabNavBar title="Elenco Eventi" showBack backPageName="Homepage" backPageRoute="" icon={<CalendarIcon />}>
                <Text size="large" content="Dal" align="center" />
                <DatePicker
                  className={controlClass.control}
                  styles={dpStyles}
                  strings={DayPickerStrings}
                  firstDayOfWeek={DayOfWeek.Monday}
                  onSelectDate={this.setStartOfDay}
                  showGoToToday
                  showCloseButton
                  placeholder="Seleziona una data..."
                  ariaLabel="Seleziona una data..."
                  formatDate={(date) => date.toLocaleDateString()}
                  value={startOfDay}
                />
                <Text size="large" content="al" align="center" />
                <DatePicker
                  className={controlClass.control}
                  styles={dpStyles}
                  strings={DayPickerStrings}
                  firstDayOfWeek={DayOfWeek.Monday}
                  onSelectDate={this.setEndOfDay}
                  showGoToToday
                  showCloseButton
                  placeholder="Seleziona una data..."
                  ariaLabel="Seleziona una data..."
                  formatDate={(date) => date.toLocaleDateString()}
                  value={endOfDay}
                />
                <ButtonTooltip icon={<UndoIcon />} secondary iconOnly content="" tooltip="Ripristina filtro" onClick={this.resetFilter} />
                <Divider vertical styles={{ height: '1.7rem' }} />
                <ButtonTooltip icon={<SyncIcon />} secondary iconOnly content="" tooltip="Aggiorna" onClick={this.getEvents} />
              </TabNavBar>
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      (
                        eventDetails && eventDetails.length > 0 ?
                          <Grid accessibility={cardsContainerBehavior} columns={4}>
                            {
                              eventDetails.map((eventDetail, key) => {
                                const nonZeroIndex = key + 1
                                return (<EventCard key={nonZeroIndex} index={nonZeroIndex} eventDetail={eventDetail} startOfDay={startOfDay} endOfDay={endOfDay} />
                                )
                              })
                            }
                          </Grid> :
                          <Text size="large" content="Nessun evento disponibile" align="center" />
                      )
                  }
                </Segment>
              </Flex.Item>
            </> :
            <>
              {
                !myContext || !myContext.msTeamsContext || loading ?
                  <Loader label="Caricamento..." /> :
                  (
                    eventDetails && eventDetails.length > 0 ?
                      <Grid accessibility={cardsContainerBehavior} columns={4}>
                        {
                          eventDetails.map((eventDetail, key) => {
                            const nonZeroIndex = key + 1
                            return (<EventCard key={nonZeroIndex} index={nonZeroIndex} eventDetail={eventDetail} startOfDay={startOfDay} endOfDay={endOfDay} />
                            )
                          })
                        }
                      </Grid> :
                      <Text size="large" content="Nessun evento disponibile" align="center" />
                  )
              }
            </>
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default Events;