
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastOptions = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    className: 'myToast',
};

const toastConfirmOptions = {
    position: "top-center",
    autoClose: false,
    closeOnClick: true,
    draggable: false,
    className: 'myToastConfirm',
};

class ToastHelper {
    static Info(msg) {
        toast.info(msg, toastOptions);
    }

    static Error(msg) {
        toast.error(msg, toastOptions);
    }

    static Success(msg) {
        toast.success(msg, toastOptions);
    }

    static Warn(msg) {
        toast.warn(msg, toastOptions);
    }

    static Confirm(msg) {
        toast(msg, toastConfirmOptions);
    }

    static Close() {
        toast.dismiss();
    }
}

export default ToastHelper;