/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SketchPicker } from 'react-color';
import { ValidatorComponent } from 'react-form-validator-core';

const defaultColor = { r: '33', g: '150', b: '243', a: '1' };
const presetColors = [
    { color: '#002851', title: 'Livion Blue' },
    { color: '#00bdbd', title: 'Livion Lightblue' },
    { color: '#ffffff', title: 'Livion White' },
    { color: '#172b4d', title: 'Default' },
    { color: '#2196f3', title: 'Primary' },
    { color: '#f7fafc', title: 'Secondary' },
    { color: '#2dce89', title: 'Success' },
    { color: '#11cdef', title: 'Info' },
    { color: '#fb6340', title: 'Warning' },
    { color: '#f5365c', title: 'Danger' },
    { color: '#3b5999', title: 'Facebook' },
    { color: '#1da1f2', title: 'Twitter' },
    { color: '#e4405f', title: 'Instagram' },
    { color: '#3aaf85', title: 'Slack' },
    { color: '#222222', title: 'Github' }
];

class ColorPickerExt extends ValidatorComponent {

    constructor(props) {
        super();
        
        this.state = {
            displayColorPicker: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { name, triggers, onChange } = this.props;

        if (!CommonHelper.IsEmpty(triggers) && !CommonHelper.IsEmpty(triggers[name]) && !CommonHelper.AreEquals(triggers[name], prevProps.value))
            onChange(triggers[name], name);
    }

    togglePicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    closePicker = () => {
        this.setState({ displayColorPicker: false })
    };

    onHandleChange = (color, event) => {
        let value = color.rgb;

        if (CommonHelper.IsFunction(this.props.onChange))
            this.props.onChange(value, this.props.name, !CommonHelper.IsEmpty(this.props.callback) ? this.props.callback(value) : undefined);

        if (!CommonHelper.IsEmpty(this.props.handle) && this.props.handle.length > 0) {
            this.props.handle.map(h => this.props.onTrigger(value, h.field));
        }
    }

    render() {
        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value,
            ...rest
        } = this.props;
        let customValue = value;

        if (CommonHelper.IsEmpty(customValue))
            customValue = defaultColor;
        else
            customValue = !CommonHelper.IsObject(customValue) ? JSON.parse(customValue) : customValue;
        
        const styles = {
            container: {
                height: '50%',
                maxHeight: '30px'
            },
            color: {
                width: '36px',
                height: '100%',
                borderRadius: '2px',
                background: `rgba(${customValue.r}, ${customValue.g}, ${customValue.b}, ${customValue.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                height: '100%',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        };

        return (
            <div style={styles.container}>
                <div style={styles.swatch} onClick={this.togglePicker}>
                    <div style={styles.color} />
                </div>
                {
                    this.state.displayColorPicker &&
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.closePicker} />
                        <SketchPicker
                            color={customValue}
                            onChange={this.onHandleChange}
                            presetColors={presetColors}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default withStyles(styles)(ColorPickerExt);