import React, { useContext, useEffect, useState } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import moment from 'moment';

import { TeamsContext, teamsTabStyles, WorkInProgress } from "libs/teams-tab";

import {
    Flex, List, Loader, Button, SplitButton, Text, Card, cardChildrenFocusableBehavior, Status,
    QuestionCircleIcon, PlayIcon, BanIcon, AttendeeIcon, TenantPersonalIcon, MeetingTimeIcon,
    AcceptIcon, CallIcon, CallEndIcon, ExcelIcon, TrashCanIcon, EyeIcon, ApprovalsAppbarIcon, 
    CallMissedLineIcon, CallRecordingIcon,
} from '@fluentui/react-northstar'

export const AnswersTab = ({ index, eventDetail, startOfDay, endOfDay, teamId, TeamChannelId, ...unhandledProps }) => {
    const teamsContext = useContext(TeamsContext)
    
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [eventStatus, setEventStatus] = useStateWithCallbackLazy(null);

    // console.info("RUNNING", event, runningCalls, eventStatus)
    // console.info("COMPLETED", event, completedCalls, eventStatus)

    const refreshStatus = async (id) => {
        if (id) {
            // let result = await teamsContext.dataProviders.bot.getOne('status', { id: callId });
            // if (result && result.data) { setEventStatus(result.data) }
        }
    }

    useEffect(() => {
        async function doAsync() {
            //await getOrganizer();
        }
        doAsync()
    }, []);

    return (
        <div style={teamsTabStyles.cardContainer}>
            <WorkInProgress />
        </div>
    )
}