import { createContext } from 'react';

const TeamsContext = createContext({ 
    authProvider: null, 
    dataProvider: null, 
    botDataProvider: null,
    msTeamsContext: null,
    setState: () => { } }
);

TeamsContext.displayName = 'TeamsContext';

export default TeamsContext;