import * as React from 'react';
import TeamsContext from './TeamsContext';

const TeamsContextProvider = ({ value, children }) => (
    <TeamsContext.Provider value={value}>
        {children}
    </TeamsContext.Provider>
);

export default TeamsContextProvider;
