import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

const red500 = red['500'];

export const styles = theme => ({
  cssOutlinedInput: {
    backgroundColor: grey[50],
    "& $notchedOutline": {
      borderColor: grey['A700'],
    },
  },
  notchedOutline: {},
  dropdownStyle:
  {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: 'lightgrey',
  },
  input: {
    display: 'none',
  },
  img: {
    marginLeft: '4rem',
    maxHeight: '2rem',
  },
  filepreview: {
    marginRight: '4rem',
    marginLeft: '2rem',
    maxHeight: '3rem',
    cursor: 'pointer'
  },
  autocompleteRoot: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  inputLabel: {
    backgroundColor: "#ffffff"
  },
  divider: {
    height: 16,
  },
  progress: {
    margin: 16,
  },
  errorMessageFileUpload: {
    color: '#f44336',
    margin: '8px 12px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    minHeight: '1em',
    lineHeight: '1em',
  },
  errorMessage: {
    fontSize: '0.75rem',
    color: red500,
    position: 'absolute',
    margin: '28px 0px 0px 15px',
    fontFamily: 'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif'
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    minWidth: 'auto !important',
  },
  iconsGrid: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  iconsItem: {
    textAlign: 'center',
    width: '20%',
    flexGrow: 1,
    position: 'relative',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  iconsItemCaption: {
    textTransform: 'uppercase',
    fontSize: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    zIndex: 2,
    maxWidth: 100
  },
  iconsItemIcon: {
    color: 'rgb(117, 117, 117)',
    fontSize: 28,
    width: 28,
    height: 28
  },
  checkBoxRootNoInlineLabel: {
    marginLeft: 0,
    marginRight: 0
  },
});
