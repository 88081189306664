/*eslint-disable*/
import React from "react";
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import Avatar from 'react-avatar';

import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { AdminDashboardContext } from "libs/admin-dashboard";
import { ListItemExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

const styles = theme => ({
  brandLogo: {
    position: 'absolute',
    top: '0.5rem',
    left: '50%',
    transform: 'translate(-50%, 0px)',
  },
  listRoot: {
    marginLeft: '-1.5rem',
    marginRight: '-1.5rem',
    backgroundColor: theme.palette.background.paper
  },
  listItemNested: {
    paddingLeft: `${theme.spacing(5)}px !important`,
  },
});

class Sidebar extends React.Component {

  state = {
    collapseMobileOpen: false,
    collapseDesktopOpen: true,
    open: false
  };

  constructor(props) {
    super();
    this.activeRoute.bind(this);
  }

  logout = e => {
    let homepageUrl = `/`;
    e.preventDefault();
    CommonHelper.LogOff();
    this.props.history.push(homepageUrl);
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  // toggles collapse between opened and closed (true/false)
  toggleMobileCollapse = () => {
    this.setState({
      collapseMobileOpen: !this.state.collapseMobileOpen
    });
  };

  // closes the collapse
  closeMobileCollapse = () => {
    this.setState({
      collapseMobileOpen: false
    });
  };

  // toggles collapse between opened and closed (true/false)
  toggleDesktopCollapse = () => {
    this.setState({
      collapseDesktopOpen: !this.state.collapseDesktopOpen
    });
  };

  // closes the collapse
  closeDestopCollapse = () => {
    this.setState({
      collapseDesktopOpen: false
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    const that = this;
    const { classes } = this.props;
    const { collapseDesktopOpen } = this.state;

    let userRole = CommonHelper.GetUserRole();
    let groupedRoutes = CommonHelper.GroupBy(routes.filter(r => r.inSidebar && (r.roles.includes(userRole) || r.roles.includes('*'))), 'parentFolder');

    return Object.keys(groupedRoutes).map(function (groupName, index) {
      if (groupName === 'undefined'){
        return groupedRoutes[groupName].map(({ layout, path, icon, name }, key) => {
          let routeName = layout + path;
          let active = that.activeRoute(routeName);
          return (
            <ListItemExt key={`main_${key}`} link={routeName} active={active} name={name} icon={icon} collapseDesktopOpen={collapseDesktopOpen} closeMobileCollapse={that.closeMobileCollapse} />
          )
        })
      }
      else {
        let containActive = groupedRoutes[groupName].filter(gr => that.activeRoute(gr.layout + gr.path)).length > 0;
        return (
          <ListItemExt key={groupName.toLowerCase()} active={containActive} name={groupName} collapseDesktopOpen={collapseDesktopOpen}>
            {
              groupedRoutes[groupName].map(({ layout, path, icon, name }, key) => {
                let routeName = layout + path;
                let active = that.activeRoute(routeName);
                return (
                  <ListItemExt key={`${groupName.toLowerCase()}_${key}`} link={routeName} active={active} className={classes.listItemNested} name={name} icon={icon} collapseDesktopOpen={collapseDesktopOpen} closeMobileCollapse={that.closeMobileCollapse} />
                )
              })
            }
          </ListItemExt>
        );
      }
    });
  };

  handleClick = () => {
    this.setState(prevState => ({ open: prevState.open }));
  };

  render() {
    const { collapseDesktopOpen, collapseMobileOpen, open } = this.state;
    const { routes, logo, classes } = this.props;
    let navbarBrandProps;

    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        //target: "_blank"
      };
    }

    return (
      <AdminDashboardContext.Consumer>
        {context => {
          return (
            <Navbar
              className={classNames("navbar-vertical navbar-light bg-white", collapseDesktopOpen ? 'fixed-left' : 'fixed-left-collapsed')}
              expand="md"
              id="sidenav-main"
            >
              <Container fluid>
                {/* Toggler */}
                <button className="navbar-toggler" type="button" onClick={this.toggleMobileCollapse}>
                  <span className="navbar-toggler-icon" />
                </button>
                {
                  window.innerWidth > 768 &&
                  <div className="d-flex justify-content-end">
                    <Tooltip title={collapseDesktopOpen ? 'Close' : 'Open'}>
                      <i className="fas fa-bars cursor-pointer" onClick={this.toggleDesktopCollapse}></i>
                    </Tooltip>
                  </div>
                }
                {
                  logo && collapseDesktopOpen && (
                    <NavbarBrand className='p-0' style={styles.brandLogo} {...navbarBrandProps}>
                      <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
                      {/* <h4 className="text-livion-gray title-small m-0" style={{ fontSize: '0.8rem' }}>CWS MEETINGS</h4> */}
                    </NavbarBrand>
                  )
                }
                <Nav className="align-items-center d-md-none">
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm">
                          <Avatar round={true} size="36" name={`${CommonHelper.GetFullName()}`} />
                        </span>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow m-0">Welcome!</h6>
                      </DropdownItem>
                      <DropdownItem to="/" tag={Link}>
                        <i className="fas fa-home" />
                        <span>HomePage</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => this.logout(e, context)}>
                        <i className="fas fa-sign-out-alt" />
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                {/* Collapse */}
                <Collapse navbar isOpen={collapseMobileOpen}>
                  {/* Collapse header */}
                  <div className="navbar-collapse-header d-md-none">
                    <Row>
                      <Col className="collapse-brand" xs="8">
                        {
                          logo && (
                            <Link to="/admin">
                              <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
                              {/* <h4 className="text-livion-gray title-small m-0">CWS Meetings</h4> */}
                            </Link>
                          )
                        }
                      </Col>
                      <Col className="collapse-close" xs="4">
                        <button
                          className="navbar-toggler"
                          type="button"
                          onClick={this.toggleMobileCollapse}
                        >
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  {/* Navigation */}

                  <List component="nav" className={classNames(classes.listRoot, 'navbar-nav')}>
                    {this.createLinks(routes)}
                  </List>

                  {/* Divider */}
                  <hr className="my-3" />
                  {/* Help */}
                  {
                    collapseDesktopOpen ?
                      <>
                        <h6 className="navbar-heading text-muted">HELP</h6>
                        <h6 className="navbar-heading text-muted helpMail">In caso di necessità, scrivere all’indirizzo: <a href='mailto:supporto-api@cws.it'>supporto-api@cws.it</a><br />Grazie</h6>
                      </> :
                      <Tooltip title='supporto-api@cws.it'>
                        <a href='mailto:supporto-api@cws.it'>
                          <i className="far fa-life-ring text-livion-blue"></i>
                        </a>
                      </Tooltip>
                  }
                </Collapse>
              </Container>
            </Navbar>
          )
        }}
      </AdminDashboardContext.Consumer>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  }),
  logoDealer: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgData: PropTypes.string,
  })
};

export default withStyles(styles)(withRouter(Sidebar)); 
