/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter } from 'react-router';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import classNames from 'classnames';

import { Flex, Segment, Text, Loader, CallPstnIcon, } from '@fluentui/react-northstar'

import { TeamsContext, teamsTabStyles, TabNavBar } from "libs/teams-tab";

import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { QuestionsTab } from './QuestionsTab';
import { AnswersTab } from './AnswersTab';

let myContext = null;

class SurveyDetails extends React.Component {
  constructor(props) {
    super();

    this.state = {
      loading: true,
      error: null,
      id: null,
      data: null,
    };
  }

  componentDidMount() {
    let id = null;

    if (this.props.match.params && this.props.match.params.id) {
      id = this.props.match.params.id;
    }

    this.getSurvey(id);
  }

  componentDidUpdate(prevProps) {
    if (!CommonHelper.AreEquals(this.props, prevProps)) {
    }
  }

  componentWillMount() {
  }

  getSurvey = async (id) => {
    let error = null;
    if (id) {
      const filter = { id };
      const expand = `surveyquestions($expand=surveyanswers),callsurveyactivities($expand=surveyteammemberanswers) `;
      let result = await myContext.dataProviders.api.getList('surveys', { filter, expand });
      if (result && result.data.length > 0) {
        this.setState({ loading: false, id, data: result.data[0] });
        return;
      }
      else {
        error = `Impossibile recuperare il sondaggio con id '${id}'`;
      }
    }
    else {
      error = 'Id del sondaggio mancante';
    }

    ToastHelper.Error(error);
    myContext.navigate('surveys');
  }

  renderQuestionsTab = () => {
    const { data, id } = this.state;

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100">
          <QuestionsTab id={id} survey={data} />
        </Col>
      </Row>
    )
  }

  renderAnswersTab = () => {
    const { data, id } = this.state;

    return (
      <Row className="h-100">
        <Col xs="12" className="h-100 d-flex justify-content-center align-items-center">
          <AnswersTab id={id} survey={data} />
        </Col>
      </Row>
    )
  }

  buildSurveyTabs = () => {
    return [
      { title: CommonHelper.GetTrans("questions.tabs.questions"), content: this.renderQuestionsTab() },
      { title: CommonHelper.GetTrans("questions.tabs.answers"), content: this.renderAnswersTab() }
    ]
  }

  renderSurveyTabs = () => {
    return <EnhancedTabs tabs={this.buildSurveyTabs()} activeTab={0} />
  }

  render() {
    const { loading, data } = this.state;
    
    return (
      <TeamsContext.Consumer>
        {context => {
          myContext = context;
          
          return (
            <>
              <TabNavBar
                title={<><Text content="Sondaggio" /> {data && <Text content={data.title} weight="bold" size="large" />}</>}
                showBack
                backPageName="Sondaggi"
                backPageRoute="surveys"
                icon={<CallPstnIcon size="large" />}
              />
              <Flex.Item>
                <Segment styles={teamsTabStyles.content}>
                  {
                    !myContext || !myContext.msTeamsContext || loading ?
                      <Loader label="Caricamento..." /> :
                      <Container fluid>
                        <Row>
                          <Col className="p-0">
                            {this.renderSurveyTabs()}
                          </Col>
                        </Row>
                      </Container>
                  }
                </Segment>
              </Flex.Item>
            </>
          )
        }}
      </TeamsContext.Consumer>
    )
  }
}

export default withRouter(SurveyDetails);