import React, { useContext, useEffect, useState } from "react";
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { Users, Groups, teamsRolesEnum } from 'libs/microsoft-graph-service';
import { TeamsContext,  ButtonTooltip, rolesEnum } from "libs/teams-tab";

import {
    Button, Dialog, Loader, Text, List, Input, Dropdown, AddIcon,
} from '@fluentui/react-northstar'

import { mergeStyleSets } from 'office-ui-fabric-react';
import { convertToObject } from "typescript";

export const CreateDialog = ({ reload, member, ...props }) => {
    const teamsContext = useContext(TeamsContext);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useStateWithCallbackLazy(false);
    const [errors, setErrors] = useState({})
    const [isEdit, setIsEdit] = useState(!CommonHelper.IsEmpty(member))
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        type: 'Student',
    });

    const { msTeamsContext, teamChannel } = teamsContext;
    const { groupId, channelId } = msTeamsContext;

    let dialogHeader = null;
    let dialogContent = null;
    
    useEffect(() => {
        if (!CommonHelper.IsEmpty(member)) {
            var role = 'Student';
            var memberRoles = member.msteamMemberMsteamChannels.filter(tm => tm !== null && tm.teamChannelId === teamChannel.id);
            if (memberRoles.length > 0) {
                role = memberRoles.sort(CommonHelper.CompareValues('type')).map(memberRole => memberRole.type)[0];

                if (role === rolesEnum.Guest || role === rolesEnum.Member) {
                    role = 'Student';
                }
            }

            const _formData = {
                firstname: member.givenName,
                lastname: member.surname,
                email: member.mail,
                type: CommonHelper.Capitalize(role),
            }

            setFormData(_formData);
            setIsEdit(true);
        }
    }, [member])

    const onCancel = () => {
        setOpen(false);
    }

    const onChange = (e, { name, value }) => {
        if (Object.keys(errors).length > 0) { setErrors({}); }
        setFormData(_formData => ({ ..._formData, [name]: value }));
    }

    const validateForm = () => {
        let _errors = {};
        for (let fieldName in formData)
            if (CommonHelper.IsEmpty(formData[fieldName]))
                _errors[fieldName] = true;
        setErrors(_errors);
        return Object.keys(_errors).length === 0;
    }

    const onConfirm = async () => {
        if (!validateForm()) {
            ToastHelper.Error("Modulo non valido");
        }
        else {
            setLoading(true, async () => {
                let success = false;
                let user = null;
                let error = null;

                let password = CommonHelper.GetEnv("DEFAULT_USER_PASSWORD", "CwsM33t1ng5!");
                let userExist = await Users.Check(formData.email).catch(err => { return { err } });

                if (userExist === null || !userExist.err) {
                    let tempUser = userExist;
                    if (tempUser) {
                        tempUser = await Users.Update(tempUser.id, formData.firstname, formData.lastname, formData.email).catch(err => err);
                    }
                    else {
                        const nickName = `${formData.firstname.replace(" ", "")}${formData.lastname.replace(" ", "").charAt(0)}`;
                        tempUser = await Users.Create(formData.firstname, formData.lastname, nickName, formData.email, password).catch(err => err);
                    }

                    if (tempUser) {
                        let currentUser = {};
                        let isGuest = tempUser.userType === "Guest";
                        let teamsRole = null;
                        let customRole = null;

                        switch (formData.type.toLowerCase()) {
                            case "student":
                            default:
                                teamsRole = isGuest ? teamsRolesEnum.Guest : teamsRolesEnum.Member;
                                customRole = isGuest ? rolesEnum.Guest : rolesEnum.Member;
                                break;
                            case "teacher":
                                teamsRole = isGuest ? teamsRolesEnum.Guest : teamsRolesEnum.Member;
                                customRole = rolesEnum.Teacher;
                                break;
                            case "organizer":
                                teamsRole = teamsRolesEnum.Owner;
                                customRole = rolesEnum.Organizer;
                                break;
                        }

                        currentUser.id = tempUser.id;
                        currentUser.displayName = tempUser.displayName;
                        currentUser.mail = tempUser.mail || tempUser.userPrincipalName;
                        currentUser.userPrincipalName = tempUser.userPrincipalName;
                        currentUser.teamsRole = teamsRole;
                        currentUser.role = customRole;

                        user = currentUser;

                        let hasGroup = await Users.HasGroup(currentUser.id, groupId).catch(err => err);

                        if (!hasGroup) {
                            let addMemberInTeam = await Groups.AddMember(groupId, currentUser.id, currentUser.teamsRole).catch(err => err);
                            if (addMemberInTeam) {
                                hasGroup = true;
                            }
                        }
                        
                        if (hasGroup) {
                            if (currentUser.role === rolesEnum.Teacher) {
                                let teachersGroupName = CommonHelper.GetEnv("DEFAULT_TEACHERS_GROUP_NAME", "Teachers");
                                if (!CommonHelper.IsEmpty(teachersGroupName)) {
                                    let teachersGroup = await Groups.GetByName(teachersGroupName).catch(err => err);
                                    if (teachersGroup && CommonHelper.IsArray(teachersGroup) && teachersGroup.length > 0) {
                                        let teacherGroup = teachersGroup[0];
                                        let hasTeacherGroup = await Users.HasGroup(currentUser.id, teacherGroup.id).catch(err => err);
                                        if (!hasTeacherGroup) {
                                            let addMemberInTeam = await Groups.AddMember(teacherGroup.id, currentUser.id, teamsRolesEnum.Member).catch(err => err);
                                            if (!addMemberInTeam) {
                                                ToastHelper.Error(`Utente non aggiunto al gruppo "${teachersGroupName}". Procedere manualmente`);
                                            }
                                        }
                                    }
                                }
                            }

                            let data = {
                                id: currentUser.id,
                                displayName: `${formData.firstname} ${formData.lastname}`,
                                givenName: formData.firstname,
                                surname: formData.lastname,
                                mail: formData.email,
                                userPrincipalName: currentUser.userPrincipalName,
                                msteamMemberMsteamChannels: [{ teamChannelId: teamChannel.id, teamMemberId: currentUser.id, type: currentUser.role }]
                            }

                            let result = await teamsContext.dataProviders.api.create('msteammembers/createorupdate', { data, addMsToken: true, teamChannelId: teamChannel.id }).catch(e => e);

                            if (result && result.data) { success = true; }
                            else { error = 'Utente non aggiunto nel Database'; }
                        }
                        else { error = 'Utente non aggiunto nel Team'; }
                    }
                    else { error = 'Utente non creato/aggiunto'; }
                }
                else { error = 'Errore durante la verifica dell\'utente'; }

                if (success) {
                    ToastHelper.Success(`${user.displayName} (${user.mail})`);
                    reload();
                }
                else {
                    ToastHelper.Error(error);
                }
                setLoading(false, () => {
                    setOpen(!success);
                });
            });
        }
    }

    dialogHeader = (
        <Text content={isEdit ? "Modifica utente" : "Crea un nuovo utente"} />
    );

    let items = [
        <Input
            key="firstname"
            name="firstname"
            label="Nome"
            defaultValue={formData.firstname}
            onChange={onChange}
            required
            error={errors['firstname']}
            clearable
            fluid
            showSuccessIndicator={false}
        />,
        <Input
            key="lastname"
            name="lastname"
            label="Cognome"
            defaultValue={formData.lastname}
            onChange={onChange}
            required
            error={errors['lastname']}
            clearable
            fluid
            showSuccessIndicator={false}
        />,
        <Input
            key="email"
            name="email"
            label="Email"
            type="email"
            defaultValue={formData.email}
            onChange={onChange}
            required
            error={errors['email']}
            clearable
            fluid
            showSuccessIndicator={false}
        />,
        <Dropdown
            key="type"
            name="type"
            label="type"
            items={['Student', 'Teacher', 'Organizer']}
            defaultValue={formData.type}
            onChange={onChange}
            required
            error={errors['type']}
            clearable
            fluid
        />,
    ];

    dialogContent = (
        loading ?
            <Loader label={isEdit ? "Modifica utente in corso..." : "Creazione utente in corso..."} /> :
            <List items={items} />
    );

    let triggerButton = isEdit ?
        <Button text content="Modifica" primary /> :
        <ButtonTooltip icon={<AddIcon />} secondary iconOnly content="" tooltip={"Crea"} />;

    return (
        <Dialog
            cancelButton="Chiudi"
            confirmButton="Conferma"
            onCancel={!loading ? onCancel : null}
            onConfirm={!loading ? onConfirm : null}
            onOpen={() => setOpen(true)}
            open={open}
            closeOnOutsideClick={false}
            content={dialogContent}
            header={dialogHeader}
            styles={{ minHeight: 400 }}
            trigger={triggerButton}
        />
    )
}