import React, { useContext, useEffect, useState } from "react";
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'reactstrap-button-loader';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { SelectExt, TextFieldExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';
import { Container, Row, Col } from "reactstrap";

import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';

import { makeStyles } from '@material-ui/core/styles';

import { AdminDashboardContext } from "libs/admin-dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const roles = [
    { value: 'Student', label: 'Student' },
    { value: 'Teacher', label: 'Teacher' },
    { value: 'Guest', label: 'Guest' },
];

const statusList = [
    //{ value: 'Invited', label: 'Invited' },
    { value: 'Connected', label: 'Connected' },
    { value: 'Disconnected', label: 'Disconnected' },
    //{ value: 'Ended', label: 'Ended' },
    //{ value: 'EndedManually', label: 'EndedManually' },
]

const hours = [];
const minutes = [];

for (var i = 0; i < 24; i++) {
    hours.push({ value: i, label: i })
}

for (var i = 0; i < 60; i++) {
    minutes.push({ value: i, label: i })
}

export default function AddParticipant({ callId, callInfo, getReport, ...props }) {
    const classes = useStyles();
    const context = useContext(AdminDashboardContext)
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [devices, setDevices] = useState([]);
    const [data, setData] = useState({
        callId: callId,
        userId: '',
        displayName: '',
        email: '',
        role: 'Guest',
        status: 'Connected',
        device: '',
        hours: 9,
        minutes: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            let result = await context.dataProvider.getList(`getMembersByCallId/${callId}`);
            if (result && result.data) {
                const options = result.data.map(j => ({
                    value: j.userId,
                    label: `${j.displayName}${(CommonHelper.IsEmpty(j.email) ? '' : `(${j.email})`)}`,
                    data: j
                })).sort(CommonHelper.CompareValues("label"));
                setUsers(options);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (data.userId) {
                let result = await context.dataProvider.getList(`getDevicesByMember/${callId}/${data.userId}`);
                if (result && result.data) {
                    const options = result.data.map(j => ({
                        value: j,
                        label: j
                    })).sort(CommonHelper.CompareValues("label"));
                    setDevices(options);
                }
            }
            else {
                setDevices([]);
            }
        }
        fetchData();
    }, [data.userId])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onHandleChange = (value, field) => {
        const tempData = { ...data };
        tempData[field] = value;
        setData(tempData);
    }

    const getRandomSeconds = () => {
        const min = Math.ceil(1);
        const max = Math.floor(59);
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return `${number}`.padStart(2, '0');
    }

    const getRandomMilliseconds = () => {
        const min = Math.ceil(1);
        const max = Math.floor(9999999);
        const number = Math.floor(Math.random() * (max - min + 1)) + min;
        return `${number}`.padStart(7, '0');
    }

    const handleSubmit = async () => {
        var eventStartTimeMoment = moment(callInfo.eventStartTime);
        var eventDate = eventStartTimeMoment.format("YYYY-MM-DD");

        let requestData = {
            callId: data.callId,
            role: data.role,
            status: data.status,
            device: data.device,
            sessionType: 'Audio',
            creationDate: `${eventDate} ${`${data.hours}`.padStart(2, '0')}:${`${data.minutes}`.padStart(2, '0')}:${getRandomSeconds()}.${getRandomMilliseconds()}`
        };

        if (CommonHelper.IsEmpty(data.userId)) {
            requestData.displayName = data.displayName;
            requestData.email = data.email;
        }
        else {
            let user = users.filter(x => x !== null && x.value === data.userId)[0];
            requestData.userId = data.userId;
            requestData.displayName = user.data.displayName;
            requestData.email = user.data.email;
        }

        let result = await context.dataProvider.create(`CallParticipantsTrackings`, { data: requestData });

        if (result && result.data) {
            ToastHelper.Success("Aggiunto");
            // setOpen(false);
            // getReport();
        }
        else {
            ToastHelper.Error("Errore");
        }
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Add Participant
                </Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
                    <DialogContent>
                        <Container className="py-2 px-4 mw-100">
                            <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                                <Row className="no-gutters">
                                    <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        <SelectExt name="userId" label="User" options={users} onChange={onHandleChange} value={data.userId} />
                                    </Col>
                                    {
                                        CommonHelper.IsEmpty(data.userId) &&
                                        <>
                                            <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                                <TextFieldExt name="displayName" label="Display Name" required onChange={onHandleChange} value={data.displayName} />
                                            </Col>
                                            <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                                <TextFieldExt name="email" label="Email" type="email" required onChange={onHandleChange} value={data.email} />
                                            </Col>
                                        </>
                                    }
                                    <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        {
                                            devices.length > 0 ?
                                                <SelectExt name="device" label="Device" options={devices} required onChange={onHandleChange} value={data.device} /> :
                                                <TextFieldExt name="device" label="Device" required onChange={onHandleChange} value={data.device} />
                                        }
                                    </Col>
                                    <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        <SelectExt name="role" label="Role" options={roles} required onChange={onHandleChange} value={data.role} />
                                    </Col>
                                    <Col xs="12" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        <SelectExt name="status" label="Status" options={statusList} required onChange={onHandleChange} value={data.status} />
                                    </Col>
                                    <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        <SelectExt name="hours" label="Hours" options={hours} required onChange={onHandleChange} value={data.hours} />
                                    </Col>
                                    <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                        <SelectExt name="minutes" label="Minutes" options={minutes} required onChange={onHandleChange} value={data.minutes} />
                                    </Col>
                                </Row>
                            </ValidatorForm>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{CommonHelper.GetTrans("buttons.cancel")}</Button>
                        <Button className={classes.button} variant="contained" color="success" onClick={handleSubmit}>{CommonHelper.GetTrans("buttons.save")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </form>
    );
}
