import { useContext } from 'react';

import ListContext from './ListContext';

const useListContext = (props) => {
    const context = useContext(ListContext);
    if (!context.resource) {
        return props;
    }
    return context;
};

export default useListContext;
