import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";

import { AdminLayout, PublicLayout, TeamsTabLayout } from "layouts";

import { CommonHelper } from 'components/Common/Helper/Helper';

import { MuiThemeProvider } from '@material-ui/core/styles';
import MyTheme from './MyTheme';

import { AdminDashboard } from "libs/admin-dashboard";
import authProvider, { UNAUTHORIZED, AUTHORIZED, ELAPSED } from "authProvider";
import dataProvider from "dataProvider";

class App extends Component {

    constructor(props) {
        super();

        this.state = {
            width: window.innerWidth
        };

        this.myDataProvider = dataProvider(CommonHelper.GetApiDomain(), props.history);
    }

    checkIfIsLogged = async () => {
        const { pathname } = this.props.location;

        if (!pathname.includes("/teamstab")) {
            let authorized = false;
            let redirectTo = null;
            
            let authResponse = await authProvider.checkAuth().then((response) => response).catch((response) => response);

            switch (authResponse.status) {
                case AUTHORIZED:
                    authorized = true;
                    break;
                case UNAUTHORIZED:
                    authorized = false;
                    redirectTo = authResponse.redirectTo;
                    break;
                case ELAPSED:
                    let refreshTokenResponse = await authProvider.refreshToken().then((response) => response).catch((response) => response);
                    authorized = refreshTokenResponse.status === AUTHORIZED;
                    redirectTo = refreshTokenResponse.redirectTo;
                    break;
                default:
                    break;
            }

            if (authorized) {
                if (['/login', '/register', '/'].includes(pathname)) {
                    let adminDefaultPage = '/admin/msteamsimporter';
                    let userRole = CommonHelper.GetUserRole();

                    if (['User', 'Organizer', 'Teacher'].includes(userRole)) {
                        adminDefaultPage = '/admin/reports/call';
                    }

                    this.props.history.push(adminDefaultPage);
                }
            }
            else {
                if ((pathname.includes("/admin") || pathname !== redirectTo) && !CommonHelper.IsEmpty(redirectTo)) {
                    this.props.history.push(redirectTo);
                }
            }
        }
    }

    componentDidMount() {
        this.checkIfIsLogged();
    }

    render() {
        this.checkIfIsLogged();

        let adminDefaultPage = '/admin/msteamsimporter';
        let userRole = CommonHelper.GetUserRole();

        if (['User', 'Organizer', 'Teacher'].includes(userRole)) {
            adminDefaultPage = '/admin/reports/call';
        }

        return (
            <MuiThemeProvider theme={MyTheme}>
                <AdminDashboard history={this.props.history} authProvider={authProvider} dataProvider={this.myDataProvider}>
                    <Switch>
                        <Route exact path="/admin" render={() => (<Redirect to={adminDefaultPage} />)} />
                        <Route path="/admin" render={props => <AdminLayout {...props} layout="/admin" />} />
                        <Route path="/teamstab" render={props => <TeamsTabLayout {...props} layout="/teamstab" />} />
                        <Route path="/" render={props => <PublicLayout {...props} layout="/public" />} />
                    </Switch>
                </AdminDashboard>
            </MuiThemeProvider>
        );
    }
}

export default App;