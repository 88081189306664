import React from 'react';
import { CommonHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { SelectExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';

import { AdminDashboardContext } from "libs/admin-dashboard";
import EnhancedTable from 'components/Common/EnhancedTable/EnhancedTable';

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Button from 'reactstrap-button-loader';

import { Groups } from 'libs/microsoft-graph-service';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({});

const baseApi = 'msteammembers';
const orderTableBy = "displayName";

class ImportMSTeamMembers extends React.Component {
    static contextType = AdminDashboardContext;

    constructor(props) {
        super();

        this.state = {
            data: null,
            loading: true,
            relatedData: {
                teamsOptions: [],
                teamChannelsOptions: []
            },
            request: {
                teamId: undefined,
                teamChannelId: undefined,
            },
            importedData: null,
            updatingData: false,
            msDataPage: 0
        };
    }

    componentDidMount() {
        this.getTeams();
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
        }
    }

    onHandleChange = (value, field) => {
        let { request } = this.state;

        request[field] = value;

        this.setState({ request })
        
        switch (field) {
            case 'teamId':
                this.getTeamChannels();
                break;
            case 'teamChannelId':
                this.getTeamMembers();
                break;
        }
    }

    isLoaded = () => {
        if (this.state.loading)
            setTimeout(() => { this.setState({ loading: false }) }, 500);
    }

    isLoading = () => {
        if (this.state.loading === false)
            this.setState({ loading: true });
    }

    getTeams = async () => {
        let result = await this.context.dataProvider.getList('msteams');

        if (result && result.data) {
            let options = result.data.map(j => ({ value: j.id, label: j.displayName }));
            options = options.sort(CommonHelper.CompareValues("label"));

            this.setState({ relatedData: { teamsOptions: options } });
        }
        else {
            if (result.error !== null) ToastHelper.Error(result.error);
        }
    }

    getTeamChannels = async () => {
        let { relatedData, request } = this.state;
        let { teamId } = request;

        relatedData.teamChannelsOptions = [];
        request.teamChannelId = undefined;

        this.setState({ relatedData: relatedData, data: null, request: request }, async () => {
            if (CommonHelper.IsEmpty(teamId)) {
                return;
            }

            let result = await this.context.dataProvider.getList(`msteamchannelsbyteam/${teamId}`);

            if (result && result.data) {
                let options = result.data.map(j => ({ value: j.id, label: j.displayName, data: j }));
                options = options.sort(CommonHelper.CompareValues("label"));

                let { relatedData } = this.state;
                relatedData.teamChannelsOptions = options;
                this.setState({ relatedData: relatedData });
            }
        });
    }

    getTeamMembers = async (callback) => {
        const { request } = this.state;
        const { teamChannelId } = request;

        if (CommonHelper.IsEmpty(teamChannelId)) {
            this.setState({ data: null, importedData: null });
            return null;
        }

        let result = await this.context.dataProvider.getList(`msteammembersbyteamchannel/${teamChannelId}`);

        if (result && result.data) {
            if (result.data.length === 0) {
                ToastHelper.Info(CommonHelper.GetTrans("msteamsimporter.messages.ms_team_members.no_data"))
            }

            this.setState({ data: result.data, importedData: null }, async () => {
                this.isLoaded();
                if (CommonHelper.IsFunction(callback))
                    callback();
            });
        }
    }

    setStore = (id, value) => {
        const { importedData } = this.state;
        importedData.filter(d => d.id === id)[0].store = value;
        this.setState({ importedData: importedData });
    }

    renderMSData = () => {
        let { data, updatingData } = this.state;

        if (CommonHelper.IsEmpty(data))
            return null;

        const columns = [
            { id: 'displayName', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.displayName"), type: 'string', filter: true },
            { id: 'givenName', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.givenName"), type: 'string', filter: true },
            { id: 'surname', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.surname"), type: 'string', filter: true },
            { id: 'mail', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.mail"), type: 'string', filter: true },
            { id: 'userPrincipalName', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.userPrincipalName"), type: 'string', filter: true },
            { id: 'type', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.type"), type: 'string', filter: true },
            { id: 'status', label: CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.status"), type: 'string', filter: true, size: 'small', align: 'center', width: 100 }
        ];

        data = data.map(d => {
            const status = this.getDataStatus(d);

            switch (status) {
                case 'equals':
                    d.bgColor = "#85caac";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.nothing");
                    break;
                case 'updated':
                    d.bgColor = "#e6d88e";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.to_update");
                    break;
                case 'removed':
                    d.bgColor = "#ea8585";
                    d.status = CommonHelper.GetTrans("msteamsimporter.status.to_delete");
                    break;
                default:
                    delete d.bgColor;
                    delete d.status;
                    break;
            }

            return d;
        })

        return (
            <Row className="h-100">
                <Col xs="12" className="h-100 d-flex justify-content-center align-items-center p-0 mt--2">
                    <EnhancedTable
                        innerRef={ref => (this.table = ref)}
                        updatingData={updatingData}
                        noBorderShadows
                        columns={columns}
                        data={data}
                        readOnly={true}
                        orderBy={orderTableBy}
                        handleLoading={this.isLoading}
                        handleLoaded={this.isLoaded}
                    />
                </Col>
            </Row>
        )
    }

    renderImportedMSData = () => {
        const { importedData, updatingData, msDataPage } = this.state;

        if (CommonHelper.IsEmpty(importedData))
            return null;

        const rowsPerPage = 4;

        const handleChangePage = (event, page) => {
            this.setState({ msDataPage: page });
        };

        return (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.displayName")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.givenName")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.surname")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.mail")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.userPrincipalName")}</TableCell>
                            <TableCell>{CommonHelper.GetTrans("msteamsimporter.fields.ms_team_members.type")}</TableCell>
                            <TableCell size="small" align="center">{CommonHelper.GetTrans("common.text.import")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {importedData.slice(msDataPage * rowsPerPage, msDataPage * rowsPerPage + rowsPerPage)
                            .map((data, key) => {
                                return (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">{data.id}</TableCell>
                                        <TableCell>{data.displayName}</TableCell>
                                        <TableCell>{data.givenName}</TableCell>
                                        <TableCell>{data.surname}</TableCell>
                                        <TableCell>{data.mail}</TableCell>
                                        <TableCell>{data.userPrincipalName}</TableCell>
                                        <TableCell>{data.type}</TableCell>
                                        <TableCell size="small" align="center" style={{ width: 100 }}>
                                            <Switch
                                                checked={data.store}
                                                onChange={() => this.setStore(data.id, !data.store)}
                                                value={data.store}
                                                color='primary'
                                                disabled={updatingData}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage={CommonHelper.GetTrans("common.table.pagination.labelRowsPerPage")}
                    component="div"
                    count={CommonHelper.IsEmpty(importedData) ? 0 : importedData.length}
                    rowsPerPageOptions={[rowsPerPage]}
                    rowsPerPage={rowsPerPage}
                    page={msDataPage}
                    backIconButtonProps={{
                        'aria-label': CommonHelper.GetTrans("common.table.pagination.previousPage"),
                    }}
                    nextIconButtonProps={{
                        'aria-label': CommonHelper.GetTrans("common.table.pagination.nextPage"),
                    }}
                    onChangePage={handleChangePage}
                />
            </TableContainer>
        );
    }

    getDataStatus = (data) => {
        const { importedData } = this.state;

        if (CommonHelper.IsEmpty(importedData)) {
            return null;
        }

        if (CommonHelper.IsEmpty(data)) {
            return "equals";
        }

        let newData = importedData.filter(d => d.id === data.id);

        if (newData.length === 0) {
            return "removed";
        }

        for (var propertyName in newData[0]) {
            let toUpdate = false;

            switch (propertyName) {
                case 'store':
                    toUpdate = false;
                    break;
                default:
                    toUpdate = newData[0][propertyName] !== data[propertyName];
                    break;
            }

            if (toUpdate)
                return "updated";
        }

        return "equals";
    }

    getMSData = async () => {
        const { data, request } = this.state;
        const { teamId } = request;

        if (CommonHelper.IsEmpty(teamId))
            return null;

        let allMsTeamMembers = [];

        let msTeamOwners = await Groups.GetOwners(teamId).catch(e => { if (e.authError) { ToastHelper.Info(e.errorMessage); } });

        if (!CommonHelper.IsEmpty(msTeamOwners)) {
            msTeamOwners = msTeamOwners.map(msTeamOwners => {
                delete msTeamOwners["@odata.type"];
                msTeamOwners.store = !CommonHelper.IsEmpty(data) && data.filter(d => d.id === msTeamOwners.id).length > 0;
                msTeamOwners.type = "Owner";
                return msTeamOwners;
            })
        }

        let msTeamMembers = await Groups.GetMembers(teamId).catch(e => { if (e.authError) { ToastHelper.Info(e.errorMessage); } });;;

        if (!CommonHelper.IsEmpty(msTeamMembers)) {
            msTeamMembers = msTeamMembers.reduce(function (result, msTeamMember) {
                if (msTeamOwners.filter(to => to.id === msTeamMember.id).length === 0) {
                    delete msTeamMember["@odata.type"];
                    msTeamMember.store = !CommonHelper.IsEmpty(data) && data.filter(d => d.id === msTeamMember.id).length > 0;
                    msTeamMember.type = "Member";
                    result.push(msTeamMember);
                }
                return result;
            }, []);
        }

        if (msTeamOwners.length > 0 || msTeamMembers.length > 0) {
            allMsTeamMembers = [...msTeamOwners, ...msTeamMembers];
            this.setState({ importedData: allMsTeamMembers }, this.checkToStore);
        }
        else {
            if (msTeamOwners !== undefined && msTeamMembers !== undefined) {
                ToastHelper.Info(CommonHelper.GetTrans("msteamsimporter.messages.ms_team_members.not_found"));
            }
        }
    }

    checkToStore = () => {
        let { data, importedData } = this.state;
        importedData = importedData.map(id => {
            if (!CommonHelper.IsEmpty(data)) {
                id.store = data.filter(d => d !== null && d.id === id.id && this.getDataStatus(d) !== "equals").length > 0;
            }
            return id;
        })
        this.setState({ importedData });
    }

    resetMSData = () => {
        this.setState({ importedData: null });
    }

    syncMSData = async (removeOld = false) => {
        const { data, importedData, request } = this.state;
        const { teamId } = request;

        await Promise.all(importedData.map(async (msData) => {
            if (msData.store) {
                if (!CommonHelper.IsEmpty(data) && data.filter(d => d.id === msData.id).length > 0) {
                    msData = { ...data.filter(d => d.id === msData.id)[0], ...msData };
                }

                if (CommonHelper.IsEmpty(msData.createdAt)) {
                    msData.msteamMemberMsteamChannels = [{ teamChannelId: teamId, type: msData.type }];
                    await this.context.dataProvider.create(baseApi, { data: msData });
                }
                else {
                    msData.msteamMemberMsteamChannels = [{ teamChannelId: teamId, teamMemberId: msData.id, type: msData.type }];
                    await this.context.dataProvider.update(baseApi, { id: msData.id, data: msData });
                }
            }
        }));

        if (removeOld) {
            await Promise.all(data.map(async (d) => {
                if (importedData.filter(id => id.id === d.id).length === 0) {
                    await this.context.dataProvider.delete(baseApi, { id: d.id });
                }
            }));
        }

        this.setState({ updatingData: false }, () => {
            this.getTeamMembers();
        })
    }

    canSync = () => {
        const { updatingData, data, importedData } = this.state;

        return (
            !updatingData &&
            importedData && (
                importedData.filter(d => d.store).length > 0 ||
                data && data.filter(d => importedData.filter(id => id.id === d.id).length === 0).length > 0)
        )
    }

    render() {
        const { updatingData, importedData, relatedData, request } = this.state;
        const { teamId, teamChannelId } = request;
        const { classes } = this.props;

        return (
            <Container className="py-2 px-4 mw-100">
                {
                    !importedData &&
                    <ValidatorForm onSubmit={() => false} onError={errors => console.error(errors)} className="w-100 mb-3">
                        <Row className="no-gutters">
                            <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <SelectExt name="teamId" label="Teams" options={relatedData.teamsOptions} onChange={this.onHandleChange} value={teamId} disabled={updatingData} />
                            </Col>
                            <Col xs="4" className="no-gutters d-flex justify-content-center align-items-center pr-2">
                                <SelectExt name="teamChannelId" label="Team Channels" options={relatedData.teamChannelsOptions} onChange={this.onHandleChange} value={teamChannelId} disabled={updatingData} />
                            </Col>
                        </Row>
                    </ValidatorForm>
                }
                <Row className="py-2 row bg-light rounded row">
                    {
                        !importedData ?
                            <>
                                <Col xs="12" className="text-center">
                                    <Button className={classNames(classes.button, 'm-0')} variant="contained" color="success" onClick={this.getMSData} disabled={updatingData || CommonHelper.IsEmpty(teamChannelId)}>{CommonHelper.GetTrans("buttons.getdatafromMS")}</Button>
                                </Col>
                            </> :
                            <>
                                <Col xs="12">
                                    {this.renderImportedMSData()}
                                </Col>
                                <Col xs="12" className="pt-3 text-center">
                                    <Button className={classes.button} variant="contained" color="danger" onClick={this.resetMSData} disabled={updatingData}>{CommonHelper.GetTrans("buttons.cancel")}</Button>
                                    <Button className={classes.button} variant="contained" color="success" onClick={() => this.syncMSData()} disabled={!this.canSync()}>{CommonHelper.GetTrans("buttons.sync")}</Button>
                                    <Button className={classes.button} variant="contained" color="success" onClick={() => this.syncMSData(true)} disabled={!this.canSync()}>{CommonHelper.GetTrans("buttons.syncAndRemove")}</Button>
                                </Col>
                            </>
                    }
                </Row>
                {this.renderMSData()}
            </Container>
        );
    }
}

export default withStyles(styles)(ImportMSTeamMembers); 