import CommonHelper from "./CommonHelper";
import ToastHelper from "./ToastHelper";

import authProvider, { UNAUTHORIZED, AUTHORIZED, ELAPSED } from "authProvider";

class FetchHelper {

    static async DoFetch(apiName, method, formData, successCallback, errorCallback, isJson = true, includeFiles = false, blobResp = false) {
        try {
            let authResponse = await authProvider.checkAuth().then((response) => response).catch((response) => response);

            if (authResponse.status !== AUTHORIZED) {
                return false;
            }

            const apiUri = `${CommonHelper.GetApiDomain()}/${apiName}`;
            const token = CommonHelper.GetToken();

            let headers = new Headers();
            const request = {};

            if (!CommonHelper.IsEmpty(token))
                headers.append('Authorization', 'Bearer ' + token);

            if (isJson)
                headers.append('Content-Type', 'application/json');

            request.method = method;
            request.headers = headers;

            if (formData)
                request.body = isJson ? JSON.stringify(formData) : buildFormData(formData, includeFiles);

            return doFetch(apiUri, request, successCallback, errorCallback, blobResp);
        }
        catch (e) {
            errorCallback({ message: e.message });
        }
    }

    static async DoFetchAnonymous(apiName, method, formData, successCallback, errorCallback, isJson = true, includeFiles = false, blobResp = false) {
        const apiUri = `${CommonHelper.GetApiDomain()}/${apiName}`;

        let headers = new Headers();
        const request = {};

        if (isJson)
            headers.append('Content-Type', 'application/json');

        request.method = method;
        request.headers = headers;

        if (formData)
            request.body = isJson ? JSON.stringify(formData) : buildFormData(formData, includeFiles);

        return doFetch(apiUri, request, successCallback, errorCallback, blobResp);
    }

    static async DoFetchExternal(apiName, method, formData, successCallback, errorCallback, isJson = true, includeFiles = true, blobResp = false) {
        const apiUri = apiName;

        const request = {},
            headers = new Headers();

        request.method = method;

        if (isJson)
            headers.append('Content-Type', 'application/json');

        if (formData) 
            request.body = isJson ? JSON.stringify(formData) : buildFormData(formData, includeFiles);

        request.headers = headers;

        return doFetch(apiUri, request, successCallback, errorCallback, blobResp);
    }

    static async DoMultipleFetch(fetchList) {
        fetchList = fetchList.map(fl => {
            const { apiName, method, formData, isJson, includeFiles } = fl;
            
            const apiUri = `${CommonHelper.GetApiDomain()}/${apiName}`;
            const token = CommonHelper.GetToken();

            let headers = new Headers();
            const request = {};

            if (!CommonHelper.IsEmpty(token))
                headers.append('Authorization', 'Bearer ' + token);

            if (isJson)
                headers.append('Content-Type', 'application/json');

            request.method = method;
            request.headers = headers;

            if (formData)
                request.body = isJson ? JSON.stringify(formData) : buildFormData(formData, includeFiles);

            return { url: apiUri, request, successCallback: fl.successCallback, errorCallback: fl.errorCallback };
        });

        return multipleDoFetch(fetchList);
    }

    static async DoFetchWithProgress(params) {
        // WORK IN PROGRESS

        function consume(response) {
            if (!response.ok) {
                throw Error(response.status + ' ' + response.statusText)
            }

            // ensure ReadableStream is supported
            if (!response.body) {
                throw Error('ReadableStream not yet supported in this browser.')
            }

            // store the size of the entity-body, in bytes
            const contentLength = response.headers.get('content-length');

            // ensure contentLength is available
            if (!contentLength) {
                throw Error('Content-Length response header unavailable');
            }

            // parse the integer into a base-10 number
            const total = parseInt(contentLength, 10);
            var loaded = 0;
            return new Response(

                // create and return a readable stream
                new ReadableStream({
                    start(controller) {
                        const reader = response.body.getReader();

                        read();
                        function read() {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                loaded += value.byteLength;
                                console.log(Math.round(loaded / total * 100) + '%');
                                controller.enqueue(value);
                                read();
                            }).catch(error => {
                                console.error(error);
                                controller.error(error)
                            })
                        }
                    }
                })
            );
        }

        // fetch("https://fetch-progress.anthum.com/30kbps/images/sunrise-baseline.jpg")
        //     .then(res => { return consume(res); })
        //     .then(response => console.log(response))
        //     .catch(e => console.log("something went wrong: " + e));

        const request = async () => {
            const response = await fetch("https://fetch-progress.anthum.com/30kbps/images/sunrise-baseline.jpg").then(res => { return consume(res); }).then(response => response);
            const json = await response.json();
            console.log(json);
        }

        request();
    }
}

function fetchResponse(response) {
    if (response.ok) {
        return response;
    }
    throw response;
}

function fetchResponseBlob(response) {
    try {
        return response.blob();
    }
    catch{
        throw response;
    }
}

async function manageError(error) {
    if (error.message !== undefined && error.message.includes('Failed to fetch')) {
        ToastHelper.Error("API non disponibile");
        // cookies.remove('token', { path: '/' });
        // cookies.remove('customer_key', { path: '/' });
        console.log(error.message);
        return null;
    }
    else {
        let result = await error.json().then(data => {
            if (data.msg)
                return data.msg;
            else {
                try {
                    return parseError(data);
                }
                catch{
                    return catchError(data);
                }
            }
        }).catch((err) => { return catchError(err); });
        return result;
    }
}

async function doFetch(apiUri, request, successCallback, errorCallback, blobResp) {
    try {
        let response = await fetch(apiUri, request);
        let succResult = await fetchResponse(response);

        if (blobResp) {
            let responseBlob = await fetchResponseBlob(succResult);
            return successCallback(responseBlob, succResult);
        }
        else {
            return successCallback(succResult);
        }
    }
    catch (error) {
        let errResult = await manageError(error);
        return errorCallback(errResult);
    }
};

async function multipleDoFetch(fetchList) {
    return Promise.all(
        fetchList.map(async f => {
            try {
                let response = await fetch(f.url, f.request);
                let succResult = await fetchResponse(response);
                return CommonHelper.IsFunction(f.successCallback) ? f.successCallback(succResult) : true;
            }
            catch (error) {
                let errResult = await manageError(error);
                return CommonHelper.IsFunction(f.errorCallback) ? f.errorCallback(errResult) : false;
            }
        })
    );
}

function parseError(resp) {
    let myError = null;
    if (!CommonHelper.IsEmpty(resp.success) && !resp.success) {
        if (resp.errors !== undefined && resp.errors.length > 0) {
            if (resp.errors[0] === 'Incorrect password.')
                myError = "Vecchia password non corretta";
        }
    }
    else if (!CommonHelper.IsEmpty(resp.errors)) {
        myError = resp;
    }
    else if (!CommonHelper.IsEmpty(resp.message)) {
        myError = resp.message;
    }
    else if (resp.code !== undefined) {
        if (resp.code === "login_failure")
            myError = resp.description;
        else
            myError = resp;
    }
    else if (resp[0].code !== undefined) {
        if (resp[0].code === "login_failure")
            myError = resp[0].description;
        else
            myError = resp;
    }
    console.log(myError);
    return myError;
};

function catchError(resp) {
    let myError = null;
    if ((resp.status === 401 || resp.status === 500) && resp.url.substr(resp.url.lastIndexOf('/') + 1) === 'login')
        myError = 'Username e/o Password non corretti';
    else if (resp.status === 401) {
        myError = "Richiesta non autorizzata";
    }
    else if (resp.status === 403) {
        myError = "Non si possiedono i requisiti";
    }
    else if (resp.status === 404) {
        myError = "La risorsa richiesta non esiste";
    }
    else
        myError = resp;

    console.log(myError);
    return myError;
};

function buildFormData(formData, includeFiles) {
    var data = new FormData();

    let distinctFormData = CommonHelper.Distinct(Object.keys(formData).map(key => { return { key: key, value: formData[key] }; }), "key");

    distinctFormData.forEach(dfd => {
        if (CommonHelper.IsArray(dfd.value)) {
            dfd.key = `${dfd.key}[]`;

            dfd.value.forEach(val => {
                if (val !== null && val !== undefined) {
                    if (CommonHelper.IsFile(val)) {
                        if (includeFiles)
                            data.append(dfd.key, val, val.name);
                    }
                    else {
                        let name = dfd.key;
                        let value = val;

                        if (CommonHelper.IsBoolean(value))
                            value = JSON.parse(value) ? 1 : 0;

                        if (CommonHelper.IsObject(value))
                            value = JSON.stringify(value);

                        data.append(name, value);
                    }
                }
            });
        }
        else {
            if (dfd.value !== null && dfd.value !== undefined) {
                if (CommonHelper.IsFile(dfd.value)) {
                    if (includeFiles)
                        data.append(dfd.key, dfd.value, dfd.value.name);
                }
                else {
                    let name = dfd.key;
                    let value = dfd.value;

                    if (CommonHelper.IsBoolean(value))
                        value = JSON.parse(value) ? 1 : 0;

                    if (CommonHelper.IsObject(value))
                        value = JSON.stringify(value);

                    data.append(name, value);
                }
            }
        }
    });

    return data;
};

export default FetchHelper;