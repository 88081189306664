import common from "./common.json";
import buttons from "./buttons.json";
import validator from "./validator.json";
import auth from "./auth.json";

import surveys from "./surveys.json";
import questions from "./questions.json";
import answers from "./answers.json";
import botconfigurations from "./botconfigurations.json";
import reports from "./reports.json";
import msteamsimporter from "./msteamsimporter.json";

export default {
    common: common,
    buttons: buttons,
    validator: validator,
    auth: auth,

    surveys: surveys,
    questions: questions,
    answers: answers,
    botconfigurations: botconfigurations,
    reports: reports,
    msteamsimporter: msteamsimporter
}