import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import classNames from 'classnames';
import { CommonHelper } from 'components/Common/Helper/Helper';

import { TeamsContext, ButtonTooltip, answerTypeEnum } from "libs/teams-tab";

import { Flex, RadioGroup, Checkbox, AcceptIcon, } from '@fluentui/react-northstar'
import { mergeStyleSets } from 'office-ui-fabric-react';

const customClass = mergeStyleSets({
    answer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
        '&.sent': {
            color: '#FFFFFF',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'none',
            },
            '&.sent *': {
                color: '#FFFFFF',
                cursor: 'default',
            },
        }
    },
});

const Choice = ({ answers, userAnswer, changeAnswer, sent }) => {
    return answers.map((answer, key) => {
        const checked = userAnswer && answer.id === userAnswer.answerId;

        if (checked) {
            console.info(answer.id, checked)
        }

        return (
            <Flex key={key} gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.answer, sent ? 'sent' : '')} >
                <Checkbox disabled={sent} label={answer.text} value={answer.id} checked={checked} onChange={(e, el) => changeAnswer(answer.id, el.checked)} />
            </Flex>
        )
    })
}

const MultipleChoice = ({ answers, userAnswers, changeAnswer, sent }) => {
    let selected = answers.find(a => a !== null && userAnswers && userAnswers.filter(ua => ua.answerId === a.id).length > 0);
    return (
        <Flex gap="gap.medium" padding="padding.medium" vAlign="center" className={classNames(customClass.answer, sent ? 'sent' : '')} >
            <RadioGroup
                defaultCheckedValue={selected && selected.id}
                items={answers.map((answer, key) => { return { key: key, label: answer.text, value: answer.id, disabled: sent } })} />
        </Flex>
    )
}

const Answers = ({ question, sent }) => {
    const teamsContext = useContext(TeamsContext);
    const [answers, setAnswers] = useStateWithCallbackLazy([]);
    const [userAnswer, setUserAnswer] = useState(null);
    
    useEffect(() => {
        if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            setAnswers(question.surveyAnswers.sort(CommonHelper.CompareValues("order")))
        }
    }, [question.surveyAnswers]);

    useEffect(() => {
        if (question.surveyTeamMemberAnswers && question.surveyTeamMemberAnswers.length > 0) {
            let _userAnswer = null;

            switch (question.type) {
                case answerTypeEnum.Text:
                default:
                    break;
                case answerTypeEnum.SingleChoice:
                    _userAnswer = question.surveyTeamMemberAnswers.find(stma => stma.teamMemberId === teamsContext.user.id);
                    break;
                case answerTypeEnum.MultipleChoice:
                    _userAnswer = question.surveyTeamMemberAnswers.filter(stma => stma !== null && stma.teamMemberId === teamsContext.user.id);
                    break;
            }
            setUserAnswer(_userAnswer);
        }
    }, [question.surveyTeamMemberAnswers]);

    const changeAnswer = (id, checked) =>{
        console.info(id, checked);

        // const _userAnswer = {
        //     answerId: answer.id,
        //     callSurveyActivityId: "17ca4f54-1ab8-4162-af3c-e14ab51d717c",
        //     correct: answer.correct,
        //     questionId: answer.questionId,
        //     teamMemberId: "88ed4df9-75ae-4b9a-9168-9698226bc279",
        //     weight: answer.weight
        // }
    }

    const mounted = useRef();
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (JSON.stringify(question.surveyAnswers) !== JSON.stringify(answers)) {
            }
        }
    });

    const renderAnswers = () => {
        if (answers.length > 0) {
            let answerCmp = null;

            switch (question.type) {
                case answerTypeEnum.Text:
                default:
                    break;
                case answerTypeEnum.SingleChoice:
                    answerCmp = <Choice answers={answers} userAnswer={userAnswer} changeAnswer={changeAnswer} />
                    break;
                case answerTypeEnum.MultipleChoice:
                    answerCmp = <MultipleChoice answers={answers} userAnswers={userAnswer} changeAnswer={changeAnswer} />
                    break;
            }
            return answerCmp ? React.cloneElement(answerCmp, { sent }): null;
        }
    }


    return (
        <Flex gap="gap.medium" padding="padding.medium" column>
            {
                renderAnswers(answers)
            }
        </Flex>
    )
}

export default Answers;