import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoaderExt extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <CircularProgress className={classes.progress} />
      </div>
    )
  }
}

export default withStyles(styles)(LoaderExt);