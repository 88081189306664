/* eslint-disable no-unused-vars */

import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './theme';

// react plugin that creates text editor
import { Editor } from '@tinymce/tinymce-react';

const apiKey = 'u0q8a2a6a3np1vlzuyo79q6vbdtu4onhqpuhjplq822rmvhb';

// const config = {
//     //plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help quickbars',
//     //images_upload_url: `${CommonHelper.GetApiDomain()}/TinyMCE/UploadFile`,
//     //images_reuse_filename: true,
//     //images_upload_base_path: `${CommonHelper.GetFtpUrl()}/`,
//     //image_advtab: true,
//     //image_caption: true,
//     //menubar: 'file edit view insert format tools table tc help',
//     //toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
//     height: 200,
//     plugins: [
//         'advlist autolink lists link charmap print preview anchor',
//         'searchreplace visualblocks fullscreen',
//         'insertdatetime media paste code help wordcount'
//     ],
//     contextmenu: false,
//     menubar: false,
//     toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | numlist bullist checklist | casechange formatpainter removeformat | fullscreen preview',
// };

class TinyMceExt extends React.Component {

    constructor(props) {
        super();

        this.onHandleChange = this.onHandleChange.bind(this);

        this.state = {
            config: {
                height: CommonHelper.IsEmpty(props.height) ? 200 : props.height,
                plugins: [
                    'advlist autolink lists link charmap print preview anchor',
                    'searchreplace visualblocks fullscreen',
                    'insertdatetime media paste code help wordcount'
                ],
                contextmenu: false,
                menubar: false,
                toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | numlist bullist checklist | casechange formatpainter removeformat | fullscreen preview',
            }
        };
    }

    onHandleChange = (content, editor) => {
        const { name } = this.props;
        const value = content;

        if (CommonHelper.IsFunction(this.props.onChange))
            this.props.onChange(value, name, !CommonHelper.IsEmpty(this.props.callback) ? this.props.callback(value) : undefined);

        if (!CommonHelper.IsEmpty(this.props.handle) && this.props.handle.length > 0) {
            switch (this.props.type) {
                case 'number':
                    this.props.handle.map(h => this.props.onTrigger(CommonHelper.Compute(h.value, value, this.props.decimals, this.props.commons), h.field));
                    break;
                default:
                    this.props.handle.map(h => this.props.onTrigger(value, h.field));
                    break;
            }
        }
    }

    render() {
        const { config } = this.state;
        const {
            classes, callback, error, errorMessages, validators, requiredError, helperText,
            validatorListener, withRequiredValidator, symbol, pattern, patternTitle,
            min, max, step, onTrigger, triggers, onCommon, commons, decimals, value,
            label, name, ...rest
        } = this.props;

        return (
            <>
                {label && <label>{label}</label>}
                <Editor apiKey={apiKey} id={name} init={config} icons='material' initialValue={value} onEditorChange={this.onHandleChange} />
            </>
        );
    }
}

export default withStyles(styles)(TinyMceExt);