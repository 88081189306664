import React from 'react';
import classNames from 'classnames';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { getSchema } from './DataModels';
import { TextFieldExt, SelectExt, LoaderExt } from 'components/Common/MaterialUI-Ext/MaterialUI-Ext';
import EnhancedTabs from 'components/Common/EnhancedTabs/EnhancedTabs';

import AnswersType from '../AnswersType/AnswersType';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Form extends React.Component {

    constructor(props) {
        super();

        this.tabsRef = React.createRef();

        this.setRelatedData = this.setRelatedData.bind(this);

        this.state = {
            formData: props.formData,
            schema: getSchema(),
            mode: props.mode,
            ready: false
        };
    }

    componentDidMount() {
        this.setRelatedData();
        this.setMode();
    }

    componentDidUpdate(prevProps) {
        if (!CommonHelper.AreEquals(this.props, prevProps)) {
            if (!CommonHelper.AreEquals(this.props.relatedData, prevProps.relatedData)) {
                this.setRelatedData();
            }
        }
    }

    setRelatedData() {
        const { relatedData } = this.props;
        let { schema } = this.state;
        
        if (CommonHelper.IsArray(relatedData) && relatedData.length > 0) {
            relatedData.forEach(rd => {
                schema.fields = CommonHelper.MergeDeep(schema.fields, rd);
            });
        }

        this.setState({ schema: schema, ready: true });
    }

    setMode() {
        this.setState({ mode: this.props.mode });
    }

    onChange = (value, field, callback) => {
        let formData = { ...this.state.formData };
        let schema = { ...this.state.schema };
        let { form } = this.props;
        formData[field] = value;

        let _field = schema.fields[field];

        if (!CommonHelper.IsEmpty(_field.validators) && _field.validators.includes("serverValidation")) {
            if (_field.validators.length > 1) {
                _field.validators.splice(0, 1);
                _field.errorMessages.splice(0, 1);
            }
            else {
                delete _field.validators
                delete _field.errorMessages
            }
            form.isFormValid(false).then(valid => { });
        }

        this.setState({
            formData: formData,
            schema: schema
        });

        this.props.updateFormData(value, field, callback);
    }
    
    buildFormTabs = (formData, fields) => {
        let { updatingData } = this.props;

        const questionTab = (
            <>
                <Row>
                    <Col>
                        <SelectExt {...fields.surveyId} onChange={this.onChange} value={formData.surveyId} disabled={updatingData} />
                    </Col>
                    <Col>
                        <SelectExt {...fields.type} onChange={this.onChange} value={formData.type} disabled={updatingData} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFieldExt {...fields.text} onChange={this.onChange} value={formData.text} disabled={updatingData} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <TextFieldExt {...fields.timeFrame} onChange={this.onChange} value={formData.timeFrame} disabled={updatingData} />
                    </Col>
                </Row>
            </>
        );

        const answersTab = (
            <>
                <Row>
                    <Col>
                        <AnswersType {...fields.answers} onChange={this.onChange} value={formData.answers} disabled={updatingData} answersType={formData.type} />
                    </Col>
                </Row>
            </>
        );

        return [
            { title: CommonHelper.GetTrans("questions.tabs.question"), content: questionTab },
            { title: CommonHelper.GetTrans("questions.tabs.answers"), content: answersTab }
        ]
    }

    render() {
        const { serverErrorMessages } = this.props;
        const { formData, schema, ready } = this.state;
        const { fields } = schema;

        if (!CommonHelper.IsEmpty(serverErrorMessages)) {
            serverErrorMessages.forEach((serverErrorMessage) => {
                let field = fields[serverErrorMessage.field];
                if (!CommonHelper.IsEmpty(field.validators) && field.validators.length > 0 && !field.validators.includes("serverValidation")) {
                    field.validators.splice(0, 0, "serverValidation");
                    field.errorMessages.splice(0, 0, serverErrorMessage.message);
                }
                else {
                    field.validators = ["serverValidation"];
                    field.errorMessages = [serverErrorMessage.message];
                }
            })
        }

        return (
            !ready ?
                <Container fluid>
                    <Row className="justify-content-center">
                        <LoaderExt />
                    </Row>
                </Container> :
                <Container fluid>
                    <Row>
                        <Col>
                            <EnhancedTabs ref={this.tabsRef} tabs={this.buildFormTabs(formData, fields)} activeTab={0} />
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default Form; 