import React from 'react';
import { CommonHelper, FetchHelper, ToastHelper } from 'components/Common/Helper/Helper';
import { withStyles } from '@material-ui/core/styles';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { ValidatorForm } from 'react-material-ui-form-validator';

import classNames from 'classnames';
import _styles from './EnhancedModal.module.css';

const size = 'full'; //'lg';

const styles = theme => ({
  root: {
    boxShadow: 'none'
  },
});

class EnhancedModal extends React.Component {

  constructor(props) {
    super();

    this.form = React.createRef();
    
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    this.state = {
      isOpen: false,
      isEdit: (props.isEdit !== undefined) ? props.isEdit : false,
      loading: false
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('serverValidation', (value) => { return false; });
  }

  toggle(e) {
    if (!CommonHelper.IsEmpty(e))
      e.preventDefault();
      
    this.props.modal.openForm(this.props.isEdit, this.props.selectedData);
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      loading: false
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = this.form.current;
    form.isFormValid(false).then(valid => {
      if (valid) {
        this.props.modal.onSubmit(form, (leaveOpen) => {
          this.props.clearSelected();
          if (!leaveOpen)
            this.toggle(e);
        });
      }
    });
  }

  handleCancel = (e) => {
    e.preventDefault();
    this.props.modal.onCancel();
    this.toggle(e);
  }

  render() {
    const externalCloseBtn = <button className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.toggle}>&times;</button>;
    const { isOpen, isEdit  } = this.state;
    const { newTitle, editTitle, form, updatingData } = this.props.modal;
    const { classes } = this.props;

    const modalTooltip = (this.state.isEdit) ?
      <Tooltip title="Modifica" className="float-right" >
        <IconButton aria-label="Edit" onClick={this.toggle}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      :
      <Tooltip title="Aggiungi" className="float-right bg-white" >
        <IconButton aria-label="Add" onClick={this.toggle}>
          <AddIcon />
        </IconButton>
      </Tooltip>;

    let customClassNames = classNames(this.props.className, size === 'full' ? _styles.modalFullscreen : '');

    return (
      <>
        {modalTooltip}
        <Modal isOpen={isOpen} toggle={this.toggle} className={customClassNames} size={size === 'full' ? 'lg' : size} backdrop={updatingData ? 'static' : true} keyboard={!updatingData}>
          <ValidatorForm ref={this.form} onSubmit={this.handleSubmit} onError={errors => console.log(errors)}>
            <ModalHeader>
              {(isEdit) ? editTitle : newTitle}
              {externalCloseBtn}
            </ModalHeader>
            <ModalBody>
              {form}
            </ModalBody>
            <ModalFooter>
              <div className="text-right">
                <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={updatingData} loading={updatingData}>Salva</Button>
                <Button variant="contained" color="secondary" className={classes.button} onClick={this.handleCancel} disabled={updatingData}>Annulla</Button>
              </div>
            </ModalFooter>
          </ValidatorForm>
        </Modal>
      </>
    );
  }
}

export default withStyles(styles)(EnhancedModal); 